import React from 'react';
import {
  Box,
  Divider,
  Typography,
  Flex,
  Chip,
  styled,
  useTheme,
  useMediaQuery,
} from '@dripcapital/dripui';
import { ChipColor } from 'types';
import { useTranslation } from 'react-i18next';
import { Chip as NewChip } from '@dripcapital/dripui-v1/components/Chip';

type Props = {
  title: string;
  label?: string;
  chipLabel?: string;
  newChip?: boolean;
  chipColor?: ChipColor;
};

const DividerContainer = styled(Box)(({ theme }) => {
  return {
    margin: theme.spacing(0),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    width: 100,
  };
});

const Heading: React.FC<Props> = ({
  title,
  chipLabel,
  newChip = false,
  label,
  chipColor = 'info',
}: Props) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const smLimit = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Flex dataTestId="primary-header-element" direction="column">
      <Flex alignItems={'center'} spacing={3}>
        {/* TODO: Remove once Typography UI library responsive feature is added */}
        <Typography variant={smLimit ? 'h2' : 'h1'} fontWeight="bold">
          {title}
        </Typography>
        {chipLabel &&
          (newChip ? (
            <NewChip
              label={chipLabel}
              color={chipColor}
              size="medium"
              _sx={{
                div: {
                  width: 'max-content',
                },
              }}
            />
          ) : (
            <Chip
              label={chipLabel}
              color="secondary"
              _sx={{
                div: {
                  width: 'max-content',
                },
              }}
            />
          ))}
      </Flex>
      <DividerContainer>
        <Divider orientation="horizontal" variant="Primary" flexItem />
      </DividerContainer>
      {label && (
        <Typography variant="body1">
          {t('label_profile')}: {label}
        </Typography>
      )}
    </Flex>
  );
};

export default Heading;
