import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Grid, Link, Typography } from '@dripcapital/dripui';
import { useReactiveVar } from '@apollo/client';
import { LinkBoxWithTitle, NotifyLead, TalkToAgentCard } from 'components';
import { SideInformationType } from 'types';
import { SideCard, Spacer } from 'atoms';
import { appDataVar } from 'config/server';
import { ArrowForward, SettingsOutlined } from '@mui/icons-material';
import { DAJ_COLORS } from 'utils';

type NotifyLead = {
  mailDate: any;
  mailSent: boolean;
  show: boolean;
  refetchApplicationDetails: () => void;
};

type Props = {
  gridSize?: {
    xs?: number;
    md?: number;
  };
  dataList: SideInformationType[];
  notifyLeadSideCard?: NotifyLead;
};

const SideCardList: React.FC<Props> = ({
  dataList,
  gridSize,
  notifyLeadSideCard,
}: Props) => {
  const { t } = useTranslation();

  const { salesContactVar: salesContact } = useReactiveVar(appDataVar);

  const renderContent = (type: string, body: any) => {
    switch (type) {
      case 'agent':
        return <TalkToAgentCard email={salesContact?.email as string} />;
      case 'content':
        return (
          <Box>
            <Typography variant="body1" color="secondary">
              {t(`${body.content}`)}
            </Typography>
          </Box>
        );
      case 'features':
        return (
          <Box>
            <Typography variant="body1" color="secondary">
              {t(`${body.content}`)}
            </Typography>
            <Grid container>
              {Array.isArray(body?.featuresList) &&
                body.featuresList.map((feature: string, index: any) => (
                  <Grid key={index} item sm={6}>
                    <Typography
                      variant="body1"
                      color="secondary"
                      fontWeight="bold"
                    >
                      {t(`${feature}`)}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Box>
        );
      case 'list':
        return (
          <Box>
            <ul style={{ paddingLeft: 16, margin: 0 }}>
              {Array.isArray(body?.list) &&
                body.list.map((item: string, index: any) => (
                  <li key={index}>
                    <Typography variant="body1" color="secondary">
                      {t(`${item}`)}
                    </Typography>
                    <Spacer verticalSpace={1} />
                  </li>
                ))}
            </ul>
          </Box>
        );
      case 'assist':
        return (
          <>
            <Flex alignItems="center">
              <SettingsOutlined sx={{ marginRight: 1 }} />
              <Typography variant="h4" color="secondary" fontWeight="bold">
                {t(`${body.title}`)}
              </Typography>
            </Flex>
            <Flex
              alignItems="center"
              _sx={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(body.link, '_blank');
              }}
            >
              <Link _sx={{ marginRight: 0.4, color: DAJ_COLORS.GREEN }}>
                {t(`${body.label}`)}
              </Link>
              <ArrowForward
                sx={{ transform: 'rotate(-45deg)', color: DAJ_COLORS.GREEN }}
              />
            </Flex>
          </>
        );
      case 'contact':
        return (
          <Box>
            <Typography variant="body1" color="secondary" fontWeight="bold">
              {t(`${body.content}`)}
            </Typography>
            <Typography variant="body1" fontWeight="bold" color="primary">
              {body.email}
            </Typography>
          </Box>
        );
    }
  };

  return (
    <Grid
      container
      item
      rowSpacing={3}
      spacing={3}
      _sx={{ alignContent: 'flex-start', position: 'sticky', top: '84px' }}
    >
      {notifyLeadSideCard?.show && (
        <Grid
          dataTestId="side-card-list"
          md={gridSize?.md || 12}
          xs={gridSize?.xs || 12}
          item
        >
          <NotifyLead
            mailDate={notifyLeadSideCard.mailDate}
            mailSent={notifyLeadSideCard.mailSent}
            refetchApplicationDetails={
              notifyLeadSideCard.refetchApplicationDetails
            }
          />
        </Grid>
      )}
      {dataList &&
        Array.isArray(dataList) &&
        dataList.map((item, index) => (
          <Grid
            dataTestId="side-card-list"
            key={index}
            md={gridSize?.md || 12}
            xs={gridSize?.xs || 12}
            item
          >
            {item.body && (
              <SideCard
                title={item.heading}
                content={renderContent(item.type, item.body)}
              />
            )}
            {item.label && item.link && (
              <LinkBoxWithTitle
                link={item.link}
                icon={item.icon}
                label={item.label}
              />
            )}
          </Grid>
        ))}
    </Grid>
  );
};

export default SideCardList;
