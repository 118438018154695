import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Flex,
  Divider,
  Box,
  useTheme,
  useMediaQuery,
} from '@dripcapital/dripui';
import { useReactiveVar } from '@apollo/client';
import { Help } from '@mui/icons-material';
import { AtomicButton } from 'atoms';
import {
  HelpNotifications,
  TranslationMenu,
  UserProfileMenu,
} from 'components';
import { appDataVar } from 'config/server';
import { ButtonTypeEnum } from 'enums';
import { UserProfile } from 'api';
import { ONBOARDING_STAGES } from 'utils';
import logo from 'assets/drip-logo.png';

type Props = {
  userData?: UserProfile;
  logout: () => void;
  paths: {
    [key: string]: string;
  };
  goToPath: (path: string) => void;
};

const AppHeader: React.FC<Props> = ({
  userData,
  logout,
  paths,
  goToPath,
}: Props) => {
  const { spacing, breakpoints } = useTheme();

  const {
    isLoggedInVar: isLoggedIn,
    regionVar,
    isBDMVar: isBDM,
    isChannelPartnerVar: isCP,
    onboardingStageVar: onboardingStage,
  } = useReactiveVar(appDataVar);

  const isDesktops = useMediaQuery(breakpoints.up('sm'));

  const isNotMobile = useMediaQuery(breakpoints.up('md'));

  const padHeader = useMediaQuery(breakpoints.up('lg'));

  const { t } = useTranslation();

  const [openHelp, setOpenHelp] = useState(false);

  const openLink = () => {
    if (isCP || isBDM) {
      paths.applicants && goToPath(paths.applicants);
    } else {
      if (regionVar == 'US') {
        // paths.onboard && goToPath(paths.onboard);
      } else {
        // for IN, MX
        if (onboardingStage !== ONBOARDING_STAGES.OFFER_ACCEPTANCE_PENDING) {
          paths.accepted && goToPath(paths.accepted);
        }
      }
    }
  };

  const onCloseDrawer = () => {
    setOpenHelp(false);
  };

  return (
    <AppBar
      dataTestId="app-header"
      position={isDesktops ? 'fixed' : 'absolute'}
      _toolbarSx={{
        paddingX: padHeader ? '32px!important' : '0px!important',
      }}
      _sx={{ minHeight: 60 }}
    >
      <Flex
        direction="row"
        alignItems="center"
        justifyContent={'space-between'}
        _sx={{ marginX: padHeader ? 4 : 2, width: '100%' }}
      >
        <Flex
          dataTestId="drip-logo"
          onClick={() => openLink()}
          _sx={{ cursor: 'pointer' }}
        >
          <img src={logo} alt="drip" />
        </Flex>
        <Flex>
          {!isCP && (
            <>
              {isLoggedIn && <TranslationMenu />}
              <Box _sx={{ padding: isNotMobile ? 1 : 0.8 }} />
              <Divider
                orientation="vertical"
                variant="Tertiary"
                weight={1}
                _sx={{
                  height: spacing(4.5),
                  margin: 'auto',
                }}
                flexItem
              />
              <Box _sx={{ padding: isNotMobile ? 1 : 0.8 }} />
              {isNotMobile ? (
                <AtomicButton
                  dataTestId="help-drawer-button"
                  variant={ButtonTypeEnum.TEXT}
                  startIcon={<Help />}
                  onClick={() => setOpenHelp(true)}
                  _sx={{ display: 'flex' }}
                >
                  {t('btn_help')}
                </AtomicButton>
              ) : (
                <Flex
                  dataTestId="help-drawer-button"
                  alignItems={'center'}
                  onClick={() => setOpenHelp(true)}
                >
                  <Help />
                </Flex>
              )}
            </>
          )}
          <HelpNotifications open={openHelp} onCloseDrawer={onCloseDrawer} />
          {isLoggedIn && (
            <>
              <Box _sx={{ padding: isNotMobile ? 1 : 0.8 }} />
              {!isCP && (
                <Divider
                  orientation="vertical"
                  variant="Tertiary"
                  weight={1}
                  _sx={{
                    height: spacing(4.5),
                    margin: 'auto',
                  }}
                  flexItem
                />
              )}
              <Box _sx={{ padding: isNotMobile ? 1 : 0.8 }} />
              <UserProfileMenu userData={userData} logout={logout} />
            </>
          )}
        </Flex>
      </Flex>
    </AppBar>
  );
};

export default AppHeader;
