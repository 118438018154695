import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Typography,
  theme,
  Chip,
  Grid,
  Box,
  COLORS,
} from '@dripcapital/dripui';
import { saveAs } from 'file-saver';
import { BorderCardContainer, Tab } from 'atoms';
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Download as DownloadIcon,
  FolderOpen as FolderOpenIcon,
} from '@mui/icons-material';
import { AuthenticationStatusType } from 'types';
import { AuthenticationStatusCardEnum } from 'enums';
import { File } from 'api';
import { DAJ_COLORS, STYLES } from 'utils';

type Props = {
  title: string;
  subtitle?: string;
  status?: AuthenticationStatusCardEnum;
  roles?: string[];
  file?: File[];
  statusText?: string;
  showUploadedFiles?: boolean;
};

const AuthenticationStatusCard: React.FC<Props> = ({
  title,
  subtitle,
  status,
  roles,
  file,
  statusText,
  showUploadedFiles = true,
}) => {
  const { t } = useTranslation();

  const authenticationStatus: AuthenticationStatusType = {
    pending: {
      text: statusText || t('label_pending'),
      icon: <ErrorIcon color="warning" />,
    },
    progress: {
      text: statusText || t('label_progress'),
      icon: <ErrorIcon sx={{ color: COLORS.SECONDARY }} />,
    },
    complete: {
      text: statusText || t('label_complete'),
      icon: <CheckCircleIcon sx={{ color: DAJ_COLORS.SPRING_GREEN }} />,
    },
  };

  const handleDownloadFile = (uploadedFile: File) => {
    saveAs(
      uploadedFile?.url ?? '',
      t('mrfi_download_report', { fileName: uploadedFile.name })
    );
  };

  const renderUploadedFiles = () => {
    return (
      <>
        {file &&
          Array.isArray(file) &&
          file.map((upload, index) => (
            <Flex alignItems={'center'} _sx={{ marginTop: 2 }} key={index}>
              <FolderOpenIcon />
              <Typography
                variant="subtitle1"
                _sx={{
                  marginLeft: 1,
                  ...STYLES.textOverflow,
                }}
              >
                {upload?.name || t('phrase_no_document_uploaded')}
              </Typography>
            </Flex>
          ))}
      </>
    );
  };

  const renderDownloadLinks = () => {
    return (
      <>
        {file &&
          Array.isArray(file) &&
          file.map((upload, index) => {
            if (upload.url) {
              return (
                <Grid container justifyContent={'end'} key={index}>
                  <Grid item>
                    <Tab
                      label=""
                      buttonLabel={t('btn_download')}
                      Icon={<DownloadIcon />}
                      callback={() => {
                        handleDownloadFile(upload);
                      }}
                    />
                  </Grid>
                </Grid>
              );
            } else return <></>;
          })}
      </>
    );
  };

  return (
    <BorderCardContainer dataTestId="auth-status-card">
      <Flex
        _sx={{ margin: '0 8px' }}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
      >
        <Box _sx={{ width: '70%' }}>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            noWrap
            _sx={{ wordBreak: 'break-all' }}
          >
            {title}
          </Typography>
          {subtitle && <Typography>{subtitle}</Typography>}
          {roles && (
            <Flex spacing={1} _sx={{ marginTop: theme.spacing(1) }}>
              {roles?.map((role: string, index) => (
                <Chip key={index} label={t(`${role}`)} />
              ))}
            </Flex>
          )}
          {showUploadedFiles &&
            file &&
            Array.isArray(file) &&
            renderUploadedFiles()}
        </Box>
        <Flex direction={'column'} spacing={2} alignSelf="center">
          {status ? (
            <Flex spacing={1} alignItems={'center'}>
              <>{authenticationStatus[status].icon}</>
              <Typography fontWeight="bold">
                {authenticationStatus[status].text}
              </Typography>
            </Flex>
          ) : (
            <Flex spacing={1} alignItems={'center'} _sx={{ margin: 1.6 }} />
          )}
          {file && Array.isArray(file) && renderDownloadLinks()}
        </Flex>
      </Flex>
    </BorderCardContainer>
  );
};

export default AuthenticationStatusCard;
