import React, { useContext } from 'react';
import {
  Button as ButtonComponent,
  CssOverrideType,
  useMediaQuery,
  useTheme,
} from '@dripcapital/dripui';
import { AnalyticsContext } from 'shared/context/AnalyticsContext';
import { ButtonTypeEnum } from 'enums';
import { capitalizeFirst } from 'utils';

type Props = CssOverrideType & {
  dataTestId?: string;
  id?: string;
  loading?: boolean;
  type?: string;
  children: React.ReactNode;
  variant?: ButtonTypeEnum | undefined;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  component?: React.ElementType<any>;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const AtomicButton: React.FC<Props> = ({
  dataTestId = 'button-element',
  id,
  children,
  loading,
  type = 'button',
  disabled,
  variant = ButtonTypeEnum.CONTAINED,
  startIcon,
  endIcon,
  _sx,
  className,
  onClick,
  component,
}: Props) => {
  const theme = useTheme();

  const { mixpanel } = useContext(AnalyticsContext);

  const padding = theme.spacing(1.5);

  const smLimit = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (dataTestId && dataTestId !== 'button-element') {
      mixpanel.track(dataTestId);
    }
    onClick(e);
  };

  return (
    <ButtonComponent
      dataTestId={dataTestId}
      id={id}
      type={type}
      loading={loading}
      className={className}
      variant={variant}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      _sx={{
        paddingTop: padding,
        paddingBottom: padding,
        width: smLimit ? '100%' : 'initial',
        fontSize: theme.spacing(smLimit ? 2 : 2.5),
        textTransform: 'none',
        borderRadius: theme.spacing(1),
        ..._sx,
      }}
      onClick={handleOnClick}
      component={component}
    >
      {typeof children == 'string' ? capitalizeFirst(children) : children}
    </ButtonComponent>
  );
};

export default AtomicButton;
