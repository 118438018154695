/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Typography,
  Flex,
  LinearProgress,
  useTheme,
  Box,
} from '@dripcapital/dripui';
import { useTranslation } from 'react-i18next';
import { Heading, Spacer } from 'atoms';
import { IMG_URLS } from 'utils';
import { Timer } from 'types';

type Props = {
  title: string;
  heading: string;
  subHeading: string;
  timeoutHandler: () => void;
};

type ProgressBarType = {
  count: number;
  interval: number;
  img: string;
  msg: string;
  percent: number;
};

const OfferLoading: React.FC<Props> = ({
  title,
  heading,
  subHeading,
  timeoutHandler,
}: Props) => {
  const theme = useTheme();

  const { t } = useTranslation();

  const [progressBarProps, setProgressBarProps] = useState<ProgressBarType>({
    count: 0,
    interval: 13000,
    img: IMG_URLS.PROGRESS_IMG_1,
    msg: t('bep_loading_text1'),
    percent: 0,
  });

  useEffect(() => {
    let timer: Timer | number = setTimeout(
      function progress(progressBarProps: ProgressBarType) {
        let newProgressBarProps = progressBarProps;
        switch (progressBarProps.count) {
          case 0:
            newProgressBarProps = {
              ...newProgressBarProps,
              interval: 10000,
              img: IMG_URLS.PROGRESS_IMG_2,
              msg: t('bep_loading_text2'),
              percent: 35,
              count: 1,
            };
            break;
          case 1:
            newProgressBarProps = {
              ...newProgressBarProps,
              interval: 5000,
              img: IMG_URLS.PROGRESS_IMG_3,
              msg: t('bep_loading_text3'),
              percent: 70,
              count: 2,
            };
            break;
          case 2:
            newProgressBarProps = {
              ...newProgressBarProps,
              interval: 2000,
              percent: 99.99,
              count: 3,
            };
            break;
        }

        if (progressBarProps.count !== 3) {
          setProgressBarProps(() => {
            return newProgressBarProps;
          });
          timer = setTimeout(
            progress,
            newProgressBarProps.interval,
            newProgressBarProps
          );
        } else {
          clearTimeout(timer as Timer);
          timeoutHandler();
        }
      },
      progressBarProps.interval,
      progressBarProps
    );

    return () => {
      clearTimeout(timer as Timer);
    };
  }, []);

  return (
    <Box dataTestId="offer-loading-screen">
      <Spacer verticalSpace={3} />
      <Heading title={title} />
      <Spacer verticalSpace={6} />
      <Typography variant="h2" color="secondary" fontWeight="bold">
        {heading}
      </Typography>
      <Typography variant="subtitle1">{subHeading}</Typography>
      <Spacer verticalSpace={6} />
      <Flex
        _sx={{
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src={progressBarProps.img}
          alt=""
          width={theme.spacing(40)}
          height={'100%'}
        />
        <Typography variant="h4" _sx={{ marginTop: 4 }}>
          {progressBarProps.msg}
        </Typography>
      </Flex>
      <Spacer verticalSpace={6} />
      <LinearProgress
        variant="determinate"
        _sx={{ height: '20px' }}
        value={progressBarProps.percent}
      />
    </Box>
  );
};

export default OfferLoading;
