import React from 'react';
import { Box, Grid, Typography } from '@dripcapital/dripui';
import { Heading, MainContainer, Spacer, Alert } from 'atoms';
import {
  WhatsNext,
  CustomerContactList,
  SideCardList,
  OfferInfoSection,
} from 'components';
import {
  AccordionType,
  AlertType,
  ButtonType,
  CellsType,
  CustomerContactInfo,
  HeadingType,
  SeverityStatus,
  SideInformationType,
} from 'types';

type Props = {
  heading: {
    title: string;
    chipLabel?: string;
  };
  pageAlert?: AlertType;
  topSection?: {
    title?: string;
    body?: string;
    list?: string[];
    button_title?: string;
    startOnboardingHandler: () => void;
  };
  centerSection: {
    title?: string;
    body?: string;
    contentAlert?: AlertType;
    alerts?: {
      title: string;
      body?: string;
    };
    table?: {
      headings?: HeadingType[];
      cells?: CellsType[];
    };
    accordionItems?: AccordionType[];
    buttons?: ButtonType[];
    cardsTitle?: string;
    informationCards?: SideInformationType[];
  };
  bottomSection?: {
    title?: string;
    body?: string;
    button_title?: string;
    startOnboardingHandler: () => void;
  };
  contactsList?: {
    title: string;
    contacts: CustomerContactInfo[];
  };
  sideInformation?: SideInformationType[];
  children?: React.ReactNode;
  dataTestId?: string;
};

const OfferComponent: React.FC<Props> = ({
  heading,
  pageAlert,
  topSection,
  centerSection,
  bottomSection,
  contactsList,
  sideInformation,
  children,
  dataTestId,
}: Props) => {
  return (
    <MainContainer dataTestId={dataTestId}>
      <Grid
        dataTestId="offer-container"
        container
        columnSpacing={4}
        rowSpacing={3}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          {pageAlert && (
            <Box _sx={{ paddingBottom: 3 }}>
              <Alert
                severity={pageAlert.severity as SeverityStatus}
                title={pageAlert.title}
                close
              >
                {pageAlert.body}
              </Alert>
            </Box>
          )}
          <Heading title={heading.title} chipLabel={heading.chipLabel} />
          <Spacer verticalSpace={2} />
        </Grid>
        <Grid
          item
          md={12}
          lg={8}
          xl={9}
          container
          rowSpacing={8}
          alignItems="flex-start"
        >
          {topSection && (
            <Grid xs={12} item>
              <WhatsNext
                title={topSection.title}
                body={topSection.body}
                list={topSection.list}
                button_title={topSection.button_title}
                onClick={topSection.startOnboardingHandler}
              />
            </Grid>
          )}
          {centerSection && (
            <Grid xs={12} item>
              <OfferInfoSection
                tableHeading={centerSection?.title}
                tableSubHeading={centerSection.body}
                table={{
                  heading: centerSection.table?.headings,
                  cells: centerSection.table?.cells,
                }}
                accordionItems={centerSection.accordionItems}
                contentAlert={centerSection.contentAlert}
                buttons={centerSection?.buttons}
              />
              {!centerSection.alerts && <Spacer verticalSpace={4} />}
            </Grid>
          )}
          {centerSection.alerts && (
            // TODO: commented this cause there was a lot of spacing because of Grid
            // <Grid xs={12} item>
            <Alert severity="info" title={centerSection.alerts.title}>
              {centerSection.alerts.body}
            </Alert>
            // </Grid>
          )}
          {centerSection.informationCards && (
            <Grid xs={12} item>
              <Grid container spacing={3}>
                <Grid xs={12} item>
                  <Typography variant="h3" color="secondary" fontWeight="bold">
                    {centerSection.cardsTitle}
                  </Typography>
                </Grid>
                <SideCardList
                  gridSize={{
                    xs: 12,
                    md: 6,
                  }}
                  dataList={centerSection.informationCards}
                />
              </Grid>
            </Grid>
          )}
          {bottomSection && (
            <Grid xs={12} item>
              <WhatsNext
                title={bottomSection.title}
                body={bottomSection.body}
                button_title={bottomSection.button_title}
                onClick={bottomSection.startOnboardingHandler}
              />
              <Spacer verticalSpace={6} />
            </Grid>
          )}
          {contactsList?.contacts && (
            <Grid xs={12} item>
              <CustomerContactList
                title={contactsList.title}
                dataList={contactsList.contacts}
              />
              <Spacer verticalSpace={6} />
            </Grid>
          )}
          {children && (
            <Grid xs={12} item>
              {children}
            </Grid>
          )}
        </Grid>
        {sideInformation && (
          <Grid
            item
            md={12}
            lg={4}
            xl={3}
            container
            rowSpacing={2}
            _sx={{ alignContent: 'flex-start' }}
          >
            <SideCardList dataList={sideInformation} />
          </Grid>
        )}
      </Grid>
    </MainContainer>
  );
};

export default OfferComponent;
