import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Box, Chip, Flex, Typography } from '@dripcapital/dripui';
import { Spacer, StyledSubtitle } from 'atoms';
import { BusinessInfoCard } from 'components';
import { DAJ_COLORS, formatters } from 'utils';
import { Buyer } from 'api';

type Props = {
  title?: string;
  dataList?: Buyer[];
};

const BuyerList: React.FC<Props> = ({ title, dataList = [] }: Props) => {
  const { t } = useTranslation();

  return (
    <Box dataTestId="buyers-listing">
      {title && (
        <>
          <Typography variant="h3" color="secondary">
            {title}
          </Typography>
          <Spacer verticalSpace={2} />
        </>
      )}
      {dataList &&
        Array.isArray(dataList) &&
        dataList.map((buyer: Buyer) => {
          const phoneNumberExist =
            buyer.contactPhoneNumber?.phoneNumber &&
            buyer.contactPhoneNumber?.countryCode;
          const contactDetailsExist =
            !!buyer.contactName || !!buyer.contactName || phoneNumberExist;

          return (
            <React.Fragment key={buyer.index}>
              <BusinessInfoCard
                showRightButtons={false}
                title={buyer.name || ''}
                chipLabel={buyer.country || ''}
                // moreDetailsHandler={buyer.detailsHandler}
                contentSection={
                  <>
                    <Spacer verticalSpace={1} />
                    {buyer.hasInvoice && (
                      <Chip label={t('label_invoice_ready')} size="small" />
                    )}
                    <Spacer verticalSpace={1} />
                    <Flex>
                      <StyledSubtitle
                        weight="light"
                        color="gray"
                        _sx={{
                          marginRight: 1,
                          color: DAJ_COLORS.DARK_GRAY,
                        }}
                      >
                        {t('title_buyer_currency')} :
                      </StyledSubtitle>
                      <StyledSubtitle weight="regular">
                        {_.upperCase(buyer.currency || undefined)}
                      </StyledSubtitle>
                    </Flex>
                    <Spacer verticalSpace={1} />
                    {contactDetailsExist && (
                      <Typography
                        variant="subtitle1"
                        fontWeight="light"
                        _sx={{
                          marginRight: 1,
                          color: DAJ_COLORS.DARK_GRAY,
                        }}
                      >
                        {t('title_company_details')} :
                      </Typography>
                    )}
                    <StyledSubtitle weight="regular" noWrap>
                      {buyer.contactName}
                    </StyledSubtitle>
                    <StyledSubtitle weight="regular" noWrap>
                      {buyer.contactEmail}
                    </StyledSubtitle>
                    {phoneNumberExist && (
                      <StyledSubtitle weight="regular">
                        {formatters.displayPhoneNumber(
                          buyer.contactPhoneNumber
                        )}
                      </StyledSubtitle>
                    )}
                  </>
                }
                // filename={buyer.filename}
              />
              <Spacer verticalSpace={2} />
            </React.Fragment>
          );
        })}
    </Box>
  );
};

export default BuyerList;
