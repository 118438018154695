import React from 'react';
import { Box, COLORS, BoxProps } from '@dripcapital/dripui';

type Props = Pick<BoxProps, '_sx'> & {
  children: React.ReactNode;
  dataTestId?: string;
};

const BorderCardContainer: React.FC<Props> = ({
  children,
  dataTestId,
  _sx,
}: Props) => {
  return (
    <Box
      dataTestId={dataTestId}
      _sx={{
        borderColor: COLORS.GRAY,
        borderWidth: 1,
        borderStyle: 'solid',
        paddingX: 1.5,
        paddingY: 1.25,
        borderRadius: 2,
        ..._sx,
      }}
    >
      {children}
    </Box>
  );
};

export default BorderCardContainer;
