import Cookies from 'universal-cookie';
import { DEFAULT_LANGUAGE_MAP } from './constants';

export const APP_NAME = 'onboarding';
const VERSION = '22.6';
const FULLCLEAR_VER = '1';
const cookies = new Cookies();

type Obj = {
  [key: string]: string | number | object;
};

class Settings {
  app: string;
  constructor() {
    this.app = APP_NAME;
  }

  init = (app?: string): void => {
    if (app !== undefined) {
      this.app = app;
    }
    const storage = localStorage.getItem(this.app);
    if (storage !== null) {
      this.clearCache();
    } else {
      localStorage.setItem('fullClear', FULLCLEAR_VER);
    }
    let map: Obj = this.getMain();
    if (map['appversion'] !== VERSION) {
      map = {};
    }
    map['appversion'] = VERSION;
    if (!map['langMap']) {
      map['langMap'] = DEFAULT_LANGUAGE_MAP;
    }
    this.saveMain(map);
  };

  static isObject(value: string | number | object) {
    return value && typeof value === 'object' && value.constructor === Object;
  }

  getMain = (): Obj => {
    const storage = localStorage.getItem(this.app);
    if (storage !== null && storage !== undefined) {
      return JSON.parse(storage);
    }
    return {};
  };

  saveMain = (val: Obj): void => {
    localStorage.setItem(this.app, JSON.stringify(val));
  };

  getSettings = (settingKey: string): string | number | object | null => {
    const map = this.getMain();
    if (map[settingKey]) {
      return map[settingKey];
    }
    return null;
  };

  saveSettings = (
    settingKey: string,
    settingValue: string | number | object | Obj
  ): void => {
    const map = this.getMain();
    if (map[settingKey]) {
      if (Settings.isObject(settingValue)) {
        map[settingKey] = {
          ...(map[settingKey] as Record<string, unknown>),
          ...(settingValue as Record<string, unknown>),
        };
      } else {
        map[settingKey] = settingValue;
      }
    } else {
      map[settingKey] = settingValue;
    }
    this.saveMain(map);
  };

  removeSettings = (settingKey: string): void => {
    const map = this.getMain();
    if (map[settingKey]) {
      delete map[settingKey];
      this.saveMain(map);
    }
  };

  clearUserSettings = (): void => {
    const token = this.getSettings('token');
    localStorage.clear();
    localStorage.setItem('fullClear', FULLCLEAR_VER);
    if (token) {
      this.init();
      this.saveSettings('token', token);
    }
  };

  clearCache = (): void => {
    const item = localStorage.getItem('fullClear');
    if (item === null || item !== FULLCLEAR_VER) {
      localStorage.clear();
      localStorage.setItem('fullClear', FULLCLEAR_VER);
    }
  };

  getCookie = (name: string): string => {
    return cookies.get(name);
  };

  removeCookie = (name: string, options: object): void => {
    cookies.remove(name, options);
  };

  setSession = (settingKey: string, settingValue: string) => {
    sessionStorage.setItem(settingKey, settingValue);
  };

  getSession = (settingKey: string): string | null => {
    return sessionStorage.getItem(settingKey);
  };

  removeSession = (settingKey: string): void => {
    sessionStorage.removeItem(settingKey);
  };

  clearSession = (): void => {
    sessionStorage.clear();
  };
}

const settings = new Settings();
export default settings;
