import React from 'react';
import {
  Typography,
  Flex,
  Box,
  useTheme,
  useMediaQuery,
} from '@dripcapital/dripui';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  TextFieldForFormik,
  BorderCardContainer,
  Spacer,
  DatePicker,
  ChipsForFormik,
  // GenderChip,
  AtomicButton,
} from 'atoms';
import {
  // GENDER_OPTIONS,
  SHAREHOLDER_INFO_INITIAL_VALUES,
  UPLOAD_OPTIONS,
} from 'conversion';
import { ShareholderType } from 'types';
import {
  formatDateInput,
  REGION_CODE_MAP,
  shareholderInfoSchemaIN,
  shareholderInfoSchemaMX,
} from 'utils';
import { ButtonTypeEnum } from 'enums';

type Props = {
  loading: boolean;
  type: string;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onSave: (formValues: ShareholderType) => void;
  data?: ShareholderType;
  cardLabels?: {
    identificationCardName: string;
    partnerName: string;
  };
  title?: string;
  formProps?: {
    legalRepresentative?: boolean;
  };
};

const CompanyInfoForm: React.FC<Props> = ({
  loading,
  type,
  onCancel,
  onSave,
  data,
  title = 'label_shareholder',
  cardLabels,
  formProps = {},
}) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const smLimit = useMediaQuery(theme.breakpoints.down('sm'));

  const { spacing } = useTheme();

  return (
    <Formik
      initialValues={data ? data : SHAREHOLDER_INFO_INITIAL_VALUES}
      onSubmit={(values: ShareholderType) => {
        onSave(
          type == REGION_CODE_MAP.INDIA
            ? shareholderInfoSchemaIN.cast(values)
            : shareholderInfoSchemaMX.cast(values)
        );
      }}
      validationSchema={
        type == REGION_CODE_MAP.INDIA
          ? shareholderInfoSchemaIN
          : shareholderInfoSchemaMX
      }
    >
      {(formik) => {
        const { values, handleSubmit, isValid, isSubmitting, errors } = formik;

        if (!isValid && isSubmitting) {
          const names = Object.keys(errors);
          if (names.length > 0)
            document
              .querySelector(`input[name='${names[0]}']`)
              ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        return (
          <BorderCardContainer
            dataTestId="underwriting-company-form"
            _sx={{ paddingX: 4, paddingY: 3 }}
          >
            <Typography variant="h5" fontWeight="bold">
              {t('title_shareholder_role', { role: t(`${title}`) })}
            </Typography>
            <Spacer verticalSpace={1} />
            <Box _sx={{ width: spacing(40) }}>
              <Field
                name="shareholderName"
                label={cardLabels?.partnerName}
                component={TextFieldForFormik}
                placeholder={t('placeholder_partner_name')}
                fullWidth
              />
              <Spacer verticalSpace={3} />
              <Box _sx={{ width: spacing(40) }}>
                <Field
                  name="dob"
                  label={t('label_date_of_birth')}
                  date={formatDateInput(values.dob as string)}
                  placeholder={t('placeholder_dob')}
                  component={DatePicker}
                  fullWidth
                />
              </Box>
              <Spacer verticalSpace={3} />
              <Field
                name="identificationCardNumber"
                label={cardLabels?.identificationCardName}
                placeholder={t('placeholder_pan')}
                component={TextFieldForFormik}
                fullWidth
              />
            </Box>
            {/* <Spacer verticalSpace={4} /> */}
            {/* <Typography>{t('label_icon_preference')}</Typography>
            <Field
              name="gender"
              row
              options={GENDER_OPTIONS}
              component={GenderChip}
            /> */}
            {formProps.legalRepresentative && (
              <>
                <Spacer verticalSpace={3} />
                <Typography variant="subtitle1">
                  {t('title_is_legal_representative')}
                </Typography>
                <Spacer verticalSpace={0.4} />
                <Field
                  name="isLegalRepresentative"
                  row
                  options={UPLOAD_OPTIONS}
                  component={ChipsForFormik}
                  fullWidth
                />
              </>
            )}
            <Spacer verticalSpace={4} />
            <Flex spacing={3} direction={smLimit ? 'column' : 'row'}>
              <AtomicButton
                dataTestId="save-company-form"
                type="submit"
                loading={loading}
                _sx={{ width: smLimit ? '100%' : 'initial' }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t('btn_save')}
              </AtomicButton>
              <AtomicButton
                dataTestId="cancel-company-form"
                variant={ButtonTypeEnum.TEXT}
                _sx={{ width: smLimit ? '100%' : 'initial' }}
                onClick={onCancel}
              >
                {t('btn_cancel')}
              </AtomicButton>
            </Flex>
          </BorderCardContainer>
        );
      }}
    </Formik>
  );
};

export default CompanyInfoForm;
