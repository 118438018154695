import { ProductRouteConfig } from './RouteConfig.types';

export const routeConfig: ProductRouteConfig = {
  RF: {
    profile: {
      component: 'Profile',
      // allowedPaths: ['profile'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'identifier',
      },
    },
    identifier: {
      component: 'MXBusinessIdentifier',
      // allowedPaths: ['profile', 'identifier'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'details',
      },
    },
    details: {
      component: 'MXBusinessDetails',
      // allowedPaths: ['profile', 'identifier', 'details'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'eligibility',
      },
    },
    eligibility: {
      component: 'MXBusinessEligibility',
      // allowedPaths: ['profile', 'identifier', 'details', 'eligibility'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'granted',
      },
    },
    denied: {
      component: 'MXOfferDetailsDenied',
      // allowedPaths: [
      //   'profile',
      //   'identifier',
      //   'details',
      //   'eligibility',
      //   'denied',
      //   'onboard',
      // ],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'accepted',
      },
    },
    timeout: {
      component: 'MXOfferDetailsTimeOut',
      // allowedPaths: [
      //   'profile',
      //   'identifier',
      //   'details',
      //   'eligibility',
      //   'timeout',
      //   'onboard',
      // ],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'accepted',
      },
    },
    applicants: {
      component: 'MXApplicants',
      // allowedPaths: ['applicants'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: ['applicants'],
      props: {
        nextStage: '',
      },
    },
    onboard: {
      component: 'MXCIECValidation',
      allowedPaths: [],
      uAllowedPaths: ['onboard', 'denied', 'timeout'],
      suAllowedPaths: [],
      props: {
        nextStage: 'granted',
      },
    },
    generation: {
      component: 'MXOfferDetailsGeneration',
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: ['applicants', 'generation'],
      props: {
        nextStage: 'granted',
      },
    },
    granted: {
      component: 'MXOfferDetailsGranted',
      // allowedPaths: [
      //   'profile',
      //   'identifier',
      //   'details',
      //   'eligibility',
      //   'onboard',
      //   'granted',
      // ],
      allowedPaths: ['granted'],
      uAllowedPaths: ['onboard'],
      suAllowedPaths: ['applicants'],
      props: {
        nextStage: 'accepted',
      },
    },
    accepted: {
      component: 'MXOfferDetailsAccepted',
      allowedPaths: ['accepted', 'company', 'financial', 'buyer'],
      uAllowedPaths: ['review'],
      suAllowedPaths: ['applicants'],
      props: {
        nextStage: 'company',
      },
    },
    company: {
      component: 'MXCompanyInfo',
      allowedPaths: ['accepted', 'company', 'financial', 'buyer'],
      uAllowedPaths: ['review'],
      suAllowedPaths: ['applicants'],
      props: {
        nextStage: 'financial',
      },
    },
    financial: {
      component: 'MXFinancialInfo',
      allowedPaths: ['accepted', 'company', 'financial', 'buyer'],
      uAllowedPaths: ['review'],
      suAllowedPaths: ['applicants'],
      props: {
        nextStage: 'buyer',
      },
    },
    buyer: {
      component: 'MXBuyerInfo',
      allowedPaths: ['accepted', 'company', 'financial', 'buyer'],
      uAllowedPaths: ['review'],
      suAllowedPaths: ['applicants'],
      props: {
        nextStage: 'review',
      },
    },
    review: {
      component: 'MXReview',
      allowedPaths: ['accepted', 'company', 'financial', 'buyer'],
      uAllowedPaths: ['review'],
      suAllowedPaths: [],
      props: {
        nextStage: 'application',
      },
    },
    application: {
      component: 'MXApplicationStatus',
      allowedPaths: ['accepted', 'application'],
      uAllowedPaths: [],
      suAllowedPaths: ['applicants', 'company', 'financial', 'buyer'],
      props: {
        nextStage: '',
      },
    },
  },
  stages: {
    bdm: {
      applicants: 'applicants',
      New: 'generation',
      'CIEC Validation Pending': 'generation',
      'CIEC Validation Success': 'generation',
      'Offer Acceptance Pending': 'granted',
      'Company Information Pending': 'accepted',
      'Financial Information Pending': 'financial',
      'Buyer Information Pending': 'buyer',
      'Review Pending': 'application',
      Processing: 'application',
    },
    exporter: {
      profile: 'profile',
      identifier: 'identifier',
      details: 'details',
      eligibility: 'eligibility',
      denied: 'denied',
      timeout: 'timeout',
      'CIEC Validation Pending': 'onboard',
      'CIEC Validation Success': 'onboard',
      'Offer Acceptance Pending': 'granted',
      'Company Information Pending': 'accepted',
      'Financial Information Pending': 'financial',
      'Buyer Information Pending': 'buyer',
      'Review Pending': 'review',
      Processing: 'application',
    },
    cp: {},
  },
  paths: {
    profile: '/mx/rf/profile',
    identifier: '/mx/rf/identifier',
    details: '/mx/rf/details',
    eligibility: '/mx/rf/eligibility',
    denied: '/mx/rf/denied',
    timeout: '/mx/rf/timeout',
    applicants: '/mx/rf/applicants',
    onboard: '/mx/rf/onboard',
    generation: '/mx/rf/generation',
    granted: '/mx/rf/granted',
    accepted: '/mx/rf/accepted',
    company: '/mx/rf/company',
    financial: '/mx/rf/financial',
    buyer: '/mx/rf/buyer',
    review: '/mx/rf/review',
    application: '/mx/rf/application',
  },
};
