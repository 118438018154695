import React from 'react';
import { theme, Box, Grid, Flex, Typography } from '@dripcapital/dripui';
import { Divider } from '@dripcapital/dripui-v1/components/Divider';
import {
  LockOutlined,
  AttachMoney,
  CheckCircle,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { DAJ_COLORS } from 'utils';
import { Spacer } from 'atoms';
import { useTranslation } from 'react-i18next';

const WhyPlaid: React.FC = () => {
  const whyPlaidConstant = [
    {
      Icon: LockOutlined,
      heading: 'usoa_bd_why_plaid_heading_1',
      subheading: 'usoa_bd_why_plaid_subheading_1',
    },
    {
      Icon: AttachMoney,
      heading: 'usoa_bd_why_plaid_heading_2',
      subheading: 'usoa_bd_why_plaid_subheading_2',
    },
    {
      Icon: CheckCircle,
      heading: 'usoa_bd_why_plaid_heading_3',
      subheading: 'usoa_bd_why_plaid_subheading_3',
    },
    {
      Icon: KeyboardDoubleArrowRight,
      heading: 'usoa_bd_why_plaid_heading_4',
      subheading: 'usoa_bd_why_plaid_subheading_4',
    },
  ];

  const { t } = useTranslation();

  return (
    <Box _sx={styles.container}>
      <Typography
        _sx={{ color: DAJ_COLORS.BLUE }}
        fontWeight="bold"
        align="center"
        variant="subtitle1"
      >
        {t('title_why_plaid')}
      </Typography>
      <Spacer verticalSpace={1} />
      <Grid container spacing={0} columnSpacing={1}>
        {whyPlaidConstant.map((reason, index) => {
          const { Icon, heading, subheading } = reason;
          return (
            <Grid item xs={12} md={3} key={index}>
              <Flex _sx={{ height: '100%' }}>
                <Box _sx={{ width: 'max-content', margin: 'auto' }}>
                  <Flex alignItems="center" justifyContent="center">
                    <Icon sx={{ color: DAJ_COLORS.SKY_BLUE_400 }} />
                  </Flex>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    align="center"
                    _sx={{ color: DAJ_COLORS.BLUE }}
                  >
                    {t(heading)}
                  </Typography>
                  <Typography
                    fontWeight="regular"
                    variant="body2"
                    align="center"
                  >
                    {t(subheading)}
                  </Typography>
                </Box>
                <Box _sx={{ width: 2, height: '100%' }}>
                  {index !== 3 && (
                    <Divider
                      _sx={{ marginX: 1, height: '100%' }}
                      orientation="vertical"
                      borderColor={DAJ_COLORS.SKY_BLUE_400}
                      weight={1}
                      flexItem
                    />
                  )}
                </Box>
              </Flex>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const styles = {
  container: {
    backgroundColor: DAJ_COLORS.SKY_BLUE_50,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
};

export default WhyPlaid;
