import { gql } from '@apollo/client';

export const GET_USER_PROFILE = gql`
  query userProfile($userId: ID!) {
    userProfile(userId: $userId) {
      accountManager {
        email
        name
      }
      id
      name
      email
      status
      phone
      utype
      businesses {
        id
        businessIdentifierKey
        businessIdentifierValue
        productCategory
        typeOfGoods
        status
        companyName
        debtorType
        dripProfile {
          id
          designation
          profileType
          onboardingStage
        }
        location {
          address
          country {
            name
          }
          state {
            name
          }
        }
        salesContact {
          name
          email
          phNumber
        }
      }
    }
  }
`;

export const GET_APPLICANTS = gql`
  query onboardingApplicants($pageNo: Int!, $pageSize: Int, $search: String!) {
    onboardingApplicants(
      pageNo: $pageNo
      pageSize: $pageSize
      search: $search
    ) {
      leads {
        id
        name
        company
        email
        cpName
        dajStage
      }
      totalLeads
      totalPages
    }
  }
`;

export const GET_CIEC_STATUS = gql`
  query getCiecStatus($id: ID!) {
    ciecStatus(id: $id) {
      error {
        message
        path
      }
      uid
      validation
    }
  }
`;

export const GET_CONTACT = gql`
  query contact {
    contact {
      name
      company
      phone
      codeType
      code
    }
  }
`;

export const SCF_PRIMARY_SIGNATORY = gql`
  query scfPrimarySignatory($id: ID!) {
    scfPrimarySignatory(id: $id) {
      message
      primarySignatoryPresent
    }
  }
`;
