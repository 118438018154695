import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, FormControl, TextFieldProps } from '@dripcapital/dripui';
import { FieldProps } from 'formik';
import { TextFieldSizeEnum } from 'enums';

type TextFieldForFormikProps = Pick<TextFieldProps, '_sx' | 'className'> &
  Pick<FieldProps, 'field' | 'form'> & {
    label: string;
    disabled?: boolean;
    fullWidth?: boolean;
    placeholder?: string;
    error?: string;
    startIcon?: JSX.Element | undefined;
    endIcon?: JSX.Element | undefined;
    setPasswordChecks?: (value: string) => void;
    type: string;
    onChangeInput: (value?: string) => void;
    size?: TextFieldSizeEnum;
  };

const INPUT_WIDTH_OPTIONS = {
  small: 392,
  medium: 450,
  large: 640,
};

const TextFieldForFormik = ({
  _sx,
  disabled,
  form,
  field,
  label,
  placeholder,
  fullWidth = false,
  className = '',
  setPasswordChecks,
  startIcon,
  endIcon,
  type,
  onChangeInput,
  size = TextFieldSizeEnum.SMALL,
}: TextFieldForFormikProps) => {
  const { t } = useTranslation();

  const { name, value } = field;

  const { setFieldValue, setFieldTouched } = form;

  const { error, touched } = form.getFieldMeta(name);

  const calcWidth = () => {
    switch (size) {
      case TextFieldSizeEnum.SMALL:
        return INPUT_WIDTH_OPTIONS.small;
      case TextFieldSizeEnum.MEDIUM:
        return INPUT_WIDTH_OPTIONS.medium;
      case TextFieldSizeEnum.LARGE:
        return INPUT_WIDTH_OPTIONS.large;
    }
  };

  return (
    <FormControl error={!!error} fullWidth={fullWidth}>
      <TextField
        dataTestId="text-field-element"
        {...field}
        disabled={disabled}
        value={value}
        fullWidth={fullWidth}
        labelText={label}
        error={error && touched ? t(`${error}`) : undefined}
        _sx={{
          width: fullWidth ? '100%' : calcWidth(),
          '& > div': {
            borderRadius: '8px',
          },
          ..._sx,
        }}
        className={className}
        placeholder={placeholder}
        type={type}
        startIcon={startIcon}
        endIcon={endIcon}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (type === 'password' && setPasswordChecks) {
            setPasswordChecks(e.target.value);
          }
          if (!touched) {
            setFieldTouched(name);
          }
          setFieldValue(name, e.target.value);
          if (onChangeInput && typeof onChangeInput === 'function') {
            onChangeInput(e.target.value);
          }
        }}
      />
    </FormControl>
  );
};

export default TextFieldForFormik;
