import React, { FC } from 'react';
import { Box, Flex, styled } from '@dripcapital/dripui';
import { ArrowForward } from '@mui/icons-material';
import { DAJ_COLORS, FONT } from 'utils';

type Props = {
  linkNavigateCallback: () => void;
  linkTitle: React.ReactNode;
  icon?: React.ReactNode;
};

const Row = styled(Flex)(({ theme }) => {
  return {
    background: DAJ_COLORS.LAVENDER,
    height: theme.spacing(5),
    color: DAJ_COLORS.ARSENIC,
  };
});

export const LinkBox: FC<Props> = ({
  linkNavigateCallback,
  linkTitle,
  icon,
}: Props): React.ReactElement => {
  return (
    <Box
      dataTestId="side-card-link-element"
      _sx={{
        width: '100%',
        cursor: 'pointer',
      }}
      onClick={linkNavigateCallback}
    >
      <Row justifyContent={'center'}>
        <Flex
          flexGrow={1}
          alignItems={'center'}
          _sx={{ paddingLeft: 2, fontWeight: FONT.WEIGHT.SEMI_BOLD }}
        >
          {linkTitle}
        </Flex>
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          _sx={{ padding: 2 }}
        >
          {icon ? icon : <ArrowForward sx={{ transform: 'rotate(-45deg)' }} />}
        </Flex>
      </Row>
    </Box>
  );
};

export default LinkBox;
