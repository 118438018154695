import React from 'react';
import { Box, Paper, COLORS, Flex, Typography } from '@dripcapital/dripui';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DAJ_COLORS } from 'utils';

type RadioPlaceholderProps = {
  label: string;
  selected: boolean;
};

const RadioPlaceholder: React.FC<RadioPlaceholderProps> = ({
  selected,
  label,
}) => {
  const { t } = useTranslation();

  return (
    <Paper
      dataTestId="radio-placeholder-element"
      elevation={0}
      variant="outlined"
      _sx={{
        overflow: 'hidden',
        position: 'relative',
        padding: 0.8,
        width: 160,
        borderRadius: 2,
        backgroundColor: DAJ_COLORS.ALICE_BLUE,
      }}
    >
      {selected && (
        <>
          <Box
            _sx={{
              position: 'absolute',
              top: '-8px',
              right: '-22px',
              background: DAJ_COLORS.GREEN,
              transform: 'rotate(40deg)',
              width: 72,
              height: 32,
            }}
          ></Box>
          <Check
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              color: COLORS.WHITE,
            }}
          />
        </>
      )}
      <Flex _sx={{ marginY: '10%' }} justifyContent={'center'}>
        <Typography variant="subtitle1">{label}</Typography>
      </Flex>
      <Paper
        _sx={{
          width: '100%',
          paddingY: '5%',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: selected ? COLORS.PRIMARY : COLORS.LIGHT_GRAY,
        }}
        elevation={0}
        variant="outlined"
      >
        <Typography>
          {selected ? t('label_unselect') : t('label_select')}
        </Typography>
      </Paper>
    </Paper>
  );
};

export default RadioPlaceholder;
