import mixpanel from './mixpanel';
import logrocket from './logrocket';
import sentry from './sentry';
import googleAnalytics from './googleAnalytics';

const analyticsInit = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  logrocket.init(process.env.REACT_APP_LOGROCKET_BOARD);
  sentry.init(process.env.REACT_APP_SENTRY_URL);
  googleAnalytics.init(process.env.REACT_APP_GA_TOKEN);
};

export { mixpanel, logrocket, sentry, googleAnalytics };
export default analyticsInit;
