import React, { useState } from 'react';
import {
  Alert as AlertComponent,
  CssOverrideType,
  COLORS,
} from '@dripcapital/dripui';
import { SeverityStatus } from 'types';
import { DAJ_COLORS, FONT } from 'utils';
import { SeverityTypeEnum } from 'enums';
import { CheckCircle, Error } from '@mui/icons-material';

type Props = CssOverrideType & {
  severity: SeverityStatus;
  variant?: SeverityTypeEnum;
  title?: string;
  children?: React.ReactNode;
  onClose?: (event: React.SyntheticEvent) => void;
  close?: boolean;
};

const Alert: React.FC<Props> = ({
  severity,
  variant,
  title = '',
  children,
  onClose,
  close = false,
  _sx = {},
  className = '',
}: Props) => {
  const [hideAlert, setHideAlert] = useState<boolean>(false);

  const styles:
    | Partial<Record<'success' | 'info' | 'warning' | 'error', React.ReactNode>>
    | undefined = {};

  const updateStyles = (severity: string) => {
    switch (severity) {
      case 'success':
        styles.success = (
          <CheckCircle fontSize="inherit" sx={{ fill: DAJ_COLORS.GREEN }} />
        );
        break;
      case 'error':
        styles.success = (
          <CheckCircle fontSize="inherit" sx={{ fill: COLORS.SECONDARY }} />
        );
        break;
      case 'warning':
        styles.warning = (
          <Error fontSize="inherit" sx={{ fill: COLORS.ORANGE }} />
        );
        break;
      case 'info':
        styles.info = (
          <Error fontSize="inherit" sx={{ fill: COLORS.SECONDARY }} />
        );
        break;
    }
    return styles;
  };

  if (hideAlert) {
    return null;
  }

  return (
    <AlertComponent
      dataTestId="alert-element"
      severity={severity}
      variant={variant}
      title={title}
      className={className}
      _sx={{
        ..._sx,
        '.MuiAlertTitle-root': {
          display: !title ? 'none !important' : 'initial',
        },
      }}
      _alertTitleSx={{
        '.MuiTypography-body1': {
          fontWeight: FONT.WEIGHT.BOLD,
          fontSize: FONT.SIZES.F16,
        },
      }}
      iconMapping={updateStyles(severity)}
      onClose={!close ? onClose : () => setHideAlert(true)}
    >
      {children}
    </AlertComponent>
  );
};

export default Alert;
