import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** Represents untyped JSON */
  JSON: any;
};

/** Autogenerated input type of AcceptOffer */
export type AcceptOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of AcceptOffer */
export type AcceptOfferPayload = {
  __typename?: 'AcceptOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Autogenerated input type of AddCompanyFinancialInfo */
export type AddCompanyFinancialInfoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  company: CompanyFinancialInputObject;
  id: Scalars['ID'];
};

/** Autogenerated return type of AddCompanyFinancialInfo */
export type AddCompanyFinancialInfoPayload = {
  __typename?: 'AddCompanyFinancialInfoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyFinancial>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AddOwner */
export type AddOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: OwnerInputObject;
};

/** Autogenerated return type of AddOwner */
export type AddOwnerPayload = {
  __typename?: 'AddOwnerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
  owner?: Maybe<Owner>;
};

/** Autogenerated input type of AddShareholderFinancialInfo */
export type AddShareholderFinancialInfoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  shareholder: ShareholderFinancialInputObject;
};

/** Autogenerated return type of AddShareholderFinancialInfo */
export type AddShareholderFinancialInfoPayload = {
  __typename?: 'AddShareholderFinancialInfoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
  shareholder?: Maybe<ShareholderFinancial>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AddressInputObject = {
  city: Scalars['String'];
  country: Scalars['String'];
  municipality: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  zipcode: Scalars['String'];
};

export type ApplicantDetails = {
  __typename?: 'ApplicantDetails';
  company?: Maybe<Scalars['String']>;
  cpName?: Maybe<Scalars['String']>;
  dajStage?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type Application = {
  __typename?: 'Application';
  buyerInfo: BuyerInfo;
  companyInfo: CompanyInfo;
  financialInfo: FinInfo;
  onboardingStage?: Maybe<Scalars['String']>;
};

export type ApplicationDetails = {
  __typename?: 'ApplicationDetails';
  application: Application;
  message: Scalars['String'];
};

export type ApplicationInfo = {
  __typename?: 'ApplicationInfo';
  buyerInfo: BuyerInfo;
  companyInfo: CompanyInfo;
  financialInfo: FinancialInfo;
  legalDocs: Scalars['JSON'];
  onboardingStage?: Maybe<Scalars['String']>;
};

export type ApplicationStatus = {
  __typename?: 'ApplicationStatus';
  application: ApplicationInfo;
  message: Scalars['String'];
};

export type BankDetail = {
  __typename?: 'BankDetail';
  files?: Maybe<Array<File>>;
  plaidFailed?: Maybe<Scalars['Boolean']>;
  plaidLinked?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type Business = {
  __typename?: 'Business';
  businessIdentifierKey?: Maybe<Scalars['String']>;
  businessIdentifierValue?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  dba?: Maybe<Scalars['String']>;
  debtorType?: Maybe<Scalars['String']>;
  detailsPending?: Maybe<Scalars['Boolean']>;
  dripProfile?: Maybe<Array<DripProfile>>;
  ecomSeller?: Maybe<EcomDetailObject>;
  ein?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  productCategory?: Maybe<Scalars['String']>;
  salesContact?: Maybe<SalesContact>;
  scfOwners?: Maybe<Array<ScfOwner>>;
  status?: Maybe<Scalars['String']>;
  typeOfGoods?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  yearIncorporated?: Maybe<Scalars['String']>;
};

export type Buyer = {
  __typename?: 'Buyer';
  addToOfferProfile?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  canSubmitAllBill?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<PhoneNumber>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currentSales?: Maybe<Scalars['String']>;
  documentReleaseType?: Maybe<Scalars['String']>;
  documentSendType?: Maybe<Scalars['String']>;
  dripLimit?: Maybe<Scalars['Int']>;
  hasInvoice?: Maybe<Scalars['Boolean']>;
  idNumber?: Maybe<Scalars['String']>;
  idType?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  operationDrip?: Maybe<Scalars['String']>;
  pastSales?: Maybe<Scalars['String']>;
  paymentDays?: Maybe<Scalars['String']>;
  paymentTenor?: Maybe<Scalars['String']>;
  paymentTerms?: Maybe<Scalars['String']>;
  related?: Maybe<Scalars['String']>;
  rfc?: Maybe<Scalars['String']>;
  shippingTerms?: Maybe<Scalars['String']>;
  writeoffs?: Maybe<Scalars['String']>;
  yearsSelling?: Maybe<Scalars['String']>;
};

export type BuyerInfo = {
  __typename?: 'BuyerInfo';
  pending?: Maybe<Array<Pending>>;
  present?: Maybe<Scalars['Boolean']>;
};

export type BuyerInputObject = {
  addToOfferProfile?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  canSubmitAllBill?: InputMaybe<Scalars['Boolean']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhoneNumber?: InputMaybe<PhoneInputObject>;
  country?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  currentSales?: InputMaybe<Scalars['String']>;
  documentReleaseType?: InputMaybe<Scalars['String']>;
  documentSendType?: InputMaybe<Scalars['String']>;
  dripLimit?: InputMaybe<Scalars['Int']>;
  hasInvoice?: InputMaybe<Scalars['Boolean']>;
  idNumber?: InputMaybe<Scalars['String']>;
  idType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  operationDrip?: InputMaybe<Scalars['String']>;
  pastSales?: InputMaybe<Scalars['String']>;
  paymentDays?: InputMaybe<Scalars['String']>;
  paymentTenor?: InputMaybe<Scalars['String']>;
  paymentTerms?: InputMaybe<Scalars['String']>;
  related?: InputMaybe<Scalars['String']>;
  rfc?: InputMaybe<Scalars['String']>;
  shippingTerms?: InputMaybe<Scalars['String']>;
  writeoffs?: InputMaybe<Scalars['String']>;
  yearsSelling?: InputMaybe<Scalars['String']>;
};

export type Buyers = {
  __typename?: 'Buyers';
  pending?: Maybe<Array<PendingBuyers>>;
  present?: Maybe<Scalars['Boolean']>;
};

export type Cibil = {
  __typename?: 'Cibil';
  present?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type Company = {
  __typename?: 'Company';
  pending?: Maybe<Array<Scalars['JSON']>>;
  present?: Maybe<Scalars['Boolean']>;
};

export type CompanyFinancial = {
  __typename?: 'CompanyFinancial';
  address?: Maybe<Address>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  file?: Maybe<CreditReport>;
  rfc?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CompanyFinancialInputObject = {
  address: AddressInputObject;
  companyName: Scalars['String'];
  email: Scalars['String'];
  rfc: Scalars['String'];
};

export type CompanyInfo = {
  __typename?: 'CompanyInfo';
  pending?: Maybe<Array<Pending>>;
  present?: Maybe<Scalars['Boolean']>;
};

export type Contact = {
  __typename?: 'Contact';
  code?: Maybe<Scalars['String']>;
  codeType?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateBuyer */
export type CreateBuyerInput = {
  buyer: BuyerInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of CreateBuyer */
export type CreateBuyerPayload = {
  __typename?: 'CreateBuyerPayload';
  buyer?: Maybe<Buyer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateContact */
export type CreateContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

/** Autogenerated return type of CreateContact */
export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateScfImporter */
export type CreateScfImporterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  scfImporter: ScfImporterInputObject;
};

/** Autogenerated return type of CreateScfImporter */
export type CreateScfImporterPayload = {
  __typename?: 'CreateScfImporterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
  scfImporter?: Maybe<Business>;
};

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  user: UserInputObject;
};

/** Autogenerated return type of CreateUser */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<CustomError>>;
  user?: Maybe<User>;
};

export type CreditReport = {
  __typename?: 'CreditReport';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** A user-readable error */
export type CustomError = {
  __typename?: 'CustomError';
  /** A description of the error */
  message: Scalars['String'];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars['String']>>;
};

export type Date = {
  __typename?: 'Date';
  day?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type DateInputObject = {
  day: Scalars['String'];
  month: Scalars['String'];
  year: Scalars['String'];
};

/** Autogenerated input type of DeleteBuyer */
export type DeleteBuyerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  index?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of DeleteBuyer */
export type DeleteBuyerPayload = {
  __typename?: 'DeleteBuyerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteOwner */
export type DeleteOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  index: Scalars['Int'];
};

/** Autogenerated return type of DeleteOwner */
export type DeleteOwnerPayload = {
  __typename?: 'DeleteOwnerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteSession */
export type DeleteSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteSession */
export type DeleteSessionPayload = {
  __typename?: 'DeleteSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
};

export type DripProfile = {
  __typename?: 'DripProfile';
  designation?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  onboardingStage?: Maybe<Scalars['String']>;
  profileType?: Maybe<Scalars['String']>;
};

export type EcomDetailInputObject = {
  ecomSellerLink?: InputMaybe<Scalars['String']>;
  isEcomSeller?: Scalars['String'];
};

export type EcomDetailObject = {
  __typename?: 'EcomDetailObject';
  ecomSellerLink?: Maybe<Scalars['String']>;
  isEcomSeller?: Maybe<Scalars['String']>;
};

export type EsignCredential = {
  __typename?: 'EsignCredential';
  apiKey?: Maybe<Scalars['String']>;
  documentId?: Maybe<Scalars['String']>;
  signatoryId?: Maybe<Scalars['String']>;
};

export type EsignStatus = {
  __typename?: 'EsignStatus';
  esignStatus?: Maybe<Scalars['String']>;
};

export type FacilityDetails = {
  __typename?: 'FacilityDetails';
  advanceRate?: Maybe<Scalars['String']>;
  facilitySize?: Maybe<Scalars['String']>;
  generatedDocUrl?: Maybe<Scalars['String']>;
  interestFee?: Maybe<Scalars['String']>;
  marginRate?: Maybe<Scalars['String']>;
  maxCreditPeriod?: Maybe<Scalars['Int']>;
  offerGeneratedNotSent?: Maybe<Scalars['Boolean']>;
  overdueRate?: Maybe<Scalars['String']>;
  sentDate?: Maybe<Scalars['String']>;
  setupFee?: Maybe<Scalars['String']>;
  signedDate?: Maybe<Scalars['String']>;
  transactionFee?: Maybe<Scalars['String']>;
};

export type FactoringCommission = {
  __typename?: 'FactoringCommission';
  days?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Float']>;
};

export type File = {
  __typename?: 'File';
  documentId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FileArray = {
  __typename?: 'FileArray';
  files?: Maybe<Array<File>>;
  status?: Maybe<Scalars['String']>;
};

export type FinInfo = {
  __typename?: 'FinInfo';
  cibil?: Maybe<Cibil>;
  gst?: Maybe<Array<GstDetail>>;
  itr?: Maybe<Itr>;
};

export type FinancialInfo = {
  __typename?: 'FinancialInfo';
  company?: Maybe<Company>;
  shareholders?: Maybe<Buyers>;
};

export type Gst = {
  __typename?: 'Gst';
  credLink?: Maybe<Scalars['String']>;
  gstNo: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  recordStatus: Scalars['String'];
};

export type GstDetail = {
  __typename?: 'GstDetail';
  gstNo?: Maybe<Scalars['String']>;
  recordStatus?: Maybe<Scalars['String']>;
};

export type IdentifierInputObject = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Itr = {
  __typename?: 'Itr';
  files?: Maybe<Array<File>>;
  status?: Maybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Tag>;
  country?: Maybe<Tag>;
  state?: Maybe<Tag>;
  zipCode?: Maybe<Scalars['String']>;
};

export type MetaData = {
  __typename?: 'MetaData';
  allStates?: Maybe<Scalars['JSON']>;
  annualImportsList?: Maybe<Scalars['JSON']>;
  businessStructureList?: Maybe<Scalars['JSON']>;
  countries?: Maybe<Array<Scalars['String']>>;
  countriesWithId?: Maybe<Scalars['JSON']>;
  currencyValues?: Maybe<Scalars['JSON']>;
  docsRelease?: Maybe<Scalars['JSON']>;
  docsRouting?: Maybe<Scalars['JSON']>;
  paymentDurations?: Maybe<Scalars['JSON']>;
  paymentTerms?: Maybe<Scalars['JSON']>;
  productCategory?: Maybe<Scalars['JSON']>;
  states?: Maybe<Array<Scalars['String']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptOffer?: Maybe<AcceptOfferPayload>;
  addCompany?: Maybe<AddCompanyFinancialInfoPayload>;
  addOwner?: Maybe<AddOwnerPayload>;
  addShareholder?: Maybe<AddShareholderFinancialInfoPayload>;
  createBuyer?: Maybe<CreateBuyerPayload>;
  createContact?: Maybe<CreateContactPayload>;
  createScfImporter?: Maybe<CreateScfImporterPayload>;
  createUser?: Maybe<CreateUserPayload>;
  deleteBuyer?: Maybe<DeleteBuyerPayload>;
  deleteOwner?: Maybe<DeleteOwnerPayload>;
  logout?: Maybe<DeleteSessionPayload>;
  offerEsign?: Maybe<OfferEsignPayload>;
  onfidoDocumentUploadCompleted?: Maybe<OnfidoDocumentUploadCompletedPayload>;
  onfidoGenerateSdkToken?: Maybe<OnfidoGenerateSdkTokenPayload>;
  removeDocument?: Maybe<RemoveDocumentPayload>;
  sendCibilOtp?: Maybe<SendCibilOtpPayload>;
  submitApplication?: Maybe<SubmitApplicationPayload>;
  updateBuyer?: Maybe<UpdateBuyerPayload>;
  updateCompany?: Maybe<UpdateCompanyFinancialInfoPayload>;
  updateOwner?: Maybe<UpdateOwnerPayload>;
  updateScfImporter?: Maybe<UpdateScfImporterPayload>;
  updateShareholder?: Maybe<UpdateShareholderFinancialInfoPayload>;
  updateStatus?: Maybe<UpdateStatusPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  uploadCiec?: Maybe<UploadCiecPayload>;
  verifyCibilOtp?: Maybe<VerifyCibilOtpPayload>;
};

export type MutationAcceptOfferArgs = {
  input: AcceptOfferInput;
};

export type MutationAddCompanyArgs = {
  input: AddCompanyFinancialInfoInput;
};

export type MutationAddOwnerArgs = {
  input: AddOwnerInput;
};

export type MutationAddShareholderArgs = {
  input: AddShareholderFinancialInfoInput;
};

export type MutationCreateBuyerArgs = {
  input: CreateBuyerInput;
};

export type MutationCreateContactArgs = {
  input: CreateContactInput;
};

export type MutationCreateScfImporterArgs = {
  input: CreateScfImporterInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteBuyerArgs = {
  input: DeleteBuyerInput;
};

export type MutationDeleteOwnerArgs = {
  input: DeleteOwnerInput;
};

export type MutationLogoutArgs = {
  input: DeleteSessionInput;
};

export type MutationOfferEsignArgs = {
  input: OfferEsignInput;
};

export type MutationOnfidoDocumentUploadCompletedArgs = {
  input: OnfidoDocumentUploadCompletedInput;
};

export type MutationOnfidoGenerateSdkTokenArgs = {
  input: OnfidoGenerateSdkTokenInput;
};

export type MutationRemoveDocumentArgs = {
  input: RemoveDocumentInput;
};

export type MutationSendCibilOtpArgs = {
  input: SendCibilOtpInput;
};

export type MutationSubmitApplicationArgs = {
  input: SubmitApplicationInput;
};

export type MutationUpdateBuyerArgs = {
  input: UpdateBuyerInput;
};

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyFinancialInfoInput;
};

export type MutationUpdateOwnerArgs = {
  input: UpdateOwnerInput;
};

export type MutationUpdateScfImporterArgs = {
  input: UpdateScfImporterInput;
};

export type MutationUpdateShareholderArgs = {
  input: UpdateShareholderFinancialInfoInput;
};

export type MutationUpdateStatusArgs = {
  input: UpdateStatusInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUploadCiecArgs = {
  input: UploadCiecInput;
};

export type MutationVerifyCibilOtpArgs = {
  input: VerifyCibilOtpInput;
};

export type Name = {
  __typename?: 'Name';
  firstname?: Maybe<Scalars['String']>;
  lastname1?: Maybe<Scalars['String']>;
  lastname2?: Maybe<Scalars['String']>;
  middlename?: Maybe<Scalars['String']>;
};

export type NameInputObject = {
  firstname: Scalars['String'];
  lastname1: Scalars['String'];
  lastname2: Scalars['String'];
  middlename?: InputMaybe<Scalars['String']>;
};

export type Offer = {
  __typename?: 'Offer';
  currency?: Maybe<Scalars['String']>;
  offerDetails?: Maybe<RfInOfferDetails>;
  pricingDetails?: Maybe<PricingDetails>;
};

export type OfferDetails = {
  __typename?: 'OfferDetails';
  advanceRate?: Maybe<Scalars['Float']>;
  creditLimit?: Maybe<Scalars['Float']>;
  downloadLink?: Maybe<Scalars['String']>;
  establishmentFee?: Maybe<Scalars['Float']>;
  facilitySize?: Maybe<Scalars['Float']>;
  interestRate?: Maybe<Scalars['Float']>;
  maximumCreditTenor?: Maybe<Scalars['Int']>;
};

export type OfferDetailsResponse = {
  __typename?: 'OfferDetailsResponse';
  message?: Maybe<Scalars['String']>;
  offerDetails?: Maybe<OfferDetails>;
};

/** Autogenerated input type of OfferEsign */
export type OfferEsignInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of OfferEsign */
export type OfferEsignPayload = {
  __typename?: 'OfferEsignPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  done?: Maybe<Scalars['Boolean']>;
  error?: Maybe<CustomError>;
  message: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type OfferEsignStatus = {
  __typename?: 'OfferEsignStatus';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type OnboardingLeads = {
  __typename?: 'OnboardingLeads';
  leads?: Maybe<Array<ApplicantDetails>>;
  totalLeads?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of OnfidoDocumentUploadCompleted */
export type OnfidoDocumentUploadCompletedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of OnfidoDocumentUploadCompleted */
export type OnfidoDocumentUploadCompletedPayload = {
  __typename?: 'OnfidoDocumentUploadCompletedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<CustomError>>;
  message?: Maybe<Scalars['String']>;
};

export type OnfidoGenerateSdkToken = {
  __typename?: 'OnfidoGenerateSdkToken';
  onfidoSdkToken: Scalars['String'];
};

/** Autogenerated input type of OnfidoGenerateSdkToken */
export type OnfidoGenerateSdkTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of OnfidoGenerateSdkToken */
export type OnfidoGenerateSdkTokenPayload = {
  __typename?: 'OnfidoGenerateSdkTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<CustomError>>;
  generateSdkToken?: Maybe<OnfidoGenerateSdkToken>;
};

export type Owner = {
  __typename?: 'Owner';
  dob?: Maybe<Date>;
  gender?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  legalRepresentative?: Maybe<Scalars['Boolean']>;
  majorityShareholder?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  owned?: Maybe<Scalars['String']>;
  pan?: Maybe<Scalars['String']>;
  rfc?: Maybe<Scalars['String']>;
  secondaryShareholder?: Maybe<Scalars['Boolean']>;
};

export type OwnerInputObject = {
  dob?: InputMaybe<DateInputObject>;
  gender?: InputMaybe<Scalars['String']>;
  legalRepresentative?: InputMaybe<Scalars['Boolean']>;
  majorityShareholder?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  owned?: InputMaybe<Scalars['String']>;
  pan?: InputMaybe<Scalars['String']>;
  rfc?: InputMaybe<Scalars['String']>;
  secondaryShareholder?: InputMaybe<Scalars['Boolean']>;
};

export type Pending = {
  __typename?: 'Pending';
  index?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type PendingBuyers = {
  __typename?: 'PendingBuyers';
  key?: Maybe<Scalars['JSON']>;
  shareholderId?: Maybe<Scalars['String']>;
};

export type PhoneInputObject = {
  countryCode: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  countryCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type PricingDetails = {
  __typename?: 'PricingDetails';
  factoringCommission?: Maybe<Array<FactoringCommission>>;
  overdueRate?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  applicationDetails?: Maybe<ApplicationDetails>;
  applicationStatus?: Maybe<ApplicationStatus>;
  buyer?: Maybe<Buyer>;
  buyers?: Maybe<Array<Buyer>>;
  ciecStatus: Response;
  company?: Maybe<CompanyFinancial>;
  contact?: Maybe<Contact>;
  downloadOffer?: Maybe<Scalars['String']>;
  esignCredentials?: Maybe<EsignCredential>;
  esignStatus?: Maybe<EsignStatus>;
  getDuns?: Maybe<Business>;
  getGstDetails?: Maybe<Array<Gst>>;
  metaData?: Maybe<MetaData>;
  offer?: Maybe<OfferDetailsResponse>;
  offerDetails?: Maybe<Offer>;
  offerEsignStatus?: Maybe<OfferEsignStatus>;
  onboardingApplicants?: Maybe<OnboardingLeads>;
  owner?: Maybe<Owner>;
  owners?: Maybe<Array<Owner>>;
  scfApplicationDetails?: Maybe<ScfApplicationDetails>;
  scfFacilityDetails?: Maybe<ScfFacilityDetails>;
  scfGenerateOffer?: Maybe<ScfGenerateOffer>;
  scfMetaData?: Maybe<MetaData>;
  scfPrimarySignatory?: Maybe<ScfPrimarySignatory>;
  scfSendOffer?: Maybe<ScfSendOffer>;
  shareholders?: Maybe<Array<ShareholderFinancial>>;
  states?: Maybe<Array<Scalars['String']>>;
  test: Test;
  userProfile?: Maybe<UserProfile>;
};

export type QueryApplicationDetailsArgs = {
  id: Scalars['ID'];
};

export type QueryApplicationStatusArgs = {
  id: Scalars['ID'];
};

export type QueryBuyerArgs = {
  id: Scalars['ID'];
  index: Scalars['Int'];
};

export type QueryBuyersArgs = {
  id: Scalars['ID'];
};

export type QueryCiecStatusArgs = {
  id: Scalars['ID'];
};

export type QueryCompanyArgs = {
  id: Scalars['ID'];
};

export type QueryDownloadOfferArgs = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type QueryEsignCredentialsArgs = {
  id: Scalars['ID'];
  shareholderId?: InputMaybe<Scalars['String']>;
};

export type QueryEsignStatusArgs = {
  id: Scalars['ID'];
  shareholderId?: InputMaybe<Scalars['String']>;
};

export type QueryGetDunsArgs = {
  duns: Scalars['String'];
};

export type QueryGetGstDetailsArgs = {
  userId: Scalars['ID'];
};

export type QueryOfferArgs = {
  id: Scalars['ID'];
};

export type QueryOfferDetailsArgs = {
  id: Scalars['ID'];
  type?: InputMaybe<Scalars['String']>;
};

export type QueryOfferEsignStatusArgs = {
  id: Scalars['ID'];
};

export type QueryOnboardingApplicantsArgs = {
  pageNo: Scalars['Int'];
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryOwnerArgs = {
  id: Scalars['ID'];
  index: Scalars['Int'];
};

export type QueryOwnersArgs = {
  id: Scalars['ID'];
};

export type QueryScfApplicationDetailsArgs = {
  businessId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
};

export type QueryScfFacilityDetailsArgs = {
  id: Scalars['ID'];
};

export type QueryScfGenerateOfferArgs = {
  date: Scalars['String'];
  id: Scalars['ID'];
};

export type QueryScfPrimarySignatoryArgs = {
  id: Scalars['ID'];
};

export type QueryScfSendOfferArgs = {
  cc: Array<Scalars['String']>;
  id: Scalars['ID'];
};

export type QueryShareholdersArgs = {
  id: Scalars['ID'];
};

export type QueryStatesArgs = {
  id: Scalars['ID'];
};

export type QueryUserProfileArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of RemoveDocument */
export type RemoveDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['ID'];
  id: Scalars['ID'];
};

/** Autogenerated return type of RemoveDocument */
export type RemoveDocumentPayload = {
  __typename?: 'RemoveDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
};

export type Response = {
  __typename?: 'Response';
  error?: Maybe<CustomError>;
  uid?: Maybe<Scalars['ID']>;
  validation?: Maybe<Scalars['String']>;
};

export type RfInOfferDetails = {
  __typename?: 'RfInOfferDetails';
  advanceRate?: Maybe<Scalars['String']>;
  establishimentFee?: Maybe<Scalars['String']>;
  facilitySize?: Maybe<Scalars['String']>;
  interestRate?: Maybe<Scalars['String']>;
  maxCreditTenor?: Maybe<Scalars['String']>;
};

export type SalesContact = {
  __typename?: 'SalesContact';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phNumber?: Maybe<Scalars['String']>;
};

export type ScfApplication = {
  __typename?: 'ScfApplication';
  bankDetails?: Maybe<BankDetail>;
  businessDetails: Business;
  certificateOfIncorporation?: Maybe<FileArray>;
  dbaDocuments?: Maybe<FileArray>;
  financialDetails?: Maybe<FileArray>;
  mailSent?: Maybe<Scalars['Boolean']>;
  mailSentDate?: Maybe<Scalars['ISO8601Date']>;
  onboardingStage?: Maybe<Scalars['String']>;
  personalDetails: User;
  uid: Scalars['String'];
};

export type ScfApplicationDetails = {
  __typename?: 'ScfApplicationDetails';
  application: ScfApplication;
  message: Scalars['String'];
};

export type ScfFacilityDetails = {
  __typename?: 'ScfFacilityDetails';
  error?: Maybe<CustomError>;
  facilityDetails: FacilityDetails;
  message: Scalars['String'];
};

export type ScfGenerateOffer = {
  __typename?: 'ScfGenerateOffer';
  error?: Maybe<CustomError>;
  message: Scalars['String'];
  offerDocumentUrl: Scalars['String'];
};

export type ScfImporterInputObject = {
  address?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  company: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  dunsNo?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type ScfImporterUpdateInputObject = {
  address?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dba?: InputMaybe<Scalars['String']>;
  ecomSeller?: InputMaybe<EcomDetailInputObject>;
  ein?: InputMaybe<Scalars['String']>;
  owners?: InputMaybe<Array<ScfOwnerInputObject>>;
  state?: InputMaybe<Scalars['String']>;
  updateType?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  yearIncorporated?: InputMaybe<Scalars['Int']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type ScfOwner = {
  __typename?: 'ScfOwner';
  email: Scalars['String'];
  name: Scalars['String'];
  ownership: Scalars['String'];
  phone?: Maybe<PhoneNumber>;
  userId?: Maybe<Scalars['Int']>;
};

export type ScfOwnerInputObject = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ownership?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<PhoneInputObject>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type ScfPrimarySignatory = {
  __typename?: 'ScfPrimarySignatory';
  error?: Maybe<CustomError>;
  message: Scalars['String'];
  primarySignatoryPresent?: Maybe<Scalars['Boolean']>;
};

export type ScfSendOffer = {
  __typename?: 'ScfSendOffer';
  error?: Maybe<CustomError>;
  message: Scalars['String'];
  offerSent?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of SendCibilOtp */
export type SendCibilOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SendCibilOtp */
export type SendCibilOtpPayload = {
  __typename?: 'SendCibilOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
};

export type ShareholderFinancial = {
  __typename?: 'ShareholderFinancial';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  file?: Maybe<CreditReport>;
  gender?: Maybe<Scalars['String']>;
  indexNo?: Maybe<Scalars['Int']>;
  legalRepresentative?: Maybe<Scalars['Boolean']>;
  majorityShareholder?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Name>;
  rfc?: Maybe<Scalars['String']>;
  secondaryShareholder?: Maybe<Scalars['Boolean']>;
  shareholderId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ShareholderFinancialInputObject = {
  address: AddressInputObject;
  email: Scalars['String'];
  gender: Scalars['String'];
  indexNo?: InputMaybe<Scalars['String']>;
  legalRepresentative: Scalars['Boolean'];
  majorityShareholder: Scalars['Boolean'];
  name: NameInputObject;
  rfc: Scalars['String'];
  secondaryShareholder: Scalars['Boolean'];
  shareholderId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of SubmitApplication */
export type SubmitApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  consent: Scalars['Boolean'];
  id: Scalars['ID'];
};

/** Autogenerated return type of SubmitApplication */
export type SubmitApplicationPayload = {
  __typename?: 'SubmitApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
};

export type Tag = {
  __typename?: 'Tag';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  parentTagId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TagInputObject = {
  id: Scalars['Int'];
};

export type Test = {
  __typename?: 'Test';
  testField: Scalars['String'];
};

/** Autogenerated input type of UpdateBuyer */
export type UpdateBuyerInput = {
  buyer: BuyerInputObject;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  index: Scalars['Int'];
};

/** Autogenerated return type of UpdateBuyer */
export type UpdateBuyerPayload = {
  __typename?: 'UpdateBuyerPayload';
  buyer?: Maybe<Buyer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateCompanyFinancialInfo */
export type UpdateCompanyFinancialInfoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  company: CompanyFinancialInputObject;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateCompanyFinancialInfo */
export type UpdateCompanyFinancialInfoPayload = {
  __typename?: 'UpdateCompanyFinancialInfoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyFinancial>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateOwner */
export type UpdateOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  index: Scalars['Int'];
  owner: OwnerInputObject;
};

/** Autogenerated return type of UpdateOwner */
export type UpdateOwnerPayload = {
  __typename?: 'UpdateOwnerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
  owner?: Maybe<Owner>;
};

/** Autogenerated input type of UpdateScfImporter */
export type UpdateScfImporterInput = {
  businessId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  scfImporter: ScfImporterUpdateInputObject;
};

/** Autogenerated return type of UpdateScfImporter */
export type UpdateScfImporterPayload = {
  __typename?: 'UpdateScfImporterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
  scfImporter?: Maybe<Business>;
};

/** Autogenerated input type of UpdateShareholderFinancialInfo */
export type UpdateShareholderFinancialInfoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  shareholder: ShareholderFinancialInputObject;
};

/** Autogenerated return type of UpdateShareholderFinancialInfo */
export type UpdateShareholderFinancialInfoPayload = {
  __typename?: 'UpdateShareholderFinancialInfoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
  shareholder?: Maybe<ShareholderFinancial>;
};

/** Autogenerated input type of UpdateStatus */
export type UpdateStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateStatus */
export type UpdateStatusPayload = {
  __typename?: 'UpdateStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message: Scalars['String'];
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  user: UserUpdateInputObject;
  userId: Scalars['ID'];
};

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
  message?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UploadCiec */
export type UploadCiecInput = {
  ciec: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  rfc: Scalars['String'];
};

/** Autogenerated return type of UploadCiec */
export type UploadCiecPayload = {
  __typename?: 'UploadCiecPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  citizenship?: Maybe<Tag>;
  consumerCreditConsent?: Maybe<Scalars['String']>;
  detailsPending?: Maybe<Scalars['Boolean']>;
  dob?: Maybe<Scalars['ISO8601Date']>;
  email: Scalars['String'];
  id: Scalars['String'];
  identifiers?: Maybe<Array<Tag>>;
  name: Scalars['String'];
  onfidoUploadCompleted?: Maybe<Scalars['Boolean']>;
  phone: PhoneNumber;
  title?: Maybe<Scalars['String']>;
};

export type UserInputObject = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<PhoneInputObject>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  accountManager?: Maybe<User>;
  businesses?: Maybe<Array<Business>>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  utype?: Maybe<Scalars['String']>;
};

export type UserUpdateInputObject = {
  citizenship?: InputMaybe<TagInputObject>;
  dob?: InputMaybe<Scalars['ISO8601Date']>;
  identifiers?: InputMaybe<Array<IdentifierInputObject>>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<PhoneInputObject>;
};

/** Autogenerated input type of VerifyCibilOtp */
export type VerifyCibilOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  otp: Scalars['String'];
};

/** Autogenerated return type of VerifyCibilOtp */
export type VerifyCibilOtpPayload = {
  __typename?: 'VerifyCibilOtpPayload';
  cibilUrl?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<CustomError>;
};

export type AddOwnerMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  owned?: InputMaybe<Scalars['String']>;
  pan?: InputMaybe<Scalars['String']>;
  rfc?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  year: Scalars['String'];
  month: Scalars['String'];
  day: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  principleShareholder?: InputMaybe<Scalars['Boolean']>;
  legalRepresentative?: InputMaybe<Scalars['Boolean']>;
  secondaryShareholder?: InputMaybe<Scalars['Boolean']>;
}>;

export type AddOwnerMutation = {
  __typename?: 'Mutation';
  addOwner?: { __typename?: 'AddOwnerPayload'; message?: string | null } | null;
};

export type UpdateOwnerMutationVariables = Exact<{
  id: Scalars['ID'];
  index: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  owned?: InputMaybe<Scalars['String']>;
  pan?: InputMaybe<Scalars['String']>;
  rfc?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  year: Scalars['String'];
  month: Scalars['String'];
  day: Scalars['String'];
  principleShareholder?: InputMaybe<Scalars['Boolean']>;
  legalRepresentative?: InputMaybe<Scalars['Boolean']>;
  secondaryShareholder?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateOwnerMutation = {
  __typename?: 'Mutation';
  updateOwner?: {
    __typename?: 'UpdateOwnerPayload';
    message?: string | null;
  } | null;
};

export type DeleteOwnerMutationVariables = Exact<{
  id: Scalars['ID'];
  index: Scalars['Int'];
}>;

export type DeleteOwnerMutation = {
  __typename?: 'Mutation';
  deleteOwner?: {
    __typename?: 'DeleteOwnerPayload';
    message?: string | null;
  } | null;
};

export type SendCibilOtpMutationVariables = Exact<{
  id: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
}>;

export type SendCibilOtpMutation = {
  __typename?: 'Mutation';
  sendCibilOtp?: {
    __typename?: 'SendCibilOtpPayload';
    message?: string | null;
    error?: {
      __typename?: 'CustomError';
      message: string;
      path?: Array<string> | null;
    } | null;
  } | null;
};

export type AddCompanyMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  companyName: Scalars['String'];
  email: Scalars['String'];
  rfc: Scalars['String'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  municipality: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  street: Scalars['String'];
}>;

export type AddCompanyMutation = {
  __typename?: 'Mutation';
  addCompany?: {
    __typename?: 'AddCompanyFinancialInfoPayload';
    message?: string | null;
    error?: { __typename?: 'CustomError'; message: string } | null;
  } | null;
};

export type UpdateCompanyDetailsMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  companyName: Scalars['String'];
  email: Scalars['String'];
  rfc: Scalars['String'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  municipality: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  street: Scalars['String'];
}>;

export type UpdateCompanyDetailsMutation = {
  __typename?: 'Mutation';
  updateCompany?: {
    __typename?: 'UpdateCompanyFinancialInfoPayload';
    message?: string | null;
    error?: { __typename?: 'CustomError'; message: string } | null;
  } | null;
};

export type AddShareholderMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  index?: InputMaybe<Scalars['String']>;
  firstname: Scalars['String'];
  middlename: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  rfc: Scalars['String'];
  gender: Scalars['String'];
  majorityShareholder: Scalars['Boolean'];
  legalRepresentative: Scalars['Boolean'];
  secondaryShareholder: Scalars['Boolean'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  municipality: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  street: Scalars['String'];
}>;

export type AddShareholderMutation = {
  __typename?: 'Mutation';
  addShareholder?: {
    __typename?: 'AddShareholderFinancialInfoPayload';
    message?: string | null;
    error?: { __typename?: 'CustomError'; message: string } | null;
  } | null;
};

export type VerifyCibilOtpMutationVariables = Exact<{
  id: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  otp: Scalars['String'];
}>;

export type VerifyCibilOtpMutation = {
  __typename?: 'Mutation';
  verifyCibilOtp?: {
    __typename?: 'VerifyCibilOtpPayload';
    cibilUrl?: string | null;
    clientMutationId?: string | null;
    error?: {
      __typename?: 'CustomError';
      message: string;
      path?: Array<string> | null;
    } | null;
  } | null;
};

export type UpdateShareholderDetailsMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  indexNo?: InputMaybe<Scalars['String']>;
  firstname: Scalars['String'];
  middlename: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  rfc: Scalars['String'];
  shareholderId?: InputMaybe<Scalars['String']>;
  gender: Scalars['String'];
  majorityShareholder: Scalars['Boolean'];
  legalRepresentative: Scalars['Boolean'];
  secondaryShareholder: Scalars['Boolean'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  municipality: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  street: Scalars['String'];
}>;

export type UpdateShareholderDetailsMutation = {
  __typename?: 'Mutation';
  updateShareholder?: {
    __typename?: 'UpdateShareholderFinancialInfoPayload';
    message?: string | null;
    error?: { __typename?: 'CustomError'; message: string } | null;
  } | null;
};

export type RegisterScfBusinessMutationVariables = Exact<{
  company: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
  dunsNo?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
}>;

export type RegisterScfBusinessMutation = {
  __typename?: 'Mutation';
  createScfImporter?: {
    __typename?: 'CreateScfImporterPayload';
    message?: string | null;
    clientMutationId?: string | null;
    scfImporter?: {
      __typename?: 'Business';
      businessIdentifierKey?: string | null;
      businessIdentifierValue?: string | null;
      companyName?: string | null;
      id: string;
      productCategory?: string | null;
      status?: string | null;
      typeOfGoods?: string | null;
      dripProfile?: Array<{
        __typename?: 'DripProfile';
        designation?: string | null;
        id: string;
        onboardingStage?: string | null;
        profileType?: string | null;
      }> | null;
      location?: {
        __typename?: 'Location';
        address?: string | null;
        zipCode?: string | null;
        country?: { __typename?: 'Tag'; name?: string | null } | null;
        state?: { __typename?: 'Tag'; name?: string | null } | null;
      } | null;
    } | null;
    error?: {
      __typename?: 'CustomError';
      message: string;
      path?: Array<string> | null;
    } | null;
  } | null;
};

export type AddBuyerMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  idType?: InputMaybe<Scalars['String']>;
  idNumber?: InputMaybe<Scalars['String']>;
  rfc?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<PhoneInputObject>;
  writeoffs?: InputMaybe<Scalars['String']>;
  pastSales?: InputMaybe<Scalars['String']>;
  paymentTerms?: InputMaybe<Scalars['String']>;
  paymentTenor?: InputMaybe<Scalars['String']>;
  currentSales?: InputMaybe<Scalars['String']>;
  paymentDays?: InputMaybe<Scalars['String']>;
  related?: InputMaybe<Scalars['String']>;
  shippingTerms?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  hasInvoice?: InputMaybe<Scalars['Boolean']>;
  documentReleaseType?: InputMaybe<Scalars['String']>;
  documentSendType?: InputMaybe<Scalars['String']>;
  canSubmitAllBill?: InputMaybe<Scalars['Boolean']>;
  dripLimit?: InputMaybe<Scalars['Int']>;
  addToOfferProfile?: InputMaybe<Scalars['String']>;
  operationDrip?: InputMaybe<Scalars['String']>;
}>;

export type AddBuyerMutation = {
  __typename?: 'Mutation';
  createBuyer?: {
    __typename?: 'CreateBuyerPayload';
    message?: string | null;
  } | null;
};

export type UpdateBuyerMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  idType?: InputMaybe<Scalars['String']>;
  idNumber?: InputMaybe<Scalars['String']>;
  rfc?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<PhoneInputObject>;
  writeoffs?: InputMaybe<Scalars['String']>;
  pastSales?: InputMaybe<Scalars['String']>;
  paymentTerms?: InputMaybe<Scalars['String']>;
  paymentTenor?: InputMaybe<Scalars['String']>;
  currentSales?: InputMaybe<Scalars['String']>;
  paymentDays?: InputMaybe<Scalars['String']>;
  related?: InputMaybe<Scalars['String']>;
  shippingTerms?: InputMaybe<Scalars['String']>;
  index: Scalars['Int'];
  hasInvoice?: InputMaybe<Scalars['Boolean']>;
  documentReleaseType?: InputMaybe<Scalars['String']>;
  documentSendType?: InputMaybe<Scalars['String']>;
  canSubmitAllBill?: InputMaybe<Scalars['Boolean']>;
  dripLimit?: InputMaybe<Scalars['Int']>;
  addToOfferProfile?: InputMaybe<Scalars['String']>;
  operationDrip?: InputMaybe<Scalars['String']>;
}>;

export type UpdateBuyerMutation = {
  __typename?: 'Mutation';
  updateBuyer?: {
    __typename?: 'UpdateBuyerPayload';
    message?: string | null;
  } | null;
};

export type DeleteBuyerMutationVariables = Exact<{
  id: Scalars['ID'];
  index: Scalars['Int'];
}>;

export type DeleteBuyerMutation = {
  __typename?: 'Mutation';
  deleteBuyer?: {
    __typename?: 'DeleteBuyerPayload';
    message?: string | null;
  } | null;
};

export type SubmitApplicationMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  consent: Scalars['Boolean'];
}>;

export type SubmitApplicationMutation = {
  __typename?: 'Mutation';
  submitApplication?: {
    __typename?: 'SubmitApplicationPayload';
    error?: { __typename?: 'CustomError'; message: string } | null;
  } | null;
};

export type UpdateStatusMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UpdateStatusMutation = {
  __typename?: 'Mutation';
  updateStatus?: {
    __typename?: 'UpdateStatusPayload';
    message: string;
    error?: {
      __typename?: 'CustomError';
      message: string;
      path?: Array<string> | null;
    } | null;
  } | null;
};

export type LogoutMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
}>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout?: {
    __typename?: 'DeleteSessionPayload';
    message?: string | null;
    error?: { __typename?: 'CustomError'; message: string } | null;
  } | null;
};

export type RemoveFileMutationVariables = Exact<{
  input: RemoveDocumentInput;
}>;

export type RemoveFileMutation = {
  __typename?: 'Mutation';
  removeDocument?: {
    __typename?: 'RemoveDocumentPayload';
    id?: string | null;
    message?: string | null;
    error?: {
      __typename?: 'CustomError';
      message: string;
      path?: Array<string> | null;
    } | null;
  } | null;
};

export type AcceptOfferMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AcceptOfferMutation = {
  __typename?: 'Mutation';
  acceptOffer?: { __typename?: 'AcceptOfferPayload'; message: string } | null;
};

export type OfferESignMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OfferESignMutation = {
  __typename?: 'Mutation';
  offerEsign?: {
    __typename?: 'OfferEsignPayload';
    message: string;
    done?: boolean | null;
    url?: string | null;
    error?: {
      __typename?: 'CustomError';
      message: string;
      path?: Array<string> | null;
    } | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  countryCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  title: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: {
    __typename?: 'CreateUserPayload';
    user?: { __typename?: 'User'; id: string } | null;
    errors?: Array<{
      __typename?: 'CustomError';
      message: string;
      path?: Array<string> | null;
    }> | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'UpdateUserPayload';
    message?: string | null;
    error?: {
      __typename?: 'CustomError';
      message: string;
      path?: Array<string> | null;
    } | null;
  } | null;
};

export type UpdateScfImporterMutationVariables = Exact<{
  input: UpdateScfImporterInput;
}>;

export type UpdateScfImporterMutation = {
  __typename?: 'Mutation';
  updateScfImporter?: {
    __typename?: 'UpdateScfImporterPayload';
    message?: string | null;
    error?: {
      __typename?: 'CustomError';
      message: string;
      path?: Array<string> | null;
    } | null;
  } | null;
};

export type CreateContactMutationVariables = Exact<{
  password: Scalars['String'];
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact?: {
    __typename?: 'CreateContactPayload';
    message?: string | null;
    error?: {
      __typename?: 'CustomError';
      message: string;
      path?: Array<string> | null;
    } | null;
  } | null;
};

export type UploadCiecMutationVariables = Exact<{
  id: Scalars['ID'];
  rfc: Scalars['String'];
  ciec: Scalars['String'];
}>;

export type UploadCiecMutation = {
  __typename?: 'Mutation';
  uploadCiec?: { __typename?: 'UploadCiecPayload'; message: string } | null;
};

export type GenerateOnFidoSdkTokenMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']>;
}>;

export type GenerateOnFidoSdkTokenMutation = {
  __typename?: 'Mutation';
  onfidoGenerateSdkToken?: {
    __typename?: 'OnfidoGenerateSdkTokenPayload';
    generateSdkToken?: {
      __typename?: 'OnfidoGenerateSdkToken';
      onfidoSdkToken: string;
    } | null;
    errors?: Array<{
      __typename?: 'CustomError';
      message: string;
      path?: Array<string> | null;
    }> | null;
  } | null;
};

export type OnfidoDocumentUploadMutationVariables = Exact<{
  clientMutationId?: InputMaybe<Scalars['String']>;
}>;

export type OnfidoDocumentUploadMutation = {
  __typename?: 'Mutation';
  onfidoDocumentUploadCompleted?: {
    __typename?: 'OnfidoDocumentUploadCompletedPayload';
    message?: string | null;
  } | null;
};

export type GetOwnersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOwnersQuery = {
  __typename?: 'Query';
  owners?: Array<{
    __typename?: 'Owner';
    index: number;
    name?: string | null;
    owned?: string | null;
    pan?: string | null;
    rfc?: string | null;
    legalRepresentative?: boolean | null;
    majorityShareholder?: boolean | null;
    secondaryShareholder?: boolean | null;
    gender?: string | null;
    dob?: {
      __typename?: 'Date';
      year?: string | null;
      day?: string | null;
      month?: string | null;
    } | null;
  }> | null;
};

export type FinancialOwnersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FinancialOwnersQuery = {
  __typename?: 'Query';
  owners?: Array<{
    __typename?: 'Owner';
    index: number;
    name?: string | null;
    gender?: string | null;
    rfc?: string | null;
    legalRepresentative?: boolean | null;
    majorityShareholder?: boolean | null;
    secondaryShareholder?: boolean | null;
  }> | null;
};

export type GetGstDetailsQueryVariables = Exact<{
  exporterId: Scalars['ID'];
}>;

export type GetGstDetailsQuery = {
  __typename?: 'Query';
  getGstDetails?: Array<{
    __typename?: 'Gst';
    credLink?: string | null;
    gstNo: string;
    id?: string | null;
    recordStatus: string;
  }> | null;
};

export type ShareholderFinanceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ShareholderFinanceQuery = {
  __typename?: 'Query';
  shareholders?: Array<{
    __typename?: 'ShareholderFinancial';
    indexNo?: number | null;
    email?: string | null;
    rfc?: string | null;
    status?: string | null;
    gender?: string | null;
    shareholderId?: string | null;
    legalRepresentative?: boolean | null;
    majorityShareholder?: boolean | null;
    secondaryShareholder?: boolean | null;
    name?: {
      __typename?: 'Name';
      firstname?: string | null;
      middlename?: string | null;
      lastname1?: string | null;
      lastname2?: string | null;
    } | null;
    address?: {
      __typename?: 'Address';
      city?: string | null;
      country?: string | null;
      municipality?: string | null;
      state?: string | null;
      street?: string | null;
      zipcode?: string | null;
    } | null;
    file?: {
      __typename?: 'CreditReport';
      name?: string | null;
      url?: string | null;
    } | null;
  }> | null;
};

export type CompanyFinanceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CompanyFinanceQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'CompanyFinancial';
    companyName?: string | null;
    email?: string | null;
    rfc?: string | null;
    status?: string | null;
    address?: {
      __typename?: 'Address';
      city?: string | null;
      country?: string | null;
      municipality?: string | null;
      state?: string | null;
      street?: string | null;
      zipcode?: string | null;
    } | null;
    file?: {
      __typename?: 'CreditReport';
      name?: string | null;
      url?: string | null;
    } | null;
  } | null;
};

export type GetDunsStatusQueryVariables = Exact<{
  duns: Scalars['String'];
}>;

export type GetDunsStatusQuery = {
  __typename?: 'Query';
  getDuns?: {
    __typename?: 'Business';
    businessIdentifierKey?: string | null;
    businessIdentifierValue?: string | null;
    companyName?: string | null;
    id: string;
    productCategory?: string | null;
    status?: string | null;
    typeOfGoods?: string | null;
    dripProfile?: Array<{
      __typename?: 'DripProfile';
      designation?: string | null;
      id: string;
      profileType?: string | null;
    }> | null;
    location?: {
      __typename?: 'Location';
      address?: string | null;
      zipCode?: string | null;
      country?: { __typename?: 'Tag'; name?: string | null } | null;
      state?: { __typename?: 'Tag'; name?: string | null } | null;
    } | null;
  } | null;
};

export type EsignStatusQueryVariables = Exact<{
  id: Scalars['ID'];
  shareholderId?: InputMaybe<Scalars['String']>;
}>;

export type EsignStatusQuery = {
  __typename?: 'Query';
  esignStatus?: {
    __typename?: 'EsignStatus';
    esignStatus?: string | null;
  } | null;
};

export type EsignCredentialsQueryVariables = Exact<{
  id: Scalars['ID'];
  shareholderId?: InputMaybe<Scalars['String']>;
}>;

export type EsignCredentialsQuery = {
  __typename?: 'Query';
  esignCredentials?: {
    __typename?: 'EsignCredential';
    apiKey?: string | null;
    documentId?: string | null;
    signatoryId?: string | null;
  } | null;
};

export type GetBuyersQueryVariables = Exact<{
  exporterId: Scalars['ID'];
}>;

export type GetBuyersQuery = {
  __typename?: 'Query';
  buyers?: Array<{
    __typename?: 'Buyer';
    index?: number | null;
    address?: string | null;
    contactEmail?: string | null;
    contactName?: string | null;
    country?: string | null;
    currency?: string | null;
    currentSales?: string | null;
    hasInvoice?: boolean | null;
    idType?: string | null;
    name?: string | null;
    idNumber?: string | null;
    pastSales?: string | null;
    paymentDays?: string | null;
    paymentTerms?: string | null;
    paymentTenor?: string | null;
    related?: string | null;
    rfc?: string | null;
    shippingTerms?: string | null;
    writeoffs?: string | null;
    documentReleaseType?: string | null;
    documentSendType?: string | null;
    canSubmitAllBill?: boolean | null;
    dripLimit?: number | null;
    addToOfferProfile?: string | null;
    operationDrip?: string | null;
    contactPhoneNumber?: {
      __typename?: 'PhoneNumber';
      countryCode?: string | null;
      phoneNumber?: string | null;
    } | null;
  }> | null;
};

export type GetBuyerQueryVariables = Exact<{
  exporterId: Scalars['ID'];
  index: Scalars['Int'];
}>;

export type GetBuyerQuery = {
  __typename?: 'Query';
  buyer?: {
    __typename?: 'Buyer';
    index?: number | null;
    address?: string | null;
    contactEmail?: string | null;
    contactName?: string | null;
    country?: string | null;
    currency?: string | null;
    currentSales?: string | null;
    hasInvoice?: boolean | null;
    idType?: string | null;
    name?: string | null;
    idNumber?: string | null;
    pastSales?: string | null;
    paymentDays?: string | null;
    paymentTerms?: string | null;
    related?: string | null;
    rfc?: string | null;
    shippingTerms?: string | null;
    writeoffs?: string | null;
    documentReleaseType?: string | null;
    documentSendType?: string | null;
    canSubmitAllBill?: boolean | null;
    dripLimit?: number | null;
    addToOfferProfile?: string | null;
    operationDrip?: string | null;
    contactPhoneNumber?: {
      __typename?: 'PhoneNumber';
      countryCode?: string | null;
      phoneNumber?: string | null;
    } | null;
  } | null;
};

export type GetMetaDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetMetaDataQuery = {
  __typename?: 'Query';
  metaData?: {
    __typename?: 'MetaData';
    countries?: Array<string> | null;
    currencyValues?: any | null;
    docsRelease?: any | null;
    docsRouting?: any | null;
    paymentDurations?: any | null;
    paymentTerms?: any | null;
    states?: Array<string> | null;
  } | null;
};

export type GetApplicationDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetApplicationDetailsQuery = {
  __typename?: 'Query';
  applicationDetails?: {
    __typename?: 'ApplicationDetails';
    message: string;
    application: {
      __typename?: 'Application';
      onboardingStage?: string | null;
      buyerInfo: {
        __typename?: 'BuyerInfo';
        present?: boolean | null;
        pending?: Array<{
          __typename?: 'Pending';
          index?: string | null;
          key?: string | null;
        }> | null;
      };
      companyInfo: {
        __typename?: 'CompanyInfo';
        present?: boolean | null;
        pending?: Array<{
          __typename?: 'Pending';
          index?: string | null;
          key?: string | null;
        }> | null;
      };
      financialInfo: {
        __typename?: 'FinInfo';
        cibil?: {
          __typename?: 'Cibil';
          present?: boolean | null;
          verified?: boolean | null;
        } | null;
        gst?: Array<{
          __typename?: 'GstDetail';
          gstNo?: string | null;
          recordStatus?: string | null;
        }> | null;
        itr?: {
          __typename?: 'Itr';
          status?: string | null;
          files?: Array<{
            __typename?: 'File';
            name?: string | null;
            url?: string | null;
            documentId?: string | null;
          }> | null;
        } | null;
      };
    };
  } | null;
};

export type GetMxApplicationStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMxApplicationStatusQuery = {
  __typename?: 'Query';
  applicationStatus?: {
    __typename?: 'ApplicationStatus';
    message: string;
    application: {
      __typename?: 'ApplicationInfo';
      onboardingStage?: string | null;
      legalDocs: any;
      buyerInfo: {
        __typename?: 'BuyerInfo';
        present?: boolean | null;
        pending?: Array<{
          __typename?: 'Pending';
          index?: string | null;
          key?: string | null;
        }> | null;
      };
      companyInfo: {
        __typename?: 'CompanyInfo';
        present?: boolean | null;
        pending?: Array<{
          __typename?: 'Pending';
          index?: string | null;
          key?: string | null;
        }> | null;
      };
      financialInfo: {
        __typename?: 'FinancialInfo';
        company?: {
          __typename?: 'Company';
          pending?: Array<any> | null;
          present?: boolean | null;
        } | null;
        shareholders?: {
          __typename?: 'Buyers';
          present?: boolean | null;
          pending?: Array<{
            __typename?: 'PendingBuyers';
            shareholderId?: string | null;
            key?: any | null;
          }> | null;
        } | null;
      };
    };
  } | null;
};

export type GetScfApplicationDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
}>;

export type GetScfApplicationDetailsQuery = {
  __typename?: 'Query';
  scfApplicationDetails?: {
    __typename?: 'ScfApplicationDetails';
    message: string;
    application: {
      __typename?: 'ScfApplication';
      uid: string;
      mailSent?: boolean | null;
      mailSentDate?: any | null;
      onboardingStage?: string | null;
      bankDetails?: {
        __typename?: 'BankDetail';
        plaidLinked?: boolean | null;
        plaidFailed?: boolean | null;
        status?: string | null;
        files?: Array<{
          __typename?: 'File';
          name?: string | null;
          url?: string | null;
          documentId?: string | null;
        }> | null;
      } | null;
      businessDetails: {
        __typename?: 'Business';
        website?: string | null;
        businessIdentifierKey?: string | null;
        businessIdentifierValue?: string | null;
        companyName?: string | null;
        dba?: string | null;
        detailsPending?: boolean | null;
        ein?: string | null;
        id: string;
        productCategory?: string | null;
        status?: string | null;
        typeOfGoods?: string | null;
        yearIncorporated?: string | null;
        ecomSeller?: {
          __typename?: 'EcomDetailObject';
          isEcomSeller?: string | null;
          ecomSellerLink?: string | null;
        } | null;
        dripProfile?: Array<{
          __typename?: 'DripProfile';
          designation?: string | null;
          id: string;
          onboardingStage?: string | null;
          profileType?: string | null;
        }> | null;
        location?: {
          __typename?: 'Location';
          address?: string | null;
          zipCode?: string | null;
          country?: {
            __typename?: 'Tag';
            name?: string | null;
            parentTagId?: string | null;
            type?: string | null;
          } | null;
          state?: {
            __typename?: 'Tag';
            name?: string | null;
            parentTagId?: string | null;
            type?: string | null;
          } | null;
          city?: { __typename?: 'Tag'; name?: string | null } | null;
        } | null;
        salesContact?: {
          __typename?: 'SalesContact';
          email?: string | null;
          name?: string | null;
          phNumber?: string | null;
        } | null;
        scfOwners?: Array<{
          __typename?: 'ScfOwner';
          email: string;
          name: string;
          ownership: string;
          userId?: number | null;
          phone?: {
            __typename?: 'PhoneNumber';
            countryCode?: string | null;
            phoneNumber?: string | null;
          } | null;
        }> | null;
      };
      financialDetails?: {
        __typename?: 'FileArray';
        status?: string | null;
        files?: Array<{
          __typename?: 'File';
          name?: string | null;
          url?: string | null;
          documentId?: string | null;
        }> | null;
      } | null;
      certificateOfIncorporation?: {
        __typename?: 'FileArray';
        status?: string | null;
        files?: Array<{
          __typename?: 'File';
          name?: string | null;
          url?: string | null;
          documentId?: string | null;
        }> | null;
      } | null;
      dbaDocuments?: {
        __typename?: 'FileArray';
        status?: string | null;
        files?: Array<{
          __typename?: 'File';
          name?: string | null;
          url?: string | null;
          documentId?: string | null;
        }> | null;
      } | null;
      personalDetails: {
        __typename?: 'User';
        consumerCreditConsent?: string | null;
        detailsPending?: boolean | null;
        email: string;
        id: string;
        name: string;
        onfidoUploadCompleted?: boolean | null;
        title?: string | null;
        dob?: any | null;
        phone: {
          __typename?: 'PhoneNumber';
          countryCode?: string | null;
          phoneNumber?: string | null;
        };
        citizenship?: {
          __typename?: 'Tag';
          id?: string | null;
          name?: string | null;
          type?: string | null;
          value?: string | null;
        } | null;
        identifiers?: Array<{
          __typename?: 'Tag';
          id?: string | null;
          name?: string | null;
          type?: string | null;
          value?: string | null;
        }> | null;
      };
    };
  } | null;
};

export type GetStatesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetStatesQuery = {
  __typename?: 'Query';
  states?: Array<string> | null;
};

export type ScfMetaDataQueryVariables = Exact<{ [key: string]: never }>;

export type ScfMetaDataQuery = {
  __typename?: 'Query';
  scfMetaData?: {
    __typename?: 'MetaData';
    allStates?: any | null;
    annualImportsList?: any | null;
    businessStructureList?: any | null;
    countries?: Array<string> | null;
    countriesWithId?: any | null;
    currencyValues?: any | null;
    docsRelease?: any | null;
    docsRouting?: any | null;
    paymentTerms?: any | null;
    paymentDurations?: any | null;
    productCategory?: any | null;
    states?: Array<string> | null;
  } | null;
};

export type GetOfferDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
  type?: InputMaybe<Scalars['String']>;
}>;

export type GetOfferDetailsQuery = {
  __typename?: 'Query';
  offerDetails?: {
    __typename?: 'Offer';
    currency?: string | null;
    offerDetails?: {
      __typename?: 'RfInOfferDetails';
      facilitySize?: string | null;
      interestRate?: string | null;
      establishimentFee?: string | null;
      maxCreditTenor?: string | null;
      advanceRate?: string | null;
    } | null;
    pricingDetails?: {
      __typename?: 'PricingDetails';
      overdueRate?: number | null;
      factoringCommission?: Array<{
        __typename?: 'FactoringCommission';
        days?: number | null;
        rate?: number | null;
      }> | null;
    } | null;
  } | null;
};

export type DownloadOfferLinkInQueryVariables = Exact<{
  id: Scalars['ID'];
  type: Scalars['String'];
}>;

export type DownloadOfferLinkInQuery = {
  __typename?: 'Query';
  downloadOffer?: string | null;
};

export type OfferDetailsMxQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OfferDetailsMxQuery = {
  __typename?: 'Query';
  offer?: {
    __typename?: 'OfferDetailsResponse';
    offerDetails?: {
      __typename?: 'OfferDetails';
      advanceRate?: number | null;
      creditLimit?: number | null;
      establishmentFee?: number | null;
      facilitySize?: number | null;
      interestRate?: number | null;
      maximumCreditTenor?: number | null;
      downloadLink?: string | null;
    } | null;
  } | null;
};

export type OfferESignStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OfferESignStatusQuery = {
  __typename?: 'Query';
  offerEsignStatus?: {
    __typename?: 'OfferEsignStatus';
    message?: string | null;
    status: string;
  } | null;
};

export type ScfFacilityDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ScfFacilityDetailsQuery = {
  __typename?: 'Query';
  scfFacilityDetails?: {
    __typename?: 'ScfFacilityDetails';
    message: string;
    error?: { __typename?: 'CustomError'; message: string } | null;
    facilityDetails: {
      __typename?: 'FacilityDetails';
      advanceRate?: string | null;
      facilitySize?: string | null;
      generatedDocUrl?: string | null;
      interestFee?: string | null;
      marginRate?: string | null;
      maxCreditPeriod?: number | null;
      offerGeneratedNotSent?: boolean | null;
      overdueRate?: string | null;
      setupFee?: string | null;
      transactionFee?: string | null;
      sentDate?: string | null;
      signedDate?: string | null;
    };
  } | null;
};

export type ScfGenerateOfferQueryVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['String'];
}>;

export type ScfGenerateOfferQuery = {
  __typename?: 'Query';
  scfGenerateOffer?: {
    __typename?: 'ScfGenerateOffer';
    message: string;
    offerDocumentUrl: string;
    error?: { __typename?: 'CustomError'; message: string } | null;
  } | null;
};

export type ScfSendOfferQueryVariables = Exact<{
  id: Scalars['ID'];
  cc: Array<Scalars['String']> | Scalars['String'];
}>;

export type ScfSendOfferQuery = {
  __typename?: 'Query';
  scfSendOffer?: {
    __typename?: 'ScfSendOffer';
    offerSent?: boolean | null;
    message: string;
    error?: { __typename?: 'CustomError'; message: string } | null;
  } | null;
};

export type UserProfileQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserProfileQuery = {
  __typename?: 'Query';
  userProfile?: {
    __typename?: 'UserProfile';
    id: string;
    name?: string | null;
    email?: string | null;
    status?: string | null;
    phone?: string | null;
    utype?: string | null;
    accountManager?: {
      __typename?: 'User';
      email: string;
      name: string;
    } | null;
    businesses?: Array<{
      __typename?: 'Business';
      id: string;
      businessIdentifierKey?: string | null;
      businessIdentifierValue?: string | null;
      productCategory?: string | null;
      typeOfGoods?: string | null;
      status?: string | null;
      companyName?: string | null;
      debtorType?: string | null;
      dripProfile?: Array<{
        __typename?: 'DripProfile';
        id: string;
        designation?: string | null;
        profileType?: string | null;
        onboardingStage?: string | null;
      }> | null;
      location?: {
        __typename?: 'Location';
        address?: string | null;
        country?: { __typename?: 'Tag'; name?: string | null } | null;
        state?: { __typename?: 'Tag'; name?: string | null } | null;
      } | null;
      salesContact?: {
        __typename?: 'SalesContact';
        name?: string | null;
        email?: string | null;
        phNumber?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type OnboardingApplicantsQueryVariables = Exact<{
  pageNo: Scalars['Int'];
  pageSize?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
}>;

export type OnboardingApplicantsQuery = {
  __typename?: 'Query';
  onboardingApplicants?: {
    __typename?: 'OnboardingLeads';
    totalLeads?: number | null;
    totalPages?: number | null;
    leads?: Array<{
      __typename?: 'ApplicantDetails';
      id: number;
      name?: string | null;
      company?: string | null;
      email?: string | null;
      cpName?: string | null;
      dajStage?: string | null;
    }> | null;
  } | null;
};

export type GetCiecStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCiecStatusQuery = {
  __typename?: 'Query';
  ciecStatus: {
    __typename?: 'Response';
    uid?: string | null;
    validation?: string | null;
    error?: {
      __typename?: 'CustomError';
      message: string;
      path?: Array<string> | null;
    } | null;
  };
};

export type ContactQueryVariables = Exact<{ [key: string]: never }>;

export type ContactQuery = {
  __typename?: 'Query';
  contact?: {
    __typename?: 'Contact';
    name?: string | null;
    company?: string | null;
    phone?: string | null;
    codeType?: string | null;
    code?: string | null;
  } | null;
};

export type ScfPrimarySignatoryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ScfPrimarySignatoryQuery = {
  __typename?: 'Query';
  scfPrimarySignatory?: {
    __typename?: 'ScfPrimarySignatory';
    message: string;
    primarySignatoryPresent?: boolean | null;
  } | null;
};

export const AddOwnerDocument = gql`
  mutation addOwner(
    $id: ID!
    $name: String
    $owned: String
    $pan: String
    $rfc: String
    $gender: String
    $year: String!
    $month: String!
    $day: String!
    $clientMutationId: String
    $principleShareholder: Boolean
    $legalRepresentative: Boolean
    $secondaryShareholder: Boolean
  ) {
    addOwner(
      input: {
        id: $id
        owner: {
          name: $name
          owned: $owned
          pan: $pan
          rfc: $rfc
          gender: $gender
          dob: { year: $year, month: $month, day: $day }
          majorityShareholder: $principleShareholder
          legalRepresentative: $legalRepresentative
          secondaryShareholder: $secondaryShareholder
        }
        clientMutationId: $clientMutationId
      }
    ) {
      message
    }
  }
`;
export type AddOwnerMutationFn = Apollo.MutationFunction<
  AddOwnerMutation,
  AddOwnerMutationVariables
>;

/**
 * __useAddOwnerMutation__
 *
 * To run a mutation, you first call `useAddOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOwnerMutation, { data, loading, error }] = useAddOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      owned: // value for 'owned'
 *      pan: // value for 'pan'
 *      rfc: // value for 'rfc'
 *      gender: // value for 'gender'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      day: // value for 'day'
 *      clientMutationId: // value for 'clientMutationId'
 *      principleShareholder: // value for 'principleShareholder'
 *      legalRepresentative: // value for 'legalRepresentative'
 *      secondaryShareholder: // value for 'secondaryShareholder'
 *   },
 * });
 */
export function useAddOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOwnerMutation,
    AddOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOwnerMutation, AddOwnerMutationVariables>(
    AddOwnerDocument,
    options
  );
}
export type AddOwnerMutationHookResult = ReturnType<typeof useAddOwnerMutation>;
export type AddOwnerMutationResult = Apollo.MutationResult<AddOwnerMutation>;
export type AddOwnerMutationOptions = Apollo.BaseMutationOptions<
  AddOwnerMutation,
  AddOwnerMutationVariables
>;
export const UpdateOwnerDocument = gql`
  mutation updateOwner(
    $id: ID!
    $index: Int!
    $name: String
    $owned: String
    $pan: String
    $rfc: String
    $gender: String
    $year: String!
    $month: String!
    $day: String!
    $principleShareholder: Boolean
    $legalRepresentative: Boolean
    $secondaryShareholder: Boolean
  ) {
    updateOwner(
      input: {
        id: $id
        index: $index
        owner: {
          name: $name
          owned: $owned
          pan: $pan
          rfc: $rfc
          gender: $gender
          dob: { year: $year, month: $month, day: $day }
          majorityShareholder: $principleShareholder
          legalRepresentative: $legalRepresentative
          secondaryShareholder: $secondaryShareholder
        }
      }
    ) {
      message
    }
  }
`;
export type UpdateOwnerMutationFn = Apollo.MutationFunction<
  UpdateOwnerMutation,
  UpdateOwnerMutationVariables
>;

/**
 * __useUpdateOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerMutation, { data, loading, error }] = useUpdateOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      index: // value for 'index'
 *      name: // value for 'name'
 *      owned: // value for 'owned'
 *      pan: // value for 'pan'
 *      rfc: // value for 'rfc'
 *      gender: // value for 'gender'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      day: // value for 'day'
 *      principleShareholder: // value for 'principleShareholder'
 *      legalRepresentative: // value for 'legalRepresentative'
 *      secondaryShareholder: // value for 'secondaryShareholder'
 *   },
 * });
 */
export function useUpdateOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnerMutation,
    UpdateOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOwnerMutation, UpdateOwnerMutationVariables>(
    UpdateOwnerDocument,
    options
  );
}
export type UpdateOwnerMutationHookResult = ReturnType<
  typeof useUpdateOwnerMutation
>;
export type UpdateOwnerMutationResult =
  Apollo.MutationResult<UpdateOwnerMutation>;
export type UpdateOwnerMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnerMutation,
  UpdateOwnerMutationVariables
>;
export const DeleteOwnerDocument = gql`
  mutation deleteOwner($id: ID!, $index: Int!) {
    deleteOwner(input: { id: $id, index: $index }) {
      message
    }
  }
`;
export type DeleteOwnerMutationFn = Apollo.MutationFunction<
  DeleteOwnerMutation,
  DeleteOwnerMutationVariables
>;

/**
 * __useDeleteOwnerMutation__
 *
 * To run a mutation, you first call `useDeleteOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnerMutation, { data, loading, error }] = useDeleteOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useDeleteOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOwnerMutation,
    DeleteOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOwnerMutation, DeleteOwnerMutationVariables>(
    DeleteOwnerDocument,
    options
  );
}
export type DeleteOwnerMutationHookResult = ReturnType<
  typeof useDeleteOwnerMutation
>;
export type DeleteOwnerMutationResult =
  Apollo.MutationResult<DeleteOwnerMutation>;
export type DeleteOwnerMutationOptions = Apollo.BaseMutationOptions<
  DeleteOwnerMutation,
  DeleteOwnerMutationVariables
>;
export const SendCibilOtpDocument = gql`
  mutation sendCibilOtp($id: ID!, $clientMutationId: String) {
    sendCibilOtp(input: { id: $id, clientMutationId: $clientMutationId }) {
      message
      error {
        message
        path
      }
    }
  }
`;
export type SendCibilOtpMutationFn = Apollo.MutationFunction<
  SendCibilOtpMutation,
  SendCibilOtpMutationVariables
>;

/**
 * __useSendCibilOtpMutation__
 *
 * To run a mutation, you first call `useSendCibilOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCibilOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCibilOtpMutation, { data, loading, error }] = useSendCibilOtpMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useSendCibilOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendCibilOtpMutation,
    SendCibilOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendCibilOtpMutation,
    SendCibilOtpMutationVariables
  >(SendCibilOtpDocument, options);
}
export type SendCibilOtpMutationHookResult = ReturnType<
  typeof useSendCibilOtpMutation
>;
export type SendCibilOtpMutationResult =
  Apollo.MutationResult<SendCibilOtpMutation>;
export type SendCibilOtpMutationOptions = Apollo.BaseMutationOptions<
  SendCibilOtpMutation,
  SendCibilOtpMutationVariables
>;
export const AddCompanyDocument = gql`
  mutation AddCompany(
    $clientMutationId: String
    $id: ID!
    $companyName: String!
    $email: String!
    $rfc: String!
    $zipcode: String!
    $state: String!
    $municipality: String!
    $country: String!
    $city: String!
    $street: String!
  ) {
    addCompany(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        company: {
          companyName: $companyName
          email: $email
          rfc: $rfc
          address: {
            zipcode: $zipcode
            state: $state
            municipality: $municipality
            country: $country
            city: $city
            street: $street
          }
        }
      }
    ) {
      message
      error {
        message
      }
    }
  }
`;
export type AddCompanyMutationFn = Apollo.MutationFunction<
  AddCompanyMutation,
  AddCompanyMutationVariables
>;

/**
 * __useAddCompanyMutation__
 *
 * To run a mutation, you first call `useAddCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyMutation, { data, loading, error }] = useAddCompanyMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      id: // value for 'id'
 *      companyName: // value for 'companyName'
 *      email: // value for 'email'
 *      rfc: // value for 'rfc'
 *      zipcode: // value for 'zipcode'
 *      state: // value for 'state'
 *      municipality: // value for 'municipality'
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      street: // value for 'street'
 *   },
 * });
 */
export function useAddCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCompanyMutation,
    AddCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCompanyMutation, AddCompanyMutationVariables>(
    AddCompanyDocument,
    options
  );
}
export type AddCompanyMutationHookResult = ReturnType<
  typeof useAddCompanyMutation
>;
export type AddCompanyMutationResult =
  Apollo.MutationResult<AddCompanyMutation>;
export type AddCompanyMutationOptions = Apollo.BaseMutationOptions<
  AddCompanyMutation,
  AddCompanyMutationVariables
>;
export const UpdateCompanyDetailsDocument = gql`
  mutation updateCompanyDetails(
    $clientMutationId: String
    $id: ID!
    $companyName: String!
    $email: String!
    $rfc: String!
    $zipcode: String!
    $state: String!
    $municipality: String!
    $country: String!
    $city: String!
    $street: String!
  ) {
    updateCompany(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        company: {
          companyName: $companyName
          email: $email
          rfc: $rfc
          address: {
            zipcode: $zipcode
            state: $state
            municipality: $municipality
            country: $country
            city: $city
            street: $street
          }
        }
      }
    ) {
      error {
        message
      }
      message
    }
  }
`;
export type UpdateCompanyDetailsMutationFn = Apollo.MutationFunction<
  UpdateCompanyDetailsMutation,
  UpdateCompanyDetailsMutationVariables
>;

/**
 * __useUpdateCompanyDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyDetailsMutation, { data, loading, error }] = useUpdateCompanyDetailsMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      id: // value for 'id'
 *      companyName: // value for 'companyName'
 *      email: // value for 'email'
 *      rfc: // value for 'rfc'
 *      zipcode: // value for 'zipcode'
 *      state: // value for 'state'
 *      municipality: // value for 'municipality'
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      street: // value for 'street'
 *   },
 * });
 */
export function useUpdateCompanyDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyDetailsMutation,
    UpdateCompanyDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyDetailsMutation,
    UpdateCompanyDetailsMutationVariables
  >(UpdateCompanyDetailsDocument, options);
}
export type UpdateCompanyDetailsMutationHookResult = ReturnType<
  typeof useUpdateCompanyDetailsMutation
>;
export type UpdateCompanyDetailsMutationResult =
  Apollo.MutationResult<UpdateCompanyDetailsMutation>;
export type UpdateCompanyDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyDetailsMutation,
  UpdateCompanyDetailsMutationVariables
>;
export const AddShareholderDocument = gql`
  mutation AddShareholder(
    $clientMutationId: String
    $id: ID!
    $index: String
    $firstname: String!
    $middlename: String!
    $lastname: String!
    $email: String!
    $rfc: String!
    $gender: String!
    $majorityShareholder: Boolean!
    $legalRepresentative: Boolean!
    $secondaryShareholder: Boolean!
    $zipcode: String!
    $state: String!
    $municipality: String!
    $country: String!
    $city: String!
    $street: String!
  ) {
    addShareholder(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        shareholder: {
          name: {
            firstname: $firstname
            middlename: $middlename
            lastname1: $middlename
            lastname2: $lastname
          }
          rfc: $rfc
          email: $email
          gender: $gender
          indexNo: $index
          majorityShareholder: $majorityShareholder
          legalRepresentative: $legalRepresentative
          secondaryShareholder: $secondaryShareholder
          address: {
            zipcode: $zipcode
            state: $state
            municipality: $municipality
            country: $country
            city: $city
            street: $street
          }
        }
      }
    ) {
      message
      error {
        message
      }
    }
  }
`;
export type AddShareholderMutationFn = Apollo.MutationFunction<
  AddShareholderMutation,
  AddShareholderMutationVariables
>;

/**
 * __useAddShareholderMutation__
 *
 * To run a mutation, you first call `useAddShareholderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShareholderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShareholderMutation, { data, loading, error }] = useAddShareholderMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      id: // value for 'id'
 *      index: // value for 'index'
 *      firstname: // value for 'firstname'
 *      middlename: // value for 'middlename'
 *      lastname: // value for 'lastname'
 *      email: // value for 'email'
 *      rfc: // value for 'rfc'
 *      gender: // value for 'gender'
 *      majorityShareholder: // value for 'majorityShareholder'
 *      legalRepresentative: // value for 'legalRepresentative'
 *      secondaryShareholder: // value for 'secondaryShareholder'
 *      zipcode: // value for 'zipcode'
 *      state: // value for 'state'
 *      municipality: // value for 'municipality'
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      street: // value for 'street'
 *   },
 * });
 */
export function useAddShareholderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddShareholderMutation,
    AddShareholderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddShareholderMutation,
    AddShareholderMutationVariables
  >(AddShareholderDocument, options);
}
export type AddShareholderMutationHookResult = ReturnType<
  typeof useAddShareholderMutation
>;
export type AddShareholderMutationResult =
  Apollo.MutationResult<AddShareholderMutation>;
export type AddShareholderMutationOptions = Apollo.BaseMutationOptions<
  AddShareholderMutation,
  AddShareholderMutationVariables
>;
export const VerifyCibilOtpDocument = gql`
  mutation verifyCibilOtp($id: ID!, $clientMutationId: String, $otp: String!) {
    verifyCibilOtp(
      input: { id: $id, clientMutationId: $clientMutationId, otp: $otp }
    ) {
      cibilUrl
      clientMutationId
      error {
        message
        path
      }
    }
  }
`;
export type VerifyCibilOtpMutationFn = Apollo.MutationFunction<
  VerifyCibilOtpMutation,
  VerifyCibilOtpMutationVariables
>;

/**
 * __useVerifyCibilOtpMutation__
 *
 * To run a mutation, you first call `useVerifyCibilOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCibilOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCibilOtpMutation, { data, loading, error }] = useVerifyCibilOtpMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clientMutationId: // value for 'clientMutationId'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useVerifyCibilOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyCibilOtpMutation,
    VerifyCibilOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyCibilOtpMutation,
    VerifyCibilOtpMutationVariables
  >(VerifyCibilOtpDocument, options);
}
export type VerifyCibilOtpMutationHookResult = ReturnType<
  typeof useVerifyCibilOtpMutation
>;
export type VerifyCibilOtpMutationResult =
  Apollo.MutationResult<VerifyCibilOtpMutation>;
export type VerifyCibilOtpMutationOptions = Apollo.BaseMutationOptions<
  VerifyCibilOtpMutation,
  VerifyCibilOtpMutationVariables
>;
export const UpdateShareholderDetailsDocument = gql`
  mutation updateShareholderDetails(
    $clientMutationId: String
    $id: ID!
    $indexNo: String
    $firstname: String!
    $middlename: String!
    $lastname: String!
    $email: String!
    $rfc: String!
    $shareholderId: String
    $gender: String!
    $majorityShareholder: Boolean!
    $legalRepresentative: Boolean!
    $secondaryShareholder: Boolean!
    $zipcode: String!
    $state: String!
    $municipality: String!
    $country: String!
    $city: String!
    $street: String!
  ) {
    updateShareholder(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        shareholder: {
          name: {
            firstname: $firstname
            middlename: $middlename
            lastname1: $middlename
            lastname2: $lastname
          }
          email: $email
          rfc: $rfc
          gender: $gender
          indexNo: $indexNo
          shareholderId: $shareholderId
          majorityShareholder: $majorityShareholder
          legalRepresentative: $legalRepresentative
          secondaryShareholder: $secondaryShareholder
          address: {
            zipcode: $zipcode
            state: $state
            municipality: $municipality
            country: $country
            city: $city
            street: $street
          }
        }
      }
    ) {
      error {
        message
      }
      message
    }
  }
`;
export type UpdateShareholderDetailsMutationFn = Apollo.MutationFunction<
  UpdateShareholderDetailsMutation,
  UpdateShareholderDetailsMutationVariables
>;

/**
 * __useUpdateShareholderDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateShareholderDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareholderDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareholderDetailsMutation, { data, loading, error }] = useUpdateShareholderDetailsMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      id: // value for 'id'
 *      indexNo: // value for 'indexNo'
 *      firstname: // value for 'firstname'
 *      middlename: // value for 'middlename'
 *      lastname: // value for 'lastname'
 *      email: // value for 'email'
 *      rfc: // value for 'rfc'
 *      shareholderId: // value for 'shareholderId'
 *      gender: // value for 'gender'
 *      majorityShareholder: // value for 'majorityShareholder'
 *      legalRepresentative: // value for 'legalRepresentative'
 *      secondaryShareholder: // value for 'secondaryShareholder'
 *      zipcode: // value for 'zipcode'
 *      state: // value for 'state'
 *      municipality: // value for 'municipality'
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      street: // value for 'street'
 *   },
 * });
 */
export function useUpdateShareholderDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShareholderDetailsMutation,
    UpdateShareholderDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShareholderDetailsMutation,
    UpdateShareholderDetailsMutationVariables
  >(UpdateShareholderDetailsDocument, options);
}
export type UpdateShareholderDetailsMutationHookResult = ReturnType<
  typeof useUpdateShareholderDetailsMutation
>;
export type UpdateShareholderDetailsMutationResult =
  Apollo.MutationResult<UpdateShareholderDetailsMutation>;
export type UpdateShareholderDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateShareholderDetailsMutation,
    UpdateShareholderDetailsMutationVariables
  >;
export const RegisterScfBusinessDocument = gql`
  mutation registerSCFBusiness(
    $company: String!
    $country: String
    $state: String
    $address: String
    $category: String
    $products: String
    $customerId: String
    $zipCode: String
    $dunsNo: String
    $clientMutationId: String
  ) {
    createScfImporter(
      input: {
        scfImporter: {
          company: $company
          country: $country
          state: $state
          address: $address
          category: $category
          products: $products
          customerId: $customerId
          zipCode: $zipCode
          dunsNo: $dunsNo
        }
        clientMutationId: $clientMutationId
      }
    ) {
      scfImporter {
        businessIdentifierKey
        businessIdentifierValue
        companyName
        dripProfile {
          designation
          id
          onboardingStage
          profileType
        }
        id
        location {
          address
          country {
            name
          }
          state {
            name
          }
          zipCode
        }
        productCategory
        status
        typeOfGoods
      }
      message
      clientMutationId
      error {
        message
        path
      }
    }
  }
`;
export type RegisterScfBusinessMutationFn = Apollo.MutationFunction<
  RegisterScfBusinessMutation,
  RegisterScfBusinessMutationVariables
>;

/**
 * __useRegisterScfBusinessMutation__
 *
 * To run a mutation, you first call `useRegisterScfBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterScfBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerScfBusinessMutation, { data, loading, error }] = useRegisterScfBusinessMutation({
 *   variables: {
 *      company: // value for 'company'
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      address: // value for 'address'
 *      category: // value for 'category'
 *      products: // value for 'products'
 *      customerId: // value for 'customerId'
 *      zipCode: // value for 'zipCode'
 *      dunsNo: // value for 'dunsNo'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useRegisterScfBusinessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterScfBusinessMutation,
    RegisterScfBusinessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterScfBusinessMutation,
    RegisterScfBusinessMutationVariables
  >(RegisterScfBusinessDocument, options);
}
export type RegisterScfBusinessMutationHookResult = ReturnType<
  typeof useRegisterScfBusinessMutation
>;
export type RegisterScfBusinessMutationResult =
  Apollo.MutationResult<RegisterScfBusinessMutation>;
export type RegisterScfBusinessMutationOptions = Apollo.BaseMutationOptions<
  RegisterScfBusinessMutation,
  RegisterScfBusinessMutationVariables
>;
export const AddBuyerDocument = gql`
  mutation addBuyer(
    $id: ID!
    $name: String
    $address: String
    $country: String
    $idType: String
    $idNumber: String
    $rfc: String
    $currency: String
    $contactName: String
    $contactEmail: String
    $phone: PhoneInputObject
    $writeoffs: String
    $pastSales: String
    $paymentTerms: String
    $paymentTenor: String
    $currentSales: String
    $paymentDays: String
    $related: String
    $shippingTerms: String
    $clientMutationId: String
    $hasInvoice: Boolean
    $documentReleaseType: String
    $documentSendType: String
    $canSubmitAllBill: Boolean
    $dripLimit: Int
    $addToOfferProfile: String
    $operationDrip: String
  ) {
    createBuyer(
      input: {
        id: $id
        buyer: {
          name: $name
          address: $address
          country: $country
          idType: $idType
          idNumber: $idNumber
          rfc: $rfc
          currency: $currency
          contactName: $contactName
          contactEmail: $contactEmail
          contactPhoneNumber: $phone
          writeoffs: $writeoffs
          pastSales: $pastSales
          paymentTerms: $paymentTerms
          paymentTenor: $paymentTenor
          currentSales: $currentSales
          paymentDays: $paymentDays
          related: $related
          shippingTerms: $shippingTerms
          hasInvoice: $hasInvoice
          documentReleaseType: $documentReleaseType
          documentSendType: $documentSendType
          canSubmitAllBill: $canSubmitAllBill
          dripLimit: $dripLimit
          addToOfferProfile: $addToOfferProfile
          operationDrip: $operationDrip
        }
        clientMutationId: $clientMutationId
      }
    ) {
      message
    }
  }
`;
export type AddBuyerMutationFn = Apollo.MutationFunction<
  AddBuyerMutation,
  AddBuyerMutationVariables
>;

/**
 * __useAddBuyerMutation__
 *
 * To run a mutation, you first call `useAddBuyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBuyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBuyerMutation, { data, loading, error }] = useAddBuyerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      country: // value for 'country'
 *      idType: // value for 'idType'
 *      idNumber: // value for 'idNumber'
 *      rfc: // value for 'rfc'
 *      currency: // value for 'currency'
 *      contactName: // value for 'contactName'
 *      contactEmail: // value for 'contactEmail'
 *      phone: // value for 'phone'
 *      writeoffs: // value for 'writeoffs'
 *      pastSales: // value for 'pastSales'
 *      paymentTerms: // value for 'paymentTerms'
 *      paymentTenor: // value for 'paymentTenor'
 *      currentSales: // value for 'currentSales'
 *      paymentDays: // value for 'paymentDays'
 *      related: // value for 'related'
 *      shippingTerms: // value for 'shippingTerms'
 *      clientMutationId: // value for 'clientMutationId'
 *      hasInvoice: // value for 'hasInvoice'
 *      documentReleaseType: // value for 'documentReleaseType'
 *      documentSendType: // value for 'documentSendType'
 *      canSubmitAllBill: // value for 'canSubmitAllBill'
 *      dripLimit: // value for 'dripLimit'
 *      addToOfferProfile: // value for 'addToOfferProfile'
 *      operationDrip: // value for 'operationDrip'
 *   },
 * });
 */
export function useAddBuyerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBuyerMutation,
    AddBuyerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBuyerMutation, AddBuyerMutationVariables>(
    AddBuyerDocument,
    options
  );
}
export type AddBuyerMutationHookResult = ReturnType<typeof useAddBuyerMutation>;
export type AddBuyerMutationResult = Apollo.MutationResult<AddBuyerMutation>;
export type AddBuyerMutationOptions = Apollo.BaseMutationOptions<
  AddBuyerMutation,
  AddBuyerMutationVariables
>;
export const UpdateBuyerDocument = gql`
  mutation updateBuyer(
    $id: ID!
    $name: String
    $address: String
    $country: String
    $idType: String
    $idNumber: String
    $rfc: String
    $currency: String
    $contactName: String
    $contactEmail: String
    $phone: PhoneInputObject
    $writeoffs: String
    $pastSales: String
    $paymentTerms: String
    $paymentTenor: String
    $currentSales: String
    $paymentDays: String
    $related: String
    $shippingTerms: String
    $index: Int!
    $hasInvoice: Boolean
    $documentReleaseType: String
    $documentSendType: String
    $canSubmitAllBill: Boolean
    $dripLimit: Int
    $addToOfferProfile: String
    $operationDrip: String
  ) {
    updateBuyer(
      input: {
        id: $id
        index: $index
        buyer: {
          name: $name
          address: $address
          country: $country
          idType: $idType
          idNumber: $idNumber
          rfc: $rfc
          currency: $currency
          contactName: $contactName
          contactEmail: $contactEmail
          contactPhoneNumber: $phone
          writeoffs: $writeoffs
          pastSales: $pastSales
          paymentTerms: $paymentTerms
          paymentTenor: $paymentTenor
          currentSales: $currentSales
          paymentDays: $paymentDays
          related: $related
          shippingTerms: $shippingTerms
          hasInvoice: $hasInvoice
          documentReleaseType: $documentReleaseType
          documentSendType: $documentSendType
          canSubmitAllBill: $canSubmitAllBill
          dripLimit: $dripLimit
          addToOfferProfile: $addToOfferProfile
          operationDrip: $operationDrip
        }
      }
    ) {
      message
    }
  }
`;
export type UpdateBuyerMutationFn = Apollo.MutationFunction<
  UpdateBuyerMutation,
  UpdateBuyerMutationVariables
>;

/**
 * __useUpdateBuyerMutation__
 *
 * To run a mutation, you first call `useUpdateBuyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuyerMutation, { data, loading, error }] = useUpdateBuyerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      country: // value for 'country'
 *      idType: // value for 'idType'
 *      idNumber: // value for 'idNumber'
 *      rfc: // value for 'rfc'
 *      currency: // value for 'currency'
 *      contactName: // value for 'contactName'
 *      contactEmail: // value for 'contactEmail'
 *      phone: // value for 'phone'
 *      writeoffs: // value for 'writeoffs'
 *      pastSales: // value for 'pastSales'
 *      paymentTerms: // value for 'paymentTerms'
 *      paymentTenor: // value for 'paymentTenor'
 *      currentSales: // value for 'currentSales'
 *      paymentDays: // value for 'paymentDays'
 *      related: // value for 'related'
 *      shippingTerms: // value for 'shippingTerms'
 *      index: // value for 'index'
 *      hasInvoice: // value for 'hasInvoice'
 *      documentReleaseType: // value for 'documentReleaseType'
 *      documentSendType: // value for 'documentSendType'
 *      canSubmitAllBill: // value for 'canSubmitAllBill'
 *      dripLimit: // value for 'dripLimit'
 *      addToOfferProfile: // value for 'addToOfferProfile'
 *      operationDrip: // value for 'operationDrip'
 *   },
 * });
 */
export function useUpdateBuyerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBuyerMutation,
    UpdateBuyerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBuyerMutation, UpdateBuyerMutationVariables>(
    UpdateBuyerDocument,
    options
  );
}
export type UpdateBuyerMutationHookResult = ReturnType<
  typeof useUpdateBuyerMutation
>;
export type UpdateBuyerMutationResult =
  Apollo.MutationResult<UpdateBuyerMutation>;
export type UpdateBuyerMutationOptions = Apollo.BaseMutationOptions<
  UpdateBuyerMutation,
  UpdateBuyerMutationVariables
>;
export const DeleteBuyerDocument = gql`
  mutation deleteBuyer($id: ID!, $index: Int!) {
    deleteBuyer(input: { id: $id, index: $index }) {
      message
    }
  }
`;
export type DeleteBuyerMutationFn = Apollo.MutationFunction<
  DeleteBuyerMutation,
  DeleteBuyerMutationVariables
>;

/**
 * __useDeleteBuyerMutation__
 *
 * To run a mutation, you first call `useDeleteBuyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBuyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBuyerMutation, { data, loading, error }] = useDeleteBuyerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useDeleteBuyerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBuyerMutation,
    DeleteBuyerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBuyerMutation, DeleteBuyerMutationVariables>(
    DeleteBuyerDocument,
    options
  );
}
export type DeleteBuyerMutationHookResult = ReturnType<
  typeof useDeleteBuyerMutation
>;
export type DeleteBuyerMutationResult =
  Apollo.MutationResult<DeleteBuyerMutation>;
export type DeleteBuyerMutationOptions = Apollo.BaseMutationOptions<
  DeleteBuyerMutation,
  DeleteBuyerMutationVariables
>;
export const SubmitApplicationDocument = gql`
  mutation SubmitApplication(
    $clientMutationId: String
    $id: ID!
    $consent: Boolean!
  ) {
    submitApplication(
      input: { clientMutationId: $clientMutationId, id: $id, consent: $consent }
    ) {
      error {
        message
      }
    }
  }
`;
export type SubmitApplicationMutationFn = Apollo.MutationFunction<
  SubmitApplicationMutation,
  SubmitApplicationMutationVariables
>;

/**
 * __useSubmitApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitApplicationMutation, { data, loading, error }] = useSubmitApplicationMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      id: // value for 'id'
 *      consent: // value for 'consent'
 *   },
 * });
 */
export function useSubmitApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitApplicationMutation,
    SubmitApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitApplicationMutation,
    SubmitApplicationMutationVariables
  >(SubmitApplicationDocument, options);
}
export type SubmitApplicationMutationHookResult = ReturnType<
  typeof useSubmitApplicationMutation
>;
export type SubmitApplicationMutationResult =
  Apollo.MutationResult<SubmitApplicationMutation>;
export type SubmitApplicationMutationOptions = Apollo.BaseMutationOptions<
  SubmitApplicationMutation,
  SubmitApplicationMutationVariables
>;
export const UpdateStatusDocument = gql`
  mutation updateStatus($id: ID!) {
    updateStatus(input: { id: $id }) {
      error {
        message
        path
      }
      message
    }
  }
`;
export type UpdateStatusMutationFn = Apollo.MutationFunction<
  UpdateStatusMutation,
  UpdateStatusMutationVariables
>;

/**
 * __useUpdateStatusMutation__
 *
 * To run a mutation, you first call `useUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusMutation, { data, loading, error }] = useUpdateStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStatusMutation,
    UpdateStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStatusMutation,
    UpdateStatusMutationVariables
  >(UpdateStatusDocument, options);
}
export type UpdateStatusMutationHookResult = ReturnType<
  typeof useUpdateStatusMutation
>;
export type UpdateStatusMutationResult =
  Apollo.MutationResult<UpdateStatusMutation>;
export type UpdateStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateStatusMutation,
  UpdateStatusMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout($clientMutationId: String, $id: ID!) {
    logout(input: { clientMutationId: $clientMutationId, id: $id }) {
      error {
        message
      }
      message
    }
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const RemoveFileDocument = gql`
  mutation removeFile($input: RemoveDocumentInput!) {
    removeDocument(input: $input) {
      id
      message
      error {
        message
        path
      }
    }
  }
`;
export type RemoveFileMutationFn = Apollo.MutationFunction<
  RemoveFileMutation,
  RemoveFileMutationVariables
>;

/**
 * __useRemoveFileMutation__
 *
 * To run a mutation, you first call `useRemoveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFileMutation, { data, loading, error }] = useRemoveFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFileMutation,
    RemoveFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFileMutation, RemoveFileMutationVariables>(
    RemoveFileDocument,
    options
  );
}
export type RemoveFileMutationHookResult = ReturnType<
  typeof useRemoveFileMutation
>;
export type RemoveFileMutationResult =
  Apollo.MutationResult<RemoveFileMutation>;
export type RemoveFileMutationOptions = Apollo.BaseMutationOptions<
  RemoveFileMutation,
  RemoveFileMutationVariables
>;
export const AcceptOfferDocument = gql`
  mutation acceptOffer($id: ID!) {
    acceptOffer(input: { id: $id }) {
      message
    }
  }
`;
export type AcceptOfferMutationFn = Apollo.MutationFunction<
  AcceptOfferMutation,
  AcceptOfferMutationVariables
>;

/**
 * __useAcceptOfferMutation__
 *
 * To run a mutation, you first call `useAcceptOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptOfferMutation, { data, loading, error }] = useAcceptOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptOfferMutation,
    AcceptOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptOfferMutation, AcceptOfferMutationVariables>(
    AcceptOfferDocument,
    options
  );
}
export type AcceptOfferMutationHookResult = ReturnType<
  typeof useAcceptOfferMutation
>;
export type AcceptOfferMutationResult =
  Apollo.MutationResult<AcceptOfferMutation>;
export type AcceptOfferMutationOptions = Apollo.BaseMutationOptions<
  AcceptOfferMutation,
  AcceptOfferMutationVariables
>;
export const OfferESignDocument = gql`
  mutation offerESign($id: ID!) {
    offerEsign(input: { id: $id }) {
      message
      error {
        message
        path
      }
      done
      url
    }
  }
`;
export type OfferESignMutationFn = Apollo.MutationFunction<
  OfferESignMutation,
  OfferESignMutationVariables
>;

/**
 * __useOfferESignMutation__
 *
 * To run a mutation, you first call `useOfferESignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferESignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerESignMutation, { data, loading, error }] = useOfferESignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfferESignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OfferESignMutation,
    OfferESignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OfferESignMutation, OfferESignMutationVariables>(
    OfferESignDocument,
    options
  );
}
export type OfferESignMutationHookResult = ReturnType<
  typeof useOfferESignMutation
>;
export type OfferESignMutationResult =
  Apollo.MutationResult<OfferESignMutation>;
export type OfferESignMutationOptions = Apollo.BaseMutationOptions<
  OfferESignMutation,
  OfferESignMutationVariables
>;
export const CreateUserDocument = gql`
  mutation createUser(
    $name: String!
    $email: String!
    $countryCode: String!
    $phoneNumber: String!
    $title: String!
    $clientMutationId: String
  ) {
    createUser(
      input: {
        user: {
          name: $name
          email: $email
          phone: { countryCode: $countryCode, phoneNumber: $phoneNumber }
          title: $title
        }
        clientMutationId: $clientMutationId
      }
    ) {
      user {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      countryCode: // value for 'countryCode'
 *      phoneNumber: // value for 'phoneNumber'
 *      title: // value for 'title'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      message
      error {
        message
        path
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UpdateScfImporterDocument = gql`
  mutation updateScfImporter($input: UpdateScfImporterInput!) {
    updateScfImporter(input: $input) {
      message
      error {
        message
        path
      }
    }
  }
`;
export type UpdateScfImporterMutationFn = Apollo.MutationFunction<
  UpdateScfImporterMutation,
  UpdateScfImporterMutationVariables
>;

/**
 * __useUpdateScfImporterMutation__
 *
 * To run a mutation, you first call `useUpdateScfImporterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScfImporterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScfImporterMutation, { data, loading, error }] = useUpdateScfImporterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScfImporterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScfImporterMutation,
    UpdateScfImporterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScfImporterMutation,
    UpdateScfImporterMutationVariables
  >(UpdateScfImporterDocument, options);
}
export type UpdateScfImporterMutationHookResult = ReturnType<
  typeof useUpdateScfImporterMutation
>;
export type UpdateScfImporterMutationResult =
  Apollo.MutationResult<UpdateScfImporterMutation>;
export type UpdateScfImporterMutationOptions = Apollo.BaseMutationOptions<
  UpdateScfImporterMutation,
  UpdateScfImporterMutationVariables
>;
export const CreateContactDocument = gql`
  mutation createContact($password: String!) {
    createContact(input: { password: $password }) {
      error {
        message
        path
      }
      message
    }
  }
`;
export type CreateContactMutationFn = Apollo.MutationFunction<
  CreateContactMutation,
  CreateContactMutationVariables
>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactMutation,
    CreateContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CreateContactDocument, options);
}
export type CreateContactMutationHookResult = ReturnType<
  typeof useCreateContactMutation
>;
export type CreateContactMutationResult =
  Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const UploadCiecDocument = gql`
  mutation uploadCiec($id: ID!, $rfc: String!, $ciec: String!) {
    uploadCiec(input: { id: $id, rfc: $rfc, ciec: $ciec }) {
      message
    }
  }
`;
export type UploadCiecMutationFn = Apollo.MutationFunction<
  UploadCiecMutation,
  UploadCiecMutationVariables
>;

/**
 * __useUploadCiecMutation__
 *
 * To run a mutation, you first call `useUploadCiecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCiecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCiecMutation, { data, loading, error }] = useUploadCiecMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rfc: // value for 'rfc'
 *      ciec: // value for 'ciec'
 *   },
 * });
 */
export function useUploadCiecMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadCiecMutation,
    UploadCiecMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadCiecMutation, UploadCiecMutationVariables>(
    UploadCiecDocument,
    options
  );
}
export type UploadCiecMutationHookResult = ReturnType<
  typeof useUploadCiecMutation
>;
export type UploadCiecMutationResult =
  Apollo.MutationResult<UploadCiecMutation>;
export type UploadCiecMutationOptions = Apollo.BaseMutationOptions<
  UploadCiecMutation,
  UploadCiecMutationVariables
>;
export const GenerateOnFidoSdkTokenDocument = gql`
  mutation GenerateOnFidoSdkToken($clientMutationId: String) {
    onfidoGenerateSdkToken(input: { clientMutationId: $clientMutationId }) {
      generateSdkToken {
        onfidoSdkToken
      }
      errors {
        message
        path
      }
    }
  }
`;
export type GenerateOnFidoSdkTokenMutationFn = Apollo.MutationFunction<
  GenerateOnFidoSdkTokenMutation,
  GenerateOnFidoSdkTokenMutationVariables
>;

/**
 * __useGenerateOnFidoSdkTokenMutation__
 *
 * To run a mutation, you first call `useGenerateOnFidoSdkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOnFidoSdkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOnFidoSdkTokenMutation, { data, loading, error }] = useGenerateOnFidoSdkTokenMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useGenerateOnFidoSdkTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateOnFidoSdkTokenMutation,
    GenerateOnFidoSdkTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateOnFidoSdkTokenMutation,
    GenerateOnFidoSdkTokenMutationVariables
  >(GenerateOnFidoSdkTokenDocument, options);
}
export type GenerateOnFidoSdkTokenMutationHookResult = ReturnType<
  typeof useGenerateOnFidoSdkTokenMutation
>;
export type GenerateOnFidoSdkTokenMutationResult =
  Apollo.MutationResult<GenerateOnFidoSdkTokenMutation>;
export type GenerateOnFidoSdkTokenMutationOptions = Apollo.BaseMutationOptions<
  GenerateOnFidoSdkTokenMutation,
  GenerateOnFidoSdkTokenMutationVariables
>;
export const OnfidoDocumentUploadDocument = gql`
  mutation OnfidoDocumentUpload($clientMutationId: String) {
    onfidoDocumentUploadCompleted(
      input: { clientMutationId: $clientMutationId }
    ) {
      message
    }
  }
`;
export type OnfidoDocumentUploadMutationFn = Apollo.MutationFunction<
  OnfidoDocumentUploadMutation,
  OnfidoDocumentUploadMutationVariables
>;

/**
 * __useOnfidoDocumentUploadMutation__
 *
 * To run a mutation, you first call `useOnfidoDocumentUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnfidoDocumentUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onfidoDocumentUploadMutation, { data, loading, error }] = useOnfidoDocumentUploadMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useOnfidoDocumentUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnfidoDocumentUploadMutation,
    OnfidoDocumentUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OnfidoDocumentUploadMutation,
    OnfidoDocumentUploadMutationVariables
  >(OnfidoDocumentUploadDocument, options);
}
export type OnfidoDocumentUploadMutationHookResult = ReturnType<
  typeof useOnfidoDocumentUploadMutation
>;
export type OnfidoDocumentUploadMutationResult =
  Apollo.MutationResult<OnfidoDocumentUploadMutation>;
export type OnfidoDocumentUploadMutationOptions = Apollo.BaseMutationOptions<
  OnfidoDocumentUploadMutation,
  OnfidoDocumentUploadMutationVariables
>;
export const GetOwnersDocument = gql`
  query GetOwners($id: ID!) {
    owners(id: $id) {
      index
      name
      owned
      pan
      rfc
      legalRepresentative
      majorityShareholder
      secondaryShareholder
      gender
      dob {
        year
        day
        month
      }
    }
  }
`;

/**
 * __useGetOwnersQuery__
 *
 * To run a query within a React component, call `useGetOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOwnersQuery(
  baseOptions: Apollo.QueryHookOptions<GetOwnersQuery, GetOwnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnersQuery, GetOwnersQueryVariables>(
    GetOwnersDocument,
    options
  );
}
export function useGetOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnersQuery,
    GetOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnersQuery, GetOwnersQueryVariables>(
    GetOwnersDocument,
    options
  );
}
export type GetOwnersQueryHookResult = ReturnType<typeof useGetOwnersQuery>;
export type GetOwnersLazyQueryHookResult = ReturnType<
  typeof useGetOwnersLazyQuery
>;
export type GetOwnersQueryResult = Apollo.QueryResult<
  GetOwnersQuery,
  GetOwnersQueryVariables
>;
export const FinancialOwnersDocument = gql`
  query FinancialOwners($id: ID!) {
    owners(id: $id) {
      index
      name
      gender
      rfc
      legalRepresentative
      majorityShareholder
      secondaryShareholder
    }
  }
`;

/**
 * __useFinancialOwnersQuery__
 *
 * To run a query within a React component, call `useFinancialOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialOwnersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinancialOwnersQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinancialOwnersQuery,
    FinancialOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FinancialOwnersQuery, FinancialOwnersQueryVariables>(
    FinancialOwnersDocument,
    options
  );
}
export function useFinancialOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinancialOwnersQuery,
    FinancialOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FinancialOwnersQuery,
    FinancialOwnersQueryVariables
  >(FinancialOwnersDocument, options);
}
export type FinancialOwnersQueryHookResult = ReturnType<
  typeof useFinancialOwnersQuery
>;
export type FinancialOwnersLazyQueryHookResult = ReturnType<
  typeof useFinancialOwnersLazyQuery
>;
export type FinancialOwnersQueryResult = Apollo.QueryResult<
  FinancialOwnersQuery,
  FinancialOwnersQueryVariables
>;
export const GetGstDetailsDocument = gql`
  query getGstDetails($exporterId: ID!) {
    getGstDetails(userId: $exporterId) {
      credLink
      gstNo
      id
      recordStatus
    }
  }
`;

/**
 * __useGetGstDetailsQuery__
 *
 * To run a query within a React component, call `useGetGstDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGstDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGstDetailsQuery({
 *   variables: {
 *      exporterId: // value for 'exporterId'
 *   },
 * });
 */
export function useGetGstDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGstDetailsQuery,
    GetGstDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGstDetailsQuery, GetGstDetailsQueryVariables>(
    GetGstDetailsDocument,
    options
  );
}
export function useGetGstDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGstDetailsQuery,
    GetGstDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGstDetailsQuery, GetGstDetailsQueryVariables>(
    GetGstDetailsDocument,
    options
  );
}
export type GetGstDetailsQueryHookResult = ReturnType<
  typeof useGetGstDetailsQuery
>;
export type GetGstDetailsLazyQueryHookResult = ReturnType<
  typeof useGetGstDetailsLazyQuery
>;
export type GetGstDetailsQueryResult = Apollo.QueryResult<
  GetGstDetailsQuery,
  GetGstDetailsQueryVariables
>;
export const ShareholderFinanceDocument = gql`
  query ShareholderFinance($id: ID!) {
    shareholders(id: $id) {
      indexNo
      name {
        firstname
        middlename
        lastname1
        lastname2
      }
      email
      rfc
      status
      gender
      shareholderId
      address {
        city
        country
        municipality
        state
        street
        zipcode
      }
      legalRepresentative
      majorityShareholder
      secondaryShareholder
      file {
        name
        url
      }
    }
  }
`;

/**
 * __useShareholderFinanceQuery__
 *
 * To run a query within a React component, call `useShareholderFinanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareholderFinanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareholderFinanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShareholderFinanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShareholderFinanceQuery,
    ShareholderFinanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ShareholderFinanceQuery,
    ShareholderFinanceQueryVariables
  >(ShareholderFinanceDocument, options);
}
export function useShareholderFinanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShareholderFinanceQuery,
    ShareholderFinanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ShareholderFinanceQuery,
    ShareholderFinanceQueryVariables
  >(ShareholderFinanceDocument, options);
}
export type ShareholderFinanceQueryHookResult = ReturnType<
  typeof useShareholderFinanceQuery
>;
export type ShareholderFinanceLazyQueryHookResult = ReturnType<
  typeof useShareholderFinanceLazyQuery
>;
export type ShareholderFinanceQueryResult = Apollo.QueryResult<
  ShareholderFinanceQuery,
  ShareholderFinanceQueryVariables
>;
export const CompanyFinanceDocument = gql`
  query CompanyFinance($id: ID!) {
    company(id: $id) {
      address {
        city
        country
        municipality
        state
        street
        zipcode
      }
      companyName
      email
      rfc
      status
      file {
        name
        url
      }
    }
  }
`;

/**
 * __useCompanyFinanceQuery__
 *
 * To run a query within a React component, call `useCompanyFinanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFinanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFinanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyFinanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyFinanceQuery,
    CompanyFinanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyFinanceQuery, CompanyFinanceQueryVariables>(
    CompanyFinanceDocument,
    options
  );
}
export function useCompanyFinanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyFinanceQuery,
    CompanyFinanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyFinanceQuery, CompanyFinanceQueryVariables>(
    CompanyFinanceDocument,
    options
  );
}
export type CompanyFinanceQueryHookResult = ReturnType<
  typeof useCompanyFinanceQuery
>;
export type CompanyFinanceLazyQueryHookResult = ReturnType<
  typeof useCompanyFinanceLazyQuery
>;
export type CompanyFinanceQueryResult = Apollo.QueryResult<
  CompanyFinanceQuery,
  CompanyFinanceQueryVariables
>;
export const GetDunsStatusDocument = gql`
  query GetDunsStatus($duns: String!) {
    getDuns(duns: $duns) {
      businessIdentifierKey
      businessIdentifierValue
      companyName
      dripProfile {
        designation
        id
        profileType
      }
      id
      location {
        address
        country {
          name
        }
        state {
          name
        }
        zipCode
      }
      productCategory
      status
      typeOfGoods
    }
  }
`;

/**
 * __useGetDunsStatusQuery__
 *
 * To run a query within a React component, call `useGetDunsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDunsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDunsStatusQuery({
 *   variables: {
 *      duns: // value for 'duns'
 *   },
 * });
 */
export function useGetDunsStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDunsStatusQuery,
    GetDunsStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDunsStatusQuery, GetDunsStatusQueryVariables>(
    GetDunsStatusDocument,
    options
  );
}
export function useGetDunsStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDunsStatusQuery,
    GetDunsStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDunsStatusQuery, GetDunsStatusQueryVariables>(
    GetDunsStatusDocument,
    options
  );
}
export type GetDunsStatusQueryHookResult = ReturnType<
  typeof useGetDunsStatusQuery
>;
export type GetDunsStatusLazyQueryHookResult = ReturnType<
  typeof useGetDunsStatusLazyQuery
>;
export type GetDunsStatusQueryResult = Apollo.QueryResult<
  GetDunsStatusQuery,
  GetDunsStatusQueryVariables
>;
export const EsignStatusDocument = gql`
  query EsignStatus($id: ID!, $shareholderId: String) {
    esignStatus(id: $id, shareholderId: $shareholderId) {
      esignStatus
    }
  }
`;

/**
 * __useEsignStatusQuery__
 *
 * To run a query within a React component, call `useEsignStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEsignStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEsignStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *      shareholderId: // value for 'shareholderId'
 *   },
 * });
 */
export function useEsignStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    EsignStatusQuery,
    EsignStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EsignStatusQuery, EsignStatusQueryVariables>(
    EsignStatusDocument,
    options
  );
}
export function useEsignStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EsignStatusQuery,
    EsignStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EsignStatusQuery, EsignStatusQueryVariables>(
    EsignStatusDocument,
    options
  );
}
export type EsignStatusQueryHookResult = ReturnType<typeof useEsignStatusQuery>;
export type EsignStatusLazyQueryHookResult = ReturnType<
  typeof useEsignStatusLazyQuery
>;
export type EsignStatusQueryResult = Apollo.QueryResult<
  EsignStatusQuery,
  EsignStatusQueryVariables
>;
export const EsignCredentialsDocument = gql`
  query EsignCredentials($id: ID!, $shareholderId: String) {
    esignCredentials(id: $id, shareholderId: $shareholderId) {
      apiKey
      documentId
      signatoryId
    }
  }
`;

/**
 * __useEsignCredentialsQuery__
 *
 * To run a query within a React component, call `useEsignCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEsignCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEsignCredentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      shareholderId: // value for 'shareholderId'
 *   },
 * });
 */
export function useEsignCredentialsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EsignCredentialsQuery,
    EsignCredentialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EsignCredentialsQuery, EsignCredentialsQueryVariables>(
    EsignCredentialsDocument,
    options
  );
}
export function useEsignCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EsignCredentialsQuery,
    EsignCredentialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EsignCredentialsQuery,
    EsignCredentialsQueryVariables
  >(EsignCredentialsDocument, options);
}
export type EsignCredentialsQueryHookResult = ReturnType<
  typeof useEsignCredentialsQuery
>;
export type EsignCredentialsLazyQueryHookResult = ReturnType<
  typeof useEsignCredentialsLazyQuery
>;
export type EsignCredentialsQueryResult = Apollo.QueryResult<
  EsignCredentialsQuery,
  EsignCredentialsQueryVariables
>;
export const GetBuyersDocument = gql`
  query getBuyers($exporterId: ID!) {
    buyers(id: $exporterId) {
      index
      address
      contactEmail
      contactName
      contactPhoneNumber {
        countryCode
        phoneNumber
      }
      country
      currency
      currentSales
      hasInvoice
      idType
      name
      idNumber
      pastSales
      paymentDays
      paymentTerms
      paymentTenor
      related
      rfc
      shippingTerms
      writeoffs
      hasInvoice
      documentReleaseType
      documentSendType
      canSubmitAllBill
      dripLimit
      addToOfferProfile
      operationDrip
    }
  }
`;

/**
 * __useGetBuyersQuery__
 *
 * To run a query within a React component, call `useGetBuyersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuyersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuyersQuery({
 *   variables: {
 *      exporterId: // value for 'exporterId'
 *   },
 * });
 */
export function useGetBuyersQuery(
  baseOptions: Apollo.QueryHookOptions<GetBuyersQuery, GetBuyersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBuyersQuery, GetBuyersQueryVariables>(
    GetBuyersDocument,
    options
  );
}
export function useGetBuyersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBuyersQuery,
    GetBuyersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBuyersQuery, GetBuyersQueryVariables>(
    GetBuyersDocument,
    options
  );
}
export type GetBuyersQueryHookResult = ReturnType<typeof useGetBuyersQuery>;
export type GetBuyersLazyQueryHookResult = ReturnType<
  typeof useGetBuyersLazyQuery
>;
export type GetBuyersQueryResult = Apollo.QueryResult<
  GetBuyersQuery,
  GetBuyersQueryVariables
>;
export const GetBuyerDocument = gql`
  query getBuyer($exporterId: ID!, $index: Int!) {
    buyer(id: $exporterId, index: $index) {
      index
      address
      contactEmail
      contactName
      contactPhoneNumber {
        countryCode
        phoneNumber
      }
      country
      currency
      currentSales
      hasInvoice
      idType
      name
      idNumber
      pastSales
      paymentDays
      paymentTerms
      related
      rfc
      shippingTerms
      writeoffs
      hasInvoice
      documentReleaseType
      documentSendType
      canSubmitAllBill
      dripLimit
      addToOfferProfile
      operationDrip
    }
  }
`;

/**
 * __useGetBuyerQuery__
 *
 * To run a query within a React component, call `useGetBuyerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuyerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuyerQuery({
 *   variables: {
 *      exporterId: // value for 'exporterId'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetBuyerQuery(
  baseOptions: Apollo.QueryHookOptions<GetBuyerQuery, GetBuyerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBuyerQuery, GetBuyerQueryVariables>(
    GetBuyerDocument,
    options
  );
}
export function useGetBuyerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBuyerQuery,
    GetBuyerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBuyerQuery, GetBuyerQueryVariables>(
    GetBuyerDocument,
    options
  );
}
export type GetBuyerQueryHookResult = ReturnType<typeof useGetBuyerQuery>;
export type GetBuyerLazyQueryHookResult = ReturnType<
  typeof useGetBuyerLazyQuery
>;
export type GetBuyerQueryResult = Apollo.QueryResult<
  GetBuyerQuery,
  GetBuyerQueryVariables
>;
export const GetMetaDataDocument = gql`
  query getMetaData {
    metaData {
      countries
      currencyValues
      docsRelease
      docsRouting
      paymentDurations
      paymentTerms
      states
    }
  }
`;

/**
 * __useGetMetaDataQuery__
 *
 * To run a query within a React component, call `useGetMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetaDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMetaDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMetaDataQuery,
    GetMetaDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMetaDataQuery, GetMetaDataQueryVariables>(
    GetMetaDataDocument,
    options
  );
}
export function useGetMetaDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetaDataQuery,
    GetMetaDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMetaDataQuery, GetMetaDataQueryVariables>(
    GetMetaDataDocument,
    options
  );
}
export type GetMetaDataQueryHookResult = ReturnType<typeof useGetMetaDataQuery>;
export type GetMetaDataLazyQueryHookResult = ReturnType<
  typeof useGetMetaDataLazyQuery
>;
export type GetMetaDataQueryResult = Apollo.QueryResult<
  GetMetaDataQuery,
  GetMetaDataQueryVariables
>;
export const GetApplicationDetailsDocument = gql`
  query getApplicationDetails($id: ID!) {
    applicationDetails(id: $id) {
      message
      application {
        buyerInfo {
          pending {
            index
            key
          }
          present
        }
        companyInfo {
          pending {
            index
            key
          }
          present
        }
        financialInfo {
          cibil {
            present
            verified
          }
          gst {
            gstNo
            recordStatus
          }
          itr {
            files {
              name
              url
              documentId
            }
            status
          }
        }
        onboardingStage
      }
    }
  }
`;

/**
 * __useGetApplicationDetailsQuery__
 *
 * To run a query within a React component, call `useGetApplicationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationDetailsQuery,
    GetApplicationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationDetailsQuery,
    GetApplicationDetailsQueryVariables
  >(GetApplicationDetailsDocument, options);
}
export function useGetApplicationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationDetailsQuery,
    GetApplicationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationDetailsQuery,
    GetApplicationDetailsQueryVariables
  >(GetApplicationDetailsDocument, options);
}
export type GetApplicationDetailsQueryHookResult = ReturnType<
  typeof useGetApplicationDetailsQuery
>;
export type GetApplicationDetailsLazyQueryHookResult = ReturnType<
  typeof useGetApplicationDetailsLazyQuery
>;
export type GetApplicationDetailsQueryResult = Apollo.QueryResult<
  GetApplicationDetailsQuery,
  GetApplicationDetailsQueryVariables
>;
export const GetMxApplicationStatusDocument = gql`
  query getMXApplicationStatus($id: ID!) {
    applicationStatus(id: $id) {
      message
      application {
        onboardingStage
        buyerInfo {
          pending {
            index
            key
          }
          present
        }
        companyInfo {
          pending {
            index
            key
          }
          present
        }
        financialInfo {
          company {
            pending
            present
          }
          shareholders {
            pending {
              shareholderId
              key
            }
            present
          }
        }
        legalDocs
      }
    }
  }
`;

/**
 * __useGetMxApplicationStatusQuery__
 *
 * To run a query within a React component, call `useGetMxApplicationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMxApplicationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMxApplicationStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMxApplicationStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMxApplicationStatusQuery,
    GetMxApplicationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMxApplicationStatusQuery,
    GetMxApplicationStatusQueryVariables
  >(GetMxApplicationStatusDocument, options);
}
export function useGetMxApplicationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMxApplicationStatusQuery,
    GetMxApplicationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMxApplicationStatusQuery,
    GetMxApplicationStatusQueryVariables
  >(GetMxApplicationStatusDocument, options);
}
export type GetMxApplicationStatusQueryHookResult = ReturnType<
  typeof useGetMxApplicationStatusQuery
>;
export type GetMxApplicationStatusLazyQueryHookResult = ReturnType<
  typeof useGetMxApplicationStatusLazyQuery
>;
export type GetMxApplicationStatusQueryResult = Apollo.QueryResult<
  GetMxApplicationStatusQuery,
  GetMxApplicationStatusQueryVariables
>;
export const GetScfApplicationDetailsDocument = gql`
  query GetScfApplicationDetails($id: String, $customerId: String) {
    scfApplicationDetails(businessId: $id, customerId: $customerId) {
      application {
        uid
        mailSent
        mailSentDate
        bankDetails {
          files {
            name
            url
            documentId
          }
          plaidLinked
          plaidFailed
          status
        }
        businessDetails {
          website
          ecomSeller {
            isEcomSeller
            ecomSellerLink
          }
          businessIdentifierKey
          businessIdentifierValue
          companyName
          dba
          detailsPending
          dripProfile {
            designation
            id
            onboardingStage
            profileType
          }
          ein
          id
          location {
            address
            country {
              name
              parentTagId
              type
            }
            state {
              name
              parentTagId
              type
            }
            city {
              name
            }
            zipCode
          }
          productCategory
          salesContact {
            email
            name
            phNumber
          }
          scfOwners {
            email
            name
            ownership
            phone {
              countryCode
              phoneNumber
            }
            userId
          }
          status
          typeOfGoods
          yearIncorporated
        }
        financialDetails {
          files {
            name
            url
            documentId
          }
          status
        }
        certificateOfIncorporation {
          files {
            name
            url
            documentId
          }
          status
        }
        dbaDocuments {
          files {
            name
            url
            documentId
          }
          status
        }
        onboardingStage
        personalDetails {
          consumerCreditConsent
          detailsPending
          email
          id
          name
          phone {
            countryCode
            phoneNumber
          }
          onfidoUploadCompleted
          title
          dob
          citizenship {
            id
            name
            type
            value
          }
          identifiers {
            id
            name
            type
            value
          }
        }
      }
      message
    }
  }
`;

/**
 * __useGetScfApplicationDetailsQuery__
 *
 * To run a query within a React component, call `useGetScfApplicationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScfApplicationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScfApplicationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetScfApplicationDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetScfApplicationDetailsQuery,
    GetScfApplicationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetScfApplicationDetailsQuery,
    GetScfApplicationDetailsQueryVariables
  >(GetScfApplicationDetailsDocument, options);
}
export function useGetScfApplicationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScfApplicationDetailsQuery,
    GetScfApplicationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetScfApplicationDetailsQuery,
    GetScfApplicationDetailsQueryVariables
  >(GetScfApplicationDetailsDocument, options);
}
export type GetScfApplicationDetailsQueryHookResult = ReturnType<
  typeof useGetScfApplicationDetailsQuery
>;
export type GetScfApplicationDetailsLazyQueryHookResult = ReturnType<
  typeof useGetScfApplicationDetailsLazyQuery
>;
export type GetScfApplicationDetailsQueryResult = Apollo.QueryResult<
  GetScfApplicationDetailsQuery,
  GetScfApplicationDetailsQueryVariables
>;
export const GetStatesDocument = gql`
  query getStates($id: ID!) {
    states(id: $id)
  }
`;

/**
 * __useGetStatesQuery__
 *
 * To run a query within a React component, call `useGetStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStatesQuery(
  baseOptions: Apollo.QueryHookOptions<GetStatesQuery, GetStatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatesQuery, GetStatesQueryVariables>(
    GetStatesDocument,
    options
  );
}
export function useGetStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStatesQuery,
    GetStatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatesQuery, GetStatesQueryVariables>(
    GetStatesDocument,
    options
  );
}
export type GetStatesQueryHookResult = ReturnType<typeof useGetStatesQuery>;
export type GetStatesLazyQueryHookResult = ReturnType<
  typeof useGetStatesLazyQuery
>;
export type GetStatesQueryResult = Apollo.QueryResult<
  GetStatesQuery,
  GetStatesQueryVariables
>;
export const ScfMetaDataDocument = gql`
  query scfMetaData {
    scfMetaData {
      allStates
      annualImportsList
      businessStructureList
      countries
      countriesWithId
      currencyValues
      docsRelease
      docsRouting
      paymentTerms
      paymentDurations
      productCategory
      states
    }
  }
`;

/**
 * __useScfMetaDataQuery__
 *
 * To run a query within a React component, call `useScfMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useScfMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScfMetaDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useScfMetaDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ScfMetaDataQuery,
    ScfMetaDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScfMetaDataQuery, ScfMetaDataQueryVariables>(
    ScfMetaDataDocument,
    options
  );
}
export function useScfMetaDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScfMetaDataQuery,
    ScfMetaDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScfMetaDataQuery, ScfMetaDataQueryVariables>(
    ScfMetaDataDocument,
    options
  );
}
export type ScfMetaDataQueryHookResult = ReturnType<typeof useScfMetaDataQuery>;
export type ScfMetaDataLazyQueryHookResult = ReturnType<
  typeof useScfMetaDataLazyQuery
>;
export type ScfMetaDataQueryResult = Apollo.QueryResult<
  ScfMetaDataQuery,
  ScfMetaDataQueryVariables
>;
export const GetOfferDetailsDocument = gql`
  query getOfferDetails($id: ID!, $type: String) {
    offerDetails(id: $id, type: $type) {
      currency
      offerDetails {
        facilitySize
        interestRate
        establishimentFee
        maxCreditTenor
        advanceRate
      }
      pricingDetails {
        overdueRate
        factoringCommission {
          days
          rate
        }
      }
    }
  }
`;

/**
 * __useGetOfferDetailsQuery__
 *
 * To run a query within a React component, call `useGetOfferDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetOfferDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOfferDetailsQuery,
    GetOfferDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfferDetailsQuery, GetOfferDetailsQueryVariables>(
    GetOfferDetailsDocument,
    options
  );
}
export function useGetOfferDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfferDetailsQuery,
    GetOfferDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOfferDetailsQuery,
    GetOfferDetailsQueryVariables
  >(GetOfferDetailsDocument, options);
}
export type GetOfferDetailsQueryHookResult = ReturnType<
  typeof useGetOfferDetailsQuery
>;
export type GetOfferDetailsLazyQueryHookResult = ReturnType<
  typeof useGetOfferDetailsLazyQuery
>;
export type GetOfferDetailsQueryResult = Apollo.QueryResult<
  GetOfferDetailsQuery,
  GetOfferDetailsQueryVariables
>;
export const DownloadOfferLinkInDocument = gql`
  query downloadOfferLinkIN($id: ID!, $type: String!) {
    downloadOffer(id: $id, type: $type)
  }
`;

/**
 * __useDownloadOfferLinkInQuery__
 *
 * To run a query within a React component, call `useDownloadOfferLinkInQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadOfferLinkInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadOfferLinkInQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDownloadOfferLinkInQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadOfferLinkInQuery,
    DownloadOfferLinkInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadOfferLinkInQuery,
    DownloadOfferLinkInQueryVariables
  >(DownloadOfferLinkInDocument, options);
}
export function useDownloadOfferLinkInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadOfferLinkInQuery,
    DownloadOfferLinkInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadOfferLinkInQuery,
    DownloadOfferLinkInQueryVariables
  >(DownloadOfferLinkInDocument, options);
}
export type DownloadOfferLinkInQueryHookResult = ReturnType<
  typeof useDownloadOfferLinkInQuery
>;
export type DownloadOfferLinkInLazyQueryHookResult = ReturnType<
  typeof useDownloadOfferLinkInLazyQuery
>;
export type DownloadOfferLinkInQueryResult = Apollo.QueryResult<
  DownloadOfferLinkInQuery,
  DownloadOfferLinkInQueryVariables
>;
export const OfferDetailsMxDocument = gql`
  query offerDetailsMX($id: ID!) {
    offer(id: $id) {
      offerDetails {
        advanceRate
        creditLimit
        establishmentFee
        facilitySize
        interestRate
        maximumCreditTenor
        downloadLink
      }
    }
  }
`;

/**
 * __useOfferDetailsMxQuery__
 *
 * To run a query within a React component, call `useOfferDetailsMxQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferDetailsMxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferDetailsMxQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfferDetailsMxQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferDetailsMxQuery,
    OfferDetailsMxQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferDetailsMxQuery, OfferDetailsMxQueryVariables>(
    OfferDetailsMxDocument,
    options
  );
}
export function useOfferDetailsMxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferDetailsMxQuery,
    OfferDetailsMxQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferDetailsMxQuery, OfferDetailsMxQueryVariables>(
    OfferDetailsMxDocument,
    options
  );
}
export type OfferDetailsMxQueryHookResult = ReturnType<
  typeof useOfferDetailsMxQuery
>;
export type OfferDetailsMxLazyQueryHookResult = ReturnType<
  typeof useOfferDetailsMxLazyQuery
>;
export type OfferDetailsMxQueryResult = Apollo.QueryResult<
  OfferDetailsMxQuery,
  OfferDetailsMxQueryVariables
>;
export const OfferESignStatusDocument = gql`
  query offerESignStatus($id: ID!) {
    offerEsignStatus(id: $id) {
      message
      status
    }
  }
`;

/**
 * __useOfferESignStatusQuery__
 *
 * To run a query within a React component, call `useOfferESignStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferESignStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferESignStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfferESignStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferESignStatusQuery,
    OfferESignStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferESignStatusQuery, OfferESignStatusQueryVariables>(
    OfferESignStatusDocument,
    options
  );
}
export function useOfferESignStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferESignStatusQuery,
    OfferESignStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OfferESignStatusQuery,
    OfferESignStatusQueryVariables
  >(OfferESignStatusDocument, options);
}
export type OfferESignStatusQueryHookResult = ReturnType<
  typeof useOfferESignStatusQuery
>;
export type OfferESignStatusLazyQueryHookResult = ReturnType<
  typeof useOfferESignStatusLazyQuery
>;
export type OfferESignStatusQueryResult = Apollo.QueryResult<
  OfferESignStatusQuery,
  OfferESignStatusQueryVariables
>;
export const ScfFacilityDetailsDocument = gql`
  query scfFacilityDetails($id: ID!) {
    scfFacilityDetails(id: $id) {
      message
      error {
        message
      }
      facilityDetails {
        advanceRate
        facilitySize
        generatedDocUrl
        interestFee
        marginRate
        maxCreditPeriod
        offerGeneratedNotSent
        overdueRate
        setupFee
        transactionFee
        sentDate
        signedDate
      }
    }
  }
`;

/**
 * __useScfFacilityDetailsQuery__
 *
 * To run a query within a React component, call `useScfFacilityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScfFacilityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScfFacilityDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScfFacilityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScfFacilityDetailsQuery,
    ScfFacilityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScfFacilityDetailsQuery,
    ScfFacilityDetailsQueryVariables
  >(ScfFacilityDetailsDocument, options);
}
export function useScfFacilityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScfFacilityDetailsQuery,
    ScfFacilityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScfFacilityDetailsQuery,
    ScfFacilityDetailsQueryVariables
  >(ScfFacilityDetailsDocument, options);
}
export type ScfFacilityDetailsQueryHookResult = ReturnType<
  typeof useScfFacilityDetailsQuery
>;
export type ScfFacilityDetailsLazyQueryHookResult = ReturnType<
  typeof useScfFacilityDetailsLazyQuery
>;
export type ScfFacilityDetailsQueryResult = Apollo.QueryResult<
  ScfFacilityDetailsQuery,
  ScfFacilityDetailsQueryVariables
>;
export const ScfGenerateOfferDocument = gql`
  query scfGenerateOffer($id: ID!, $date: String!) {
    scfGenerateOffer(id: $id, date: $date) {
      message
      offerDocumentUrl
      error {
        message
      }
    }
  }
`;

/**
 * __useScfGenerateOfferQuery__
 *
 * To run a query within a React component, call `useScfGenerateOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useScfGenerateOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScfGenerateOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useScfGenerateOfferQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScfGenerateOfferQuery,
    ScfGenerateOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScfGenerateOfferQuery, ScfGenerateOfferQueryVariables>(
    ScfGenerateOfferDocument,
    options
  );
}
export function useScfGenerateOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScfGenerateOfferQuery,
    ScfGenerateOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScfGenerateOfferQuery,
    ScfGenerateOfferQueryVariables
  >(ScfGenerateOfferDocument, options);
}
export type ScfGenerateOfferQueryHookResult = ReturnType<
  typeof useScfGenerateOfferQuery
>;
export type ScfGenerateOfferLazyQueryHookResult = ReturnType<
  typeof useScfGenerateOfferLazyQuery
>;
export type ScfGenerateOfferQueryResult = Apollo.QueryResult<
  ScfGenerateOfferQuery,
  ScfGenerateOfferQueryVariables
>;
export const ScfSendOfferDocument = gql`
  query scfSendOffer($id: ID!, $cc: [String!]!) {
    scfSendOffer(id: $id, cc: $cc) {
      offerSent
      message
      error {
        message
      }
    }
  }
`;

/**
 * __useScfSendOfferQuery__
 *
 * To run a query within a React component, call `useScfSendOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useScfSendOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScfSendOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cc: // value for 'cc'
 *   },
 * });
 */
export function useScfSendOfferQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScfSendOfferQuery,
    ScfSendOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScfSendOfferQuery, ScfSendOfferQueryVariables>(
    ScfSendOfferDocument,
    options
  );
}
export function useScfSendOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScfSendOfferQuery,
    ScfSendOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScfSendOfferQuery, ScfSendOfferQueryVariables>(
    ScfSendOfferDocument,
    options
  );
}
export type ScfSendOfferQueryHookResult = ReturnType<
  typeof useScfSendOfferQuery
>;
export type ScfSendOfferLazyQueryHookResult = ReturnType<
  typeof useScfSendOfferLazyQuery
>;
export type ScfSendOfferQueryResult = Apollo.QueryResult<
  ScfSendOfferQuery,
  ScfSendOfferQueryVariables
>;
export const UserProfileDocument = gql`
  query userProfile($userId: ID!) {
    userProfile(userId: $userId) {
      accountManager {
        email
        name
      }
      id
      name
      email
      status
      phone
      utype
      businesses {
        id
        businessIdentifierKey
        businessIdentifierValue
        productCategory
        typeOfGoods
        status
        companyName
        debtorType
        dripProfile {
          id
          designation
          profileType
          onboardingStage
        }
        location {
          address
          country {
            name
          }
          state {
            name
          }
        }
        salesContact {
          name
          email
          phNumber
        }
      }
    }
  }
`;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserProfileQuery,
    UserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(
    UserProfileDocument,
    options
  );
}
export function useUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProfileQuery,
    UserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(
    UserProfileDocument,
    options
  );
}
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<
  typeof useUserProfileLazyQuery
>;
export type UserProfileQueryResult = Apollo.QueryResult<
  UserProfileQuery,
  UserProfileQueryVariables
>;
export const OnboardingApplicantsDocument = gql`
  query onboardingApplicants($pageNo: Int!, $pageSize: Int, $search: String!) {
    onboardingApplicants(
      pageNo: $pageNo
      pageSize: $pageSize
      search: $search
    ) {
      leads {
        id
        name
        company
        email
        cpName
        dajStage
      }
      totalLeads
      totalPages
    }
  }
`;

/**
 * __useOnboardingApplicantsQuery__
 *
 * To run a query within a React component, call `useOnboardingApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingApplicantsQuery({
 *   variables: {
 *      pageNo: // value for 'pageNo'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useOnboardingApplicantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OnboardingApplicantsQuery,
    OnboardingApplicantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OnboardingApplicantsQuery,
    OnboardingApplicantsQueryVariables
  >(OnboardingApplicantsDocument, options);
}
export function useOnboardingApplicantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingApplicantsQuery,
    OnboardingApplicantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OnboardingApplicantsQuery,
    OnboardingApplicantsQueryVariables
  >(OnboardingApplicantsDocument, options);
}
export type OnboardingApplicantsQueryHookResult = ReturnType<
  typeof useOnboardingApplicantsQuery
>;
export type OnboardingApplicantsLazyQueryHookResult = ReturnType<
  typeof useOnboardingApplicantsLazyQuery
>;
export type OnboardingApplicantsQueryResult = Apollo.QueryResult<
  OnboardingApplicantsQuery,
  OnboardingApplicantsQueryVariables
>;
export const GetCiecStatusDocument = gql`
  query getCiecStatus($id: ID!) {
    ciecStatus(id: $id) {
      error {
        message
        path
      }
      uid
      validation
    }
  }
`;

/**
 * __useGetCiecStatusQuery__
 *
 * To run a query within a React component, call `useGetCiecStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCiecStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCiecStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCiecStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCiecStatusQuery,
    GetCiecStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCiecStatusQuery, GetCiecStatusQueryVariables>(
    GetCiecStatusDocument,
    options
  );
}
export function useGetCiecStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCiecStatusQuery,
    GetCiecStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCiecStatusQuery, GetCiecStatusQueryVariables>(
    GetCiecStatusDocument,
    options
  );
}
export type GetCiecStatusQueryHookResult = ReturnType<
  typeof useGetCiecStatusQuery
>;
export type GetCiecStatusLazyQueryHookResult = ReturnType<
  typeof useGetCiecStatusLazyQuery
>;
export type GetCiecStatusQueryResult = Apollo.QueryResult<
  GetCiecStatusQuery,
  GetCiecStatusQueryVariables
>;
export const ContactDocument = gql`
  query contact {
    contact {
      name
      company
      phone
      codeType
      code
    }
  }
`;

/**
 * __useContactQuery__
 *
 * To run a query within a React component, call `useContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactQuery(
  baseOptions?: Apollo.QueryHookOptions<ContactQuery, ContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactQuery, ContactQueryVariables>(
    ContactDocument,
    options
  );
}
export function useContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContactQuery, ContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactQuery, ContactQueryVariables>(
    ContactDocument,
    options
  );
}
export type ContactQueryHookResult = ReturnType<typeof useContactQuery>;
export type ContactLazyQueryHookResult = ReturnType<typeof useContactLazyQuery>;
export type ContactQueryResult = Apollo.QueryResult<
  ContactQuery,
  ContactQueryVariables
>;
export const ScfPrimarySignatoryDocument = gql`
  query scfPrimarySignatory($id: ID!) {
    scfPrimarySignatory(id: $id) {
      message
      primarySignatoryPresent
    }
  }
`;

/**
 * __useScfPrimarySignatoryQuery__
 *
 * To run a query within a React component, call `useScfPrimarySignatoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useScfPrimarySignatoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScfPrimarySignatoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScfPrimarySignatoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScfPrimarySignatoryQuery,
    ScfPrimarySignatoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScfPrimarySignatoryQuery,
    ScfPrimarySignatoryQueryVariables
  >(ScfPrimarySignatoryDocument, options);
}
export function useScfPrimarySignatoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScfPrimarySignatoryQuery,
    ScfPrimarySignatoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScfPrimarySignatoryQuery,
    ScfPrimarySignatoryQueryVariables
  >(ScfPrimarySignatoryDocument, options);
}
export type ScfPrimarySignatoryQueryHookResult = ReturnType<
  typeof useScfPrimarySignatoryQuery
>;
export type ScfPrimarySignatoryLazyQueryHookResult = ReturnType<
  typeof useScfPrimarySignatoryLazyQuery
>;
export type ScfPrimarySignatoryQueryResult = Apollo.QueryResult<
  ScfPrimarySignatoryQuery,
  ScfPrimarySignatoryQueryVariables
>;
