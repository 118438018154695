import { ApolloClient, from } from '@apollo/client';
import { cache } from './cache';
import { httpLink, authLink, errorLink } from './link';

const apolloClient = new ApolloClient({
  cache,
  link: from([errorLink, authLink, httpLink]),
});

export * from './cache';
export * from './link';
export default apolloClient;
