import { lazy } from 'react';

const INBusinessIdentifier = lazy(
  () => import('containers/preunderwriting/IN/INBusinessIdentifier')
);

const INBusinessDetails = lazy(
  () => import('containers/preunderwriting/IN/INBusinessDetails')
);

const INBusinessEligibility = lazy(
  () => import('containers/preunderwriting/IN/INBusinessEligibility')
);

const INApplicants = lazy(
  () => import('containers/preunderwriting/IN/INApplicants')
);

const INEsignOffer = lazy(
  () => import('containers/preunderwriting/IN/INEsignOffer')
);

const INOfferDetailsGranted = lazy(
  () => import('containers/preunderwriting/IN/INOfferDetailsGranted')
);

const INOfferDetailsDenied = lazy(
  () => import('containers/preunderwriting/IN/INOfferDetailsDenied')
);

const INOfferDetailsTimeOut = lazy(
  () => import('containers/preunderwriting/IN/INOfferDetailsTimeOut')
);

const INOfferAccepted = lazy(
  () => import('containers/preunderwriting/IN/INOfferAccepted')
);

const INOfferReleased = lazy(
  () => import('containers/preunderwriting/IN/INOfferReleased')
);

const INCompanyInfo = lazy(
  () => import('containers/underwriting/IN/INCompanyInfo')
);

const INFinancialInfo = lazy(
  () => import('containers/underwriting/IN/INFinancialInfo')
);

const INBuyerInfo = lazy(
  () => import('containers/underwriting/IN/INBuyerInfo')
);

const INReview = lazy(() => import('containers/underwriting/IN/INReview'));

const INApplicationStatus = lazy(
  () => import('containers/underwriting/IN/INApplicationStatus')
);

export const INRouteMap = {
  INBusinessIdentifier,
  INBusinessDetails,
  INBusinessEligibility,
  INApplicants,
  INEsignOffer,
  INOfferDetailsGranted,
  INOfferDetailsDenied,
  INOfferDetailsTimeOut,
  INOfferAccepted,
  INOfferReleased,
  INCompanyInfo,
  INFinancialInfo,
  INBuyerInfo,
  INReview,
  INApplicationStatus,
};
