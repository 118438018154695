import React from 'react';
import { Box, Typography } from '@dripcapital/dripui';
import { DAJ_COLORS } from 'utils';

type Props = {
  label: string;
  value: React.ReactNode | string;
};

const InputPlaceholder: React.FC<Props> = ({ label, value }: Props) => {
  return (
    <Box dataTestId="input-placeholder-element">
      <Typography
        variant="h5"
        fontWeight="regular"
        _sx={{ color: DAJ_COLORS.NICKEL_GREY }}
      >
        {label}
      </Typography>
      {typeof value === 'string' ? (
        <Typography variant="h5" fontWeight="regular">
          {value}
        </Typography>
      ) : (
        <>{value}</>
      )}
    </Box>
  );
};

export default InputPlaceholder;
