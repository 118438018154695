import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@dripcapital/dripui';
import { DAJ_COLORS } from 'utils';
import { Spacer } from 'atoms';
import { Email } from '@mui/icons-material';

type Props = object;

const AppFooter: React.FC<Props> = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const mdLimit = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      _sx={{
        minHeight: 56,
        backgroundColor: DAJ_COLORS.BLUE,
        width: 'auto',
        maxWidth: '100%',
        color: DAJ_COLORS.WHITE,
        marginTop: 15,
        padding: 2,
      }}
    >
      <Flex
        dataTestId="app-footer"
        direction={mdLimit ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        _sx={{ width: '100%' }}
      >
        <Flex
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          _sx={{
            height: '100%',
          }}
        >
          <Typography variant="body1" _sx={{ color: DAJ_COLORS.WHITE }}>
            &#169; {t('title_footer_copyright')}
          </Typography>
        </Flex>
        <Flex
          direction="column"
          alignItems={mdLimit ? 'flex-start' : 'flex-end'}
          justifyContent="flex-start"
          _sx={{
            height: '100%',
          }}
        >
          <Typography variant="body1" _sx={{ color: DAJ_COLORS.WHITE }}>
            {t('title_footer_right_section')}
          </Typography>
          <Spacer verticalSpace={1} />
          <Flex justifyContent="flex-end">
            <Email fontSize="small" />
            <Link
              underline="none"
              href={`mailto:${t('hnc_email')}`}
              _sx={{ color: DAJ_COLORS.WHITE, marginLeft: 0.4 }}
            >
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                _sx={{ color: DAJ_COLORS.WHITE, lineHeight: 1.25 }}
              >
                {t('hnc_email')}
              </Typography>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default AppFooter;
