import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  COLORS,
  Flex,
  FormControl,
  FormHelperText,
  Typography,
  ChipProps,
} from '@dripcapital/dripui';
import { FieldProps } from 'formik';
import RadioPlaceholder from './RadioPlaceholder';

type RadioButtonsProps = Pick<ChipProps, '_sx' | 'className'> &
  Pick<FieldProps, 'field' | 'form'> & {
    label?: string;
    row?: boolean;
    fullWidth?: boolean;
    options: {
      value: string | number;
      label: string;
    }[];
  };

const FancyRadioButtons: React.FC<RadioButtonsProps> = ({
  form,
  field,
  label,
  row,
  options,
  fullWidth = true,
  _sx,
  className = '',
}: RadioButtonsProps) => {
  const { t } = useTranslation();

  const { name } = field;

  const { setFieldValue, setFieldTouched } = form;

  const { error, touched, value } = form.getFieldMeta(name);

  return (
    <FormControl error={!!(error && touched)} fullWidth={fullWidth}>
      {label && (
        <Typography dataTestId="radio-label-element" variant="h4">
          {label}
        </Typography>
      )}
      <Flex
        dataTestId="radio-options-element"
        direction={row ? 'row' : 'column'}
        flexWrap="wrap"
      >
        {options.length > 0 &&
          options.map((item) => {
            return (
              <Box
                dataTestId="radio-option-element"
                key={item.value}
                onClick={() => {
                  if (value === item.value) {
                    setFieldValue(name, '');
                    return;
                  }
                  if (!touched) {
                    setFieldTouched(name);
                  }
                  setFieldValue(name, item.value);
                }}
                _sx={{
                  background: COLORS.WHITE,
                  borderRadius: 2,
                  marginBottom: 1,
                  marginRight: 2,
                  cursor: 'pointer',
                  ..._sx,
                }}
                className={className}
              >
                <RadioPlaceholder
                  label={t(`${item.label}`)}
                  selected={value === item.value}
                />
              </Box>
            );
          })}
      </Flex>
      {error && touched && <FormHelperText>{t(`${error}`)}</FormHelperText>}
    </FormControl>
  );
};

export default FancyRadioButtons;
