import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser(
    $name: String!
    $email: String!
    $countryCode: String!
    $phoneNumber: String!
    $title: String!
    $clientMutationId: String
  ) {
    createUser(
      input: {
        user: {
          name: $name
          email: $email
          phone: { countryCode: $countryCode, phoneNumber: $phoneNumber }
          title: $title
        }
        clientMutationId: $clientMutationId
      }
    ) {
      user {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

export const UPDATE_USER = gql(`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(
      input: $input
    ) {
      message
      error {
        message
        path
      }
    }
  }
`);

export const UPDATE_IMPORTER_DETAILS = gql(`
  mutation updateScfImporter($input: UpdateScfImporterInput!) {
    updateScfImporter(
      input: $input
    ) {
      message
      error {
        message
        path
      }
    }
  }
`);

export const CREATE_CONTACT = gql`
  mutation createContact($password: String!) {
    createContact(input: { password: $password }) {
      error {
        message
        path
      }
      message
    }
  }
`;

export const UPLOAD_CIEC = gql`
  mutation uploadCiec($id: ID!, $rfc: String!, $ciec: String!) {
    uploadCiec(input: { id: $id, rfc: $rfc, ciec: $ciec }) {
      message
    }
  }
`;

export const GENERATE_ONFIDO_TOKEN = gql`
  mutation GenerateOnFidoSdkToken($clientMutationId: String) {
    onfidoGenerateSdkToken(input: { clientMutationId: $clientMutationId }) {
      generateSdkToken {
        onfidoSdkToken
      }
      errors {
        message
        path
      }
    }
  }
`;

export const OnfidoDocumentUpload = gql`
  mutation OnfidoDocumentUpload($clientMutationId: String) {
    onfidoDocumentUploadCompleted(
      input: { clientMutationId: $clientMutationId }
    ) {
      message
    }
  }
`;
