import { gql } from '@apollo/client';

export const ADD_OWNER = gql`
  mutation addOwner(
    $id: ID!
    $name: String
    $owned: String
    $pan: String
    $rfc: String
    $gender: String
    $year: String!
    $month: String!
    $day: String!
    $clientMutationId: String
    $principleShareholder: Boolean
    $legalRepresentative: Boolean
    $secondaryShareholder: Boolean
  ) {
    addOwner(
      input: {
        id: $id
        owner: {
          name: $name
          owned: $owned
          pan: $pan
          rfc: $rfc
          gender: $gender
          dob: { year: $year, month: $month, day: $day }
          majorityShareholder: $principleShareholder
          legalRepresentative: $legalRepresentative
          secondaryShareholder: $secondaryShareholder
        }
        clientMutationId: $clientMutationId
      }
    ) {
      message
    }
  }
`;

export const UPDATE_OWNERS = gql`
  mutation updateOwner(
    $id: ID!
    $index: Int!
    $name: String
    $owned: String
    $pan: String
    $rfc: String
    $gender: String
    $year: String!
    $month: String!
    $day: String!
    $principleShareholder: Boolean
    $legalRepresentative: Boolean
    $secondaryShareholder: Boolean
  ) {
    updateOwner(
      input: {
        id: $id
        index: $index
        owner: {
          name: $name
          owned: $owned
          pan: $pan
          rfc: $rfc
          gender: $gender
          dob: { year: $year, month: $month, day: $day }
          majorityShareholder: $principleShareholder
          legalRepresentative: $legalRepresentative
          secondaryShareholder: $secondaryShareholder
        }
      }
    ) {
      message
    }
  }
`;

export const DELETE_OWNERS = gql`
  mutation deleteOwner($id: ID!, $index: Int!) {
    deleteOwner(input: { id: $id, index: $index }) {
      message
    }
  }
`;

/**
 * Financial's API to send cibil OTP
 */
export const SEND_CIBIL_OTP = gql`
  mutation sendCibilOtp($id: ID!, $clientMutationId: String) {
    sendCibilOtp(input: { id: $id, clientMutationId: $clientMutationId }) {
      message
      error {
        message
        path
      }
    }
  }
`;

export const ADD_COMPANY = gql`
  mutation AddCompany(
    $clientMutationId: String
    $id: ID!
    $companyName: String!
    $email: String!
    $rfc: String!
    $zipcode: String!
    $state: String!
    $municipality: String!
    $country: String!
    $city: String!
    $street: String!
  ) {
    addCompany(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        company: {
          companyName: $companyName
          email: $email
          rfc: $rfc
          address: {
            zipcode: $zipcode
            state: $state
            municipality: $municipality
            country: $country
            city: $city
            street: $street
          }
        }
      }
    ) {
      message
      error {
        message
      }
    }
  }
`;

export const UPDATE_COMPANY_DETAILS = gql`
  mutation updateCompanyDetails(
    $clientMutationId: String
    $id: ID!
    $companyName: String!
    $email: String!
    $rfc: String!
    $zipcode: String!
    $state: String!
    $municipality: String!
    $country: String!
    $city: String!
    $street: String!
  ) {
    updateCompany(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        company: {
          companyName: $companyName
          email: $email
          rfc: $rfc
          address: {
            zipcode: $zipcode
            state: $state
            municipality: $municipality
            country: $country
            city: $city
            street: $street
          }
        }
      }
    ) {
      error {
        message
      }
      message
    }
  }
`;

export const ADD_SHAREHOLDER = gql`
  mutation AddShareholder(
    $clientMutationId: String
    $id: ID!
    $index: String
    $firstname: String!
    $middlename: String!
    $lastname: String!
    $email: String!
    $rfc: String!
    $gender: String!
    $majorityShareholder: Boolean!
    $legalRepresentative: Boolean!
    $secondaryShareholder: Boolean!
    $zipcode: String!
    $state: String!
    $municipality: String!
    $country: String!
    $city: String!
    $street: String!
  ) {
    addShareholder(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        shareholder: {
          name: {
            firstname: $firstname
            middlename: $middlename
            lastname1: $middlename
            lastname2: $lastname
          }
          rfc: $rfc
          email: $email
          gender: $gender
          indexNo: $index
          majorityShareholder: $majorityShareholder
          legalRepresentative: $legalRepresentative
          secondaryShareholder: $secondaryShareholder
          address: {
            zipcode: $zipcode
            state: $state
            municipality: $municipality
            country: $country
            city: $city
            street: $street
          }
        }
      }
    ) {
      message
      error {
        message
      }
    }
  }
`;

/**
 * Financial's API to verify cibil OTP
 */
export const VERIFY_CIBIL_OTP = gql`
  mutation verifyCibilOtp($id: ID!, $clientMutationId: String, $otp: String!) {
    verifyCibilOtp(
      input: { id: $id, clientMutationId: $clientMutationId, otp: $otp }
    ) {
      cibilUrl
      clientMutationId
      error {
        message
        path
      }
    }
  }
`;

export const UPDATE_SHAREHOLDER_DETAILS = gql`
  mutation updateShareholderDetails(
    $clientMutationId: String
    $id: ID!
    $indexNo: String
    $firstname: String!
    $middlename: String!
    $lastname: String!
    $email: String!
    $rfc: String!
    $shareholderId: String
    $gender: String!
    $majorityShareholder: Boolean!
    $legalRepresentative: Boolean!
    $secondaryShareholder: Boolean!
    $zipcode: String!
    $state: String!
    $municipality: String!
    $country: String!
    $city: String!
    $street: String!
  ) {
    updateShareholder(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        shareholder: {
          name: {
            firstname: $firstname
            middlename: $middlename
            lastname1: $middlename
            lastname2: $lastname
          }
          email: $email
          rfc: $rfc
          gender: $gender
          indexNo: $indexNo
          shareholderId: $shareholderId
          majorityShareholder: $majorityShareholder
          legalRepresentative: $legalRepresentative
          secondaryShareholder: $secondaryShareholder
          address: {
            zipcode: $zipcode
            state: $state
            municipality: $municipality
            country: $country
            city: $city
            street: $street
          }
        }
      }
    ) {
      error {
        message
      }
      message
    }
  }
`;

export const REGISTER_SCF_BUSINESS = gql`
  mutation registerSCFBusiness(
    $company: String!
    $country: String
    $state: String
    $address: String
    $category: String
    $products: String
    $customerId: String
    $zipCode: String
    $dunsNo: String
    $clientMutationId: String
  ) {
    createScfImporter(
      input: {
        scfImporter: {
          company: $company
          country: $country
          state: $state
          address: $address
          category: $category
          products: $products
          customerId: $customerId
          zipCode: $zipCode
          dunsNo: $dunsNo
        }
        clientMutationId: $clientMutationId
      }
    ) {
      scfImporter {
        businessIdentifierKey
        businessIdentifierValue
        companyName
        dripProfile {
          designation
          id
          onboardingStage
          profileType
        }
        id
        location {
          address
          country {
            name
          }
          state {
            name
          }
          zipCode
        }
        productCategory
        status
        typeOfGoods
      }
      message
      clientMutationId
      error {
        message
        path
      }
    }
  }
`;
