/* eslint-disable indent */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@dripcapital/dripui-v1/components/Flex';
import { Grid } from '@dripcapital/dripui-v1/components/Grid';
import { theme } from '@dripcapital/dripui-v1/theme';
import { Accordion } from '@dripcapital/dripui-v1/components/Accordion';
import { Typography } from '@dripcapital/dripui-v1/components/Typography';
import { TertiaryButton } from '@dripcapital/dripui-v1/components/TertiaryButton';
import { DAJ_COLORS, formatNumberAsUSD, openLinkInTab } from 'utils';
import { Spacer } from 'atoms';
import { OpenInNew } from '@mui/icons-material';
import { useReactiveVar } from '@apollo/client';
import { appDataVar } from 'config/server';
import { FacilityDetails } from 'api';

type Props = {
  facilityDetails: FacilityDetails;
  showReviseOffer?: boolean;
};

const FacilityAccordion: React.FC<Props> = ({
  facilityDetails,
  showReviseOffer = true,
}) => {
  const { t } = useTranslation();

  const { businessId } = useReactiveVar(appDataVar);

  const goToAdmin = () => {
    const url =
      process.env.REACT_APP_DRIP_ADMIN +
      '/admin/importers/' +
      businessId +
      '#commercials';

    openLinkInTab(url);
  };

  const facilityDetailsData: { text: string; value: string }[] = [
    {
      text: 'title_facility_size',
      value: formatNumberAsUSD(Number(facilityDetails.facilitySize ?? 0)),
    },
    {
      text: 'title_advance_rate',
      value: facilityDetails?.advanceRate
        ? facilityDetails?.advanceRate + '%' + t('us_scf_legal_advance_rate')
        : '-',
    },
    {
      text: 'title_margin_rate',
      value: facilityDetails.marginRate
        ? facilityDetails.marginRate + '%'
        : '-',
    },
    {
      text: 'title_setup_fee',
      value: facilityDetails?.setupFee
        ? facilityDetails?.setupFee + t('us_scf_legal_setup_fee')
        : '-',
    },
    {
      text: 'title_credit_period',
      value: facilityDetails.maxCreditPeriod
        ? facilityDetails.maxCreditPeriod + t('us_scf_legal_max_credit')
        : '-',
    },
    {
      text: 'title_interest_fee',
      value: facilityDetails.interestFee
        ? facilityDetails.interestFee + '%' + t('us_scf_legal_monthly')
        : '-',
    },
    {
      text: 'title_transaction_fee',
      value: facilityDetails.transactionFee
        ? facilityDetails.transactionFee +
          '%' +
          t('us_scf_legal_transaction_fee')
        : '-',
    },
    {
      text: 'title_overdue_fee',
      value: facilityDetails.overdueRate
        ? facilityDetails.overdueRate + '%' + t('us_scf_legal_monthly')
        : '-',
    },
  ];

  return (
    <Accordion
      defaultExpandedIds={['panel-1']}
      items={[
        {
          title: (
            <Flex>
              <Typography
                fontWeight="bold"
                color="secondary"
                variant="h4"
                _sx={{ marginLeft: theme.spacing(1) }}
              >
                {t('us_scf_legal_facility_details')}
              </Typography>
            </Flex>
          ),
          content: (
            <>
              <Grid container rowSpacing={0} columnSpacing={0}>
                {facilityDetailsData.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    container
                    rowSpacing={1}
                    _sx={{
                      mt: index === 0 ? '12px' : 0,
                      mb:
                        index === 0 && index === facilityDetailsData.length - 1
                          ? 0
                          : '12px',
                    }}
                  >
                    <Grid item xs={12} md={4}>
                      <Typography
                        _sx={{
                          fontSize: theme.spacing(2),
                        }}
                        fontWeight="bold"
                        color="secondary"
                        variant="h4"
                      >
                        {t(item.text)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        _sx={{
                          fontSize: '16px',
                        }}
                        fontWeight="regular"
                        color="secondary"
                        variant="h4"
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {showReviseOffer && (
                <>
                  <Spacer verticalSpace={4} />
                  <TertiaryButton EndIcon={OpenInNew} onClick={goToAdmin}>
                    {t('title_revise_offer')}
                  </TertiaryButton>
                </>
              )}
            </>
          ),
          id: 'panel-1',
        },
      ]}
      _sx={{
        border: `1px solid ${DAJ_COLORS.SKY_BLUE_400}`,
        marginBottom: theme.spacing(1.5),
        borderRadius: theme.spacing(1),
      }}
      accordionSummarySx={{
        borderRadius: theme.spacing(1),
        padding: `0px ${theme.spacing(2)}`,
        '& .MuiAccordionSummary-content': {
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: `${theme.spacing(1.25)} 0`,
        },
      }}
      accordionDetailsSx={{
        border: 'none',
        padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
      }}
    />
  );
};

export default FacilityAccordion;
