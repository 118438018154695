import React from 'react';
import { Box, Grid, styled } from '@dripcapital/dripui';
import banner from 'assets/welcome-banner.png';

type Props = {
  children: React.ReactNode;
  dataTestId?: string;
};

const PaddedContainer = styled(Box)(({ theme }) => {
  return {
    padding: `${theme.spacing(6)} ${theme.spacing(9)}`,
    '@media (max-width: 1050px)': {
      padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    },
    '@media (max-width: 600px)': {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  };
});

const AuthContainer: React.FC<Props> = ({ children }: Props) => {
  return (
    <Box dataTestId="authentication-container">
      <Grid
        _sx={{ position: 'relative' }}
        container
        columnSpacing={2}
        justifyContent={'space-between'}
      >
        <Grid xs={12} sm={10} md={6} lg={6} item>
          <PaddedContainer>{children}</PaddedContainer>
        </Grid>
        <Grid
          md={6}
          lg={6}
          _sx={{
            display: { xs: 'none', md: 'block' },
            position: 'fixed',
            right: 0,
            width: '100%',
          }}
          item
        >
          <img
            src={banner}
            alt="placeholder"
            style={{
              width: '100%',
              height: '100vh',
              overflow: 'hidden',
              position: 'relative',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default AuthContainer;
