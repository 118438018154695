import React from 'react';
import { ColorEnum, CssOverrideType, Typography } from '@dripcapital/dripui';

type Props = CssOverrideType & {
  children: React.ReactNode;
  color?: ColorEnum;
  weight?: 'bold' | 'light' | 'regular';
  noWrap?: boolean;
};
const StyledSubtitle: React.FC<Props> = ({
  children,
  color = 'black',
  weight = 'bold',
  _sx,
  className,
  noWrap = false,
}) => {
  return (
    <Typography
      dataTestId="subtitle-element"
      variant="subtitle1"
      color={color}
      fontWeight={weight}
      _sx={{
        lineHeight: 1.235,
        ..._sx,
      }}
      className={className}
      noWrap={noWrap}
    >
      {children}
    </Typography>
  );
};

export default StyledSubtitle;
