/* eslint-disable react/display-name */
import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ServerError, useReactiveVar } from '@apollo/client';
import { RouteContext } from 'shared/context/RouteContext';
import {
  UserProfile,
  useUserProfileQuery,
  useUserProfileLazyQuery,
  useLogoutMutation,
} from 'api';
import { appDataVar } from 'config/server';
import {
  REGION_CODE_MAP,
  BDM,
  USER_ID,
  SUPER_USER,
  HOME_PATH,
  TOKEN,
  USER_TYPE,
  USER_TARGET,
  DRIP_DOMAIN,
  settings,
  DEBTOR_TYPE,
  ONBOARDING_STAGES,
  COOKIE_PATH,
  APP_TYPES,
  CHANNEL_PARTNER,
  CP,
} from 'utils';
import { AuthMessageEnum, UserProfileEnum } from 'enums';
import { AnalyticsContext } from 'shared/context/AnalyticsContext';
import mouseflowUtil from 'utils/mouseflow';

export interface WithUserProfileProps {
  loading: boolean;
  profile: UserProfile;
  applicantProfileData: UserProfile;
  logout: () => void;
  fetchApplicantProfileDetails: (
    applicationId: string,
    navigateBool: boolean
  ) => void;
  goToStage: (nextOnboardingStage: string) => void;
  goToPath: (path: string) => void;
  stages: {
    [key: string]: string;
  };
  paths: {
    [key: string]: string;
  };
}

export type WithUserProfileType =
  | 'loading'
  | 'profile'
  | 'applicantProfileData'
  | 'logout'
  | 'fetchApplicantProfileDetails'
  | 'goToStage'
  | 'goToPath'
  | 'stages'
  | 'paths';

const withUserProfile = <P extends WithUserProfileProps>(
  Component: React.ComponentType<P>
) => {
  return (props: Omit<P, WithUserProfileType>) => {
    const location = useLocation();

    const navigate = useNavigate();

    const { mixpanel } = useContext(AnalyticsContext);

    const [searchParams] = useSearchParams();

    const {
      isBDMVar: isBDM,
      isChannelPartnerVar: isCP,
      regionVar: region,
      applicantId,
      businessId,
    } = useReactiveVar(appDataVar);

    const routeContext = useContext(RouteContext);

    const [cookies] = useCookies([TOKEN, USER_ID, USER_TYPE, USER_TARGET]);

    useEffect(() => {
      if (!cookies[USER_ID]) {
        goToLogin();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [logout] = useLogoutMutation();

    const getStages = () => {
      const stages = routeContext?.[region]?.stages;
      let userStages;
      if (isBDM) userStages = stages?.bdm;
      else if (isCP) userStages = stages?.cp;
      else userStages = stages?.exporter;
      return userStages;
    };

    const getPath = (onboardingStage: string, region: string) => {
      const { stages, paths } = region
        ? routeContext[region]
        : { stages: {}, paths: {} };
      let userStages;
      if (isCP) userStages = stages?.cp;
      else if (isBDM) userStages = stages?.bdm;
      else userStages = stages?.exporter;
      const pathName = userStages?.[onboardingStage];
      const path = paths?.[pathName || ''];
      return path;
    };

    const getOnboardingStage = (
      isApplicant: boolean,
      isBDM: boolean,
      isCP: boolean,
      onboardingStage: string
    ) => {
      const stage = settings.getSession('stage') || '';
      const stages = getStages();
      if (isBDM) {
        if (isApplicant) {
          if (!stages[onboardingStage]) {
            return ONBOARDING_STAGES.APPLICANTS;
          }
        } else if (stages) {
          if (
            !(applicantId || searchParams.get('applicantId')) ||
            !(businessId || searchParams.get('businessId')) ||
            !stages?.[stage]
          ) {
            return ONBOARDING_STAGES.APPLICANTS;
          }
        }
      }

      let updatedOnboardingStage = '';
      if (onboardingStage) {
        updatedOnboardingStage = onboardingStage;
      } else if (stage) {
        updatedOnboardingStage = stage;
      } else if (isBDM) {
        updatedOnboardingStage = ONBOARDING_STAGES.APPLICANTS;
      } else if (isCP) {
        updatedOnboardingStage = ONBOARDING_STAGES.APPLICANTS;
      } else {
        updatedOnboardingStage = '';
      }

      return updatedOnboardingStage;
    };

    const getApplicantID = (isBDM: boolean, isCP: boolean) => {
      const applicantID =
        isBDM || isCP
          ? applicantId || searchParams.get('applicantId')
          : cookies[USER_ID];
      return applicantID;
    };

    const getBusinessID = (isBDM: boolean, isCP: boolean, id?: string) => {
      const businessID =
        isBDM || isCP
          ? businessId || (searchParams.get('businessId') as string)
          : id || '';
      return businessID;
    };

    const handleError = () => {
      // TODO: send error msg too
      navigate('/notfound');
    };

    const fetchApplicantProfileDetails = (
      applicantId: string,
      navigateBool: boolean,
      applicantStage?: string
    ) => {
      fetchApplicantProfile({
        variables: {
          userId: applicantId.toString(),
        },
      }).then(({ data }) => {
        if (data) {
          const businesses = data?.userProfile?.businesses?.[0];
          if (businesses) {
            const businessId = businesses?.id;
            const dripProfile = businesses?.dripProfile?.[0];
            const countryName = businesses?.location?.country?.name || '';
            const countryCode =
              DEBTOR_TYPE[businesses?.debtorType?.toUpperCase() as string] ||
              REGION_CODE_MAP[countryName?.toUpperCase()];

            const onboardingStage = getOnboardingStage(
              true,
              isBDM,
              isCP,
              dripProfile?.onboardingStage || ''
            );

            settings.setSession('stage', onboardingStage);

            const appData = appDataVar();
            appDataVar({
              ...appData,
              applicantRegionVar: countryCode,
              applicantId: applicantId,
              businessId: businessId,
              onboardingStageVar: onboardingStage,
              isLoggedInVar: true,
            });

            if (navigateBool) {
              let path = getPath(
                dripProfile?.onboardingStage || onboardingStage,
                countryCode
              );
              if (path) {
                path = `${path}?applicantId=${applicantId}`;
                if (businessId) {
                  path = `${path}&businessId=${businessId}`;
                }
                navigate(path);
              } else {
                handleError();
              }
            }
          } else if (applicantStage) {
            settings.setSession('stage', applicantStage);

            const appData = appDataVar();
            appDataVar({
              ...appData,
              applicantId: applicantId,
              onboardingStageVar: applicantStage,
              isLoggedInVar: true,
            });

            if (navigateBool) {
              const path = getPath(applicantStage, region);
              if (path) {
                navigate(`${path}?applicantId=${applicantId}`);
              } else {
                handleError();
              }
            }
          }
        }
      });
    };

    const {
      refetch: refetchUserProfile,
      loading: userProfileLoader,
      data: userProfileData,
    } = useUserProfileQuery({
      variables: {
        userId: cookies[USER_ID] || '',
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const userId = data.userProfile?.id;
        const businesses = data?.userProfile?.businesses?.[0];
        const dripProfile = businesses?.dripProfile?.[0];
        const countryName = businesses?.location?.country?.name || '';
        const countryCode =
          DEBTOR_TYPE[businesses?.debtorType?.toUpperCase() as string] ||
          REGION_CODE_MAP[countryName?.toUpperCase()];
        const isBDM = data?.userProfile?.utype === SUPER_USER ? true : false;
        const isChannelPartner =
          data?.userProfile?.utype === CHANNEL_PARTNER ? true : false;

        let onboardingStageVar = '';
        if (isBDM) {
          onboardingStageVar = '';
        } else if (isChannelPartner) {
          onboardingStageVar = '';
        } else onboardingStageVar = dripProfile?.onboardingStage || '';

        const profileType = dripProfile?.profileType || '';

        const onboardingStage = getOnboardingStage(
          false,
          isBDM,
          isChannelPartner,
          onboardingStageVar
        );

        settings.setSession('stage', onboardingStage);

        const { paths } = countryCode
          ? routeContext[countryCode]
          : { paths: {} };

        const appData = appDataVar();
        appDataVar({
          ...appData,
          regionVar: countryCode,
          isBDMVar: isBDM,
          isChannelPartnerVar: isChannelPartner,
          onboardingStageVar: onboardingStage,
          isLoggedInVar: true,
          applicantId: getApplicantID(isBDM, isChannelPartner),
          profileTypeVar: isBDM ? BDM : isChannelPartner ? CP : profileType,
          salesContactVar: businesses?.salesContact || {},
          businessId: getBusinessID(isBDM, isChannelPartner, businesses?.id),
          userId: userId || '',
        });

        mixpanel.identify(data?.userProfile?.id ?? '');

        mixpanel.set_once({
          'First Login Date': new Date(),
          $email: data?.userProfile?.email,
          $name: data?.userProfile?.name,
          'Default user Id': data?.userProfile?.id,
        });

        mouseflowUtil.SET_USER_DATA({
          id: data?.userProfile?.id,
          name: data?.userProfile?.name,
          business_id: businesses?.id,
          type: isBDM ? BDM : isCP ? CP : profileType,
        });

        if (location.pathname === HOME_PATH) {
          let path = getPath(onboardingStage, countryCode);
          if (isBDM || isChannelPartner) {
            if (paths) {
              navigate((paths as any).applicants);
            } else {
              handleError();
            }
          } else {
            if (path) {
              if (businessId) {
                path = `${path}&businessId=${businessId}`;
              }
              navigate(path);
            } else {
              handleError();
            }
          }
        }
      },
      onError: (error) => {
        if (error.message === UserProfileEnum.INVALID_USER_ID) {
          goToLogin();
        } else {
          const networkErrorObj = error?.networkError as ServerError;
          if (
            networkErrorObj?.result?.error?.[0]?.message ===
            AuthMessageEnum.UNAUTHORIZED_REQUEST
          ) {
            goToLogin();
          }
        }
      },
    });

    const [
      fetchApplicantProfile,
      { loading: applicantProfileLoader, data: applicantProfileData },
    ] = useUserProfileLazyQuery({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });

    const goToLogin = () => {
      settings.removeCookie(TOKEN, { domain: DRIP_DOMAIN, path: COOKIE_PATH });
      settings.removeCookie(USER_ID, {
        domain: DRIP_DOMAIN,
        path: COOKIE_PATH,
      });
      settings.removeCookie(USER_TYPE, {
        domain: DRIP_DOMAIN,
        path: COOKIE_PATH,
      });
      settings.removeCookie(USER_TARGET, {
        domain: DRIP_DOMAIN,
        path: COOKIE_PATH,
      });
      settings.clearSession();
      setTimeout(() => {
        const callback = window.location.href
          .replace(window.location.origin, '')
          .replace('&', encodeURIComponent('&'));
        window.location.href =
          process.env.REACT_APP_PORTAL_RF +
            `/logout?app=${
              isCP ? APP_TYPES.CP : APP_TYPES.NDAJ
            }&callback=${encodeURIComponent(callback)}` || '';
      });
    };

    const handleLogout = () => {
      logout({
        variables: {
          id: cookies[USER_ID] || '',
        },
      }).finally(() => {
        goToLogin();
      });
    };

    const goToStage = (nextOnboardingStage: string) => {
      const appData = appDataVar();
      appDataVar({
        ...appData,
        onboardingStageVar: nextOnboardingStage,
      });
      settings.setSession('stage', nextOnboardingStage);
    };

    const goToPath = (path: string, userId?: string, businessId?: string) => {
      const appData = { ...appDataVar() };
      if (userId) {
        appData['applicantId'] = userId;
      }
      if (businessId) {
        appData['businessId'] = businessId;
      }
      if (userId || businessId) {
        appDataVar(appData);
      }
      if (isBDM || isCP) {
        const applicationId = userId || applicantId;
        let url = `${path}?applicantId=${applicationId}`;
        if (businessId) {
          url = `${url}&businessId=${businessId}`;
        }
        navigate(url);
      } else {
        let url = path;
        if (businessId) {
          url = `${url}&businessId=${businessId}`;
        }
        navigate(url);
      }
    };

    useEffect(() => {
      const locationStr = settings.getSession('location');
      const fullLocationPath = `${location.pathname}${location.search}`;
      const applicantUserId = applicantId || searchParams.get('applicantId');
      let updatedLocationObj = {};
      if (locationStr) {
        const { to } = JSON.parse(locationStr);
        if (fullLocationPath !== to) {
          updatedLocationObj = {
            from: to,
            to: fullLocationPath,
          };
          settings.setSession('location', JSON.stringify(updatedLocationObj));
        }
        if (applicantUserId && isBDM && !applicantProfileLoader) {
          fetchApplicantProfileDetails(applicantUserId, false);
        }
        if (applicantUserId && isCP && !applicantProfileLoader) {
          fetchApplicantProfileDetails(applicantUserId, false);
        } else if (applicantUserId && !userProfileLoader) {
          refetchUserProfile();
        }
      } else {
        updatedLocationObj = { from: fullLocationPath, to: fullLocationPath };
        settings.setSession('location', JSON.stringify(updatedLocationObj));
        if (applicantUserId && !applicantProfileLoader) {
          fetchApplicantProfileDetails(applicantUserId, false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
      <Component
        {...(props as P)}
        loading={userProfileLoader || applicantProfileLoader}
        profile={userProfileData?.userProfile as UserProfile}
        applicantProfile={applicantProfileData?.userProfile as UserProfile}
        fetchApplicantProfileDetails={fetchApplicantProfileDetails}
        stages={getStages()}
        paths={region ? routeContext?.[region]?.paths : {}}
        logout={handleLogout}
        goToStage={goToStage}
        goToPath={goToPath}
      />
    );
  };
};

export default withUserProfile;
