import React from 'react';
import { Typography, Flex, Box } from '@dripcapital/dripui';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  TextFieldForFormik,
  BorderCardContainer,
  Spacer,
  SelectForFormik,
  GenderChip,
  AtomicButton,
} from 'atoms';
import {
  COUNTRY_OPTIONS,
  FINANCIAL_INFO_INITIAL_VALUES,
  GENDER_OPTIONS,
} from 'conversion';
import {
  companyFinancialInfoSchema,
  formatters,
  shareholderFinancialInfoSchema,
} from 'utils';
import { AuthorizationContentType } from 'types';
import { ButtonTypeEnum, RolesMXFinancial } from 'enums';

type Props = {
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onSave: (formValues: AuthorizationContentType) => void;
  data?: AuthorizationContentType;
  title?: string;
  role?: RolesMXFinancial;
  loading?: boolean;
  states?: string[];
};

const MXFinancialInfoForm: React.FC<Props> = ({
  onCancel,
  onSave,
  data,
  title = 'label_shareholder',
  role = 'shareholder',
  loading = false,
  states,
}) => {
  const { t } = useTranslation();

  const isCompany = role === RolesMXFinancial.COMPANY;

  return (
    <Formik
      initialValues={data ? data : FINANCIAL_INFO_INITIAL_VALUES}
      onSubmit={(values) => {
        if (isCompany) {
          onSave(companyFinancialInfoSchema.cast(values));
        } else {
          onSave(shareholderFinancialInfoSchema.cast(values));
        }
      }}
      validationSchema={
        isCompany ? companyFinancialInfoSchema : shareholderFinancialInfoSchema
      }
    >
      {(formik) => {
        const { handleSubmit } = formik;
        return (
          <BorderCardContainer dataTestId="mx-financial-form">
            {/* TODO: add translations */}
            <Typography fontWeight="bold">
              {isCompany
                ? t('mrfi_author_company')
                : t('fic_heading', { title })}
            </Typography>
            <Spacer verticalSpace={4} />
            <Box _sx={{ width: '80%' }}>
              {isCompany && (
                <Field
                  name="companyName"
                  label={t('label_company_name')}
                  placeholder={t('placeholder_company_name')}
                  fullWidth
                  component={TextFieldForFormik}
                />
              )}
              <Spacer verticalSpace={4} />
              <Box _sx={{ width: '60%' }}>
                {role === RolesMXFinancial.SHAREHOLDER && (
                  <>
                    <Field
                      name="name.firstname"
                      label={t('label_first_name')}
                      placeholder={t('placeholder_first_name')}
                      fullWidth
                      component={TextFieldForFormik}
                    />
                    <Spacer verticalSpace={4} />
                    <Field
                      name="name.middlename"
                      label={t('label_middle_name')}
                      placeholder={t('placeholder_middle_name')}
                      fullWidth
                      component={TextFieldForFormik}
                    />
                    <Spacer verticalSpace={4} />
                    <Field
                      name="name.lastname"
                      label={t('label_last_name')}
                      placeholder={t('placeholder_last_name')}
                      fullWidth
                      component={TextFieldForFormik}
                    />
                    <Spacer verticalSpace={4} />
                  </>
                )}
                <Field
                  name="email"
                  label={t('label_email')}
                  placeholder={t('placeholder_email')}
                  fullWidth
                  component={TextFieldForFormik}
                />
                <Spacer verticalSpace={4} />
                <Field
                  name="rfc"
                  label={t('label_rfc_code')}
                  component={TextFieldForFormik}
                  fullWidth
                  disabled={!!data?.rfc}
                />
                <Spacer verticalSpace={4} />
                <Typography fontWeight="bold" variant="h4">
                  {t('label_physical_address')}
                </Typography>
                <Spacer verticalSpace={2} />
                <Field
                  name="address.zipcode"
                  label={t('label_zip_code')}
                  placeholder={t('placeholder_zipcode')}
                  component={TextFieldForFormik}
                  fullWidth
                />
                <Spacer verticalSpace={4} />
                <Field
                  name="address.state"
                  label={t('label_state_region')}
                  placeholder={t('placeholder_select_option')}
                  component={SelectForFormik}
                  translate={false}
                  options={formatters.generateSelectOptions(states ?? [])}
                  fullWidth
                />
                <Spacer verticalSpace={4} />
                <Field
                  name="address.municipality"
                  label={t('label_municipality')}
                  placeholder={t('placeholder_municipality')}
                  component={TextFieldForFormik}
                  fullWidth
                />
                <Spacer verticalSpace={4} />
                <Field
                  name="address.country"
                  label={t('label_country')}
                  placeholder={t('placeholder_select_country')}
                  component={SelectForFormik}
                  options={COUNTRY_OPTIONS}
                  disabled
                  fullWidth
                />
                <Spacer verticalSpace={4} />
                <Field
                  name="address.city"
                  label={t('label_city')}
                  placeholder={t('placeholder_city')}
                  component={TextFieldForFormik}
                  fullWidth
                />
                <Spacer verticalSpace={4} />
                <Field
                  name="address.street"
                  label={t('label_street')}
                  placeholder={t('placeholder_street_address')}
                  component={TextFieldForFormik}
                  fullWidth
                />
              </Box>
            </Box>
            {role === RolesMXFinancial.SHAREHOLDER && (
              <>
                <Spacer verticalSpace={4} />
                <Typography>{t('label_icon_preference')}</Typography>
                <Field
                  name="gender"
                  row
                  options={GENDER_OPTIONS}
                  component={GenderChip}
                />
              </>
            )}
            <Spacer verticalSpace={4} />
            <Flex spacing={5}>
              <AtomicButton loading={loading} onClick={() => handleSubmit()}>
                {t('btn_save')}
              </AtomicButton>
              <AtomicButton onClick={onCancel} variant={ButtonTypeEnum.TEXT}>
                {t('btn_cancel')}
              </AtomicButton>
            </Flex>
          </BorderCardContainer>
        );
      }}
    </Formik>
  );
};

export default MXFinancialInfoForm;
