import React from 'react';
import {
  Box,
  COLORS,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@dripcapital/dripui';
import { HeadingType } from 'types';
import { useTranslation } from 'react-i18next';
import { DAJ_COLORS, FONT } from 'utils';

type Props = {
  startPageIndex?: number;
  pageNumber?: number; // page number (start with zero)
  pageLength?: number; // Length of single page
  count?: number; // Total number of records exists
  onPageChange?: (page: number) => void;
  tableHeadings: HeadingType[];
  tableCells: object[];
  hideHeadings?: boolean;
  hidePagination?: boolean;
};

const PaginatedTable: React.FC<Props> = ({
  startPageIndex = 0,
  pageNumber = 0,
  pageLength = 10,
  count = 10,
  onPageChange = () => 0,
  tableHeadings,
  tableCells,
  hideHeadings = false,
  hidePagination = false,
}: Props) => {
  const { t } = useTranslation();

  const renderCell = (row: object, column: HeadingType) => {
    if (typeof row[column.id] === 'string') {
      return (
        <Typography
          variant="body1"
          color="secondary"
          title={typeof row[column.id] === 'string' ? row[column.id] : ''}
          _sx={{ width: column.minWidth }}
          noWrap
        >
          {row[column.id]}
        </Typography>
      );
    }
    return <>{row[column.id]}</>;
  };
  return (
    <Box dataTestId="table-container-element">
      <TableContainer _sx={{ background: COLORS.WHITE }}>
        <Table aria-label="sticky table">
          {!hideHeadings && (
            <TableHead dataTestId="table-headings" _sx={{ border: 0 }}>
              <TableRow>
                {tableHeadings?.map((column, colHeadIndex) => (
                  <TableCell
                    key={colHeadIndex}
                    align={'left'}
                    style={{
                      background: DAJ_COLORS.ALICE_BLUE,
                      fontWeight: FONT.WEIGHT.SEMI_BOLD,
                      minWidth: column.minWidth,
                    }}
                  >
                    {t(`${column.label}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody
            dataTestId="table-body"
            _sx={{
              borderColor: hideHeadings ? DAJ_COLORS.OFF_WHITE : 'initial',
              borderStyle: hideHeadings ? 'solid' : 'initial',
              borderWidth: hideHeadings ? '1px' : 0,
            }}
          >
            {tableCells
              ?.slice(startPageIndex, startPageIndex + pageLength)
              .map((row, rowIndex) => {
                return (
                  <TableRow key={rowIndex} dataTestId="table-row" hover>
                    {tableHeadings?.map((column, colIndex) => {
                      return (
                        <TableCell
                          key={colIndex}
                          align={'left'}
                          style={{
                            width: column.minWidth,
                          }}
                        >
                          {renderCell(row, column)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {!hidePagination && count !== 0 && (
        <Table dataTestId="table-pagination">
          <TableBody>
            <TableRow>
              <TablePagination
                count={count}
                rowsPerPage={pageLength}
                onPageChange={(e, page) => {
                  if (page >= 0) {
                    onPageChange(page);
                  }
                }}
                page={pageNumber}
                _sx={{ marginLeft: 'auto' }}
                showFirstButton
                showLastButton
              />
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default PaginatedTable;
