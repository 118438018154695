import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  COLORS,
  Flex,
  Grid,
  Link,
  Typography,
  useTheme,
} from '@dripcapital/dripui';
import { AtomicButton, BorderCardContainer, Spacer } from 'atoms';
import { AuthenticationStatus, AuthenticationStatusType } from 'types';
import { Lock, CheckCircle, AcUnitOutlined, Error } from '@mui/icons-material';
import { AuthButtonTypeEnum, ButtonTypeEnum } from 'enums';
import { FONT } from 'utils';

type Props = {
  disabled?: boolean;
  title: string;
  subTitle: string;
  info?: string;
  itemList?: string[];
  status?: AuthenticationStatus;
  subText?: string;
  onClick?: () => void;
  buttonText?: string;
  buttonType?: string;
  plaidBody?: string;
  children?: React.ReactNode;
};

const AuthenticationInfoCard: React.FC<Props> = ({
  children,
  disabled,
  info,
  title,
  status,
  subText = '',
  subTitle,
  itemList,
  buttonText,
  buttonType = AuthButtonTypeEnum.BUTTON,
  onClick = () => 1,
  plaidBody,
}) => {
  const theme = useTheme();

  const { t } = useTranslation();

  const authenticationStatus: AuthenticationStatusType = {
    pending: {
      text: t('label_auth_pending'),
      icon: <Error color="warning" />,
      subText: subText,
    },
    retry: {
      text: t('label_auth_retry'),
      icon: <Error color="warning" />,
      subText: subText,
    },
    failed: {
      text: t('label_auth_failed'),
      icon: <Error color="error" />,
      subText: subText,
    },
    in_progress: {
      text: t('label_authenticating_in_progress'),
      icon: <AcUnitOutlined color="disabled" />,
      subText: subText,
    },
    progress: {
      text: t('label_authenticating_progress'),
      icon: <Error sx={{ color: COLORS.SECONDARY }} />,
      subText: subText,
    },
    completed: {
      text: t('label_authenticated'),
      icon: <CheckCircle color="success" />,
      subText: subText,
    },
  };

  const renderButton = () => {
    if (buttonType === AuthButtonTypeEnum.BUTTON) {
      return (
        <AtomicButton
          dataTestId="auth-button"
          _sx={{ height: theme.spacing(6) }}
          onClick={onClick}
          variant={ButtonTypeEnum.OUTLINED}
          disabled={disabled}
        >
          {buttonText}
        </AtomicButton>
      );
    }
    if (buttonType === AuthButtonTypeEnum.LINK) {
      return (
        <Link
          dataTestId="auth-button"
          _sx={{
            textDecoration: 'none',
            fontSize: FONT.SIZES.F16,
            fontWeight: FONT.WEIGHT.BOLD,
          }}
          onClick={onClick}
        >
          {buttonText}
        </Link>
      );
    }
  };

  return (
    <BorderCardContainer dataTestId="auth-info-card" _sx={{ padding: 2 }}>
      <Grid container rowSpacing={2} wrap="wrap">
        <Grid xs={12} item>
          <Typography variant="h4" fontWeight="bold" color="secondary">
            {title}
          </Typography>
        </Grid>
        {subTitle && (
          <Grid xs={12} item>
            <Typography variant="subtitle1">{subTitle}</Typography>
          </Grid>
        )}
        {itemList && Array.isArray(itemList) && (
          <Grid xs={12} item>
            <ul
              style={{
                listStyleType: 'decimal',
                fontSize: '14px',
                paddingLeft: 16,
                margin: 0,
              }}
            >
              {itemList.map((item: string, index) => (
                <li key={index}>
                  <Typography variant="body1">{item}</Typography>
                </li>
              ))}
            </ul>
          </Grid>
        )}
        {plaidBody && (
          <Grid xs={12} md={10} item>
            <Flex alignItems={'flex-start'} spacing={1}>
              <Lock fontSize={'small'} sx={{ marginTop: 0.8 }} />
              <Typography _sx={{ fontSize: FONT.SIZES.F12 }}>
                {plaidBody}
              </Typography>
            </Flex>
          </Grid>
        )}
        {info && (
          <Grid xs={12} item>
            <Typography variant="body1" fontWeight="bold">
              {info}
            </Typography>
          </Grid>
        )}
        {status && (
          <Grid xs={12} item>
            <Flex _sx={{ margin: '10px 0px' }} spacing={1}>
              <>{authenticationStatus[status].icon}</>
              <Flex direction={'column'}>
                <Typography variant="body1" fontWeight="bold">
                  {authenticationStatus[status].text}
                </Typography>
                {subText && (
                  <Typography variant="body1">
                    {authenticationStatus[status].subText}
                  </Typography>
                )}
              </Flex>
            </Flex>
          </Grid>
        )}
        {children && (
          <Grid xs={12} item>
            {children}
          </Grid>
        )}
        {buttonText && (
          <Grid xs={12} item>
            <Spacer verticalSpace={2} />
            {renderButton()}
          </Grid>
        )}
      </Grid>
    </BorderCardContainer>
  );
};

export default AuthenticationInfoCard;
