import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
// import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Flex } from '@dripcapital/dripui';
import { OnBoardingRoutes } from './routes';
import { useReactiveVar } from '@apollo/client';
import { Snackbar } from 'atoms';
import { SnackbarVariantEnum } from 'enums';
import { messageVar } from 'config/server';
// import getCSPHeader from 'config/csp';

type AppProps = object;

const App: FC<AppProps> = () => {
  const location = useLocation();

  const { messages, messagesStyle = {} } = useReactiveVar(messageVar);

  useEffect(() => {
    ReactGA.send('pageview');
  }, [location]);

  const renderSnackbar = ({
    message,
    variant,
    timer = 3000,
  }: {
    message?: string;
    variant?: string;
    timer?: number;
  }) => {
    return (
      <>
        <Snackbar
          open={!!messages?.length}
          title={message || ''}
          variant={(variant as SnackbarVariantEnum) || 'success'}
          autoHideDuration={timer}
          _sx={{
            position: 'static',
            marginBottom: 1,
          }}
          onClose={() => {
            messageVar({
              messages: [],
              messagesStyle: {},
            });
          }}
        />
      </>
    );
  };

  const renderSnackBarStack = () => {
    return (
      <Flex
        direction={'column'}
        alignItems={'flex-end'}
        flexWrap={'nowrap'}
        _sx={{
          position: 'fixed',
          top: '96px',
          right: '2%',
          width: '100%',
          zIndex: '10',
          ...messagesStyle,
        }}
      >
        {messages?.map((message, index) => {
          return (
            <React.Fragment key={index}>
              {renderSnackbar(message)}
            </React.Fragment>
          );
        })}
      </Flex>
    );
  };

  // const getCSPHeaders = () => {
  //   return (
  //     <Helmet>
  //       <meta httpEquiv="Content-Security-Policy" content={getCSPHeader()} />
  //     </Helmet>
  //   );
  // };

  return (
    <>
      {/* {getCSPHeaders()} */}
      {renderSnackBarStack()}
      <OnBoardingRoutes />
    </>
  );
};

export default App;
