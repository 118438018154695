import { ProductRouteConfig } from './RouteConfig.types';

export const routeConfig: ProductRouteConfig = {
  SCF: {
    profile: {
      component: 'Profile',
      // allowedPaths: ['profile'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'identifier',
      },
    },
    identifier: {
      component: 'USBusinessIdentifier',
      // allowedPaths: ['profile', 'identifier'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'details',
      },
    },
    details: {
      component: 'USBusinessDetails',
      // allowedPaths: ['profile', 'identifier', 'details'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'eligibility',
      },
    },
    eligibility: {
      component: 'USBusinessEligibility',
      // allowedPaths: ['profile', 'identifier', 'details', 'eligibility'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'granted',
      },
    },
    denied: {
      component: 'USEligibleDenied',
      // allowedPaths: [
      //   'profile',
      //   'identifier',
      //   'details',
      //   'eligibility',
      //   'denied',
      // ],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: '',
      },
    },
    applicants: {
      component: 'USApplicants',
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [
        'applicants',
        'creation',
        'registration',
        'personal',
        'company',
        'bank',
        'identity',
        'financial',
      ],
      cpAllowedPaths: ['applicants', 'registration', 'creation'],
      props: {
        nextStage: '',
      },
    },
    legal: {
      component: 'USLegalDocs',
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: ['legal', 'applicants'],
      props: {
        nextStage: '',
      },
    },
    approved: {
      component: 'USApprovedStage',
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      cpAllowedPaths: ['approved', 'applicants'],
      props: {
        nextStage: '',
      },
    },
    'legal-generated': {
      component: 'USLegalGenerated',
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: ['legal-generated', 'applicants'],
      props: {
        nextStage: '',
      },
    },
    rejected: {
      component: 'USLegalRejected',
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: ['rejected', 'applicants'],
      cpAllowedPaths: ['rejected', 'applicants'],
      props: {
        nextStage: '',
      },
    },
    creation: {
      component: 'USAssistedCreateAccount',
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: ['applicants', 'creation'],
      cpAllowedPaths: ['applicants', 'creation', 'application'],
      props: {
        nextStage: 'registration',
      },
    },
    registration: {
      component: 'USAssistedRegisterCompany',
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: ['applicants', 'creation', 'registration'],
      cpAllowedPaths: ['applicants', 'creation', 'registration'],
      props: {
        nextStage: 'application',
      },
    },
    onboard: {
      component: 'USCustomerLanding',
      allowedPaths: [],
      uAllowedPaths: ['onboard', 'personal', 'bank', 'financial', 'review'],
      suAllowedPaths: [],
      props: {
        nextStage: 'personal',
      },
    },
    personal: {
      component: 'USPersonalDetails',
      allowedPaths: [],
      uAllowedPaths: [
        'onboard',
        'personal',
        'company',
        'bank',
        'identity',
        'financial',
        'review',
        'application',
      ],
      suAllowedPaths: [
        'creation',
        'application',
        'personal',
        'company',
        'financial',
        'identity',
        'bank',
        'applicants',
      ],
      cpAllowedPaths: [
        'creation',
        'personal',
        'company',
        'identity',
        'bank',
        'financial',
        'applicants',
        'application',
      ],
      props: {
        nextStage: 'company',
      },
    },
    company: {
      component: 'USCompanyDetails',
      allowedPaths: [],
      uAllowedPaths: [
        'onboard',
        'personal',
        'company',
        'identity',
        'bank',
        'financial',
        'review',
        'application',
      ],
      suAllowedPaths: [
        'creation',
        'application',
        'personal',
        'company',
        'financial',
        'identity',
        'bank',
        'applicants',
      ],
      cpAllowedPaths: [
        'creation',
        'personal',
        'company',
        'identity',
        'bank',
        'financial',
        'applicants',
        'application',
      ],
      props: {
        nextStage: 'financial',
      },
    },
    identity: {
      component: 'USIdentityVerification',
      allowedPaths: [],
      uAllowedPaths: [
        'onboard',
        'personal',
        'company',
        'identity',
        'bank',
        'financial',
        'review',
        'application',
      ],
      suAllowedPaths: [
        'creation',
        'application',
        'personal',
        'company',
        'financial',
        'identity',
        'bank',
        'applicants',
      ],
      cpAllowedPaths: [
        'creation',
        'personal',
        'company',
        'identity',
        'bank',
        'financial',
        'applicants',
        'application',
      ],
      props: {
        nextStage: 'bank',
      },
    },
    bank: {
      component: 'USBankDetails',
      allowedPaths: [],
      uAllowedPaths: [
        'onboard',
        'personal',
        'company',
        'identity',
        'bank',
        'financial',
        'review',
        'application',
      ],
      suAllowedPaths: [
        'creation',
        'application',
        'personal',
        'company',
        'financial',
        'identity',
        'bank',
        'applicants',
      ],
      cpAllowedPaths: [
        'creation',
        'company',
        'identity',
        'bank',
        'financial',
        'applicants',
        'application',
      ],
      props: {
        nextStage: 'review',
      },
    },
    financial: {
      component: 'USFinancialDetails',
      allowedPaths: [],
      uAllowedPaths: [
        'onboard',
        'personal',
        'company',
        'identity',
        'bank',
        'financial',
        'review',
        'application',
      ],
      suAllowedPaths: [
        'creation',
        'application',
        'personal',
        'company',
        'financial',
        'identity',
        'bank',
        'applicants',
      ],
      cpAllowedPaths: [
        'creation',
        'personal',
        'company',
        'identity',
        'bank',
        'financial',
        'applicants',
        'application',
      ],
      props: {
        nextStage: 'identity',
      },
    },
    review: {
      component: 'USReview',
      allowedPaths: [],
      uAllowedPaths: [
        'onboard',
        'personal',
        'company',
        'identity',
        'bank',
        'financial',
        'review',
        'application',
      ],
      suAllowedPaths: ['bank'],
      props: {
        nextStage: 'application',
      },
    },
    application: {
      component: 'USApplicationStatus',
      allowedPaths: ['onboard', 'application'],
      uAllowedPaths: [],
      suAllowedPaths: [
        'applicants',
        'creation',
        'registration',
        'personal',
        'financial',
        'company',
        'bank',
        'identity',
      ],
      cpAllowedPaths: [
        'creation',
        'personal',
        'registration',
        'company',
        'identity',
        'bank',
        'financial',
        'applicants',
      ],
      props: {
        nextStage: 'granted',
      },
    },
    granted: {
      component: 'USOfferGranted',
      // allowedPaths: [],
      // uAllowedPaths: ['granted'],
      // suAllowedPaths: [],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'accepted',
      },
    },
    accepted: {
      component: 'USOfferAccepted',
      // allowedPaths: [],
      // uAllowedPaths: ['accepted'],
      // suAllowedPaths: [],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: '',
      },
    },
    referrals: {
      component: 'USReferrals',
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: '',
      },
    },
    referral: {
      component: 'USSubmit',
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: '',
      },
    },
  },
  stages: {
    bdm: {
      applicants: 'applicants',
      new: 'application',
      creation: 'creation',
      user_details_pending: 'personal',
      business_not_registered: 'registration',
      personal_info_pending: 'identity',
      company_details_pending: 'company',
      bank_info_pending: 'bank',
      finance_info_pending: 'financial',
      review_pending: 'application',
      New: 'application',
      processing: 'application',
      limit_approved: 'legal',
      legal_pending: 'legal',
      first_invoice_pending: 'legal-generated',
      first_invoice_added: 'legal-generated',
      rejected: 'rejected',
    },
    exporter: {
      profile: 'profile',
      identifier: 'identifier',
      details: 'details',
      eligibility: 'eligibility',
      denied: 'denied',
      new: 'onboard',
      bank_info_pending: 'bank',
      finance_info_pending: 'financial',
      review_pending: 'review',
      processing: 'application',
      granted: 'granted',
      accepted: 'accepted',
      New: 'personal',
      personal_info_pending: 'identity',
      company_details_pending: 'company',
      user_details_pending: 'personal',
    },
    cp: {
      profile: 'profile',
      identifier: 'identifier',
      details: 'details',
      eligibility: 'eligibility',
      denied: 'denied',
      new: 'application',
      processing: 'application',
      granted: 'granted',
      accepted: 'accepted',
      New: 'applicants',
      applicants: 'applicants',
      personal_info_pending: 'identity',
      company_details_pending: 'company',
      user_details_pending: 'personal',
      bank_info_pending: 'bank',
      finance_info_pending: 'financial',
      review_pending: 'application',
      business_not_registered: 'registration',
      limit_approved: 'approved',
      legal_pending: 'approved',
      first_invoice_pending: 'approved',
      first_invoice_added: 'approved',
      rejected: 'rejected',
    },
  },
  paths: {
    profile: '/us/scf/profile',
    identifier: '/us/scf/identifier',
    details: '/us/scf/details',
    eligibility: '/us/scf/eligibility',
    denied: '/us/scf/denied',
    applicants: '/us/scf/applicants',
    creation: '/us/scf/creation',
    registration: '/us/scf/registration',
    onboard: '/us/scf/onboard',
    personal: '/us/scf/personal',
    company: '/us/scf/company',
    identity: '/us/scf/identity',
    bank: '/us/scf/bank',
    financial: '/us/scf/financial',
    review: '/us/scf/review',
    application: '/us/scf/application',
    granted: '/us/scf/granted',
    accepted: '/us/scf/accepted',
    referrals: '/us/scf/referrals',
    referral: '/us/scf/referral',
    legal: '/us/scf/legal',
    'legal-generated': '/us/scf/legal-generated',
    rejected: '/us/scf/rejected',
    approved: '/us/scf/approved',
  },
};
