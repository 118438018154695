import React from 'react';
import { Box, COLORS, styled } from '@dripcapital/dripui';

type Props = {
  children: React.ReactNode;
  dataTestId?: string;
};

const BoxContainer = styled(Box)(({ theme }) => {
  return {
    flexGrow: theme.spacing(1),
    padding: `${theme.spacing(2)} ${theme.spacing(8)}`,
    minHeight: '98vh',
    height: '100%',
    backgroundColor: COLORS.WHITE,
    '@media (max-width: 1024px)': {
      padding: `${theme.spacing(1.6)} ${theme.spacing(2)}`,
    },
  };
});

const MainContainer: React.FC<Props> = ({ children, dataTestId }: Props) => (
  <BoxContainer dataTestId={dataTestId}>{children}</BoxContainer>
);

export default MainContainer;
