import React from 'react';

type Props = {
  pdfUrl: string;
  width: string;
  height: string;
};

const PDFViewer: React.FC<Props> = (props) => {
  return (
    <div style={{ height: props.height, width: props.width }}>
      <iframe
        title="Inline Frame Example"
        src={props.pdfUrl}
        height="100%"
        width="100%"
      />
    </div>
  );
};

export default PDFViewer;
