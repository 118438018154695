import { Typography } from '@dripcapital/dripui';
import React from 'react';

type Props = {
  title: string;
};

const BoldTitle: React.FC<Props> = ({ title }: Props) => {
  return (
    <Typography
      dataTestId="h3-bold-element"
      variant="h3"
      color="secondary"
      fontWeight="bold"
    >
      {title}
    </Typography>
  );
};

export default BoldTitle;
