import React from 'react';
import { theme } from '@dripcapital/dripui-v1/theme';
import { Flex } from '@dripcapital/dripui-v1/components/Flex';
import { Typography } from '@dripcapital/dripui-v1/components/Typography';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { DAJ_COLORS } from 'utils';
import { SxProps, Theme } from '@dripcapital/dripui';

type EmailAvatarProps = {
  email: string;
  _sx?: SxProps<Theme>;
  onClose?: () => void;
};

const EmailAvatar = ({ email, _sx = {}, onClose }: EmailAvatarProps) => {
  return (
    <Flex
      alignItems={'center'}
      _sx={{
        backgroundColor: DAJ_COLORS.SKY_BLUE_50,
        border: `1px solid ${DAJ_COLORS.SKY_BLUE_400}`,
        borderRadius: theme.spacing(3),
        paddingRight: '10px',
        ..._sx,
      }}
    >
      <Flex
        _sx={{
          backgroundColor: DAJ_COLORS.SKY_BLUE_100,
          borderRadius: 20,
          width: theme.spacing(3),
          height: theme.spacing(3),
          margin: '2px',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <PersonOutlineIcon
          sx={{ color: DAJ_COLORS.SKY_BLUE_500, fontSize: '12px' }}
        />
      </Flex>
      <Typography
        variant="body-small"
        color="secondary"
        _sx={{ mr: theme.spacing(1), ml: theme.spacing(1) }}
        noWrap
      >
        {email}
      </Typography>
      <ClearIcon
        sx={{
          color: DAJ_COLORS.GREEN,
          fontSize: '12px',
          '&:hover': { cursor: 'pointer' },
        }}
        onClick={onClose}
      />
    </Flex>
  );
};
export default EmailAvatar;
