import { gql } from '@apollo/client';

export const ADD_BUYER = gql`
  mutation addBuyer(
    $id: ID!
    $name: String
    $address: String
    $country: String
    $idType: String
    $idNumber: String
    $rfc: String
    $currency: String
    $contactName: String
    $contactEmail: String
    $phone: PhoneInputObject
    $writeoffs: String
    $pastSales: String
    $paymentTerms: String
    $paymentTenor: String
    $currentSales: String
    $paymentDays: String
    $related: String
    $shippingTerms: String
    $clientMutationId: String
    $hasInvoice: Boolean
    $documentReleaseType: String
    $documentSendType: String
    $canSubmitAllBill: Boolean
    $dripLimit: Int
    $addToOfferProfile: String
    $operationDrip: String
  ) {
    createBuyer(
      input: {
        id: $id
        buyer: {
          name: $name
          address: $address
          country: $country
          idType: $idType
          idNumber: $idNumber
          rfc: $rfc
          currency: $currency
          contactName: $contactName
          contactEmail: $contactEmail
          contactPhoneNumber: $phone
          writeoffs: $writeoffs
          pastSales: $pastSales
          paymentTerms: $paymentTerms
          paymentTenor: $paymentTenor
          currentSales: $currentSales
          paymentDays: $paymentDays
          related: $related
          shippingTerms: $shippingTerms
          hasInvoice: $hasInvoice
          documentReleaseType: $documentReleaseType
          documentSendType: $documentSendType
          canSubmitAllBill: $canSubmitAllBill
          dripLimit: $dripLimit
          addToOfferProfile: $addToOfferProfile
          operationDrip: $operationDrip
        }
        clientMutationId: $clientMutationId
      }
    ) {
      message
    }
  }
`;

export const UPDATE_BUYER = gql`
  mutation updateBuyer(
    $id: ID!
    $name: String
    $address: String
    $country: String
    $idType: String
    $idNumber: String
    $rfc: String
    $currency: String
    $contactName: String
    $contactEmail: String
    $phone: PhoneInputObject
    $writeoffs: String
    $pastSales: String
    $paymentTerms: String
    $paymentTenor: String
    $currentSales: String
    $paymentDays: String
    $related: String
    $shippingTerms: String
    $index: Int!
    $hasInvoice: Boolean
    $documentReleaseType: String
    $documentSendType: String
    $canSubmitAllBill: Boolean
    $dripLimit: Int
    $addToOfferProfile: String
    $operationDrip: String
  ) {
    updateBuyer(
      input: {
        id: $id
        index: $index
        buyer: {
          name: $name
          address: $address
          country: $country
          idType: $idType
          idNumber: $idNumber
          rfc: $rfc
          currency: $currency
          contactName: $contactName
          contactEmail: $contactEmail
          contactPhoneNumber: $phone
          writeoffs: $writeoffs
          pastSales: $pastSales
          paymentTerms: $paymentTerms
          paymentTenor: $paymentTenor
          currentSales: $currentSales
          paymentDays: $paymentDays
          related: $related
          shippingTerms: $shippingTerms
          hasInvoice: $hasInvoice
          documentReleaseType: $documentReleaseType
          documentSendType: $documentSendType
          canSubmitAllBill: $canSubmitAllBill
          dripLimit: $dripLimit
          addToOfferProfile: $addToOfferProfile
          operationDrip: $operationDrip
        }
      }
    ) {
      message
    }
  }
`;

export const DELETE_BUYER = gql`
  mutation deleteBuyer($id: ID!, $index: Int!) {
    deleteBuyer(input: { id: $id, index: $index }) {
      message
    }
  }
`;
