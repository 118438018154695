import React from 'react';
import { Box, Paper, COLORS, Flex, Typography } from '@dripcapital/dripui';
import { DAJ_COLORS } from 'utils';
import { Check } from '@mui/icons-material';

type GenderChipProps = {
  label: string;
  selected: boolean;
  component: React.ReactNode;
};

const GenderChip: React.FC<GenderChipProps> = ({
  label = '',
  selected,
  component = '',
}: GenderChipProps) => {
  return (
    <Paper
      dataTestId="gender-chip-element"
      elevation={0}
      variant="outlined"
      _sx={{
        overflow: 'hidden',
        position: 'relative',
        padding: 0.8,
        width: 160,
        borderRadius: 2,
        backgroundColor: DAJ_COLORS.ALICE_BLUE,
      }}
    >
      {selected && (
        <>
          <Box
            _sx={{
              position: 'absolute',
              top: '-8px',
              right: '-22px',
              background: DAJ_COLORS.GREEN,
              transform: 'rotate(40deg)',
              width: 72,
              height: 32,
            }}
          ></Box>
          <Check
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              color: COLORS.WHITE,
            }}
          />
        </>
      )}
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        direction={'column'}
        _sx={{ width: '100%' }}
      >
        <Box
          _sx={{
            fill: selected ? DAJ_COLORS.BLUE : DAJ_COLORS.LIGHT_BLUE,
          }}
        >
          {component}
        </Box>
        <Box
          _sx={{
            textAlign: 'center',
            background: selected ? DAJ_COLORS.GREEN : DAJ_COLORS.MAGIC_MINT,
            color: COLORS.WHITE,
            borderRadius: 1,
            width: '100%',
            paddingY: 0.6,
          }}
        >
          <Typography variant="h4" fontWeight="bold" color="white">
            {label}
          </Typography>
        </Box>
      </Flex>
    </Paper>
  );
};

export default GenderChip;
