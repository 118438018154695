import React from 'react';
import {
  Box,
  CssOverrideType,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@dripcapital/dripui';
import { ButtonType } from 'types';
import { AtomicButton } from 'atoms';
import { DAJ_COLORS } from 'utils';

type Props = CssOverrideType & {
  dataTestId?: string;
  isOpen: boolean;
  closeDialog: () => void;
  title?: string;
  content?: string;
  buttons?: ButtonType[];
  fullscreen?: boolean;
  children?: React.ReactNode;
};

const Modal: React.FC<Props> = ({
  dataTestId = 'modal-container-element',
  isOpen,
  closeDialog,
  title,
  content,
  buttons = [],
  fullscreen = false,
  children,
  _sx,
  className,
}) => {
  return (
    <Dialog
      dataTestId={dataTestId}
      open={isOpen}
      onClose={closeDialog}
      PaperProps={{ sx: _sx }}
      className={className}
      fullScreen={fullscreen}
    >
      {title && (
        <DialogTitle _sx={{ paddingY: '8px', color: DAJ_COLORS.BLUE }}>
          {title}
        </DialogTitle>
      )}
      <DialogContent>
        {content && (
          <DialogContentText
            dataTestId="dialog-content"
            _sx={{ color: 'black !important' }}
          >
            {content}
          </DialogContentText>
        )}
        {children && <Box dataTestId="dialog-children">{children}</Box>}
      </DialogContent>
      <DialogActions _sx={{ marginLeft: 2, marginRight: 'auto' }}>
        <Box>
          {buttons &&
            Array.isArray(buttons) &&
            buttons.map((btn: ButtonType, index) => (
              <AtomicButton
                key={index}
                dataTestId={btn.dataTestId}
                onClick={btn.handler}
                variant={btn.variant}
                _sx={{ marginRight: 1, ...btn._sx }}
              >
                {btn.title}
              </AtomicButton>
            ))}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
