import React from 'react';
import { FieldProps } from 'formik';
import {
  CssOverrideType,
  FormControl,
  FormHelperText,
  Typography,
  AdapterDateFns,
  LocalizationProvider,
  DatePicker as DatePickerComponent,
  DatePickerTextField,
  Box,
} from '@dripcapital/dripui';
import { useTranslation } from 'react-i18next';
import { DAJ_COLORS } from 'utils';

type Props = CssOverrideType &
  Pick<FieldProps, 'field' | 'form'> & {
    date: Date | null;
    label?: string;
    fullWidth: boolean;
    format: string;
    minDate?: Date;
  };

const DatePicker: React.FC<Props> = ({
  date,
  label = '',
  field,
  form,
  fullWidth = false,
  format = 'dd/MM/yyyy',
  minDate = '',
}) => {
  const { t } = useTranslation();

  const { name } = field;

  const [value, setValue] = React.useState<Date | null | unknown>(date || null);

  const { setFieldValue, setFieldTouched } = form;

  const { error, touched } = form.getFieldMeta(name);

  const handleError = () => {
    if (error === 'sis_dob') {
      return `${t('sis_dob')} ${new Date(new Date()).toLocaleDateString()}!!`;
    }
    return t(`${error}`);
  };

  return (
    <Box dataTestId="date-picker-element">
      <Typography _sx={{ marginBottom: '8px' }} color="secondary">
        {label}
      </Typography>
      <FormControl error={!!(error && touched)} fullWidth={fullWidth}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {minDate ? (
            <DatePickerComponent
              value={value}
              inputFormat={format}
              onChange={(newValue) => {
                if (!touched) {
                  setFieldTouched(name);
                }
                setFieldValue(name, newValue);
                setValue(newValue);
              }}
              minDate={minDate}
              renderInput={(params) => (
                <DatePickerTextField
                  label="date-input"
                  sx={{ svg: { color: DAJ_COLORS.GREEN } }}
                  {...params}
                />
              )}
            />
          ) : (
            <DatePickerComponent
              value={value}
              inputFormat={format}
              onChange={(newValue) => {
                if (!touched) {
                  setFieldTouched(name);
                }
                setFieldValue(name, newValue);
                setValue(newValue);
              }}
              renderInput={(params) => (
                <DatePickerTextField
                  label="date-input"
                  sx={{ svg: { color: DAJ_COLORS.GREEN } }}
                  {...params}
                />
              )}
            />
          )}
        </LocalizationProvider>
        {error && touched && <FormHelperText>{handleError()}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default DatePicker;
