import { GetOfferDetailsQuery, OfferDetailsMxQuery } from 'api';
import { BooleanVariable, CellsType } from 'types';
import { ROLES, currencySymbol, formatters } from 'utils';
import { CurrencyType } from 'enums';
import { Maybe, PhoneNumber } from 'api';
import i18n from 'config/locales';

export const getFormattedCurrency = (region: string, amount: number) => {
  const symbol = currencySymbol[region] || '';
  let convertedAmt = '';
  switch (region) {
    case CurrencyType.USD:
    case CurrencyType.EURO:
    case CurrencyType.MXN:
      convertedAmt = amount.toLocaleString('en-US');
      return `${symbol} ${convertedAmt || amount}`;
    case CurrencyType.INR:
      convertedAmt = amount.toLocaleString('en-IN');
      return `${symbol} ${convertedAmt || amount}`;
    default:
      return `${symbol} ${amount}`;
  }
};

export const renderTableHeading = (field?: {
  com_name?: React.ReactNode;
  cust_name?: string | React.ReactNode;
  email?: string | React.ReactNode;
  status?: string | React.ReactNode;
  cpName?: string | React.ReactNode;
  action?: React.ReactNode;
}) => {
  const output = [];
  for (const key in field) {
    output.push({
      id: key,
      label: `raa_table_heading_${key}`,
      minWidth: 180,
      align: 'left',
    });
  }
  return output;
};

export const formatDateInput = (value: string | Date) => {
  if (typeof value !== 'string') {
    return value;
  }
  if (value === '') {
    return value;
  }
  const [day, month, year] = value.indexOf('/')
    ? value.split('/')
    : ['', '', ''];
  return month && day && year
    ? new Date(`${month}/${day}/${year}`)
    : new Date();
};

export default {
  createData: (title: string, subject: string) => {
    return { title, subject };
  },
  dateFormatter: (value?: string | Date) => {
    const date = new Date(formatDateInput(value || ''));
    const leadingZero = (str: string) => {
      return ('0' + str).slice(-2);
    };
    return {
      year: date.getFullYear().toString(),
      month: leadingZero((date.getMonth() + 1).toString()),
      day: leadingZero(date.getDate().toString()),
    };
  },
  getRoles: (
    legalRepresentative: BooleanVariable,
    principleShareholder: BooleanVariable,
    secondaryShareholder: BooleanVariable
  ) => {
    const arr = [];
    if (legalRepresentative) {
      arr.push(ROLES.legalRepresentative);
    }
    if (principleShareholder) {
      arr.push(ROLES.principleShareholder);
    }
    if (secondaryShareholder) {
      arr.push(ROLES.shareholder);
    }
    return arr;
  },

  getFirstName: (fullName: string) => {
    if (fullName) return fullName?.split(' ')[0];
    return '';
  },

  generateOfferDetails: ({
    offerDetails,
  }: GetOfferDetailsQuery): CellsType[] => {
    const facilitySizeTmp =
      parseInt(offerDetails?.offerDetails?.facilitySize || '0') || 0;

    const currency = offerDetails?.currency || 'usd';

    return [
      formatters.createData(
        i18n.t('title_facility_limit'),
        getFormattedCurrency(currency, facilitySizeTmp) || '0'
      ),
      formatters.createData(
        i18n.t('title_advance_rate'),
        i18n.t('title_advance_rate_placeholder', {
          advanceRate: parseInt(offerDetails?.offerDetails?.advanceRate || '0'),
        })
      ),
      formatters.createData(
        i18n.t('title_maximum_credit_tenor'),
        offerDetails?.offerDetails?.maxCreditTenor || '0'
      ),
      formatters.createData(
        i18n.t('title_discount_rate'),
        i18n.t('title_interest_rate_placeholder', {
          interestRate: offerDetails?.offerDetails?.interestRate || 0,
        })
      ),
      formatters.createData(
        i18n.t('title_establishment_fee'),
        i18n.t('title_establishment_fee_placeholder', {
          establishmentFee: offerDetails?.offerDetails?.establishimentFee || 0,
        })
      ),
    ];
  },

  generateMXOfferDetails: (
    data: OfferDetailsMxQuery | undefined
  ): CellsType[] => {
    const offer = data?.offer;
    const facilitySizeTmp = offer?.offerDetails?.facilitySize || 0;

    const currency = CurrencyType.USD;

    return [
      formatters.createData(
        i18n.t('title_facility_size'),
        getFormattedCurrency(currency, facilitySizeTmp) || '0'
      ),
      formatters.createData(
        i18n.t('title_advance_rate'),
        i18n.t('title_advance_rate_placeholder', {
          advanceRate: offer?.offerDetails?.advanceRate || 0,
        })
      ),
      formatters.createData(
        i18n.t('title_maximum_credit_tenor'),
        i18n.t('title_maximum_credit_tenor_placeholder', {
          days: offer?.offerDetails?.maximumCreditTenor || 0,
        })
      ),
      formatters.createData(
        i18n.t('title_interest_rate'),
        i18n.t('title_interest_rate_placeholder', {
          interestRate: offer?.offerDetails?.interestRate || 0,
        })
      ),
      formatters.createData(
        i18n.t('title_establishment_fee'),
        i18n.t('title_establishment_fee_placeholder', {
          establishmentFee: offer?.offerDetails?.establishmentFee || 0,
        })
      ),
    ];
  },

  generateEmptyOffer: () => {
    return [
      formatters.createData(i18n.t('title_facility_limit'), '-'),
      formatters.createData(i18n.t('title_advance_rate'), '-'),
      formatters.createData(i18n.t('title_maximum_credit_tenor'), '-'),
      formatters.createData(i18n.t('title_discount_rate'), '-'),
      formatters.createData(i18n.t('title_establishment_fee'), '-'),
    ];
  },

  generateSelectOptions: (values: string[]) => {
    return values
      .map((val) => ({ value: val, label: val }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  },

  generateCountryOptions: (options?: null | string[]) => {
    if (!options) {
      return [];
    }
    return options
      .map((optionValue: string) => {
        return {
          value: optionValue,
          label: optionValue,
        };
      })
      .sort((a: { label: string }, b: { label: string }) =>
        a.label > b.label ? 1 : -1
      );
  },

  generateCountryOptionsWithId: (options?: null | any[]) => {
    if (!options) {
      return [];
    }
    return Object.keys(options)
      .map((id: string) => {
        return {
          id: id,
          value: id,
          label: options[id],
        };
      })
      .sort((a: { label: string }, b: { label: string }) =>
        a.label > b.label ? 1 : -1
      );
  },

  generateCommonOptionsWithSort: (options: object) => {
    if (!options) {
      return [];
    }
    return Object.keys(options)
      .map((key) => {
        return {
          value: key,
          label: options[key],
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  },

  generateCommonOptions: (options: object) => {
    if (!options) {
      return [];
    }
    return Object.keys(options).map((key) => {
      return {
        value: key,
        label: options[key],
      };
    });
  },

  generateProductCategoryOptions: (options: object) => {
    if (!options) {
      return [];
    }
    return Object.keys(options)
      .map((key) => {
        return {
          value: key,
          label: options[key],
        };
      })
      .sort((a, b) => {
        if (a.label === b.label) return 0;
        if (a.label === 'Other') return 1;
        if (b.label === 'Other') return -1;
        return a.label > b.label ? 1 : -1;
      });
  },

  maskFields(type: string, value?: string) {
    const generateAsterisks = (length: number) => {
      if (length && length > 0) {
        return Array(length || 0)
          .fill('*')
          .join('');
      }
      return '';
    };

    if (value) {
      switch (type) {
        case 'phone':
          value = `${generateAsterisks(value?.length || 0 - 3)}${
            value?.slice(-3) || '***'
          }`;
          break;
        case 'email':
          if (value?.indexOf('@') > 0) {
            const temp = value?.split('@');
            const username =
              (temp?.[0]?.slice(0, 3) || '***') +
              generateAsterisks(temp?.[0]?.length || 0 - 3);
            const domain =
              generateAsterisks(temp?.[1]?.length - 4) +
              temp?.[1]?.slice(temp?.[1]?.indexOf('.'));
            value = `${username}@${domain}`;
          } else {
            value = '*****@***.com';
          }
          break;
        case 'ssn': {
          value = '******' + value.substring(6);
          break;
        }
        default:
      }
    }
    return value;
  },

  sanitizeFields(data: object) {
    const dataTemp: any = {
      ...data,
    };
    if (dataTemp) {
      Object.keys(dataTemp).forEach((key) => {
        if (dataTemp[key] === null) {
          dataTemp[key] = undefined;
        }
      });
    }
    // specially for buyer form
    if (dataTemp?.contactPhoneNumber?.phoneNumber === null) {
      dataTemp.contactPhoneNumber = {
        countryCode: 'IN-91',
        phoneNumber: undefined,
      };
    }
    return dataTemp;
  },

  displayPhoneNumber: (contact?: Maybe<PhoneNumber>): string => {
    if (contact?.countryCode) {
      const code = contact?.countryCode?.split('-')?.[1] || '';
      const number =
        contact?.phoneNumber?.substring(0, 3) +
        ' ' +
        contact?.phoneNumber?.substring(3, 6) +
        ' ' +
        contact?.phoneNumber?.substring(6);
      return `${code && '+'}${code}${code && ' '}${number}`;
    }
    return '';
  },

  formatToUSDate(date: string) {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  },
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
}
