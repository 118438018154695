import LogRocket from 'logrocket';

export default {
  init: (board: string | undefined) => {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.init(board as string);
    }
  },
  identify: () => {
    // Todo Later
    // LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
    //   name: 'James Morrison',
    //   email: 'jamesmorrison@example.com',
    // });
  },
};
