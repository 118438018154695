import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  COLORS,
  Drawer,
  Flex,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@dripcapital/dripui';
import { Gst } from 'types';
import { BorderCardContainer, Spacer } from 'atoms';
import { settings, FONT } from 'utils';
import { GstStatus } from 'enums';
import {
  CallMade,
  Cancel as CancelIcon,
  Close as CloseIcon,
  CheckCircle,
  Error,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { createStyles, makeStyles } from '@material-ui/styles';

type Props = {
  open: boolean;
  onClose: () => void;
  authenticatedCount: number;
  authenticate: (gst: Gst) => void;
  gstCount: number;
  data: Gst[];
  refetchGst: () => void;
  refetchLoading?: boolean;
};

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: '$spin 0.6s linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  })
);

const GSTModal: React.FC<Props> = ({
  open,
  onClose,
  authenticatedCount,
  authenticate,
  gstCount,
  data,
  refetchGst,
  refetchLoading = false,
}: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { breakpoints, spacing } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('md'));

  const renderGSTInfoText = () => {
    if (authenticatedCount === gstCount)
      return t('irfi_gst_auth_count_complete', { count: gstCount });
    else
      return t('irfi_gst_auth_count', {
        count: gstCount - authenticatedCount,
        gstCount: gstCount,
      });
  };

  const renderGstStatus = (gst: Gst) => {
    const gstMap = settings.getSettings('gst') || {};
    const value = gstMap[gst.gstNo] || gst.recordStatus;
    switch (value) {
      case GstStatus.COMPLETED:
        return (
          <Flex
            alignItems={'center'}
            spacing={spacing(1)}
            justifyContent="space-between"
          >
            <Typography variant="h4" fontWeight="bold">
              {gst?.gstNo}
            </Typography>
            <Flex alignItems={'center'} spacing={spacing(1)}>
              <CheckCircle color="success" />
              {!isMobile && (
                <Typography variant="body1" fontWeight="bold">
                  {t('irfi_gst_auth_status_1')}
                </Typography>
              )}
            </Flex>
          </Flex>
        );
      case GstStatus.ACTIVE:
        return (
          <Flex
            alignItems={'center'}
            spacing={spacing(1)}
            justifyContent="space-between"
          >
            <Typography variant="h4" fontWeight="bold">
              {gst?.gstNo}
            </Typography>
            <Link
              component="button"
              onClick={() => authenticate(gst)}
              _sx={{ fontSize: FONT.SIZES.F20 }}
            >
              <Flex alignItems={'center'} spacing={spacing(1)}>
                {!isMobile && (
                  <Typography color="primary">
                    {t('irfi_gst_auth_status_2')}
                  </Typography>
                )}
                <CallMade fontSize="small" />
              </Flex>
            </Link>
          </Flex>
        );
      case GstStatus.PENDING:
        return (
          <>
            <Flex
              alignItems={'center'}
              spacing={spacing(1)}
              justifyContent="space-between"
            >
              <Typography variant="h4" fontWeight="bold">
                {gst?.gstNo}
              </Typography>
              <Flex spacing={spacing(1)}>
                <Error color="warning" />
                {!isMobile && (
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t('irfi_gst_auth_status_3')}
                  </Typography>
                )}
              </Flex>
            </Flex>
            <Flex spacing={spacing(1)} _sx={{ paddingTop: '10px' }}>
              <Error color="inherit" />
              <Typography variant="body1" fontWeight="bold">
                {t('irfi_gst_auth_status_5_desc')}
              </Typography>
              <Link
                component="button"
                _sx={{ fontSize: FONT.SIZES.F16 }}
                onClick={() => authenticate(gst)}
              >
                {t('irfi_gst_auth_status_5')}
              </Link>
            </Flex>
          </>
        );
      case GstStatus.INVALID_RECORD:
        return (
          <>
            <Flex
              alignItems={'center'}
              spacing={spacing(1)}
              justifyContent="space-between"
            >
              <Typography variant="h4" fontWeight="bold">
                {gst?.gstNo}
              </Typography>
              <Link
                component="button"
                onClick={() => authenticate(gst)}
                _sx={{ fontSize: FONT.SIZES.F20 }}
              >
                <Flex alignItems={'center'} spacing={spacing(1)}>
                  {!isMobile && (
                    <Typography color="primary">
                      {t('irfi_gst_auth_status_4')}
                    </Typography>
                  )}
                  <CallMade />
                </Flex>
              </Link>
            </Flex>
            <Flex spacing={spacing(1)} _sx={{ paddingTop: '10px' }}>
              <CancelIcon color="error" />
              <Typography variant="body1" fontWeight="bold">
                {t('label_auth_failed')}
              </Typography>
            </Flex>
          </>
        );
      default:
        return (
          <Flex
            alignItems={'center'}
            spacing={spacing(1)}
            justifyContent="space-between"
          >
            <Typography variant="h4" fontWeight="bold">
              {gst?.gstNo}
            </Typography>
            <Link
              component="button"
              onClick={() => authenticate(gst)}
              _sx={{ fontSize: FONT.SIZES.F20 }}
            >
              <Flex alignItems={'center'} spacing={1}>
                <Typography color="primary">
                  {t('irfi_gst_auth_status_2')}
                </Typography>
                <CallMade fontSize="small" />
              </Flex>
            </Link>
          </Flex>
        );
    }
  };

  const renderGstnList = () => {
    return (
      data.length > 0 &&
      data.map((gstn: Gst) => {
        return (
          <Box key={gstn.id}>
            <BorderCardContainer>{renderGstStatus(gstn)}</BorderCardContainer>
            <Spacer verticalSpace={2} />
          </Box>
        );
      })
    );
  };

  return (
    <Drawer
      dataTestId="gst-authentication-modal"
      open={open}
      onClose={onClose}
      variant="temporary"
      PaperProps={{
        style: { width: isMobile ? '100%' : '50%' },
      }}
    >
      <Box _sx={{ paddingX: 3, paddingY: 4 }}>
        <Flex justifyContent="space-between">
          <Typography variant="h3" fontWeight="bold">
            {t('irfi_gst_info')}
          </Typography>
          <CloseIcon onClick={onClose} sx={{ color: COLORS.PRIMARY }} />
        </Flex>
        <Spacer verticalSpace={1} />
        <Typography variant={'body1'}>
          {t('irfi_gst_auth_modal_desc')}
        </Typography>
        <Spacer verticalSpace={4} />
        <Box
          _sx={{
            background: COLORS.INFOBLUE,
            padding: 1,
          }}
        >
          <Grid container>
            <Grid item md={9} lg={8} xl={9}>
              <Typography variant={'h4'} fontWeight="bold" color="secondary">
                {renderGSTInfoText()}
              </Typography>
            </Grid>
            <Grid item md={3} lg={4} xl={3}>
              <Flex
                onClick={refetchGst}
                spacing={1}
                alignItems="center"
                _sx={{ cursor: 'pointer' }}
              >
                <Typography color="primary" fontWeight="bold">
                  {t('irfi_gst_refetch')}
                </Typography>
                <RefreshIcon
                  sx={{ color: COLORS.PRIMARY }}
                  className={refetchLoading ? classes.rotateIcon : undefined}
                />
              </Flex>
            </Grid>
          </Grid>
        </Box>
        <Spacer verticalSpace={4} />
        {renderGstnList()}
      </Box>
    </Drawer>
  );
};

export default GSTModal;
