import React from 'react';
import { Box, COLORS, Flex, Typography } from '@dripcapital/dripui';
import { Spacer } from 'atoms';
import { AuthenticationInfoCard } from 'components';
import { CheckCircle, Error } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AuthButtonTypeEnum } from 'enums';

type Props = {
  isBDM?: boolean;
  checkGstAuthentication: () => void;
  authenticatedCount: number;
  gstCount: number;
};

const GSTCard: React.FC<Props> = ({
  isBDM,
  checkGstAuthentication,
  authenticatedCount,
  gstCount,
}: Props) => {
  const { t } = useTranslation();

  const renderGSTInfoText = () => {
    if (gstCount === 0) {
      return (
        <>
          <Error color="error"></Error>
          <Flex direction="column">
            <Typography _sx={{ marginX: 1 }} fontWeight="bold">
              {t('irfi_gst_not_found_title')}
            </Typography>
            <Typography _sx={{ marginX: 1, lineHeight: 1.5 }}>
              {isBDM
                ? t('irfi_gst_not_found_desc_bdm')
                : t('irfi_gst_not_found_desc')}
            </Typography>
          </Flex>
        </>
      );
    } else if (authenticatedCount === gstCount) {
      return (
        <>
          <CheckCircle color="success" />
          <Typography _sx={{ marginX: 1 }} fontWeight="bold">
            {t('irfi_gst_auth_count_complete', { count: gstCount })}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Error color="inherit"></Error>
          <Typography _sx={{ marginX: 1 }} fontWeight="bold">
            {t('irfi_gst_auth_count', {
              count: gstCount - authenticatedCount,
              gstCount: gstCount,
            })}
          </Typography>
        </>
      );
    }
  };

  const renderButtonText = () => {
    if (gstCount !== 0 && !isBDM) {
      if (authenticatedCount === 0) {
        return t('irfi_gst_auth_btn');
      }
      if (authenticatedCount < gstCount) {
        return t('irfi_gst_auth_continue_btn');
      }
      if (authenticatedCount === gstCount) {
        return t('irfi_gst_auth_status_btn');
      }
    } else return undefined;
  };

  return (
    <Box dataTestId="gst-authentication-card">
      <Typography variant="h3" color="secondary" fontWeight="bold">
        {t('irfi_gst_info_full')}
      </Typography>
      <Spacer verticalSpace={2} />
      <AuthenticationInfoCard
        title={t('irfi_gst_info')}
        subTitle={
          gstCount === 0
            ? t('irfi_gst_auth_subtitle_no_gst')
            : isBDM
            ? ''
            : t('irfi_gst_auth_subtitle')
        }
        itemList={
          gstCount !== 0 && !isBDM
            ? [
                t('irfi_gst_list_1'),
                t('irfi_gst_list_2'),
                t('irfi_gst_list_3'),
                t('irfi_gst_list_4'),
                t('irfi_gst_list_5'),
              ]
            : undefined
        }
        buttonText={renderButtonText()}
        buttonType={
          authenticatedCount === gstCount
            ? AuthButtonTypeEnum.LINK
            : AuthButtonTypeEnum.BUTTON
        }
        onClick={isBDM ? undefined : checkGstAuthentication}
        disabled={gstCount === 0}
      >
        <Flex _sx={{ marginTop: 1, color: COLORS.SECONDARY }}>
          {renderGSTInfoText()}
        </Flex>
      </AuthenticationInfoCard>
    </Box>
  );
};

export default GSTCard;
