export default {
  SET_USER_DATA(userData: any = {}) {
    (window as any)._mfq = (window as any)._mfq || ([] as any);
    if ((window as any) && (window as any)._mfq) {
      (window as any)._mfq.push(['identify', userData.name || userData.id]);

      (window as any)._mfq.push([
        'setVariable',
        'USER_ID',
        userData.id,
        'session',
        false,
      ]);
      (window as any)._mfq.push([
        'setVariable',
        'USER_NAME',
        userData.name,
        'session',
        false,
      ]);
      (window as any)._mfq.push([
        'setVariable',
        'BUSINESS_ID',
        userData.business_id,
        'session',
        false,
      ]);
      (window as any)._mfq.push(['tag', userData.type]);
    }
  },
};
