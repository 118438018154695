import React from 'react';
import {
  Select,
  FormControl,
  FormHelperText,
  CssOverrideType,
  Box,
  COLORS,
} from '@dripcapital/dripui';
import { FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextFieldSizeEnum } from 'enums';

type SelectForFormikProps = CssOverrideType &
  Pick<FieldProps, 'field' | 'form'> & {
    label: string;
    multiple?: boolean;
    fullWidth?: boolean;
    placeholder?: string;
    disabled?: boolean;
    translate?: boolean;
    size?: TextFieldSizeEnum;
    options: {
      id?: number;
      value: string | number;
      label: string;
    }[];
    onChangeSelect: (value?: string) => void;
  };

const INPUT_WIDTH_OPTIONS = {
  small: 392,
  medium: 450,
  large: 640,
};

const SelectForFormik = ({
  form,
  field,
  label = '',
  multiple = false,
  options = [],
  fullWidth = false,
  disabled,
  placeholder,
  translate = true,
  _sx,
  size = TextFieldSizeEnum.SMALL,
  className = '',
  onChangeSelect,
}: SelectForFormikProps) => {
  const { t } = useTranslation();

  const { name, value } = field;

  const { setFieldValue, setFieldTouched } = form;

  const { error, touched } = form.getFieldMeta(name);

  const optionsWithTranslations = options.map((option) => ({
    id: option.id,
    value: option.value,
    label: translate ? t(`${option.label}`) : option.label,
  }));

  const calcWidth = () => {
    switch (size) {
      case TextFieldSizeEnum.SMALL:
        return INPUT_WIDTH_OPTIONS.small;
      case TextFieldSizeEnum.MEDIUM:
        return INPUT_WIDTH_OPTIONS.medium;
      case TextFieldSizeEnum.LARGE:
        return INPUT_WIDTH_OPTIONS.large;
    }
  };

  return (
    <FormControl error={!!(error && touched)} fullWidth={fullWidth}>
      <input
        type="text"
        name={name}
        style={{ visibility: 'hidden', position: 'absolute' }}
      />
      <Select
        MenuProps={{
          sx: {
            maxHeight: 360,
          },
        }}
        dataTestId="select-element"
        _sx={{
          width: fullWidth ? '100%' : calcWidth(),
          borderRadius: '8px',
          ..._sx,
        }}
        className={className}
        label={label}
        multiple={multiple}
        value={value}
        fullWidth={fullWidth}
        onChange={(e) => {
          if (!touched) {
            setFieldTouched(name);
          }
          setFieldValue(name, e.target.value);
          if (onChangeSelect && typeof onChangeSelect === 'function') {
            onChangeSelect(e.target.value);
          }
        }}
        displayEmpty={!!placeholder}
        renderValue={(selected) => {
          if (placeholder) {
            selected =
              optionsWithTranslations?.find((x) => x.value === selected)
                ?.label || selected;
            return String(selected)?.length ? (
              Array.isArray(selected) ? (
                <>{selected.join(', ')}</>
              ) : (
                <>{selected}</>
              )
            ) : (
              <Box _sx={{ color: COLORS.GRAY }}>{placeholder}</Box>
            );
          }
          return <>{selected}</>;
        }}
        options={optionsWithTranslations}
        disabled={disabled}
      ></Select>
      {error && touched && <FormHelperText>{t(`${error}`)}</FormHelperText>}
    </FormControl>
  );
};

export default SelectForFormik;
