/* eslint-disable indent */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { saveAs } from 'file-saver';
import {
  Flex,
  Typography,
  Chip,
  Box,
  Grid,
  useTheme,
  useMediaQuery,
} from '@dripcapital/dripui';
import { BorderCardContainer, Spacer, Modal, AtomicButton, Tab } from 'atoms';
import {
  EditOutlined as EditOutlinedIcon,
  FolderOpen as FolderOpenIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon,
  Download as DownloadIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import { appDataVar } from 'config/server';
import { ButtonTypeEnum, EsignStatusMXFinancial } from 'enums';
import { File } from 'api';
import { STYLES } from 'utils';

type Props = {
  showRightButtons?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  moreDetailsHandler?: React.MouseEventHandler<HTMLAnchorElement>;
  paymentTerms?: string;
  filename?: string;
  filenameLabel?: string;
  chipLabel?: string;
  roles?: string[];
  contentSection?: React.ReactNode;
  esignStatus?: EsignStatusMXFinancial;
  showCustomerEsignButton?: boolean;
  esignHandler?: () => void;
  title?: string;
  file?: File;
  name?: string;
};

const BusinessInfoCard: React.FC<Props> = ({
  showRightButtons = false,
  onEdit,
  onDelete,
  filename,
  filenameLabel,
  title = '',
  chipLabel = '',
  roles,
  contentSection,
  esignStatus,
  esignHandler,
  showCustomerEsignButton = false,
  file,
  name = '',
}: Props) => {
  const { t } = useTranslation();

  const { isBDMVar: isBDM } = useReactiveVar(appDataVar);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isTab = useMediaQuery(theme.breakpoints.down('md'));

  const [removeShareholder, setRemoveShareholder] =
    React.useState<boolean>(false);

  const closeDialog = () => setRemoveShareholder(false);

  const deleteShareholder = () => {
    // TODO: no need to delete the card right now as graphql state will handle it on its own
    onDelete?.();
    closeDialog();
  };

  const handleDownloadFile = () => {
    saveAs(
      file?.url ?? '',
      t('mrfi_download_report', { fileName: file?.name })
    );
  };

  const renderCountryInChip = () => {
    return (
      <Chip
        label={chipLabel}
        startIcon={<LocationOnOutlinedIcon fontSize="small" />}
        size="small"
      />
    );
  };

  const renderDownloadButton = () => {
    return (
      <Grid xs={3} container justifyContent={'end'} item>
        <Grid item>
          <Tab
            label=""
            buttonLabel={isTab ? '' : t('btn_download')}
            Icon={<DownloadIcon fontSize={isTab ? 'medium' : 'small'} />}
            callback={handleDownloadFile}
          />
        </Grid>
      </Grid>
    );
  };

  const renderRightButtons = () => {
    return (
      <Grid
        xs={isMobile ? 3 : 4}
        direction={'row'}
        alignItems="center"
        justifyContent={
          onEdit && onDelete
            ? isMobile
              ? 'space-between'
              : 'space-evenly'
            : 'end'
        }
        item
        container
        _sx={{ height: 48, paddingLeft: '5%' }}
      >
        {onEdit && (
          <Grid item>
            <Tab
              label=""
              buttonLabel={isMobile ? '' : t('edit_item')}
              Icon={<EditOutlinedIcon />}
              callback={onEdit}
            />
          </Grid>
        )}
        {onDelete && (
          <Grid item>
            <Tab
              label=""
              buttonLabel={isMobile ? '' : t('delete_item')}
              Icon={<DeleteOutlineIcon />}
              callback={() => {
                setRemoveShareholder(true);
              }}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  // TODO: Could be used in future.
  // const renderMoreDetails = () => {
  //   return (
  //     <Grid xs={12} md={3} item>
  //       <Flex alignItems={'center'}>
  //         <Link
  //           underline="none"
  //           _sx={{
  //             fontSize: FONT.SIZES.F16,
  //             marginRight: 1,
  //             marginLeft: 'auto',
  //           }}
  //           onClick={moreDetailsHandler}
  //           component={'button'}
  //           color="gray"
  //         >
  //           {t('title_more_details')}
  //         </Link>
  //         <OpenInNew fontSize="small" color="disabled" />
  //       </Flex>
  //     </Grid>
  //   );
  // };

  const renderUploadFileName = () => {
    return (
      <Flex alignItems={'center'} _sx={{ marginTop: 2 }}>
        {filenameLabel && (
          <Typography variant="subtitle1">
            {t('phrase_purchase_order_doc')}
          </Typography>
        )}
        <FolderOpenIcon style={{ margin: '0 10px' }} />
        <Typography variant="subtitle1">{filename}</Typography>
      </Flex>
    );
  };

  const renderEsignButton = () => {
    return (
      <>
        <Spacer verticalSpace={2} />
        <AtomicButton
          variant={ButtonTypeEnum.OUTLINED}
          onClick={() => esignHandler?.()}
        >
          {t('label_continue_e_sign')}
        </AtomicButton>
        <Spacer verticalSpace={1} />
      </>
    );
  };

  const renderEsignSection = () => {
    return (
      <Box>
        {contentSection}
        {esignStatus && (
          <>
            <Spacer verticalSpace={3} />
            <Flex spacing={1}>
              {esignStatus === EsignStatusMXFinancial.PENDING &&
                !showCustomerEsignButton && (
                  <>
                    <ErrorIcon />
                    <Box>
                      <Typography fontWeight="bold">
                        {t('phrase_customer_esigns')}
                      </Typography>
                      <Typography>
                        {t('phrase_customer_esigns_subtext', { name })}
                      </Typography>
                    </Box>
                  </>
                )}
              {esignStatus === EsignStatusMXFinancial.COMPLETE && (
                <>
                  <CheckCircleIcon color="success" />
                  <Typography>{t('label_auth_letter', { name })}</Typography>
                </>
              )}
            </Flex>
          </>
        )}
        {showCustomerEsignButton &&
          !isBDM &&
          esignHandler &&
          esignStatus !== EsignStatusMXFinancial.COMPLETE &&
          renderEsignButton()}
      </Box>
    );
  };

  return (
    <BorderCardContainer dataTestId="business-info-card">
      <Modal
        dataTestId="business-info-modal"
        isOpen={removeShareholder}
        closeDialog={closeDialog}
        title={'Are you sure you want to delete?'}
        content={'Once deleted, data cannot be recovered'}
        buttons={[
          {
            dataTestId: 'accept-button',
            title: t('btn_delete'),
            handler: deleteShareholder,
            variant: ButtonTypeEnum.CONTAINED,
            _sx: { padding: theme.spacing(1.3) },
          },
          {
            dataTestId: 'deny-button',
            title: t('btn_cancel'),
            handler: closeDialog,
            variant: ButtonTypeEnum.OUTLINED,
            _sx: { padding: theme.spacing(1.3) },
          },
        ]}
        _sx={{ padding: theme.spacing(3) }}
      />
      <Grid container rowSpacing={2} justifyContent={'space-between'}>
        <Grid xs={9} md={7} item>
          <Flex alignItems={'center'} spacing={1} _sx={{ height: 32 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              _sx={STYLES.textOverflow}
              title={title}
              noWrap
            >
              {title}
            </Typography>
            {chipLabel && renderCountryInChip()}
          </Flex>
          {roles && <Spacer verticalSpace={2} />}
          {roles &&
            roles.map((role, index) => (
              <Chip key={index} label={t(`${role}`)} />
            ))}
        </Grid>
        {esignStatus === EsignStatusMXFinancial.COMPLETE &&
          renderDownloadButton()}
        {showRightButtons && renderRightButtons()}
        {/* {moreDetailsHandler && renderMoreDetails()} */}
      </Grid>
      {filename ? renderUploadFileName() : renderEsignSection()}
    </BorderCardContainer>
  );
};

export default BusinessInfoCard;
