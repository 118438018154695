import { gql } from '@apollo/client';

export const OFFER_ACCEPTED = gql`
  mutation acceptOffer($id: ID!) {
    acceptOffer(input: { id: $id }) {
      message
    }
  }
`;

export const OFFER_E_SIGN = gql`
  mutation offerESign($id: ID!) {
    offerEsign(input: { id: $id }) {
      message
      error {
        message
        path
      }
      done
      url
    }
  }
`;
