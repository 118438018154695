import React, { useState } from 'react';
import { Box, Divider, Flex, Typography } from '@dripcapital/dripui';
import { KeyboardArrowDown } from '@mui/icons-material';

type Props = {
  title: string;
  body: React.ReactNode;
  defaultVisibility: boolean;
};

const ReadMoreDropdown: React.FC<Props> = ({
  defaultVisibility,
  title,
  body,
}: Props): React.ReactElement => {
  const [open, setOpen] = useState<boolean>(defaultVisibility);

  const renderTitle = () => {
    return (
      <Box dataTestId="read-accordion-title">
        <Flex>
          <Box
            dataTestId="open-accordion-button"
            onClick={() => {
              setOpen(!open);
            }}
            _sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Typography variant="h4" color="secondary" fontWeight="bold">
              {title}
            </Typography>
          </Box>
          <KeyboardArrowDown
            sx={{
              transform: open ? 'rotate(0deg)' : 'rotate(-180deg)',
              transition: 'all .3s',
            }}
          />
        </Flex>
      </Box>
    );
  };

  const renderBodyContent = () => {
    return (
      <Flex
        dataTestId="read-accordion-body"
        _sx={{
          marginTop: 1,
          height: !open ? '0px' : 'auto',
          opacity: !open ? '0' : '1',
          overflow: 'hidden',
          transition: 'all 1s',
        }}
      >
        <Divider
          orientation="vertical"
          weight={2}
          flexItem
          _sx={{ marginRight: 2 }}
        />
        <Typography variant="subtitle1">{body}</Typography>
      </Flex>
    );
  };

  return (
    <Flex direction="column">
      {renderTitle()}
      {renderBodyContent()}
    </Flex>
  );
};

export default ReadMoreDropdown;
