import React from 'react';
import { Typography } from '@dripcapital/dripui';
import AtomicButton from 'atoms/AtomicButton';
import { ButtonTypeEnum } from 'enums';

type Props = {
  label: string;
  buttonLabel?: string;
  Icon?: React.ReactNode;
  callback?: () => void;
};

const Tab: React.FC<Props> = ({
  label,
  buttonLabel = '',
  Icon,
  callback,
}: Props) => {
  return (
    <>
      <Typography
        dataTestId="tab-title-element"
        variant="h3"
        color="secondary"
        fontWeight="bold"
      >
        {label}
      </Typography>
      <AtomicButton
        dataTestId="tab-button-element"
        variant={ButtonTypeEnum.TEXT}
        startIcon={Icon && Icon}
        onClick={() => callback?.()}
        type={'button'}
        _sx={{
          fontSize: 16,
          lineHeight: 1,
          padding: 0.4,
          '.MuiButton-startIcon': {
            marginRight: 0.2,
          },
        }}
      >
        {buttonLabel}
      </AtomicButton>
    </>
  );
};

export default Tab;
