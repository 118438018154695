import React from 'react';
import { Flex, Box, CircularProgress } from '@dripcapital/dripui';

type Props = {
  show: boolean;
  mainContainerStyles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
};

const defaultMainContainerStyles = {
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 9999,
};

const defaultContainerStyles = {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: 'lightgray',
  opacity: 0.5,
};

const FullscreenLoader: React.FC<Props> = ({
  show,
  mainContainerStyles,
  containerStyles,
}: Props) => {
  if (!show) {
    return null;
  }

  return (
    <Flex
      dataTestId="full-screen-loader-element"
      alignItems="center"
      justifyContent="center"
      _sx={
        mainContainerStyles
          ? { ...defaultMainContainerStyles, ...mainContainerStyles }
          : defaultMainContainerStyles
      }
    >
      <Box
        _sx={
          containerStyles
            ? { ...defaultContainerStyles, ...containerStyles }
            : defaultContainerStyles
        }
      />
      <CircularProgress color="primary" />
    </Flex>
  );
};

export default FullscreenLoader;
