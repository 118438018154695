import React, { useRef, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  Grid,
  Link,
  Popper,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from '@dripcapital/dripui';
import { ServerError } from '@apollo/client';
import {
  newPasswordSchema,
  handleError,
  IDENTIFICATION_CARD_TYPES,
  formatters,
} from 'utils';
import { NP_FORM_INITIAL_VALUES } from 'conversion';
import {
  Alert,
  AtomicButton,
  CircularLoader,
  FullscreenLoader,
  Spacer,
  TextFieldForFormik,
} from 'atoms';
import { PasswordChecks } from 'components';
import { newPasswordType, UserRegistrationType } from 'types';
import { ErrorOutline, CheckCircleOutline } from '@mui/icons-material';
import { ButtonTypeEnum, ContactSuccessType } from 'enums';
import { useContactQuery, useCreateContactMutation } from 'api';

type Props = {
  redirectTo: () => void;
  redirectSuccess: () => void;
};

const NewPasswordForm: React.FC<Props> = ({ redirectTo, redirectSuccess }) => {
  const theme = useTheme();

  const mdLimit = useMediaQuery(theme.breakpoints.down('md'));

  const [searchParams] = useSearchParams();

  const [networkLoading, setNetworkLoading] = useState<boolean>(true);

  const accessTokens = {
    exporterId: searchParams.get('exporterId') || '',
    tempToken: searchParams.get('tempToken') || '',
  };

  const [passwordValue, setPasswordValue] = useState<string>('');

  const [userProfile, setUserProfile] = useState<UserRegistrationType>({
    name: '',
    email: '',
    company: '',
    phone: '',
    designation: '',
    codeType: '',
    code: '',
  });

  const childRef = useRef();

  const { t } = useTranslation();

  const redirectSignIn = () => {
    redirectTo();
  };

  const changeState = (value: string) => {
    setPasswordValue(value);
  };

  const { loading } = useContactQuery({
    context: {
      headers: {
        authorization: `Bearer ${accessTokens.tempToken}` || '',
      },
    },
    onCompleted: (data) => {
      setNetworkLoading(() => false);
      if (data.contact) {
        const { name, phone, company, codeType, code } = data.contact;

        setUserProfile({
          name: name || '',
          email: 'email' || '',
          company: company || '',
          phone: formatters.maskFields('phone', phone || undefined) || '',
          designation: 'designation' || '',
          codeType: codeType || '',
          code: formatters.maskFields('phone', code || undefined) || '',
        });
      }
    },
    onError: (error) => {
      const networkErrorObj = error?.networkError as ServerError;
      if (networkErrorObj?.statusCode !== 401) {
        setNetworkLoading(() => false);
      }
    },
  });

  const [updatePassword, { loading: loadingCreateContact }] =
    useCreateContactMutation();

  const updateUserContact = (password: string) => {
    updatePassword({
      variables: {
        password,
      },
      context: {
        headers: {
          authorization: `Bearer ${accessTokens.tempToken}` || '',
        },
      },
      onCompleted: (data) => {
        if (data?.createContact?.message === ContactSuccessType.SUCCESS) {
          redirectSuccess();
        } else if (
          data?.createContact?.message === ContactSuccessType.UNAUTHORIZED
        ) {
          handleError(t('title_unable_to_process'), {
            top: '40px',
            right: '1%',
          });
        } else {
          handleError(t('title_error_message'), {
            top: '40px',
            right: '1%',
          });
        }
      },
      onError: () => {
        handleError(t('title_error_message'), {
          top: '40px',
          right: '1%',
        });
      },
    });
  };

  const renderBlock = (label: string, value?: string) => {
    if (!value) return null;

    return (
      <Grid container item>
        <Grid item xs={12} lg={5}>
          <Typography variant="subtitle1" fontWeight="bold" color="black">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={7}>
          {typeof value === 'string' ? (
            <Typography variant="subtitle1" fontWeight="regular" color="black">
              {value}
            </Typography>
          ) : (
            <>{value}</>
          )}
        </Grid>
      </Grid>
    );
  };

  const fetchCodeLabel = (codeType: string) => {
    switch (codeType) {
      case IDENTIFICATION_CARD_TYPES.RFC:
        return t('label_rfc_code');
      case IDENTIFICATION_CARD_TYPES.IEC:
        return t('label_iec_code');
      case IDENTIFICATION_CARD_TYPES.DUN:
        return t('label_duns_code');
      default:
        return '';
    }
  };

  if (networkLoading) {
    return <>{<FullscreenLoader containerStyles={{ opacity: 1 }} show />}</>;
  }

  if (loading) {
    return <>{<CircularLoader />}</>;
  }

  const isParamsMissing = () => {
    return !(accessTokens.exporterId && accessTokens.tempToken);
  };

  const renderAlert = () => {
    if (isParamsMissing()) {
      return (
        <>
          <Alert severity="error" title={t('su_alert_title')}>
            {t('su_alert_subtitle')}
          </Alert>
          <Spacer verticalSpace={1} />
        </>
      );
    }
    return null;
  };

  return (
    <Formik
      initialValues={NP_FORM_INITIAL_VALUES}
      validationSchema={newPasswordSchema}
      onSubmit={(values: newPasswordType) => {
        const credentials: newPasswordType = newPasswordSchema
          .pick(['password'])
          .cast(values);

        updateUserContact(credentials.password);
      }}
    >
      {(formik) => {
        const { errors, touched, handleSubmit, isValid, isSubmitting } = formik;

        if (!isValid && isSubmitting) {
          const names = Object.keys(errors);
          if (names.length > 0)
            document
              .querySelector(`input[name='${names[0]}']`)
              ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        return (
          <Form>
            <Grid
              dataTestId="generate-new-password-form"
              container
              rowSpacing={3}
            >
              <Grid xs={12} item container rowSpacing={2}>
                {renderBlock(t('label_name'), userProfile.name)}
                {renderBlock(t('label_phone_number'), userProfile.phone)}
                {renderBlock(t('label_company_details'), userProfile.company)}
                {renderBlock(
                  fetchCodeLabel(userProfile?.codeType || ''),
                  userProfile.code
                )}
                {/* TODO: backend will add these in next MVP  */}
                {/* {renderBlock(t('label_email'), userProfile.email)} */}
                {/* {renderBlock(t('label_designation'), userProfile.designation)} */}
              </Grid>

              <Grid xs={12} xl={8} item>
                {renderAlert()}
              </Grid>
              {!isParamsMissing() && (
                <>
                  <Grid xs={12} md={12} xl={8} item>
                    <Field
                      name="password"
                      type="password"
                      label={t('label_create_password')}
                      endIcon={
                        <>
                          <Box ref={childRef}>
                            {touched.password && errors.password ? (
                              <>
                                {errors.password ? (
                                  <ErrorOutline color="error" />
                                ) : (
                                  <CheckCircleOutline color="success" />
                                )}
                                {!mdLimit && (
                                  <Popper
                                    anchorEl={childRef.current}
                                    open={true}
                                    arrow
                                    placement="right"
                                    _sx={{
                                      display: !mdLimit ? 'initial' : 'none',
                                      left: '40px!important',
                                      paddingLeft: 2,
                                      width: 240,
                                    }}
                                  >
                                    <PasswordChecks value={passwordValue} />
                                  </Popper>
                                )}
                              </>
                            ) : undefined}
                          </Box>
                        </>
                      }
                      placeholder={t('placeholder_enter_password')}
                      setPasswordChecks={changeState}
                      error={errors.password}
                      fullWidth
                      component={TextFieldForFormik}
                    />
                  </Grid>
                  <Grid xs={12} md={12} lg={8} item>
                    <Field
                      name="confirm_password"
                      type="password"
                      label={t('label_confirm_password')}
                      placeholder={t('placeholder_confirm_password')}
                      endIcon={
                        <>
                          {touched.password && !errors.confirm_password && (
                            <CheckCircleOutline color="success" />
                          )}
                        </>
                      }
                      component={TextFieldForFormik}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} md={12} lg={8} item>
                    <AtomicButton
                      dataTestId="create-user-button"
                      type="submit"
                      loading={loadingCreateContact}
                      variant={ButtonTypeEnum.CONTAINED}
                      onClick={() => handleSubmit()}
                      disabled={
                        !(accessTokens.exporterId && accessTokens.tempToken)
                      }
                      _sx={{ minWidth: '100%' }}
                    >
                      {t('btn_create_account')}
                    </AtomicButton>
                  </Grid>
                </>
              )}
              <Grid xs={12} md={12} xl={8} item>
                <Link
                  dataTestId="log-in-button"
                  onClick={() => redirectSignIn()}
                >
                  {t('btn_already_log_in')}
                </Link>
              </Grid>
              {/* INFO: Code is kept hidden unless the feature is requested
              <Grid xs={12} item>
                <Divider
                  variant="Tertiary"
                  orientation="horizontal"
                  _sx={{ margin: '2 0' }}
                >
                  {t('label_or')}
                </Divider>
              </Grid>
              <Grid xs={12} item>
                <Button
                  variant="outlined"
                  onClick={callFn}
                  _sx={{ minWidth: '100%' }}
                >
                  {t('btn_google_sign_in')}
                </Button>
              </Grid> */}
              {!isParamsMissing() && (
                <Grid xs={12} md={12} xl={8} item>
                  <Typography variant="body1">{t('su_privacy')}</Typography>
                </Grid>
              )}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NewPasswordForm;
