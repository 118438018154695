import React from 'react';
import { AuthHeading, AuthContainer, NewPasswordForm } from 'components';

type Props = {
  redirectTo: () => void;
  redirectSuccess: () => void;
};

const GeneratePassword: React.FC<Props> = ({ redirectTo, redirectSuccess }) => {
  return (
    <AuthContainer>
      <AuthHeading />
      <NewPasswordForm
        redirectTo={redirectTo}
        redirectSuccess={redirectSuccess}
      />
    </AuthContainer>
  );
};

export default GeneratePassword;
