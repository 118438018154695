import {
  defaultDataIdFromObject,
  InMemoryCache,
  makeVar,
} from '@apollo/client';
import {
  appData,
  messageData,
  onboardingData,
  sideCardData,
} from './serverconfig.types';

export const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'Tag':
        return `Tag:${responseObject.type}:${responseObject.name}:${responseObject.id}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});

export const messageVar = makeVar<messageData>({
  messages: [],
  messagesStyle: {},
});

export const appDataVar = makeVar<appData>({
  onboardingStageVar: '',
  isBDMVar: false,
  isChannelPartnerVar: false,
  regionVar: '',
  isLoggedInVar: false,
  profileTypeVar: '',
  applicantId: '',
  applicantRegionVar: '',
  salesContactVar: {},
  businessId: '',
  userId: '',
});

export const onboardingDataVar = makeVar<onboardingData>({
  isMenuItemVisible: false,
});

export const sideCardVar = makeVar<sideCardData>({
  openModal: false,
  modalType: '',
});
