import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Flex,
  useTheme,
} from '@dripcapital/dripui';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import { Spacer, Heading, ReadMoreDropdown, TextFieldForFormik } from 'atoms';
import { SIZES } from 'utils';
import { BusinessIdentifierType } from 'types';

type Props = {
  initialValues: BusinessIdentifierType;
  validationSchema: object;
  onSubmit: (values: BusinessIdentifierType) => void;
  title: string;
  titleLabel: string;
  identificationKey: string;
  readMoreBody: React.ReactElement;
  question: string;
  placeholder: string;
};

const BusinessIdentifierComponent: React.FC<Props> = ({
  initialValues,
  validationSchema,
  onSubmit,
  title,
  titleLabel,
  identificationKey,
  readMoreBody,
  question,
  placeholder,
}: Props) => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { handleSubmit } = formik;
        return (
          <Box
            dataTestId="business-identifier-form"
            _sx={{ margin: SIZES.SCREEN_MARGIN, minHeight: '100vh' }}
          >
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Heading title={title} label={titleLabel} />
                <Spacer verticalSpace={6} />
                <Typography variant="h2">{t('bip_title')}</Typography>
                <Typography variant="body1">{t('bip_subtitle')}</Typography>
                <Spacer verticalSpace={3} />
                <Typography variant="h4">{identificationKey}</Typography>
                <ReadMoreDropdown
                  body={readMoreBody}
                  defaultVisibility={true}
                  title={question}
                />
                <Spacer verticalSpace={3} />
                <Flex alignItems={'flex-start'} spacing={1}>
                  <Field
                    name="businessIdentifier"
                    _sx={{ width: theme.spacing(50) }}
                    placeholder={placeholder}
                    component={TextFieldForFormik}
                  />
                  <Button onClick={() => handleSubmit()}>
                    {t('btn_validate')}
                  </Button>
                </Flex>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Formik>
  );
};

export default BusinessIdentifierComponent;
