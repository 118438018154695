export enum CIECStatusMessageEnum {
  INVALID = 'Error in Fetching CIEC status',
}

export enum ApplicationMessageEnum {
  INCOMPLETE_APPLICATION = 'incomplete application',
}

export enum UserProfileEnum {
  INVALID_USER_ID = 'Cannot return null for non-nullable field UserProfile.id',
}

export enum AuthMessageEnum {
  UNAUTHORIZED_REQUEST = 'Unauthorized Request',
  UNAUTHORIZED_STATUS = 'Unauthorized',
}
