import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Grid, Typography } from '@dripcapital/dripui';
import { Spacer } from 'atoms';
import { CompanyType } from 'types';
import { FONT } from 'utils';

type Props = {
  title?: string;
  data: CompanyType;
};

const CompanyInformation: React.FC<Props> = ({ title = '', data }: Props) => {
  const { t } = useTranslation();

  const company = [
    {
      label: t('label_company_name'),
      value: data?.company_name || '',
    },
    {
      label: t('label_company_address'),
      value: (
        <>
          <Typography variant="body1" fontWeight="regular">
            {data?.address || ''}
          </Typography>
          <Typography variant="body1" fontWeight="regular">
            {data?.region || ''}
          </Typography>
          <Typography variant="body1" fontWeight="regular">
            {data?.city + ', ' + data?.region + ' ' + data?.pincode || ''}
          </Typography>
          <Typography variant="body1" fontWeight="regular">
            {data?.country || ''}
          </Typography>
        </>
      ),
    },
    {
      label: t('label_industry'),
      value: data?.category || '',
    },
    {
      label: t('label_types_goods'),
      value: data?.types_of_goods || '',
    },
    {
      label: t('label_designation'),
      value: data?.designation || '',
    },
  ];

  return (
    <Box dataTestId="company-info-section">
      <Grid container spacing={2}>
        {title && (
          <Grid xs={12} item>
            <Typography variant="h3" fontWeight="regular" color="secondary">
              {title}
            </Typography>
            <Spacer verticalSpace={2} />
          </Grid>
        )}
        {company.map((detail, index) => {
          if (detail.value) {
            return (
              <Grid key={index} xs={12} item>
                <Flex>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    _sx={{ minWidth: 220 }}
                  >
                    {detail.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    fontWeight="regular"
                    _sx={{ fontSize: FONT.SIZES.F16 }}
                  >
                    {detail.value}
                  </Typography>
                </Flex>
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </Box>
  );
};

export default CompanyInformation;
