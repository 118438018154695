import { StepsInfo } from 'types';

export const COUNTRY_OPTIONS = [
  { value: 'United States Of America', label: 'label_us' },
  { value: 'mx', label: 'label_mx' },
  { value: 'in', label: 'label_in' },
];

export const GENDER_OPTIONS = [
  { value: 'male', label: 'label_male' },
  { value: 'female', label: 'label_female' },
];

export const SEARCH_INITIAL_VALUES = {
  search: '',
};

export const fetchMXSteps = (
  isBDM: boolean,
  paths: { [key: string]: string }
): StepsInfo => {
  if (!isBDM) {
    return [
      {
        index: 0,
        type: 'companyInfo',
        label: 'title_company_info',
        pending: false,
        completed: false,
        route: paths.company,
      },
      {
        index: 1,
        type: 'financialInfo',
        label: 'title_financial_info',
        pending: false,
        completed: false,
        route: paths.financial,
      },
      {
        index: 2,
        type: 'buyerInfo',
        label: 'title_buyer_info',
        pending: false,
        completed: false,
        route: paths.buyer,
      },
      {
        index: 3,
        type: 'reviewInfo',
        label: 'title_review_and_submit',
        pending: false,
        completed: false,
        route: paths.review,
      },
    ];
  } else {
    return [
      {
        index: 0,
        type: 'companyInfo',
        label: 'title_company_info',
        pending: false,
        completed: false,
        route: paths.company,
      },
      {
        index: 1,
        type: 'financialInfo',
        label: 'title_financial_info',
        pending: false,
        completed: false,
        route: paths.financial,
      },
      {
        index: 2,
        type: 'buyerInfo',
        label: 'title_buyer_info',
        pending: false,
        completed: false,
        route: paths.buyer,
      },
    ];
  }
};

export const fetchINSteps = (
  isBDM: boolean,
  paths: { [key: string]: string }
): StepsInfo => {
  if (!isBDM) {
    return [
      {
        index: 0,
        type: 'companyInfo',
        label: 'title_company_info',
        pending: false,
        completed: false,
        route: paths.company,
      },
      {
        index: 1,
        type: 'financialInfo',
        label: 'title_financial_info',
        pending: false,
        completed: false,
        route: paths.financial,
      },
      {
        index: 2,
        type: 'buyerInfo',
        label: 'title_buyer_info',
        pending: false,
        completed: false,
        route: paths.buyer,
      },
      {
        index: 3,
        type: 'reviewInfo',
        label: 'title_review_and_submit',
        pending: false,
        completed: false,
        route: paths.review,
      },
    ];
  } else {
    return [
      {
        index: 0,
        type: 'companyInfo',
        label: 'title_company_info',
        pending: false,
        completed: false,
        route: paths.company,
      },
      {
        index: 1,
        type: 'financialInfo',
        label: 'title_financial_info',
        pending: false,
        completed: false,
        route: paths.financial,
      },
      {
        index: 2,
        type: 'buyerInfo',
        label: 'title_buyer_info',
        pending: false,
        completed: false,
        route: paths.buyer,
      },
    ];
  }
};

export const fetchUSSteps = (paths: { [key: string]: string }): StepsInfo => {
  return [
    {
      index: 0,
      type: 'personalDetails',
      label: 'usoa_pd_title',
      pending: false,
      completed: false,
      route: paths.personal,
    },
    {
      index: 1,
      type: 'companyDetails',
      label: 'usoa_cd_title',
      pending: false,
      completed: false,
      route: paths.company,
    },
    {
      index: 2,
      type: 'financialInfo',
      label: 'title_financial_details',
      pending: false,
      completed: false,
      route: paths.financial,
    },
    {
      index: 3,
      type: 'verifyIdentity',
      label: 'usoa_pd_heading',
      pending: false,
      completed: false,
      route: paths.identity,
    },
    {
      index: 4,
      type: 'bankInfo',
      label: 'usoa_bd_connect_bank_accounts',
      pending: false,
      completed: false,
      route: paths.bank,
    },
    {
      index: 5,
      type: 'reviewInfo',
      label: 'title_review_and_submit',
      pending: false,
      completed: false,
      route: paths.review,
    },
  ];
};

export const fetchCPSteps = (paths: { [key: string]: string }): StepsInfo => {
  return [
    {
      index: 0,
      type: 'personalDetails',
      label: 'usoa_pd_referral_title',
      pending: false,
      completed: false,
      route: paths.personal,
    },
    {
      index: 1,
      type: 'companyDetails',
      label: 'usoa_cd_title',
      pending: false,
      completed: false,
      route: paths.company,
    },
    {
      index: 2,
      type: 'financialInfo',
      label: 'title_referral_financial_details',
      pending: false,
      completed: false,
      route: paths.financial,
    },
    {
      index: 3,
      type: 'verifyIdentity',
      label: 'usoa_pd_heading',
      pending: false,
      completed: false,
      route: paths.identity,
    },
    {
      index: 4,
      type: 'bankInfo',
      label: 'usoa_bd_connect_bank_accounts',
      pending: false,
      completed: false,
      route: paths.bank,
    },
  ];
};
