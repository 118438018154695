export const SU_INITIAL_VALUES = {
  name: '',
  email: '',
  cc: 'IN-91',
  phone: '',
  password: '',
  confirm_password: '',
};

export const NP_FORM_INITIAL_VALUES = {
  password: '',
  confirm_password: '',
};
