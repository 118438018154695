import { ProductRouteConfig } from './RouteConfig.types';

export const routeConfig: ProductRouteConfig = {
  RF: {
    profile: {
      component: 'Profile',
      // allowedPaths: ['profile'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'identifier',
      },
    },
    identifier: {
      component: 'INBusinessIdentifier',
      // allowedPaths: ['profile', 'identifier'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'details',
      },
    },
    details: {
      component: 'INBusinessDetails',
      // allowedPaths: ['profile', 'identifier', 'details'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'eligibility',
      },
    },
    eligibility: {
      component: 'INBusinessEligibility',
      // allowedPaths: ['profile', 'identifier', 'details', 'eligibility'],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'granted',
      },
    },
    granted: {
      component: 'INOfferDetailsGranted',
      // allowedPaths: [
      //   'profile',
      //   'identifier',
      //   'details',
      //   'eligibility',
      //   'granted',
      // ],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'accepted',
      },
    },
    error: {
      component: 'INOfferDetailsGranted',
      // allowedPaths: [
      //   'profile',
      //   'identifier',
      //   'details',
      //   'eligibility',
      //   'error',
      // ],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'accepted',
      },
    },
    denied: {
      component: 'INOfferDetailsDenied',
      // allowedPaths: [
      //   'profile',
      //   'identifier',
      //   'details',
      //   'eligibility',
      //   'denied',
      // ],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: '',
      },
    },
    timeout: {
      component: 'INOfferDetailsTimeOut',
      // allowedPaths: [
      //   'profile',
      //   'identifier',
      //   'details',
      //   'eligibility',
      //   'timeout',
      // ],
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: [],
      props: {
        nextStage: 'accepted',
      },
    },
    applicants: {
      component: 'INApplicants',
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: ['applicants'],
      props: {
        nextStage: '',
      },
    },
    released: {
      component: 'INOfferReleased',
      allowedPaths: [],
      uAllowedPaths: [],
      suAllowedPaths: ['applicants', 'released'],
      props: {
        nextStage: '',
      },
    },
    onboard: {
      component: 'INEsignOffer',
      allowedPaths: [],
      uAllowedPaths: ['onboard'],
      suAllowedPaths: [],
      props: {
        nextStage: 'accepted',
      },
    },
    accepted: {
      component: 'INOfferAccepted',
      allowedPaths: ['accepted', 'company', 'financial', 'buyer'],
      uAllowedPaths: ['review'],
      suAllowedPaths: ['applicants'],
      props: {
        nextStage: 'company',
      },
    },
    company: {
      component: 'INCompanyInfo',
      allowedPaths: ['accepted', 'company', 'financial', 'buyer'],
      uAllowedPaths: ['review'],
      suAllowedPaths: ['applicants'],
      props: {
        nextStage: 'financial',
      },
    },
    financial: {
      component: 'INFinancialInfo',
      allowedPaths: ['accepted', 'company', 'financial', 'buyer'],
      uAllowedPaths: ['review'],
      suAllowedPaths: ['applicants'],
      props: {
        nextStage: 'buyer',
      },
    },
    buyer: {
      component: 'INBuyerInfo',
      allowedPaths: ['accepted', 'company', 'financial', 'buyer'],
      uAllowedPaths: ['review'],
      suAllowedPaths: ['applicants'],
      props: {
        nextStage: 'review',
      },
    },
    review: {
      component: 'INReview',
      allowedPaths: ['accepted', 'company', 'financial', 'buyer'],
      uAllowedPaths: ['review'],
      suAllowedPaths: [],
      props: {
        nextStage: 'application',
      },
    },
    application: {
      component: 'INApplicationStatus',
      allowedPaths: ['accepted', 'application'],
      uAllowedPaths: [],
      suAllowedPaths: ['applicants', 'company', 'financial', 'buyer'],
      props: {
        nextStage: '',
      },
    },
  },
  stages: {
    bdm: {
      applicants: 'applicants',
      New: 'released',
      'Offer Acceptance Pending': 'released',
      'Company Information Pending': 'accepted',
      'Financial Information Pending': 'financial',
      'Buyer Information Pending': 'buyer',
      'Review Pending': 'application',
      Processing: 'application',
    },
    exporter: {
      profile: 'profile',
      identifier: 'identifier',
      details: 'details',
      eligibility: 'eligibility',
      granted: 'granted',
      error: 'error',
      denied: 'denied',
      timeout: 'timeout',
      'Offer Acceptance Pending': 'onboard',
      'Company Information Pending': 'accepted',
      'Financial Information Pending': 'financial',
      'Buyer Information Pending': 'buyer',
      'Review Pending': 'review',
      Processing: 'application',
    },
    cp: {},
  },
  paths: {
    home: '/',
    profile: '/in/rf/profile',
    identifier: '/in/rf/identifier',
    details: '/in/rf/details',
    eligibility: '/in/rf/eligibility',
    granted: '/in/rf/granted',
    error: '/in/rf/granted',
    denied: '/in/rf/denied',
    timeout: '/in/rf/timeout',
    applicants: '/in/rf/applicants',
    released: '/in/rf/released',
    onboard: '/in/rf/onboard',
    accepted: '/in/rf/accepted',
    company: '/in/rf/company',
    financial: '/in/rf/financial',
    buyer: '/in/rf/buyer',
    review: '/in/rf/review',
    application: '/in/rf/application',
  },
};
