import { CardTypeEnum } from 'enums';

export const HOW_FINANCE_WORKS_CARD = {
  type: CardTypeEnum.LIST,
  heading: 'side_cards_1_section_title',
  body: {
    list: [
      'side_cards_1_section_list_1',
      'side_cards_1_section_list_2',
      'side_cards_1_section_list_3',
      'side_cards_1_section_list_4',
    ],
  },
  label: 'side_cards_1_section_label',
  link: 'https://www.youtube.com/watch?v=RubH4u6NF0I',
};

export const KEEP_DOCUMENT_HANDY_CARD = {
  type: CardTypeEnum.LIST,
  heading: 'side_cards_2_section_title',
  body: {
    list: [
      'side_cards_2_section_list_1',
      'side_cards_2_section_list_2',
      'side_cards_2_section_list_3',
    ],
  },
};

export const CONNECT_GLOBAL_TRADE_CARD = {
  type: CardTypeEnum.FEATURES,
  heading: 'side_cards_3_section_title',
  body: {
    content: 'side_cards_3_section_body',
    featuresList: [
      'side_cards_3_section_feature_1',
      'side_cards_3_section_feature_2',
      'side_cards_3_section_feature_3',
      'side_cards_3_section_feature_4',
    ],
  },
  label: 'side_cards_3_section_label',
  link: 'https://trade360.dripcapital.com',
};

export const DRIP_ASSIST_CARD = {
  type: CardTypeEnum.ASSIST,
  heading: '',
  body: {
    title: 'side_cards_5_section_title',
    label: 'side_cards_5_section_subtitle',
    link: 'https://dripassist.freshdesk.com/support/tickets/new',
  },
};

export const TALK_TO_AGENT_CARD = {
  type: CardTypeEnum.AGENT,
  heading: '',
  body: { content: 'none' },
};

export const accountManagerCard = (accountManager: any) => ({
  type: CardTypeEnum.CONTACT,
  heading: 'side_cards_am_heading',
  body: {
    content: `Contact your account manager ${accountManager?.name} at Drip Capital at`,
    email: `${accountManager?.email}.`,
  },
  label: '',
  link: '',
});

export const REJECT_OFFER_CARD = {
  type: CardTypeEnum.LINK,
  label: 'side_cards_6_section_label',
  link: '/',
};

export const onboardCustomerCard = (paths: { [key: string]: string }) => ({
  type: CardTypeEnum.CONTENT,
  heading: 'side_cards_4_section_title',
  body: { content: 'side_cards_4_section_body' },
  label: 'side_cards_7_section_label',
  link: paths.creation,
});

export const BUSINESS_PORTAL_CARD = {
  type: CardTypeEnum.CONTENT,
  heading: 'side_cards_3_section_title',
  body: { content: 'side_cards_3_section_body' },
  label: 'side_cards_2_section_label',
  link: 'https://trade360.dripcapital.com/',
};
