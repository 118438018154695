import React from 'react';
import { Box, Grid, Typography } from '@dripcapital/dripui';

type Props = {
  title?: string;
  children: React.ReactNode;
};

const ContentSection: React.FC<Props> = ({ children, title }) => {
  return (
    <Box dataTestId="content-section">
      <Grid container rowSpacing={1}>
        {title && (
          <Grid xs={12} item>
            <Typography variant="h2" color="secondary" fontWeight="bold">
              {title}
            </Typography>
          </Grid>
        )}
        <Grid xs={12} item>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentSection;
