import React, { FC, useState } from 'react';
import {
  Close,
  InfoOutlined,
  NotificationsNoneOutlined,
} from '@mui/icons-material';
import { Flex } from '@dripcapital/dripui-v1/components/Flex';
import { ThemeProvider } from '@dripcapital/dripui-v1/theme';
import { Button } from '@dripcapital/dripui-v1/components/Button';
import {
  Dialog,
  DialogContent,
} from '@dripcapital/dripui-v1/components/Dialog';
import { Typography } from '@dripcapital/dripui-v1/components/Typography';
import { DAJ_COLORS, formatters, handleError, handleSuccess } from 'utils';
import { Spacer } from 'atoms';
import SideBorderCard from 'components/SideBorderCard';
import { useTranslation } from 'react-i18next';
import { sendLeadNotification } from 'api';
import { useReactiveVar } from '@apollo/client';
import { appDataVar } from 'config/server';

type Props = {
  mailSent?: boolean;
  mailDate?: any;
  useAsSideCard?: boolean;
  refetchApplicationDetails: () => void;
};

const NotifyLead: FC<Props> = ({
  mailSent = false,
  useAsSideCard = true,
  refetchApplicationDetails,
  mailDate = '',
}) => {
  const { t } = useTranslation();
  const { businessId } = useReactiveVar(appDataVar);

  const [notifyModal, setNotifyModal] = useState<boolean>(false);

  const closeNotifyModal = () => {
    setNotifyModal(false);
  };

  const openNotifyModal = () => {
    setNotifyModal(true);
  };

  const renderNotifyModal = () => {
    const sendNotification = async () => {
      try {
        await sendLeadNotification(businessId);
        handleSuccess(t('usa_cp_notify_success'));
      } catch (e) {
        handleError(t('usa_cp_notify_failed'));
      } finally {
        refetchApplicationDetails();
        setNotifyModal(false);
      }
    };

    return (
      <ThemeProvider>
        <Dialog open={notifyModal} onClose={closeNotifyModal}>
          <DialogContent>
            <Close
              color="secondary"
              sx={{
                color: DAJ_COLORS.GREEN,
                float: 'right',
                top: 0,
                right: 0,
              }}
              onClick={closeNotifyModal}
            />
            <Spacer verticalSpace={4} />
            <Typography
              variant="h3"
              fontWeight="bold"
              color="secondary"
              _sx={{ textAlign: 'center' }}
            >
              {t('usa_cp_notify_modal_title')}
            </Typography>
            <Spacer verticalSpace={2} />
            <Typography variant="label" _sx={{ textAlign: 'center' }}>
              {t('usa_cp_notify_modal_subtitle')}
            </Typography>
            <Spacer verticalSpace={2} />
            <Flex _sx={{ textAlign: 'center' }} justifyContent={'space-around'}>
              <Button size="sm" variant="contained" onClick={sendNotification}>
                {t('usa_cp_notify_modal_confirm_btn')}
              </Button>
              <Button size="sm" variant="outlined" onClick={closeNotifyModal}>
                {t('usa_cp_notify_modal_cancel_btn')}
              </Button>
            </Flex>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  };

  return (
    <ThemeProvider>
      {renderNotifyModal()}
      <SideBorderCard>
        <Flex alignItems="flex-start">
          <InfoOutlined
            sx={{ color: DAJ_COLORS.SKY_BLUE_400, marginRight: 1 }}
          />
          <Typography
            variant="body"
            _sx={{ lineHeight: '22px' }}
            color="secondary"
            fontWeight="bold"
          >
            {mailSent
              ? t('usa_cp_notify_lead_sent_title', {
                  date: formatters.formatToUSDate(mailDate),
                })
              : t('usa_cp_notify_lead_title')}
          </Typography>
        </Flex>
        <Spacer verticalSpace={1} />
        <Typography variant="body-small">
          {t(
            mailSent
              ? 'usa_cp_notify_lead_sent_subtitle'
              : 'usa_cp_notify_lead_subtitle'
          )}
        </Typography>
        <Spacer verticalSpace={3} />
        <Button
          size="sm"
          variant="outlined"
          startIcon={<NotificationsNoneOutlined fontSize="medium" />}
          onClick={openNotifyModal}
          _sx={{ width: useAsSideCard ? '100%' : '192px' }}
        >
          {t(mailSent ? 'usa_cp_notify_again_btn' : 'usa_cp_notify_lead_btn')}
        </Button>
      </SideBorderCard>
    </ThemeProvider>
  );
};

export default NotifyLead;
