import React from 'react';
import { Box, useTheme } from '@dripcapital/dripui';
import { DAJ_COLORS } from 'utils';

type Props = {
  children: React.ReactNode;
  color?: string;
};

const SideBorderCard: React.FC<Props> = ({
  children,
  color = DAJ_COLORS.SKY_BLUE_400,
}) => {
  const { spacing } = useTheme();
  return (
    <Box
      _sx={{
        padding: `${spacing(3)} ${spacing(2)}`,
        borderStyle: 'solid',
        borderWidth: '1px 1px 1px 8px',
        borderColor: color,
        borderRadius: spacing(1),
      }}
    >
      {children}
    </Box>
  );
};

export default SideBorderCard;
