import { messageVar } from 'config/server';
import { NavigateFunction } from 'react-router-dom';
import { ShareholderFinancial } from 'api';
import { FinancialOwner, NotificationMessagesType } from 'types';
import { SnackbarVariantEnum } from 'enums';

export const navigateTo = (
  navigate: NavigateFunction,
  path: string,
  query?: {
    applicantId?: string;
    exporterId?: string;
    tempToken?: string;
  }
) => {
  const params = new URLSearchParams(query).toString();
  if (params) {
    navigate(`${path}?${params}`);
  } else {
    navigate(`${path}`);
  }
};

export const findRelativeFieldValue = (
  array: Array<object>, // array to fetch the value from
  filter: string, // the fields to compare with the input provided
  compareTo: string | undefined, // the input parameter
  fieldTarget: string // the required output field
) => {
  if (compareTo) {
    return array?.find((val: any) => val[`${filter}`] === compareTo)?.[
      fieldTarget
    ];
  }
  return '';
};

export const notifyMessage = (
  messages: NotificationMessagesType[],
  messagesStyle?: React.CSSProperties
) => {
  messageVar({ messages, messagesStyle: messagesStyle || {} });
};

export const handleSuccess = (
  message: string,
  messagesStyle?: React.CSSProperties
) => {
  notifyMessage(
    [
      {
        message,
        variant: SnackbarVariantEnum.SUCCESS,
      },
    ],
    messagesStyle
  );
};

export const handleError = (
  message: string,
  messagesStyle?: React.CSSProperties,
  timer = 3000
) => {
  notifyMessage(
    [
      {
        message,
        variant: SnackbarVariantEnum.ERROR,
        timer,
      },
    ],
    messagesStyle
  );
};

export const handleInfo = (
  message: string,
  messagesStyle?: React.CSSProperties
) => {
  notifyMessage(
    [
      {
        message,
        variant: SnackbarVariantEnum.INFO,
      },
    ],
    messagesStyle
  );
};

export const generateDummyHeaders = () => {
  return [
    {
      id: 'title',
      label: 'Description',
      minWidth: 'inherit',
      align: 'left',
    },
    {
      id: 'subject',
      label: 'Summary',
      minWidth: 240,
      align: 'left',
    },
  ];
};

export const isInfoAdded = (
  financialOwner: FinancialOwner,
  shareholderInfo: ShareholderFinancial
) => {
  let ownerMatch = false;
  if (
    financialOwner?.legalRepresentative ===
      shareholderInfo?.legalRepresentative &&
    financialOwner?.majorityShareholder ===
      shareholderInfo?.majorityShareholder &&
    financialOwner?.secondaryShareholder ===
      shareholderInfo?.secondaryShareholder &&
    financialOwner?.rfc === shareholderInfo?.rfc
  ) {
    ownerMatch = true;
  }

  if (ownerMatch) {
    if (
      shareholderInfo?.address?.city &&
      shareholderInfo?.address?.country &&
      shareholderInfo?.address?.zipcode
    ) {
      return { sameOwner: true, infoAdded: true };
    }
    return { sameOwner: true, infoAdded: false };
  }
  return { sameOwner: false };
};

export const handleShareholderInfoMatching = (
  owner: FinancialOwner,
  shareholders: ShareholderFinancial[] | undefined | null
) => {
  let matchInfo;
  if (shareholders) {
    for (let j = 0; j < shareholders?.length; j++) {
      matchInfo = isInfoAdded(owner, shareholders?.[j]);

      if (!matchInfo.sameOwner) {
        continue;
      }

      if (matchInfo.sameOwner) {
        if (matchInfo.infoAdded) {
          return { infoAdded: true, shareholderDataIndex: j };
        } else {
          return { infoAdded: false, shareholderDataIndex: j };
        }
      }
    }
  }
  return { infoAdded: false, shareholderDataIndex: -1 };
};
