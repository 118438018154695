import React from 'react';
import { Box, Typography } from '@dripcapital/dripui';
import { Spacer } from 'atoms';
import { ContentSection, PersonInfoCard } from 'components';
import { CustomerContactInfo } from 'types';

type Props = {
  title: string;
  dataList: CustomerContactInfo[];
};

const CustomerContactList: React.FC<Props> = ({ title, dataList }: Props) => {
  return (
    <ContentSection title={title}>
      {dataList &&
        dataList.length > 0 &&
        dataList.map((contacts, index) => (
          <Box
            dataTestId="customer-contact-list"
            key={index}
            _sx={{ maxWidth: '700px' }}
          >
            <PersonInfoCard
              showRightButtons={false}
              cardValues={[{}]}
              contentSection={
                <Box>
                  <Typography fontWeight="bold" variant="h4">
                    {contacts.name}
                  </Typography>
                  <Typography variant="subtitle1">
                    {contacts.designation}
                  </Typography>
                  <Typography variant="subtitle1">{contacts.email}</Typography>
                  <Typography variant="subtitle1">
                    {contacts.phoneNumber}
                  </Typography>
                </Box>
              }
            />
            <Spacer verticalSpace={4} />
          </Box>
        ))}
    </ContentSection>
  );
};

export default CustomerContactList;
