import React from 'react';
import { Box, Link, styled, Breadcrumbs } from '@dripcapital/dripui';
import { LinkType } from 'types';
import { NavigateNext } from '@mui/icons-material';
import { FONT } from 'utils';

type Props = {
  breadcrumbs?: LinkType[];
};

const Container = styled(Box)(({ theme }) => {
  return {
    padding: `0 ${theme.spacing(8)}`,
    '@media (max-width: 1024px)': {
      padding: `${theme.spacing(2)}`,
    },
  };
});

const BreadCrumbs: React.FC<Props> = ({ breadcrumbs }: Props) => {
  return (
    <Container dataTestId="breadcrumb-element">
      <Breadcrumbs
        separator={<NavigateNext sx={{ fontSize: FONT.SIZES.F20 }} />}
        aria-label="breadcrumb"
      >
        {breadcrumbs &&
          Array.isArray(breadcrumbs) &&
          breadcrumbs.map((link: LinkType, index: number) => {
            const { dataTestId, label, href, color, underline, handleClick } =
              link;
            return (
              <Link
                dataTestId={dataTestId}
                key={index}
                underline={underline}
                color={color}
                href={href}
                onClick={handleClick}
                _sx={{
                  fontSize: FONT.SIZES.F16,
                  fontWeight:
                    breadcrumbs.length - 1 === index
                      ? FONT.WEIGHT.BOLD
                      : 'initial',
                }}
              >
                {label}
              </Link>
            );
          })}
      </Breadcrumbs>
    </Container>
  );
};

export default BreadCrumbs;
