import React from 'react';
import {
  Accordion,
  Box,
  Flex,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@dripcapital/dripui';
import { Alert, AtomicButton, PaginatedTable, Spacer } from 'atoms';
import { ContentSection } from 'components';
import {
  AlertType,
  ButtonType,
  CellsType,
  HeadingType,
  SeverityStatus,
} from 'types';

type Props = {
  buttons?: ButtonType[];
  tableHeading?: string;
  tableSubHeading?: string;
  table?: {
    cells?: CellsType[];
    heading?: HeadingType[];
  };
  accordionItems?: {
    id: string;
    title: React.ReactNode;
    content: React.ReactNode;
  }[];
  contentAlert?: AlertType;
};

const OfferInfoSection: React.FC<Props> = ({
  buttons,
  tableHeading,
  table,
  tableSubHeading,
  accordionItems,
  contentAlert,
}: Props) => {
  const theme = useTheme();

  const smLimit = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box dataTestId="offer-info-section">
      <ContentSection title={tableHeading}>
        {tableSubHeading && (
          <>
            <Typography variant="subtitle1">{tableSubHeading}</Typography>
            <Spacer verticalSpace={3} />
          </>
        )}
        {accordionItems && (
          <>
            <Accordion
              defaultExpandedIds={['panel-1']}
              items={accordionItems}
            />
            <Spacer verticalSpace={2} />
          </>
        )}
        {table && (
          <Grid item xs={12} md={11}>
            {table.cells && table.heading && (
              <PaginatedTable
                hidePagination
                hideHeadings
                tableCells={table.cells}
                tableHeadings={table.heading}
              />
            )}
            <Spacer verticalSpace={2} />
          </Grid>
        )}
      </ContentSection>
      <Spacer verticalSpace={2} />
      {contentAlert && (
        <Box _sx={{ paddingY: 2 }}>
          {/* TODO: remove content alert if not being used in the components */}
          <Alert
            severity={contentAlert.severity as SeverityStatus}
            title={contentAlert.title}
          >
            {contentAlert.body}
          </Alert>
          <Spacer verticalSpace={1} />
        </Box>
      )}
      <Flex
        flexWrap={'wrap'}
        justifyContent={'start'}
        spacing={smLimit ? 0 : 4}
      >
        {buttons &&
          Array.isArray(buttons) &&
          buttons.map((btn: ButtonType, index: number) => (
            <React.Fragment key={index}>
              <AtomicButton
                onClick={btn.handler}
                variant={btn.variant}
                startIcon={btn.startIcon}
                loading={btn.loading}
                disabled={btn.disabled}
              >
                {btn.title}
              </AtomicButton>
              {smLimit && <Box _sx={{ width: '40px', height: '20px' }} />}
            </React.Fragment>
          ))}
      </Flex>
    </Box>
  );
};

export default OfferInfoSection;
