import { gql } from '@apollo/client';

export const GET_IN_OFFER = gql`
  query getOfferDetails($id: ID!, $type: String) {
    offerDetails(id: $id, type: $type) {
      currency
      offerDetails {
        facilitySize
        interestRate
        establishimentFee
        maxCreditTenor
        advanceRate
      }
      pricingDetails {
        overdueRate
        factoringCommission {
          days
          rate
        }
      }
    }
  }
`;

export const DOWNLOAD_OFFER_LINK = gql`
  query downloadOfferLinkIN($id: ID!, $type: String!) {
    downloadOffer(id: $id, type: $type)
  }
`;

export const GET_MX_OFFER = gql`
  query offerDetailsMX($id: ID!) {
    offer(id: $id) {
      offerDetails {
        advanceRate
        creditLimit
        establishmentFee
        facilitySize
        interestRate
        maximumCreditTenor
        downloadLink
      }
    }
  }
`;

export const GET_E_SIGN_STATUS = gql`
  query offerESignStatus($id: ID!) {
    offerEsignStatus(id: $id) {
      message
      status
    }
  }
`;

export const SCF_FACILITY_DETAILS = gql`
  query scfFacilityDetails($id: ID!) {
    scfFacilityDetails(id: $id) {
      message
      error {
        message
      }
      facilityDetails {
        advanceRate
        facilitySize
        generatedDocUrl
        interestFee
        marginRate
        maxCreditPeriod
        offerGeneratedNotSent
        overdueRate
        setupFee
        transactionFee
        sentDate
        signedDate
      }
    }
  }
`;

export const SCF_GENERATE_OFFER = gql`
  query scfGenerateOffer($id: ID!, $date: String!) {
    scfGenerateOffer(id: $id, date: $date) {
      message
      offerDocumentUrl
      error {
        message
      }
    }
  }
`;

export const SCF_SEND_OFFER = gql`
  query scfSendOffer($id: ID!, $cc: [String!]!) {
    scfSendOffer(id: $id, cc: $cc) {
      offerSent
      message
      error {
        message
      }
    }
  }
`;
