import React, { FC } from 'react';
import { Box, Flex, styled, Typography } from '@dripcapital/dripui';
import { useTranslation } from 'react-i18next';
import { DAJ_COLORS } from 'utils';

type Props = {
  title: React.ReactNode;
  content?: React.ReactNode;
};

const Row = styled(Flex)(({ theme }) => {
  return {
    background: DAJ_COLORS.ALICE_BLUE,
    padding: theme.spacing(2),
  };
});

export const SideCard: FC<Props> = ({
  title,
  content,
}: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Box
      dataTestId="side-card-content-element"
      _sx={{
        width: '100%',
      }}
    >
      <Row spacing={2} direction="column">
        {title && (
          <Typography variant="h4" color="secondary" fontWeight="bold">
            {t(`${title}`)}
          </Typography>
        )}
        {content && content}
      </Row>
    </Box>
  );
};

export default SideCard;
