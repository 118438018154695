import { lazy } from 'react';

const USBusinessIdentifier = lazy(
  () => import('containers/preunderwriting/US/USBusinessIdentifier')
);

const USBusinessDetails = lazy(
  () => import('containers/preunderwriting/US/USBusinessDetails')
);

const USBusinessEligibility = lazy(
  () => import('containers/preunderwriting/US/USBusinessEligibility')
);

// const USEligibleGranted = lazy(
//   () => import('containers/preunderwriting/US/USEligibleGranted')
// );

const USEligibleDenied = lazy(
  () => import('containers/preunderwriting/US/USEligibleDenied')
);

const USApplicants = lazy(
  () => import('containers/preunderwriting/US/USApplicants')
);

const USAssistedCreateAccount = lazy(
  () => import('containers/preunderwriting/US/USCreateAccount')
);

const USAssistedRegisterCompany = lazy(
  () => import('containers/preunderwriting/US/USRegisterCompany')
);

const USCustomerLanding = lazy(
  () => import('containers/preunderwriting/US/USCustomerLanding')
);

const USCompanyDetails = lazy(
  () => import('containers/underwriting/US/USCompanyDetails')
);

const USPersonalDetails = lazy(
  () => import('containers/underwriting/US/USPersonalDetails')
);

const USIdentityVerification = lazy(
  () => import('containers/underwriting/US/USIdentityVerification')
);

const USBankDetails = lazy(
  () => import('containers/underwriting/US/USBankDetails')
);

const USFinancialDetails = lazy(
  () => import('containers/underwriting/US/USFinancialDetails')
);

const USReview = lazy(() => import('containers/underwriting/US/USReview'));

const USApplicationStatus = lazy(
  () => import('containers/underwriting/US/USApplicationStatus')
);

const USOfferGranted = lazy(
  () => import('containers/underwriting/US/USOfferGranted')
);

const USOfferAccepted = lazy(
  () => import('containers/underwriting/US/USOfferAccepted')
);

const USReferrals = lazy(
  () => import('containers/channelpartner/US/USReferrals')
);

const USLegalDocs = lazy(
  () => import('containers/postunderwriting/US/USLegalDocs')
);

const USLegalGenerated = lazy(
  () => import('containers/postunderwriting/US/USLegalGenerated')
);

const USLegalRejected = lazy(
  () => import('containers/postunderwriting/US/USLegalRejected')
);

const USApprovedStage = lazy(
  () => import('containers/postunderwriting/US/USApprovedStage')
);

const USSubmit = lazy(() => import('containers/channelpartner/US/USSubmit'));

export const USRouteMap = {
  USBusinessIdentifier,
  USBusinessDetails,
  USBusinessEligibility,
  USEligibleDenied,
  USApplicants,
  USAssistedCreateAccount,
  USAssistedRegisterCompany,
  USCustomerLanding,
  USCompanyDetails,
  USPersonalDetails,
  USIdentityVerification,
  USBankDetails,
  USFinancialDetails,
  USReview,
  USApplicationStatus,
  USOfferGranted,
  USOfferAccepted,
  USReferrals,
  USSubmit,
  USLegalDocs,
  USLegalGenerated,
  USLegalRejected,
  USApprovedStage,
};
