/* eslint-disable react/display-name */
import React, { useCallback } from 'react';
import { Suspense, FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { INRouteMap } from './INRoutes';
import { MXRouteMap } from './MXRoutes';
import { USRouteMap } from './USRoutes';
import { UserRouteMap } from './UserRoutes';
import Home from 'containers/shared/Home';
import GeneratePassword from 'containers/authentication/GeneratePassword';
import INEsignOffer from 'containers/preunderwriting/IN/INEsignOffer';
import { CircularLoader, FullscreenLoader } from 'atoms';

export const routeMap = {
  ...INRouteMap,
  ...MXRouteMap,
  ...USRouteMap,
  ...UserRouteMap,
};

type RouteProps = object;

export const OnBoardingRoutes: FC<RouteProps> = () => {
  const { AuthRouteHOC, NotFound } = routeMap;

  const handleRedirectSuccess = useCallback(() => {
    window.location.href =
      process.env.REACT_APP_PORTAL_RF + '/login?registration=success' || '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = useCallback(() => {
    window.location.href = process.env.REACT_APP_PORTAL_RF + '/login' || '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route
            index
            element={<FullscreenLoader containerStyles={{ opacity: 1 }} show />}
          />
          <Route
            path=":regionId/:productId/:screenId"
            element={
              <Suspense fallback={<CircularLoader />}>
                {<AuthRouteHOC />}
              </Suspense>
            }
          />
          <Route
            path="login"
            element={<Navigate to="/" state={{ from: location }} replace />}
          />
          <Route path="notfound" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path="/generate"
          element={
            <GeneratePassword
              redirectTo={handleRedirect}
              redirectSuccess={handleRedirectSuccess}
            />
          }
        ></Route>
        <Route
          path="/details"
          element={<INEsignOffer isLoggedIn={false} />}
        ></Route>
        {/* <Route path="/signUp" element={<SignUp />}></Route> */}
        {/* <Route path="/verify" element={<EmailVerification />}></Route> */}
      </Routes>
    </Suspense>
  );
};
