import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import {
  Flex,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@dripcapital/dripui';
import { ArrowDropDownOutlined, Language } from '@mui/icons-material';
import { appDataVar } from 'config/server';
import { AtomicButton } from 'atoms';
import { ButtonTypeEnum } from 'enums';
import { REGION_LANGUAGE_MAP, settings } from 'utils';

type Props = object;

const TranslationMenu: React.FC<Props> = () => {
  const { spacing, breakpoints } = useTheme();

  const { regionVar } = useReactiveVar(appDataVar);

  const { t, i18n } = useTranslation();

  const languageList = REGION_LANGUAGE_MAP[regionVar];

  const isNotMobile = useMediaQuery(breakpoints.up('md'));

  const [helpAnchorEl, setHelpAnchor] = useState<any>(undefined);

  const [languageSelected, setLanguage] = useState<string>('');

  useEffect(() => {
    if (regionVar) {
      const language = languageList.find((language) => {
        return language.code === i18n.language;
      });
      setLanguage(t(`${language?.label}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionVar, i18n.language]);

  return (
    <>
      {isNotMobile && (
        <>
          {languageList &&
          Array.isArray(languageList) &&
          languageList.length > 1 ? (
            <AtomicButton
              dataTestId="translation-menu-button"
              id="translation-menu"
              variant={ButtonTypeEnum.TEXT}
              startIcon={<Language />}
              endIcon={<ArrowDropDownOutlined />}
              onClick={(e) => setHelpAnchor(e.currentTarget)}
              _sx={{ textTransform: 'initial' }}
            >
              {languageSelected}
            </AtomicButton>
          ) : (
            <AtomicButton
              dataTestId="translation-menu-button"
              id="translation-menu"
              variant={ButtonTypeEnum.TEXT}
              startIcon={<Language />}
              onClick={() => 1}
              _sx={{ textTransform: 'initial' }}
            >
              {languageSelected}
            </AtomicButton>
          )}
        </>
      )}
      {!isNotMobile && (
        <Flex
          dataTestId="translation-menu-button"
          alignItems={'center'}
          onClick={(e) => setHelpAnchor(e.currentTarget)}
        >
          <Language />
        </Flex>
      )}
      <Menu
        aria-labelledby="translation-menu"
        anchorEl={helpAnchorEl}
        open={Boolean(helpAnchorEl)}
        onClose={() => {
          setHelpAnchor(undefined);
        }}
        _sx={{
          top: spacing(7),
          left: spacing(-4),
        }}
        PaperProps={{
          style: {
            transform: 'translateX(35%) translateY(5%)',
            width: isNotMobile ? 112 : 88,
          },
        }}
      >
        {languageList &&
          Array.isArray(languageList) &&
          languageList.map((lang: any, index) => {
            return (
              <MenuItem
                dataTestId={lang.label}
                key={index}
                onClick={() => {
                  setHelpAnchor(undefined);
                  setLanguage(t(lang.label));
                  i18n.changeLanguage(lang.code);
                  const languageMap = settings.getSettings('langMap') || {};
                  languageMap[regionVar] = lang.code;
                  settings.saveSettings('langMap', languageMap);
                }}
              >
                {t(lang.label)}
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

export default TranslationMenu;
