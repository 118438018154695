import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Typography } from '@dripcapital/dripui';
import { AtomicButton, Spacer } from 'atoms';
import { ButtonTypeEnum } from 'enums';

type Props = {
  title?: string;
  body?: string;
  list?: string[];
  button_title?: string;
  onClick?: () => void;
};

const WhatsNext: React.FC<Props> = ({
  title,
  body,
  list,
  button_title,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Box dataTestId="whats-next-section">
      <Typography variant="h2" fontWeight="bold" color="secondary">
        {title || t('wnc_title')}
      </Typography>
      <Spacer verticalSpace={1} />
      <Typography variant="subtitle1">{body || t('wnc_body')}</Typography>
      {list &&
        Array.from(list) &&
        list.map((item, index) => {
          return (
            <Typography key={index} variant="subtitle1">
              <li>{item}</li>
            </Typography>
          );
        })}
      {button_title && (
        <>
          <Spacer verticalSpace={4} />
          <Flex justifyContent="start">
            <AtomicButton
              variant={ButtonTypeEnum.CONTAINED}
              onClick={onClick ? onClick : () => 1}
            >
              {button_title}
            </AtomicButton>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default WhatsNext;
