import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import {
  Avatar,
  COLORS,
  Flex,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@dripcapital/dripui';
import { ArrowDropDownOutlined } from '@mui/icons-material';
import { appDataVar } from 'config/server';
import { UserProfile } from 'api';
import { AtomicButton } from 'atoms';
import { ButtonTypeEnum } from 'enums';

type Props = {
  userData?: UserProfile;
  logout: () => void;
};

const UserProfileMenu: React.FC<Props> = ({ userData, logout }: Props) => {
  const { spacing, breakpoints } = useTheme();

  const { profileTypeVar: profileType } = useReactiveVar(appDataVar);

  const { t } = useTranslation();

  const isNotMobile = useMediaQuery(breakpoints.up('md'));

  const [menuAnchorEl, setMenuAnchor] = useState<any>(undefined);

  const getNamePrefix = () => {
    const nameArr = userData?.name?.split(' ');
    const fNamePrefix = nameArr?.[0]?.slice(0, 1) || '';
    const lNamePrefix = nameArr?.[nameArr?.length - 1]?.slice(0, 1) || '';
    return `${fNamePrefix}${lNamePrefix}`;
  };

  const handleLogout = () => {
    setMenuAnchor(undefined);
    logout();
  };

  return (
    <>
      <Flex
        dataTestId="user-menu"
        id="user-menu"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        _sx={{
          minWidth: isNotMobile ? 200 : 50,
          cursor: 'pointer',
        }}
        onClick={(e) => setMenuAnchor(e.currentTarget)}
      >
        <Flex direction="row" alignItems={'center'}>
          <Avatar variant="rounded">{getNamePrefix()}</Avatar>
          {isNotMobile && (
            <Flex direction="column" _sx={{ marginX: 2 }}>
              <Typography variant="body1">{t(`${profileType}`)}</Typography>
              <Typography
                variant="subtitle1"
                color="secondary"
                fontWeight="bold"
                noWrap={true}
              >
                {userData?.name}
              </Typography>
            </Flex>
          )}
        </Flex>
        <Flex alignItems="center" _sx={{ paddingX: 1 }}>
          <ArrowDropDownOutlined />
        </Flex>
      </Flex>
      <Menu
        dataTestId="menu-dropdown-button"
        aria-labelledby="user-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchor(undefined);
        }}
        _sx={{
          top: spacing(6),
          left: spacing(-4),
        }}
        PaperProps={{
          style: {
            transform: 'translateX(18%) translateY(5%)',
            minWidth: 180,
          },
        }}
      >
        <MenuItem
          _sx={{
            ':hover': {
              backgroundColor: COLORS.WHITE,
            },
          }}
        >
          <AtomicButton
            dataTestId="logout-button"
            variant={ButtonTypeEnum.OUTLINED}
            onClick={handleLogout}
          >
            {t('btn_logout')}
          </AtomicButton>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserProfileMenu;
