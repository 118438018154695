import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@dripcapital/dripui';
import { LinkBox } from 'atoms';
import { mixpanel } from 'config/analytics';

type LinkBoxProps = {
  link: string;
  icon: React.ReactNode;
  label: string;
};

export const LinkBoxWithTitle: React.FC<LinkBoxProps> = ({
  link,
  icon,
  label,
}: LinkBoxProps) => {
  const { t } = useTranslation();

  const linkNavigateCallback = () => {
    if (link === '/us/scf/creation') {
      mixpanel.track('applicants-list-navigation-cta-start-new-application');
    }
    window.open(link, '_blank');
  };

  return (
    <>
      <LinkBox
        linkNavigateCallback={linkNavigateCallback}
        linkTitle={
          <Flex>
            {icon && icon}
            <Box _sx={{ padding: 0.8 }} /> {t(`${label}`)}
          </Flex>
        }
      />
    </>
  );
};

export default LinkBoxWithTitle;
