import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  COLORS,
  Flex,
  Grid,
  Typography,
  useTheme,
} from '@dripcapital/dripui';
import { saveAs } from 'file-saver';
import { File } from 'api';
import { DAJ_COLORS } from 'utils';
import { AtomicButton } from 'atoms';
import { ButtonTypeEnum } from 'enums';
import { Download, FolderOpen } from '@mui/icons-material';

type Props = {
  statements: File[];
};

const FileList: React.FC<Props> = ({ statements }: Props) => {
  const { t } = useTranslation();

  const { spacing } = useTheme();

  const download = (upload: File) => {
    saveAs(upload?.url || '', upload?.name || '');
  };

  if (!Array.isArray(statements) || statements?.length == 0) {
    return null;
  }

  return (
    <Grid dataTestId="file-list-container" container rowSpacing={2}>
      {statements.map((file, index) => {
        return (
          <Grid dataTestId="file-list-element" key={index} item xs={12}>
            <Box
              _sx={{
                borderColor: DAJ_COLORS.LIGHT_GRAY,
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: 2,
              }}
            >
              <Flex
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                _sx={{
                  paddingX: 2,
                  paddingY: 1,
                }}
              >
                <Flex alignItems="center" _sx={{ width: '60%' }}>
                  <FolderOpen
                    sx={{ marginRight: 1, color: COLORS.SECONDARY }}
                  />
                  <Typography
                    variant="subtitle1"
                    title={file?.name || ''}
                    noWrap
                  >
                    {file.name}
                  </Typography>
                </Flex>
                <Flex alignItems="center">
                  <AtomicButton
                    dataTestId="download-file-button"
                    variant={ButtonTypeEnum.TEXT}
                    _sx={{
                      fontSize: spacing(2),
                      padding: 0.4,
                      color: COLORS.PRIMARY,
                    }}
                    startIcon={
                      <Download sx={{ marginLeft: 1, color: COLORS.PRIMARY }} />
                    }
                    onClick={() => download(file)}
                  >
                    {t('btn_download')}
                  </AtomicButton>
                </Flex>
              </Flex>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FileList;
