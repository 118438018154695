import { UserCreateType } from 'types';

export const PROFILE_INITIAL_VALUES = {
  country: '',
  profileType: '',
  jobTitle: '',
  profileCheckType: [],
};

export const PROFILE_TYPE_OPTIONS = [
  { value: 'seller', label: 'label_buyer' },
  { value: 'buyer', label: 'label_seller' },
  { value: 'shippingCompany', label: 'label_shipping_company' },
];

export const CUSTOMER_CONTACT_INITIAL_VALUES = {
  designation: '',
  email: '',
  phoneNumber: '',
  name: '',
};

export const SCF_ASSISTED_COMPANY_INITIAL_VALUES: UserCreateType = {
  customerFirstName: '',
  customerLastName: '',
  companyEmail: '',
  cc: 'US-1',
  phone: '',
  jobTitle: '',
};
