import React, { Fragment, KeyboardEvent } from 'react';
import { theme } from '@dripcapital/dripui-v1/theme';
import { Flex } from '@dripcapital/dripui-v1/components/Flex';
import { Search } from '@dripcapital/dripui-v1/components/Search';
import { Typography } from '@dripcapital/dripui-v1/components/Typography';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'atoms';
import { FieldInputProps, FormikErrors } from 'formik';
import { useBreakpoints } from '@dripcapital/dripui-v1/hooks';
import EmailAvatar from 'components/EmailAvatar';

type Props = {
  userEmail: string;
  emails: string[];
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  errors: FormikErrors<{
    email: string;
  }>;
  removeEmail: (index: number) => void;
  handleKeyDown: ((event: KeyboardEvent<HTMLInputElement>) => void) | undefined;
};

const AddEmailsLegal: React.FC<Props> = ({
  userEmail,
  emails,
  getFieldProps,
  handleSubmit,
  errors,
  removeEmail,
  handleKeyDown,
}) => {
  const { t } = useTranslation();

  const breakpoints = useBreakpoints();

  const isSm = breakpoints.isSmBreakpoint;

  return (
    <>
      <Typography variant="h4" color="secondary" fontWeight="bold">
        {t('us_scf_legal_email_heading')}
      </Typography>
      <Typography variant="body">
        {t('us_scf_legal_email_subheading')}
      </Typography>
      <Spacer verticalSpace={1} />
      <Typography variant="body" fontWeight="bold" color="secondary">
        {t('us_scf_legal_primary_signatory')} {userEmail}
      </Typography>
      <Spacer verticalSpace={3} />
      <Typography variant="label" color="secondary">
        {t('us_scf_legal_cc_email')}
      </Typography>
      <Spacer verticalSpace={1} />
      <Search
        {...getFieldProps('email')}
        name="email"
        inputStartIcon={<Fragment />}
        buttonText="Add +"
        placeholder={t('us_scf_legal_email_placeholder')}
        onClick={() => handleSubmit()}
        error={errors.email ? t(`${errors.email}`) : ''}
        onKeyDown={handleKeyDown}
      />
      {emails.length > 0 && (
        <>
          {isSm && <Spacer verticalSpace={1} />}
          <Flex flexWrap={'wrap'}>
            {emails.map((mail, idx) => (
              <EmailAvatar
                email={mail}
                key={idx}
                _sx={{
                  mr: theme.spacing(1),
                  mb: theme.spacing(1),
                }}
                onClose={removeEmail.bind(this, idx)}
              />
            ))}
          </Flex>
        </>
      )}
    </>
  );
};

export default AddEmailsLegal;
