import React from 'react';
import { Box, Checkbox, Grid } from '@dripcapital/dripui';
import { validations } from 'utils';
import { t } from 'i18next';

type Props = {
  value: string;
};

const PasswordChecks: React.FC<Props> = ({ value }: Props) => {
  return (
    <Box dataTestId="password-checks-dialog">
      <Grid container rowSpacing={1}>
        <Grid xs={12} item>
          <Checkbox
            label={t('password_contains_character')}
            size={'medium'}
            checked={validations.isInRange(value, 7, 14)}
          />
        </Grid>
        <Grid xs={12} item>
          <Checkbox
            label={t('password_contains_upper_case')}
            size={'medium'}
            checked={validations.hasUpperCase(value)}
          />
        </Grid>
        <Grid xs={12} item>
          <Checkbox
            label={t('password_contains_special_char')}
            size={'medium'}
            checked={validations.hasSpecialCase(value)}
          />
        </Grid>
        <Grid xs={12} item>
          <Checkbox
            label={t('password_contains_number')}
            size={'medium'}
            checked={validations.hasNumber(value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PasswordChecks;
