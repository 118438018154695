import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Skeleton as LoadingSkeleton,
  Flex,
  useMediaQuery,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@dripcapital/dripui';
import { BorderCardContainer, Spacer } from 'atoms';
import { DAJ_COLORS, FONT, renderTableHeading } from 'utils';

type Props = {
  rectangleHeight?: number | string;
  type?: string;
  steps?: number;
  width?: string;
  tableRows?: number;
  icon?: boolean;
};

const Skeleton: React.FC<Props> = ({
  rectangleHeight = 100,
  type = 'card',
  steps = 3,
  width = '80%',
  tableRows = 4,
  icon = false,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const shouldIncreaseWidth = useMediaQuery(theme.breakpoints.down('lg'));

  const shouldIncreaseWidthStepper = useMediaQuery(
    theme.breakpoints.down('md')
  );

  const renderSkeleton = () => {
    return (
      <LoadingSkeleton
        dataTestId="linear-skeleton-element"
        animation="pulse"
        variant="text"
        height={rectangleHeight}
        width={width}
      />
    );
  };

  const renderAuthenticationInfoCard = () => {
    return (
      <Box dataTestId="authentication-skeleton-element">
        <LoadingSkeleton
          animation="pulse"
          variant="text"
          height={40}
          width={'100%'}
        />
        <Spacer verticalSpace={2} />
        <Box _sx={{ width: '100%' }}>
          <BorderCardContainer>
            <LoadingSkeleton
              animation="pulse"
              variant="text"
              height={30}
              width={'100%'}
            />
            <Spacer verticalSpace={2} />
            <LoadingSkeleton
              variant="rectangular"
              animation="pulse"
              width="100%"
              height={20}
            />
            <Spacer verticalSpace={2} />
            <LoadingSkeleton
              variant="rectangular"
              animation="pulse"
              width="50%"
              height={20}
            />
            <Spacer verticalSpace={3} />
            <LoadingSkeleton
              variant="rectangular"
              animation="pulse"
              width="50%"
              height={40}
            />
          </BorderCardContainer>
        </Box>
      </Box>
    );
  };

  const renderAuthenticationStatusCard = () => {
    return (
      <Box dataTestId="authentication-status-skeleton-element">
        <LoadingSkeleton
          animation="pulse"
          variant="text"
          height={40}
          width={'100%'}
        />
        <Spacer verticalSpace={2} />
        <Box _sx={{ width: '100%' }}>
          <BorderCardContainer>
            <Spacer verticalSpace={1} />
            <LoadingSkeleton
              animation="pulse"
              variant="text"
              height={30}
              width={'100%'}
            />
            <Spacer verticalSpace={1} />
          </BorderCardContainer>
        </Box>
      </Box>
    );
  };

  const renderCardSkeleton = () => {
    return (
      <Box
        dataTestId="card-skeleton-element"
        _sx={{ width: shouldIncreaseWidth ? '100%' : width }}
      >
        <BorderCardContainer>
          <Flex _sx={{ alignItems: 'center' }} spacing={2}>
            {icon && (
              <LoadingSkeleton
                animation="pulse"
                variant="circular"
                height={100}
                width={110}
              />
            )}
            <Box _sx={{ width: '100%' }}>
              <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                direction={'row'}
                spacing={2}
                _sx={{ width: '100%', marginBottom: 2 }}
              >
                <Box _sx={{ width: '100%' }}>
                  <LoadingSkeleton
                    animation="pulse"
                    variant="text"
                    height={20}
                    width={'100%'}
                  />
                  <LoadingSkeleton
                    animation="pulse"
                    variant="text"
                    height={20}
                    width={'100%'}
                  />
                </Box>
                <LoadingSkeleton
                  animation="pulse"
                  variant="circular"
                  height={48}
                  width={52}
                />
                <LoadingSkeleton
                  animation="pulse"
                  variant="circular"
                  height={48}
                  width={52}
                />
              </Flex>
              <LoadingSkeleton
                variant="rectangular"
                animation="pulse"
                width="100%"
                height={rectangleHeight}
              />
            </Box>
          </Flex>
        </BorderCardContainer>
      </Box>
    );
  };

  const renderStepperSkeleton = () => {
    const Element = shouldIncreaseWidthStepper ? Flex : React.Fragment;
    const props = shouldIncreaseWidthStepper
      ? {
          spacing: 2,
          alignItems: 'baseline',
          width: '100%',
        }
      : {};

    return (
      <Element {...props}>
        {[...Array(steps)].map((_, idx: number) => (
          <Flex
            dataTestId="steps-skeleton-element"
            key={idx}
            spacing={2}
            _sx={{ marginBottom: 3, width: '100%' }}
            alignItems={'center'}
          >
            <LoadingSkeleton
              animation="pulse"
              variant="circular"
              height={40}
              width={40}
              _sx={{ minWidth: 40 }}
            />
            {!shouldIncreaseWidthStepper && (
              <LoadingSkeleton
                animation="pulse"
                variant="text"
                height={15}
                width={'40%'}
              />
            )}
            {shouldIncreaseWidthStepper && idx + 1 !== steps && (
              <LoadingSkeleton
                animation="pulse"
                variant="text"
                height={15}
                width={shouldIncreaseWidthStepper ? '100%' : '20%'}
              />
            )}
          </Flex>
        ))}
      </Element>
    );
  };

  const renderTable = () => {
    const fields = {
      com_name: '',
      cust_name: '',
      email: '',
      status: '',
      action: '',
    };

    return (
      <Table dataTestId="table-skeleton-element">
        <TableHead _sx={{ border: 0 }}>
          <TableRow>
            {renderTableHeading(fields)?.map((column, index) => (
              <TableCell
                key={index}
                align={'left'}
                style={{
                  background: DAJ_COLORS.ALICE_BLUE,
                  fontWeight: FONT.WEIGHT.SEMI_BOLD,
                  minWidth: column.minWidth,
                }}
              >
                {t(`${column.label}`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(tableRows)
            .fill(1)
            .map((_, rowIndex) => {
              return (
                <TableRow key={rowIndex} hover>
                  {Array(4)
                    .fill(1)
                    .map((column, colIndex) => {
                      return (
                        <TableCell
                          key={colIndex}
                          align={'left'}
                          style={{ width: column.minWidth }}
                        >
                          <LoadingSkeleton
                            animation="pulse"
                            variant="text"
                            height={30}
                            width={'80%'}
                            _sx={{ marginRight: 'auto' }}
                          />
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    );
  };

  const renderAlert = () => {
    return (
      <Box dataTestId="alert-skeleton-element" _sx={{ width: '100%' }}>
        <BorderCardContainer>
          <Flex spacing={2} alignItems={'center'}>
            <LoadingSkeleton
              animation="pulse"
              variant="circular"
              height={40}
              width={42}
            />
            <LoadingSkeleton
              variant="text"
              animation="pulse"
              width="100%"
              height={25}
            />
          </Flex>
        </BorderCardContainer>
      </Box>
    );
  };

  const handleSkeletonTypes = () => {
    switch (type) {
      case 'linear':
        return renderSkeleton();
      case 'card':
        return renderCardSkeleton();
      case 'stepper':
        return renderStepperSkeleton();
      case 'authCard':
        return renderAuthenticationInfoCard();
      case 'statusCard':
        return renderAuthenticationStatusCard();
      case 'table':
        return renderTable();
      case 'alert':
        return renderAlert();
      default:
        return renderAlert();
    }
  };

  return <>{handleSkeletonTypes()}</>;
};

export default Skeleton;
