import React from 'react';
import { Box, useTheme } from '@dripcapital/dripui';

type Props = {
  verticalSpace: number;
};

const Spacer: React.FC<Props> = ({ verticalSpace }: Props) => {
  const theme = useTheme();

  return (
    <Box
      dataTestId="space-element"
      _sx={{ height: theme.spacing(verticalSpace) }}
    />
  );
};

export default Spacer;
