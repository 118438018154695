import React, { useEffect, memo } from 'react';
import { Outlet } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@dripcapital/dripui';
import { AppFooter, AppHeader } from 'components';
import { FullscreenLoader } from 'atoms';
import withUserProfile from 'shared/hoc/withUserProfile';
import { UserProfile } from 'api';
import { appDataVar } from 'config/server';
import { DAJ_COLORS, settings } from 'utils';

type HomeProps = {
  loading: boolean;
  profile: UserProfile;
  logout: () => void;
  paths: {
    [key: string]: string;
  };
  goToPath: (path: string) => void;
};

const Home: React.FC<HomeProps> = ({
  loading,
  profile,
  logout,
  paths,
  goToPath,
}: HomeProps) => {
  const { regionVar: region } = useReactiveVar(appDataVar);

  const { i18n } = useTranslation();

  useEffect(() => {
    const languageMap = settings.getSettings('langMap') || {};
    i18n.changeLanguage(languageMap[region]);
  }, [region, i18n]);

  const renderLoading = () => {
    return <FullscreenLoader show={!!loading} />;
  };

  const renderPage = () => {
    return (
      <Box dataTestId="home-screen">
        <Flex _sx={{ background: DAJ_COLORS.WHITE }}>
          <AppHeader
            userData={profile}
            logout={logout}
            paths={paths}
            goToPath={goToPath}
          />
        </Flex>
        <Outlet />
        <AppFooter />
      </Box>
    );
  };

  return (
    <>
      {loading && renderLoading()}
      {!loading && renderPage()}
    </>
  );
};

export default memo(withUserProfile(Home));
