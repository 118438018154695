import axios from 'axios';

export const UploadDocument = async (
  url: string,
  method: string,
  headers: Record<string, string | number | boolean>,
  formData: FormData,
  progress: (e: any) => void
) => {
  return await axios({
    url,
    method,
    headers,
    data: formData,
    onUploadProgress: (e) => progress(e),
  });
};
