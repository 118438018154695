import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Grid } from '@dripcapital/dripui';
import { ShareholderInfoCard } from 'components';
import { InfoPendingType, SeverityStatus, ShareholderType } from 'types';
import { Alert, Spacer, Tab } from 'atoms';
import { EditOutlined } from '@mui/icons-material';
import { Positions } from 'enums';

type Props = {
  title?: string;
  edit?: {
    name?: string;
    callback: () => void;
  };
  alert?: {
    variant: SeverityStatus;
    title: string;
    body: string;
  };
  cardLabels: {
    [key: string]: string;
  };
  dataList: ShareholderType[];
  pendingInfo?: InfoPendingType;
};

const ShareholderInfoList: React.FC<Props> = ({
  title,
  edit,
  alert,
  cardLabels,
  dataList,
  pendingInfo = {},
}: Props) => {
  const { t } = useTranslation();

  return (
    <Grid
      dataTestId="shareholder-information-list"
      xs={12}
      item
      container
      rowSpacing={4}
      _sx={{ display: 'block' }}
    >
      {title && (
        <Grid item xs={12}>
          {edit ? (
            <Flex
              spacing={4}
              justifyContent={'space-between'}
              flexWrap="nowrap"
            >
              <Tab
                label={title}
                buttonLabel={edit.name || t('btn_edit_info')}
                Icon={<EditOutlined />}
                callback={edit.callback}
              />
            </Flex>
          ) : (
            <Flex spacing={24}>
              <Tab label={title} />
            </Flex>
          )}
        </Grid>
      )}
      <Grid container item xs={12} spacing={3}>
        {alert && (
          <Grid xs={12} _sx={{ paddingTop: '0px !important' }} item>
            <Spacer verticalSpace={1} />
            <Alert severity={alert.variant} title={alert.title}>
              {alert.body}
            </Alert>
          </Grid>
        )}
        {dataList &&
          Array.isArray(dataList) &&
          dataList.map((data: ShareholderType) => {
            return (
              <Grid key={data.id} xs={12} item>
                <Box>
                  <ShareholderInfoCard
                    cardLabels={cardLabels}
                    cardValues={Array.isArray(data) ? data : [data]}
                    pendingInfo={pendingInfo}
                    chipLabels={data.roles}
                    chipPlacement={Positions.BOTTOM}
                  />
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default ShareholderInfoList;
