export enum ButtonTypeEnum {
  TEXT = 'text',
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
}

export enum AuthButtonTypeEnum {
  BUTTON = 'button',
  LINK = 'link',
}

export enum TextFieldSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum CardTypeEnum {
  CONTENT = 'content',
  FEATURES = 'features',
  LIST = 'list',
  LINK = 'link',
  AGENT = 'agent',
  ASSIST = 'assist',
  CONTACT = 'contact',
}

export enum ModalTypeEnum {
  TALK_TO_AGENT = 'TALK_TO_AGENT',
}

export enum SeverityTypeEnum {
  STANDARD = 'standard',
  FILLED = 'filled',
  OUTLINED = 'outlined',
}

export enum Positions {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum LinkColorEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BLACK = 'black',
  WHITE = 'white',
  GRAY = 'gray',
  LIGHTGRAY = 'lightGray',
  TRANSPARENT = 'transparent',
}

export enum LinkUnderlineEnum {
  NONE = 'none',
  ALWAYS = 'always',
  HOVER = 'hover',
}

export enum ProgressTypeEnum {
  CIRCULAR = 'circular',
  LINEAR = 'linear',
}

export enum RolesMXFinancial {
  COMPANY = 'company',
  SHAREHOLDER = 'shareholder',
}

export enum EsignStatusMXFinancial {
  COMPLETE = 'complete',
  PENDING = 'pending',
}

export enum EsignStatusWeetrust {
  INITIATED = 'initiated',
  SUCCESS = 'success',
  PENDING = 'pending',
  FAILURE = 'failure',
}

export enum NetworkResponseStatus {
  SUCCESS = 'success',
}

export enum SnackbarVariantEnum {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum GstStatus {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PENDING = 'pending',
  INVALID_RECORD = 'invalid_record',
}

export enum CibilStatus {
  PENDING = 'pending',
  PROGRESS = 'progress',
  COMPLETED = 'completed',
}

export enum ItrStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export enum AuthenticationStatusCardEnum {
  PENDING = 'pending',
  PROGRESS = 'progress',
  COMPLETE = 'complete',
}

export enum OfferStatusEnum {
  PROPOSED = 'proposed',
  COMPLETED = 'completed',
}

export enum ContactSuccessType {
  SUCCESS = 'success',
  UNAUTHORIZED = 'Unauthorized Request',
}

export enum SCFFinancialStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export enum CurrencyType {
  USD = 'usd',
  MXN = 'mxn',
  INR = 'inr',
  EURO = 'euro',
  POUND = 'pound',
  AUD = 'aud',
  AED = 'aed',
  SGD = 'sgd',
  YEN = 'yen',
  CAD = 'cad',
  NZD = 'nzd',
  CHF = 'chf',
}

export enum CIECStatusEnum {
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'error',
}

export enum PlaidStatusEnum {
  RETRY = 'retry',
  PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum PlaidEventEnum {
  SUCCESS = 'Success',
  ERROR = 'Error',
}

export enum E_SIGN_OFFER_STATUS {
  NOT_OFFERED = 'Offer Not Created',
  PENDING = 'Offer Esign Pending',
  E_SIGNED = 'Offer Esinged',
}

export enum ON_FIDO_STATUS {
  PENDING = 'pending',
  COMPLETE = 'complete',
  INITIAL = 'initial',
}

export enum APPLICATION_STATUS {
  PENDING = 'pending',
  COMPLETE = 'complete',
}
