import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ChipProps,
  COLORS,
  Flex,
  FormControl,
  FormHelperText,
  Typography,
} from '@dripcapital/dripui';
import { FieldProps } from 'formik';
import GenderChip from './GenderChip';
import { ReactComponent as Male } from 'assets/male.svg';
import { ReactComponent as Female } from 'assets/female.svg';

type GenderChipsForFormikProps = Pick<ChipProps, '_sx' | 'className'> &
  Pick<FieldProps, 'field' | 'form'> & {
    label?: string;
    subtitle?: string;
    row?: boolean;
    fullWidth?: boolean;
    options: {
      value: string | number;
      label: string;
    }[];
  };

const GenderChipsForFormik = ({
  form,
  field,
  label,
  subtitle,
  row,
  options,
  fullWidth = true,
  _sx,
  className = '',
}: GenderChipsForFormikProps) => {
  const { t } = useTranslation();

  const { name } = field;

  const { setFieldValue, setFieldTouched } = form;

  const { error, touched, value } = form.getFieldMeta(name);

  const getImageSrc = (item: { label: string; value: string | number }) => {
    if (item.value === 'male') {
      return <Male />;
    }
    if (item.value === 'female') {
      return <Female />;
    }
    return '';
  };
  return (
    <>
      <FormControl error={!!(error && touched)} fullWidth={fullWidth}>
        {label && (
          <Typography dataTestId="gender-label" variant="h4">
            {label}
          </Typography>
        )}
        {subtitle && (
          <Typography
            dataTestId="gender-subtitle"
            variant="body1"
            _sx={{ marginBottom: 1 }}
          >
            {subtitle}
          </Typography>
        )}
        <input
          type="text"
          name={name}
          style={{ visibility: 'hidden', height: 0 }}
        />
        <Flex direction={row ? 'row' : 'column'} flexWrap="wrap">
          {options.length > 0 &&
            options.map((item) => {
              return (
                <Box
                  dataTestId="gender-chips"
                  key={item.value}
                  onClick={() => {
                    if (!touched) {
                      setFieldTouched(name);
                    }
                    setFieldValue(name, item.value);
                  }}
                  _sx={{
                    background: COLORS.WHITE,
                    borderRadius: 2,
                    marginBottom: 1,
                    marginRight: 2,
                    cursor: 'pointer',
                    ..._sx,
                  }}
                  className={className}
                >
                  <GenderChip
                    label={t(`${item.label}`)}
                    selected={value === item.value}
                    component={getImageSrc(item)}
                  />
                </Box>
              );
            })}
        </Flex>
        {error && touched && <FormHelperText>{t(`${error}`)}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default GenderChipsForFormik;
