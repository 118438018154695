import React from 'react';
import { Flex, Typography } from '@dripcapital/dripui';

type Props = {
  countryAndCallingCode: {
    countryCode: string;
    callingCode: string;
  };
};

const CountryLabel: React.FC<Props> = ({ countryAndCallingCode }: Props) => {
  return (
    <Flex alignItems="center">
      <img
        src={require(`../../assets/flags/${countryAndCallingCode.countryCode}.svg`)}
        width={30}
        height={30}
        alt={`Country flag for ${countryAndCallingCode.countryCode}`}
      />
      <Typography
        _sx={{ marginLeft: 1 }}
        dataTestId="country-label-content"
        variant="body1"
      >
        +{countryAndCallingCode.callingCode}
      </Typography>
    </Flex>
  );
};

export default CountryLabel;
