import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { appDataVar } from 'config/server';
import { Box, Grid, Typography } from '@dripcapital/dripui';
import { Spacer, Upload } from 'atoms';
import { ApplicationStatus } from 'api';
import { ProgressTypeEnum } from 'enums';
import { FILE_SIZE, SUPPORTED_FILES } from 'utils';

type Props = {
  applicationDetails: ApplicationStatus;
  refetch: () => void;
  isBDM: boolean;
};

const CompanyLegalDocuments: React.FC<Props> = ({
  applicationDetails,
  refetch,
  isBDM,
}) => {
  const { t } = useTranslation();

  const { applicantId } = useReactiveVar(appDataVar);

  const renderTitle = () => {
    return (
      <>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" color="secondary" fontWeight="bold">
              {t('cldc_legal_document_heading')}
            </Typography>
          </Grid>
        </Grid>
        <Spacer verticalSpace={2} />
      </>
    );
  };

  const uploadCompanyLegalDocuments = () => {
    const proofOfTaxUploads =
      applicationDetails?.application?.legalDocs?.[
        'constancia-de-situación-fiscal-mx'
      ]?.files || [];

    const proofOfAddress =
      applicationDetails?.application?.legalDocs?.['proof-of-address-mx']
        ?.files || [];

    const incorporationCertificate =
      applicationDetails?.application?.legalDocs?.[
        'incorporation-certificate-mx'
      ]?.files || [];

    return (
      <Grid container rowSpacing={3} dataTestId="company-legal-documents">
        <Grid item xs={12} dataTestId="company-tax-situation">
          <Upload
            title={t('cldc_legal_document_title_tax')}
            subtitle={t('cldc_legal_document_subtitle_tax')}
            buttonTitle={isBDM ? '' : t('btn_upload')}
            progressType={ProgressTypeEnum.CIRCULAR}
            multiple={false}
            uploads={proofOfTaxUploads}
            fileLimit={FILE_SIZE}
            url={`${process.env.REACT_APP_REST_API_URL}/v1/onboarding/leads/${applicantId}/documents?tag=constancia-de-situación-fiscal-mx`}
            refetch={refetch}
            supportedFiles={SUPPORTED_FILES}
          />
        </Grid>
        <Grid item xs={12} dataTestId="proof-of-address-mx">
          <Upload
            title={t('cldc_legal_document_title_company')}
            subtitle={t('cldc_legal_document_subtitle_company')}
            buttonTitle={isBDM ? '' : t('btn_upload')}
            multiple={false}
            progressType={ProgressTypeEnum.CIRCULAR}
            uploads={proofOfAddress}
            fileLimit={FILE_SIZE}
            url={`${process.env.REACT_APP_REST_API_URL}/v1/onboarding/leads/${applicantId}/documents?tag=proof-of-address-mx`}
            refetch={refetch}
            supportedFiles={SUPPORTED_FILES}
          />
        </Grid>
        <Grid item xs={12} dataTestId="incorporation-certificate-mx">
          <Upload
            title={t('cldc_legal_document_title_incorporation')}
            subtitle={t('cldc_legal_document_subtitle_incorporation')}
            buttonTitle={isBDM ? '' : t('btn_upload')}
            multiple={false}
            progressType={ProgressTypeEnum.CIRCULAR}
            uploads={incorporationCertificate}
            fileLimit={FILE_SIZE}
            url={`${process.env.REACT_APP_REST_API_URL}/v1/onboarding/leads/${applicantId}/documents?tag=incorporation-certificate-mx`}
            refetch={refetch}
            supportedFiles={SUPPORTED_FILES}
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <Box dataTestId="company-legal-doc-section">
      {renderTitle()}
      {uploadCompanyLegalDocuments()}
    </Box>
  );
};

export default CompanyLegalDocuments;
