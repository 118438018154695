import React from 'react';
import { createContext, ReactNode } from 'react';
import routeConfig from 'config/routes/RouteConfig';

export const RouteContext = createContext(routeConfig);

const ContextProvider = RouteContext.Provider;

const RouteContextProvider = ({ children }: { children?: ReactNode }) => {
  return <ContextProvider value={routeConfig}>{children}</ContextProvider>;
};

export default RouteContextProvider;
