import React from 'react';
import {
  Step,
  StepButton,
  Stepper as StepperComponent,
} from '@dripcapital/dripui';
import { Spacer } from 'atoms';
import { useTranslation } from 'react-i18next';

type StepType = {
  dataTestId?: string;
  index: number;
  label: string;
  pending: boolean;
  completed: boolean;
};

type StepperProps = {
  activeStep?: number;
  steps?: StepType[];
  orientation?: 'vertical' | 'horizontal';
  nonLinear: boolean;
  alternativeLabel?: boolean;
  onStepChange: (step: number) => void;
};

const Stepper: React.FC<StepperProps> = ({
  activeStep = 0,
  steps = [],
  orientation = 'vertical',
  nonLinear,
  alternativeLabel,
  onStepChange,
}: StepperProps) => {
  const { t } = useTranslation();

  return (
    <>
      <StepperComponent
        dataTestId="stepper-container-element"
        nonLinear={nonLinear}
        alternativeLabel={alternativeLabel}
        activeStep={activeStep}
        orientation={orientation}
      >
        {steps.map((step) => (
          <Step
            dataTestId={step.dataTestId}
            key={step.index}
            completed={activeStep !== step.index && step.completed}
          >
            <StepButton
              onClick={() => onStepChange(step.index)}
              pending={activeStep !== step.index && step.pending}
            >
              {t(`${step.label}`)}
            </StepButton>
          </Step>
        ))}
      </StepperComponent>
      <Spacer verticalSpace={2} />
    </>
  );
};

export default Stepper;
