import { CountryRouteConfig } from './RouteConfig.types';
import { routeConfig as INRouteConfig } from './INRouteConfig';
import { routeConfig as MXRouteConfig } from './MXRouteConfig';
import { routeConfig as USRouteConfig } from './USRouteConfig';

const routeConfig: CountryRouteConfig = {
  IN: INRouteConfig,
  MX: MXRouteConfig,
  US: USRouteConfig,
};

export default routeConfig;
