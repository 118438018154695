import React, { useContext } from 'react';
import {
  Typography,
  Flex,
  useTheme,
  COLORS,
  Grid,
  Box,
} from '@dripcapital/dripui';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  TextFieldForFormik,
  BorderCardContainer,
  Spacer,
  AtomicButton,
  SelectForFormik,
} from 'atoms';
import { DAJ_COLORS, getSortedCountries, scfOwnerSchema } from 'utils';
import { ButtonTypeEnum } from 'enums';
import { ArrowForwardIos, Percent } from '@mui/icons-material';
import CountryLabel from 'components/CountryLabel';
import { ScfOwnerType } from 'types';
import { US_OWNER_INFO_INTIAL_VALUES } from 'conversion';
import { AnalyticsContext } from 'shared/context/AnalyticsContext';

type Props = {
  loading: boolean;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onSave: (formValues: ScfOwnerType) => void;
  data?: ScfOwnerType;
};

const CompanyInfoForm: React.FC<Props> = ({
  loading,
  onCancel,
  onSave,
  data,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const { mixpanel } = useContext(AnalyticsContext);

  return (
    <Formik
      initialValues={data ? data : US_OWNER_INFO_INTIAL_VALUES}
      onSubmit={onSave}
      validationSchema={scfOwnerSchema}
    >
      {(formik) => {
        const { handleSubmit, isValid, isSubmitting, errors } = formik;

        if (!isValid && isSubmitting) {
          mixpanel.track('company-shareholders-form-error-triggered', {
            error: errors,
          });
          const names = Object.keys(errors);
          if (names.length > 0)
            document
              .querySelector(`input[name='${names[0]}']`)
              ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        return (
          <BorderCardContainer>
            <Typography variant="h4">{t('usoa_cd_owner_details')}</Typography>
            <Spacer verticalSpace={4} />
            <Field
              name="firstname"
              label={t('usoa_cd_first_name')}
              placeholder={t('usoa_cd_first_name_placeholder')}
              component={TextFieldForFormik}
            />
            <Spacer verticalSpace={3} />
            <Field
              name="lastname"
              label={t('usoa_cd_last_name')}
              placeholder={t('usoa_cd_last_name_placeholder')}
              component={TextFieldForFormik}
            />
            <Spacer verticalSpace={3} />
            <Typography _sx={{ marginBottom: 1 }} color="secondary">
              {t('label_phone_number')}
            </Typography>
            <Grid container columnSpacing={2}>
              <Grid xs={4} md={4} lg={2} item>
                <Field
                  name="phone.countryCode"
                  options={getSortedCountries()?.map(
                    (countryAndCallingCode) => ({
                      label: (
                        <CountryLabel
                          countryAndCallingCode={countryAndCallingCode}
                        />
                      ),
                      value: `${countryAndCallingCode.countryCode}-${countryAndCallingCode.callingCode}`,
                    })
                  )}
                  placeholder={t('placeholder_phone_cc')}
                  translate={false}
                  component={SelectForFormik}
                  _sx={{
                    height: 54,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid xs={8} md={8} lg={6} item>
                <Field
                  name="phone.phoneNumber"
                  placeholder={t('placeholder_phone_number')}
                  component={TextFieldForFormik}
                />
              </Grid>
            </Grid>
            <Spacer verticalSpace={3} />
            <Field
              name="email"
              label={t('usoa_cd_email')}
              placeholder={t('usoa_cd_email_placeholder')}
              component={TextFieldForFormik}
            />
            <Spacer verticalSpace={3} />
            <Typography variant="subtitle1" color="secondary">
              {t('usoa_cd_form_ownership_percentage')}
            </Typography>
            <Box
              _sx={{
                width: '110px',
                marginTop: theme.spacing(1),
              }}
            >
              <Field
                name="ownershipPercentage"
                placeholder="0"
                type="number"
                endIcon={<Percent sx={{ color: DAJ_COLORS.BLUE }} />}
                component={TextFieldForFormik}
                fullWidth
              />
            </Box>
            <Spacer verticalSpace={4} />
            <Flex spacing={3} _sx={{ mb: theme.spacing(3) }}>
              <AtomicButton
                dataTestId="owner-save-form-cta"
                type="submit"
                loading={loading}
                onClick={() => {
                  handleSubmit();
                }}
                variant={ButtonTypeEnum.OUTLINED}
                endIcon={<ArrowForwardIos />}
              >
                {data?.id ? t('btn_save') : t('btn_add')}
              </AtomicButton>
              <AtomicButton
                dataTestId="owner-cancel-form-cta"
                onClick={onCancel}
                variant={ButtonTypeEnum.TEXT}
                _sx={{ color: COLORS.PRIMARY }}
              >
                {t('btn_cancel')}
              </AtomicButton>
            </Flex>
          </BorderCardContainer>
        );
      }}
    </Formik>
  );
};

export default CompanyInfoForm;
