import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';

export const openLinkInTab = (link: string) => {
  window.open(link);
};

export const getSortedCountries = () => {
  const getCountryAndCallingCode = getCountries().map((country) => ({
    countryCode: country,
    callingCode: getCountryCallingCode(country),
  }));
  if (getCountryAndCallingCode) {
    return getCountryAndCallingCode.sort(
      (a, b) => Number(a.callingCode) - Number(b.callingCode)
    );
  }
  return [];
};

export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const renameFile = (originalFile: File, newName: string) => {
  return new File([originalFile], newName, {
    type: originalFile?.type ?? '.pdf',
    lastModified: originalFile?.lastModified,
  });
};

export const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export function formatDate(dateString: string) {
  const originalDate = new Date(dateString);

  // Map month names
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Function to get the ordinal suffix (st, nd, rd, th)
  function getOrdinalSuffix(number: number) {
    if (number >= 11 && number <= 13) {
      return 'th';
    }

    const lastDigit = number % 10;
    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  // Get day, month, and year
  const day = originalDate.getDate();
  const month = monthNames[originalDate.getMonth()];
  const year = originalDate.getFullYear();

  // Format the date as "3rd Jan, 2024"
  const formattedDate = `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;

  return formattedDate;
}

export const formatNumberAsUSD = (number: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
};
