import { lazy } from 'react';

const MXBusinessIdentifier = lazy(
  () => import('containers/preunderwriting/MX/MXBusinessIdentifier')
);

const MXBusinessDetails = lazy(
  () => import('containers/preunderwriting/MX/MXBusinessDetails')
);

const MXBusinessEligibility = lazy(
  () => import('containers/preunderwriting/MX/MXBusinessEligibility')
);

const MXApplicants = lazy(
  () => import('containers/preunderwriting/MX/MXApplicants')
);

const MXCIECValidation = lazy(
  () => import('containers/preunderwriting/MX/CIECValidation')
);

const MXOfferDetailsGranted = lazy(
  () => import('containers/preunderwriting/MX/MXOfferDetailsGranted')
);

const MXOfferDetailsGeneration = lazy(
  () => import('containers/preunderwriting/MX/MXOfferDetailsGeneration')
);

const MXOfferDetailsDenied = lazy(
  () => import('containers/preunderwriting/MX/MXOfferDetailsDenied')
);

const MXOfferDetailsTimeOut = lazy(
  () => import('containers/preunderwriting/MX/MXOfferDetailsTimeOut')
);

const MXOfferDetailsAccepted = lazy(
  () => import('containers/preunderwriting/MX/MXOfferDetailsAccepted')
);

const MXCompanyInfo = lazy(
  () => import('containers/underwriting/MX/MXCompanyInfo')
);

const MXFinancialInfo = lazy(
  () => import('containers/underwriting/MX/MXFinancialInfo')
);

const MXBuyerInfo = lazy(
  () => import('containers/underwriting/MX/MXBuyerInfo')
);

const MXReview = lazy(() => import('containers/underwriting/MX/MXReview'));

const MXApplicationStatus = lazy(
  () => import('containers/underwriting/MX/MXApplicationStatus')
);

export const MXRouteMap = {
  MXBusinessIdentifier,
  MXBusinessDetails,
  MXBusinessEligibility,
  MXApplicants,
  MXCIECValidation,
  MXOfferDetailsGranted,
  MXOfferDetailsGeneration,
  MXOfferDetailsDenied,
  MXOfferDetailsTimeOut,
  MXOfferDetailsAccepted,
  MXCompanyInfo,
  MXFinancialInfo,
  MXBuyerInfo,
  MXReview,
  MXApplicationStatus,
};
