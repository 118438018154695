import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Typography,
  Drawer,
  Box,
  Accordion,
  Grid,
  Link,
  COLORS,
  useTheme,
  useMediaQuery,
} from '@dripcapital/dripui';
import { HelpNotificationsType } from 'types';
import { DAJ_COLORS } from 'utils';
import { Close, EmailOutlined, ExpandMore } from '@mui/icons-material';

type Props = {
  open: boolean;
  onCloseDrawer: () => void;
};

const HelpNotifications: React.FC<Props> = ({ open, onCloseDrawer }) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const help_notifications: HelpNotificationsType[] = t('hnc_list', {
    returnObjects: true,
  });

  return (
    <Drawer
      dataTestId="help-notification-drawer"
      open={open}
      onClose={onCloseDrawer}
      variant="temporary"
      PaperProps={
        isMobile ? { style: { width: '100%' } } : { style: { width: 600 } }
      }
    >
      <Flex direction="column" _sx={{ position: 'relative', height: '100%' }}>
        <Box
          _sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            background: DAJ_COLORS.WHITE,
            borderBottomWidth: 0.5,
            borderBottomStyle: 'solid',
            borderBottomColor: DAJ_COLORS.GREY,
            paddingX: 4,
            paddingY: 3,
          }}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Typography variant="h3" fontWeight="bold" color="secondary">
              {t('hnc_heading')}
            </Typography>
            <Close onClick={onCloseDrawer} style={{ cursor: 'pointer' }} />
          </Flex>
        </Box>
        <Box _sx={{ width: '100%' }}>
          {help_notifications &&
            Array.isArray(help_notifications) &&
            help_notifications?.map((item, index) => {
              return (
                <Accordion
                  key={index}
                  items={[
                    {
                      title: (
                        <Typography
                          variant="h5"
                          _sx={{
                            color: COLORS.SECONDARY,
                            paddingLeft: '16px',
                          }}
                        >
                          {item.accordion_title}
                        </Typography>
                      ),
                      content: (
                        <Typography
                          variant="body1"
                          _sx={{
                            border: 'none',
                            paddingX: '24px',
                          }}
                        >
                          {item.accordion_content}
                        </Typography>
                      ),
                      id: item.id,
                    },
                  ]}
                  icon={<ExpandMore />}
                  iconPositionEnd={true}
                />
              );
            })}
        </Box>
        <Box
          _sx={{
            padding: 4,
            borderTop: '1px solid lightgray',
            background: '#fcfcfc',
            marginTop: 'auto !important',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant={isMobile ? 'body1' : 'subtitle1'}
                  fontWeight="bold"
                  color="secondary"
                >
                  {t('hnc_questions')} {t('hnc_subtitle')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Flex
                  alignItems="center"
                  justifyContent={isMobile ? 'flex-start' : 'end'}
                >
                  <EmailOutlined sx={{ color: DAJ_COLORS.WEALTHY_GREEN }} />
                  <Link
                    underline="none"
                    href={`mailto:${t('hnc_email')}`}
                    _sx={{ color: DAJ_COLORS.WEALTHY_GREEN }}
                  >
                    <Typography
                      variant={isMobile ? 'body1' : 'subtitle1'}
                      _sx={{ marginLeft: 1, color: DAJ_COLORS.WEALTHY_GREEN }}
                    >
                      {t('hnc_email')}
                    </Typography>
                  </Link>
                </Flex>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Flex>
    </Drawer>
  );
};
export default HelpNotifications;
