import * as Yup from 'yup';
import isEqual from 'lodash/isEqual';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { AnyObject } from 'yup/lib/object';

const AmericanPincodeRegEx =
  /^[0-9]{5}$|^[0-9]{5}\s[0-9]{4}$|^[0-9]{5}[-–—]{1}[0-9]{4}$/;

const CanadaPincodeRegEx = /^[0-9A-Za-z]{3}\s[0-9A-Za-z]{3}$/;

const OtherCountryPincodeRegEx =
  /^([a-zA-Z0-9]+[-–—\s]?[a-zA-Z0-9]+[-–—\s]?)+$/;

const validations = {
  isInRange: (value: string, start: number, end: number) =>
    Boolean(value && value.length > start && value.length < end),

  hasUpperCase: (text: string) => /^.*[A-Z].*$/.test(text),

  hasSpecialCase: (text: string) =>
    /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(text),

  hasNumber: (text: string) => /^.*[0-9].*$/.test(text),

  isValidUSZip: (text: string) => /^\d{5}(-\d{4})?$/.test(text),

  isPhoneNumberValid: (
    mobileNumber: number | undefined,
    countryCode: string
  ) => {
    let phoneNumber = '';
    const countryAlpha2Code = countryCode?.slice(3);
    const mobileNumberTrimmed = mobileNumber?.toString().trim() || '';
    if (mobileNumberTrimmed) {
      phoneNumber = `+${countryAlpha2Code}${mobileNumber}`;
    }
    return phoneNumber ? isValidPhoneNumber(phoneNumber) : true;
  },

  isPincodeRequired: (
    value: string | undefined,
    context: Yup.TestContext<AnyObject>
  ) => {
    if (
      ['United States Of America', 'Canada'].includes(context.parent.country) &&
      !value
    ) {
      return false;
    }
    return true;
  },

  isPincodeValid: (
    value: string | undefined,
    context: Yup.TestContext<AnyObject>
  ) => {
    const regUS = new RegExp(AmericanPincodeRegEx, 'i');
    const regCan = new RegExp(CanadaPincodeRegEx, 'i');
    const regOthers = new RegExp(OtherCountryPincodeRegEx, 'i');
    if (
      context.parent.country === 'United States Of America' &&
      !regUS.test(value || '')
    ) {
      return context.createError({
        message: 'bds_pincode_usa_format',
      });
    }
    if (context.parent.country === 'Canada' && !regCan.test(value || '')) {
      return context.createError({
        message: 'bds_pincode_canada_format',
      });
    }
    if (
      value &&
      !['United States Of America', 'Canada'].includes(
        context.parent.country
      ) &&
      !regOthers.test(value || '')
    ) {
      return context.createError({
        message: 'bds_pincode_others_format',
      });
    }
    return true;
  },

  matchObject: (array: object[], target: object) => {
    let flag = false;
    for (let index = 0; index < array.length; index++) {
      flag = isEqual(target, array[index]);
      if (flag) {
        return { matched: true, index };
      }
    }
    return { matched: flag, index: -1 };
  },

  isUIDCheck: (uid: string) => /^[A-Za-z0-9\-_=]{1,31}$/.test(uid),
};

export default validations;
