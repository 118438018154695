import { gql } from '@apollo/client';

export const GET_BUYERS = gql`
  query getBuyers($exporterId: ID!) {
    buyers(id: $exporterId) {
      index
      address
      contactEmail
      contactName
      contactPhoneNumber {
        countryCode
        phoneNumber
      }
      country
      currency
      currentSales
      hasInvoice
      idType
      name
      idNumber
      pastSales
      paymentDays
      paymentTerms
      paymentTenor
      related
      rfc
      shippingTerms
      writeoffs
      hasInvoice
      documentReleaseType
      documentSendType
      canSubmitAllBill
      dripLimit
      addToOfferProfile
      operationDrip
    }
  }
`;

export const GET_BUYER = gql`
  query getBuyer($exporterId: ID!, $index: Int!) {
    buyer(id: $exporterId, index: $index) {
      index
      address
      contactEmail
      contactName
      contactPhoneNumber {
        countryCode
        phoneNumber
      }
      country
      currency
      currentSales
      hasInvoice
      idType
      name
      idNumber
      pastSales
      paymentDays
      paymentTerms
      related
      rfc
      shippingTerms
      writeoffs
      hasInvoice
      documentReleaseType
      documentSendType
      canSubmitAllBill
      dripLimit
      addToOfferProfile
      operationDrip
    }
  }
`;
