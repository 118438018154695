import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Typography,
  Box,
  Chip,
  useTheme,
  useMediaQuery,
} from '@dripcapital/dripui';
import { PersonInfoCard } from 'components';
import { Positions } from 'enums';
import { InfoPendingType, ShareholderType } from 'types';
import { Error } from '@mui/icons-material';

type Props = {
  showRightButtons?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  chipLabels?: string[];
  chipPlacement?: Positions;
  cardLabels: {
    [key: string]: string;
  };
  cardValues: ShareholderType[];
  pendingInfo: InfoPendingType;
};

const ShareholderInfoCard: React.FC<Props> = ({
  showRightButtons,
  onEdit,
  onDelete,
  chipLabels,
  chipPlacement,
  cardLabels,
  cardValues,
  pendingInfo = {},
}: Props) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const smLimit = useMediaQuery(theme.breakpoints.down('sm'));

  const mdLimit = useMediaQuery(theme.breakpoints.down('md'));

  const renderContentSectionItem = (
    prop: string,
    label: string,
    value: string | boolean,
    cardValue: ShareholderType,
    style: React.CSSProperties
  ) => {
    const isInfoPending = pendingInfo?.[String(cardValue.id)]?.includes(prop);

    return (
      <Flex alignItems={'center'}>
        {isInfoPending && <Error color="warning" sx={{ marginRight: 1 }} />}
        <Typography
          variant="subtitle1"
          _sx={{
            lineHeight: 1.235,
            ...style,
          }}
        >
          {label}
          {isInfoPending ? t('label_pending') : value}
        </Typography>
      </Flex>
    );
  };

  const renderContentSection = () => {
    return (
      <Box>
        {cardValues.map((cardValue, index) => {
          const { shareholderName, dob } = cardValue;

          return (
            <React.Fragment key={index}>
              <Flex spacing={1} alignItems={'center'}>
                <Typography
                  fontWeight="bold"
                  variant="h4"
                  title={shareholderName}
                  noWrap
                  _sx={{ maxWidth: mdLimit ? '160px' : '260px' }}
                >
                  {shareholderName}
                </Typography>
                {chipLabels &&
                  chipPlacement === Positions.TOP &&
                  chipLabels.map((chipLabel) => (
                    <Chip
                      dataTestId="chip-component-top-placement"
                      key={chipLabel}
                      label={t(`${chipLabel}`)}
                    />
                  ))}
              </Flex>
              {renderContentSectionItem(
                'dob',
                t('title_date_of_birth'),
                dob as string,
                cardValue,
                {
                  marginTop: '11px',
                  marginBottom: '10px',
                  fontSize: smLimit ? 14 : 16,
                }
              )}
              {renderContentSectionItem(
                cardValue.identificationCardType || '',
                cardLabels.identificationCardName,
                cardValue.identificationCardNumber || '',
                cardValue,
                { fontSize: smLimit ? 14 : 16 }
              )}
              {chipLabels &&
                chipPlacement === Positions.BOTTOM &&
                chipLabels.map((chipLabel) => (
                  <Chip
                    dataTestId="chip-component-bottom-placement"
                    key={chipLabel}
                    label={t(`${chipLabel}`)}
                    _sx={{ marginRight: 1, marginTop: 1 }}
                  />
                ))}
            </React.Fragment>
          );
        })}
      </Box>
    );
  };

  return (
    <PersonInfoCard
      onEdit={onEdit}
      onDelete={onDelete}
      showRightButtons={showRightButtons}
      cardValues={cardValues}
      contentSection={renderContentSection()}
    />
  );
};

export default ShareholderInfoCard;
