import React from 'react';
import {
  Flex,
  Typography,
  Chip,
  useMediaQuery,
  useTheme,
  Button,
} from '@dripcapital/dripui';
import { BorderCardContainer } from 'atoms';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { ButtonTypeEnum } from 'enums';
import { DAJ_COLORS } from 'utils';

type Props = {
  traderType: string;
  addButtonText?: string;
  onClick: () => void;
  roles?: string[];
  disabled?: boolean;
};

const AddFieldCard: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const isTab = useMediaQuery(theme.breakpoints.down('md'));

  const {
    onClick,
    traderType,
    addButtonText = t('btn_add'),
    disabled = false,
    roles,
  } = props;

  return (
    <BorderCardContainer
      dataTestId="add-new-block"
      _sx={{
        paddingY: 0,
        paddingX: 0,
        backgroundColor: disabled ? DAJ_COLORS.NEUTRAL_GREY : DAJ_COLORS.WHITE,
      }}
    >
      <Flex
        dataTestId="add-new-block-button"
        justifyContent={'space-between'}
        alignItems={roles ? 'flex-start' : 'center'}
        onClick={onClick}
        _sx={{
          padding: 1.5,
          cursor: 'pointer',
          pointerEvents: disabled ? 'none' : 'initial',
        }}
      >
        <Flex direction={'column'}>
          <Typography fontWeight="bold">{traderType}</Typography>
          {roles && Array.isArray(roles) && (
            <Flex spacing={1} _sx={{ marginTop: '8px' }}>
              {roles?.map((role: string, index) => (
                <Chip key={index} label={t(`${role}`)} />
              ))}
            </Flex>
          )}
        </Flex>
        <Button
          dataTestId="add-button"
          variant={ButtonTypeEnum.TEXT}
          startIcon={<Add fontSize={isTab ? 'medium' : 'inherit'} />}
          onClick={() => 1}
          _sx={{
            fontSize: 16,
            lineHeight: 1,
            padding: 0.4,
          }}
          disabled={disabled}
        >
          {isTab ? '' : addButtonText}
        </Button>
      </Flex>
    </BorderCardContainer>
  );
};

export default AddFieldCard;
