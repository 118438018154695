import React from 'react';
import { Grid, CircularProgress } from '@dripcapital/dripui';

type Props = object;

const CircularLoader: React.FC<Props> = () => {
  return (
    <Grid
      dataTestId="circular-loader-element"
      container
      justifyContent="center"
      alignItems="center"
      _sx={{
        width: '100%',
        height: '99vh',
      }}
    >
      <CircularProgress color="primary" />
    </Grid>
  );
};

export default CircularLoader;
