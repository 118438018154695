import { ApolloLink, HttpLink, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
  settings,
  AUTH_OPERATION_PATHS,
  TOKEN,
  USER_ID,
  HOME_PATH,
  APP_TYPES,
} from 'utils';
import { AuthMessageEnum } from 'enums';

export const httpLink = new HttpLink({
  uri: process.env.REACT_APP_APOLLO_URL,
});

export const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      authorization: `Bearer ${settings.getCookie('token')}` || null,
      ...headers,
    },
  }));

  return forward(operation);
});

export const errorLink = onError(({ networkError, operation }) => {
  const networkErrorObj = networkError as ServerError;
  if (networkErrorObj && networkErrorObj?.statusCode === 401) {
    const message = networkErrorObj?.result?.error?.[0]?.message || '';
    if (message === AuthMessageEnum.UNAUTHORIZED_REQUEST) {
      const { operationName } = operation;
      if (AUTH_OPERATION_PATHS.includes(operationName)) {
        if (settings.getCookie(TOKEN) && settings.getCookie(USER_ID)) {
          window.location.href = HOME_PATH;
        } else {
          window.location.href =
            process.env.REACT_APP_PORTAL_RF +
              `/logout?app=${APP_TYPES.NDAJ}&callback=${window.location.pathname}` ||
            '';
        }
      }
    }
  }
});
