import React from 'react';
import { createContext, ReactNode } from 'react';
import mixpanel from '../../config/analytics/mixpanel';

const analyticsConfig = {
  mixpanel,
};
export const AnalyticsContext = createContext(analyticsConfig);

const ContextProvider = AnalyticsContext.Provider;

const AnalyticsContextProvider = ({ children }: { children?: ReactNode }) => {
  return <ContextProvider value={analyticsConfig}>{children}</ContextProvider>;
};

export default AnalyticsContextProvider;
