import React from 'react';
import {
  Box,
  Chip,
  ChipProps,
  COLORS,
  Flex,
  FormControl,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@dripcapital/dripui';
import { FieldProps } from 'formik';
import { FONT } from 'utils';
import { SeverityTypeEnum } from 'enums';
import { useTranslation } from 'react-i18next';

type ChipsForFormikProps = Pick<ChipProps, '_sx' | 'className'> &
  Pick<FieldProps, 'field' | 'form'> & {
    label?: string;
    subtitle?: string;
    row?: boolean;
    fullWidth?: boolean;
    translate?: boolean;
    options: {
      value: string | number;
      label: string;
    }[];
  };

const ChipsForFormik = ({
  form,
  field,
  label,
  subtitle,
  row,
  options,
  fullWidth = true,
  translate = true,
  _sx,
  className = '',
}: ChipsForFormikProps) => {
  const { t } = useTranslation();

  const { name } = field;

  const { setFieldValue, setFieldTouched } = form;

  const { error, touched, value } = form.getFieldMeta(name);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <FormControl error={!!(error && touched)} fullWidth={fullWidth}>
        {label && (
          <Typography
            dataTestId="chips-label"
            variant="subtitle1"
            color="secondary"
          >
            {label}
          </Typography>
        )}
        <Typography
          dataTestId="chips-subtitle"
          variant="body1"
          color="secondary"
          _sx={{ marginBottom: 1 }}
        >
          {subtitle}
        </Typography>
        <input
          type="text"
          name={name}
          style={{ visibility: 'hidden', height: 0 }}
        />
        <Flex direction={row ? 'row' : 'column'} flexWrap="wrap">
          {options.length > 0 &&
            options.map((item, index) => {
              return (
                <Box
                  dataTestId="chip-element"
                  key={index}
                  onClick={() => {
                    if (!touched) {
                      setFieldTouched(name);
                    }
                    setFieldValue(name, item.value);
                  }}
                  _sx={{
                    background: COLORS.WHITE,
                    borderRadius: 2,
                    marginBottom: 1,
                    flexGrow: isMobile ? 1 : 0,
                  }}
                >
                  <Chip
                    variant={
                      value === item.value
                        ? SeverityTypeEnum.FILLED
                        : SeverityTypeEnum.OUTLINED
                    }
                    label={translate ? t(`${item.label}`) : item.label}
                    color="secondary"
                    _sx={{
                      cursor: 'pointer',
                      fontSize: FONT.SIZES.F16,
                      height: 40,
                      padding: 1,
                      marginRight: 1,
                      width: isMobile ? '96%' : 'initial',
                      ..._sx,
                    }}
                    className={className}
                  />
                </Box>
              );
            })}
        </Flex>
        {error && touched && <FormHelperText>{t(`${error}`)}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default ChipsForFormik;
