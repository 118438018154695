import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@dripcapital/dripui';
import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import './index.css';
import App from './App';
import apolloClient from 'config/server';
import reportWebVitals from './reportWebVitals';
import RouteContextProvider from './shared/context/RouteContext';
import AnalyticsContextProvider from './shared/context/AnalyticsContext';
import settings from './utils/settings';
import './config/locales';
import analyticsInit from './config/analytics';

analyticsInit();
settings.init(process.env.REACT_APP_APPLICATION_NAME);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const strictMode = process.env.NODE_ENV === 'production';

const childComponents = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider>
        <RouteContextProvider>
          <AnalyticsContextProvider>
            <BrowserRouter>
              <CookiesProvider>
                <App />
              </CookiesProvider>
            </BrowserRouter>
          </AnalyticsContextProvider>
        </RouteContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

root.render(
  (strictMode && <React.StrictMode>{childComponents()}</React.StrictMode>) ||
    childComponents()
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
