import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@dripcapital/dripui';
import { AttachMoney } from '@mui/icons-material';
import {
  AtomicButton,
  BorderCardContainer,
  ChipsForFormik,
  CircularLoader,
  SelectForFormik,
  Spacer,
  TextFieldForFormik,
} from 'atoms';
import {
  mxBuyerFormSchema,
  formatters,
  getSortedCountries,
  MX_BUYER_FORM_DAYS_OPTIONS,
  mxBuyerUpdateFormSchema,
  mxBuyerAddFormSchema,
  mxContactInfoSchema,
  mxOptionalContactInfoSchema,
} from 'utils';
import { CountryLabel } from 'components';
import {
  UPLOAD_OPTIONS,
  MX_BUYER_INITIAL_VALUES,
  DATE_TYPE_OPTIONS,
} from 'conversion';
import { ButtonTypeEnum } from 'enums';
import { Buyer, useGetMetaDataQuery } from 'api';

type Props = {
  loading?: boolean;
  handleCancel: () => void;
  handleSave?: (values: Buyer) => void;
  formType?: string;
  buyer?: Buyer;
};

const MXBuyerForm: React.FC<Props> = ({
  loading,
  buyer,
  formType,
  handleCancel,
  handleSave = () => 1,
}: Props) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const lgLimit = useMediaQuery(theme.breakpoints.down('lg'));

  const { data, loading: loadingMeta } = useGetMetaDataQuery();

  const getDateTypeOptions = (): {
    label: string;
    value: string;
  }[] => {
    const arr = MX_BUYER_FORM_DAYS_OPTIONS.map((val) => ({
      label: `${val + ' ' + t('title_buyer_BL_days_days')}`,
      value: val,
    }));
    return arr;
  };

  const buyerValidationSchema = useMemo(() => {
    let mxBuyerDynamicFormSchema: any = mxBuyerUpdateFormSchema;

    if (formType === 'edit') {
      const contactNameValidation = buyer?.contactName
        ? mxContactInfoSchema.pick(['contactName'])
        : mxOptionalContactInfoSchema.pick(['contactName']);
      const contactEmailValidation = buyer?.contactEmail
        ? mxContactInfoSchema.pick(['contactEmail'])
        : mxOptionalContactInfoSchema.pick(['contactEmail']);
      const contactPhoneValidation = buyer?.contactPhoneNumber?.phoneNumber
        ? mxContactInfoSchema.pick(['contactPhoneNumber'])
        : mxOptionalContactInfoSchema.pick(['contactPhoneNumber']);

      mxBuyerDynamicFormSchema = mxBuyerDynamicFormSchema
        .concat(contactNameValidation)
        .concat(contactEmailValidation)
        .concat(contactPhoneValidation);
    } else {
      mxBuyerDynamicFormSchema = mxBuyerAddFormSchema;
    }

    return mxBuyerDynamicFormSchema;
  }, [formType, buyer]);

  if (loadingMeta) {
    return <CircularLoader />;
  }

  return (
    <>
      <Formik
        initialValues={
          buyer ? formatters.sanitizeFields(buyer) : MX_BUYER_INITIAL_VALUES
        }
        validationSchema={buyerValidationSchema}
        onSubmit={(values: Buyer) => {
          handleSave(
            mxBuyerFormSchema
              .pick(['name', 'address', 'contactName', 'contactEmail'])
              .cast(values)
          );
        }}
      >
        {(formik) => {
          const { handleSubmit, isValid, isSubmitting, errors } = formik;

          if (!isValid && isSubmitting) {
            const names = Object.keys(errors);
            if (names.length > 0)
              document
                .querySelector(`input[name='${names[0]}']`)
                ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }

          return (
            <BorderCardContainer
              dataTestId="mx-buyer-form"
              _sx={{ padding: 2 }}
            >
              <Form>
                <Typography variant="h3" color="secondary">
                  {t('btn_add_buyer')}
                </Typography>
                <Spacer verticalSpace={3} />
                <Typography variant="h4" fontWeight="bold">
                  {t('title_buyer_company_details')}
                </Typography>
                <Spacer verticalSpace={4} />
                <Grid container rowSpacing={3}>
                  <Grid xs={12} item>
                    <Field
                      name="name"
                      label={t('label_buyer_name')}
                      placeholder={t('placeholder_company_name')}
                      component={TextFieldForFormik}
                      fullWidth={lgLimit ? true : false}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Field
                      name="address"
                      label={t('label_buyer_address')}
                      placeholder={t('placeholder_address')}
                      size={lgLimit ? 'small' : 'large'}
                      fullWidth={lgLimit ? true : false}
                      component={TextFieldForFormik}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Field
                      name="country"
                      label={t('label_country')}
                      placeholder={t('placeholder_country')}
                      options={
                        loadingMeta
                          ? []
                          : formatters.generateCountryOptions(
                              data?.metaData?.countries
                            )
                      }
                      translate={false}
                      component={SelectForFormik}
                      fullWidth={lgLimit ? true : false}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Field
                      name="rfc"
                      label={t('label_rfc_code')}
                      placeholder={t('placeholder_rfc_number')}
                      component={TextFieldForFormik}
                      fullWidth={lgLimit ? true : false}
                    />
                  </Grid>
                </Grid>
                <Spacer verticalSpace={8} />
                <Typography variant="h4" fontWeight="bold">
                  {t('title_export_details')}
                </Typography>
                <Typography variant="body1">
                  {t('body_export_details')}
                </Typography>
                <Spacer verticalSpace={2} />
                <Grid container rowSpacing={5}>
                  <Grid xs={12} item>
                    <Field
                      name="currency"
                      label={t('title_buyer_currency')}
                      placeholder={t('placeholder_currency')}
                      row
                      options={
                        loadingMeta
                          ? []
                          : formatters.generateCommonOptionsWithSort(
                              data?.metaData?.currencyValues
                            )
                      }
                      translate={false}
                      component={SelectForFormik}
                      fullWidth={lgLimit ? true : false}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    columnSpacing={2}
                    justifyContent={'flex-start'}
                  >
                    <Grid item>
                      <Divider
                        orientation="vertical"
                        weight={2}
                        variant={'Tertiary'}
                      ></Divider>
                    </Grid>
                    <Grid xs={11} item>
                      <Typography variant="subtitle1" color="secondary">
                        {t('title_forms_buyer_BL_days')}
                      </Typography>
                      <Spacer verticalSpace={1} />
                      <Grid container>
                        <Grid xs={12} lg={5} item>
                          <Field
                            name="paymentDays"
                            options={getDateTypeOptions()}
                            translate={false}
                            placeholder={t('placeholder_select_payment_days')}
                            component={SelectForFormik}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          justifyContent="center"
                          alignItems="center"
                          _sx={{ padding: 2 }}
                        >
                          {t('title_from')}
                        </Grid>
                        <Grid xs={12} lg={5} item>
                          <Field
                            name="paymentTenor"
                            options={DATE_TYPE_OPTIONS}
                            placeholder={t('placeholder_select_payment_tenor')}
                            component={SelectForFormik}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Spacer verticalSpace={2} />
                    </Grid>
                  </Grid>
                  <Grid xs={12} item>
                    <Field
                      name="dripLimit"
                      label={t('title_buyer_offered_limit')}
                      placeholder={t('placeholder_enter_number')}
                      startIcon={<AttachMoney />}
                      component={TextFieldForFormik}
                      fullWidth={lgLimit ? true : false}
                    />
                  </Grid>
                </Grid>
                <Spacer verticalSpace={8} />
                <Typography variant="h4" fontWeight="bold">
                  {t('title_buyer_contact_details')}
                </Typography>
                <Typography variant="body1">
                  {t('subtitle_forms_buyer_contact_details')}
                </Typography>
                <Spacer verticalSpace={2} />
                <Grid container rowSpacing={3}>
                  <Grid xs={12} item>
                    <Field
                      name="contactName"
                      label={t('label_contact_name')}
                      placeholder={t('placeholder_contact_name')}
                      component={TextFieldForFormik}
                      fullWidth={lgLimit ? true : false}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Field
                      name="contactEmail"
                      label={t('label_contact_email')}
                      placeholder={t('placeholder_customer_email')}
                      component={TextFieldForFormik}
                      fullWidth={lgLimit ? true : false}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Typography variant="subtitle1" color="secondary">
                      {t('label_contact_phone')}
                    </Typography>
                    <Spacer verticalSpace={1.6} />
                    <Grid container columnSpacing={2}>
                      <Grid xs={4} md={4} lg={2} item>
                        <Field
                          name="contactPhoneNumber.countryCode"
                          options={getSortedCountries()?.map(
                            (countryAndCallingCode) => ({
                              label: (
                                <CountryLabel
                                  countryAndCallingCode={countryAndCallingCode}
                                />
                              ),
                              value: `${countryAndCallingCode.countryCode}-${countryAndCallingCode.callingCode}`,
                            })
                          )}
                          placeholder={t('placeholder_phone_cc')}
                          translate={false}
                          component={SelectForFormik}
                          _sx={{
                            height: 54,
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={8} md={8} lg={10} item>
                        <Field
                          name="contactPhoneNumber.phoneNumber"
                          placeholder={t('placeholder_phone_number')}
                          component={TextFieldForFormik}
                          fullWidth={lgLimit ? true : false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item>
                    <Field
                      name="hasInvoice"
                      label={t('label_invoice_present')}
                      subtitle={' '}
                      row
                      options={UPLOAD_OPTIONS}
                      component={ChipsForFormik}
                      fullWidth
                    />
                  </Grid>
                  <Spacer verticalSpace={4} />
                  <Grid container>
                    <Grid xs={6} sm={4} lg={3} xl={2} item>
                      <AtomicButton
                        dataTestId="submit-buyer"
                        type="submit"
                        loading={loading}
                        variant={ButtonTypeEnum.CONTAINED}
                        onClick={() => handleSubmit()}
                      >
                        {t('btn_save')}
                      </AtomicButton>
                    </Grid>
                    <Grid xs={6} sm={4} lg={3} xl={2} item>
                      <AtomicButton
                        variant={ButtonTypeEnum.TEXT}
                        onClick={handleCancel}
                      >
                        {t('btn_cancel')}
                      </AtomicButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </BorderCardContainer>
          );
        }}
      </Formik>
    </>
  );
};

export default MXBuyerForm;
