import React, { useState } from 'react';
import { Grid, Flex, useMediaQuery, useTheme } from '@dripcapital/dripui';
import { useTranslation } from 'react-i18next';
import { BorderCardContainer, Modal, Tab } from 'atoms';
import { DAJ_COLORS } from 'utils';
import { ButtonTypeEnum } from 'enums';
import { ShareholderType } from 'types';
import {
  EditOutlined,
  AccountCircle,
  DeleteOutline,
} from '@mui/icons-material';
// import { ReactComponent as Male } from 'assets/male.svg';
// import { ReactComponent as Female } from 'assets/female.svg';

type Props = {
  onEdit?: () => void;
  onDelete?: () => void;
  contentSection: React.ReactNode;
  showRightButtons?: boolean;
  cardValues?: ShareholderType[];
};

const PersonInfoCard: React.FC<Props> = ({
  onEdit,
  onDelete,
  contentSection,
  showRightButtons,
}: // cardValues = [],
Props): React.ReactElement => {
  const { t } = useTranslation();

  const theme = useTheme();

  const isTab = useMediaQuery(theme.breakpoints.down('md'));

  const [removeShareholder, setRemoveShareholder] = useState<boolean>(false);

  const closeDialog = () => setRemoveShareholder(false);

  const openShareholderModal = () => {
    setRemoveShareholder(true);
  };

  const deleteShareholder = () => {
    // TODO: no need to delete the card right now as graphql state will handle it on its own
    onDelete?.();
    closeDialog();
  };

  const renderModalDialog = () => {
    return (
      <Modal
        dataTestId="person-info-modal"
        isOpen={removeShareholder}
        closeDialog={closeDialog}
        title={t('phrase_delete_question')}
        content={t('phrase_delete_question_content')}
        buttons={[
          {
            dataTestId: 'accept-button',
            title: t('btn_delete'),
            handler: deleteShareholder,
            variant: ButtonTypeEnum.CONTAINED,
            _sx: { padding: theme.spacing(1.3) },
          },
          {
            dataTestId: 'deny-button',
            title: t('btn_cancel'),
            handler: closeDialog,
            variant: ButtonTypeEnum.OUTLINED,
            _sx: { padding: theme.spacing(1.3) },
          },
        ]}
        _sx={{ padding: theme.spacing(3) }}
      />
    );
  };

  const renderContent = () => {
    return (
      <Grid
        item
        direction={'row'}
        container
        xs={!onEdit && !onDelete ? 12 : isTab ? 10 : 9}
      >
        <Grid _sx={{ minWidth: isTab ? '82px' : '120px' }} item>
          <Flex
            _sx={{
              width: '100%',
              height: '100%',
              fill: DAJ_COLORS.BLUE,
            }}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {
              /* {cardValues.map((cardValue, index) => {
              const { gender } = cardValue;
              if (gender === 'male') {
                return <Male key={index} />;
              } else {
                return <Female key={index} />;
              }
            })} */
              <AccountCircle style={{ height: 75, width: 75 }} />
            }
          </Flex>
        </Grid>
        <Grid item justifyContent={'flex-start'}>
          {contentSection}
        </Grid>
      </Grid>
    );
  };

  const renderRightButtons = () => {
    if (showRightButtons) {
      return (
        <Grid
          xs={isTab ? 2 : 3}
          direction={'row'}
          justifyContent={
            onEdit && onDelete
              ? isTab
                ? 'space-between'
                : 'space-evenly'
              : 'end'
          }
          item
          container
        >
          {onEdit && (
            <Grid item>
              <Tab
                label=""
                buttonLabel={isTab ? '' : t('edit_item')}
                Icon={<EditOutlined />}
                callback={onEdit}
              />
            </Grid>
          )}
          {onDelete && (
            <Grid item>
              <Tab
                label=""
                buttonLabel={isTab ? '' : t('delete_item')}
                Icon={<DeleteOutline />}
                callback={openShareholderModal}
              />
            </Grid>
          )}
        </Grid>
      );
    }
    return null;
  };

  return (
    <BorderCardContainer dataTestId="person-information-card">
      {renderModalDialog()}
      <Grid container justifyContent={'space-between'}>
        {renderContent()}
        {renderRightButtons()}
      </Grid>
    </BorderCardContainer>
  );
};

export default PersonInfoCard;
