/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { saveAs } from 'file-saver';
import { appDataVar } from 'config/server';
import { Box, COLORS, Flex, Typography } from '@dripcapital/dripui';
import { Modal, CircularLoader, Spacer } from 'atoms';
import { AppFooter, AppHeader, OfferComponent } from 'components';
import {
  ADOBE_E_SIGN_ORIGIN,
  DAJ_COLORS,
  generateDummyHeaders,
  navigateTo,
  formatters,
  handleError,
  E_SIGN_CHECK_INTERVAL,
  ONBOARDING_STAGES,
  handleInfo,
  scrollTop,
} from 'utils';
import { AlertType, CellsType } from 'types';
import { ButtonTypeEnum, E_SIGN_OFFER_STATUS, OfferStatusEnum } from 'enums';
import {
  HOW_FINANCE_WORKS_CARD,
  KEEP_DOCUMENT_HANDY_CARD,
  DRIP_ASSIST_CARD,
  TALK_TO_AGENT_CARD,
} from 'conversion';
import {
  UserProfile,
  useGetOfferDetailsLazyQuery,
  useDownloadOfferLinkInLazyQuery,
  useOfferESignMutation,
  useOfferESignStatusQuery,
} from 'api';
import { Close, Download } from '@mui/icons-material';
import { UserRoutePath } from 'routes';

type Props = {
  profile?: UserProfile;
  isLoggedIn?: boolean;
  paths?: {
    [key: string]: string;
  };
  goToPath?: (path: string) => void;
  goToStage?: (onboardingStage: string) => void;
};

const INESignOffer: React.FC<Props> = ({
  profile,
  isLoggedIn = true,
  goToPath,
  goToStage,
  paths = {},
}: Props) => {
  const { applicantId } = useReactiveVar(appDataVar);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const accessTokens = {
    exporterId: searchParams.get('exporterId') || '',
    tempToken: searchParams.get('tempToken') || '',
  };

  const [processingAlert, setProcessingAlert] = useState<boolean>(false);

  const [openESignModal, setOpenESignModal] = useState<boolean>(false);

  const [isDocumentSigned, setIsDocumentSigned] = useState<boolean>(false);

  const [cells, setCells] = useState<CellsType[] | []>([]);

  const [esignLoading, setEsignLoading] = useState<boolean>(true);

  const [getOfferDetails, { data: offerDetails, loading: offerLoading }] =
    useGetOfferDetailsLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.offerDetails?.offerDetails) {
          setCells(formatters.generateOfferDetails(data));
        } else {
          setCells(formatters.generateEmptyOffer());
        }
      },
    });

  const {
    data: esignStatusAPI,
    startPolling,
    stopPolling,
  } = useOfferESignStatusQuery({
    variables: {
      id: applicantId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setEsignLoading(false);
      switch (data.offerEsignStatus?.status) {
        case E_SIGN_OFFER_STATUS.NOT_OFFERED:
          handleError(t('phrase_offer_not_generated'));
          break;
        case E_SIGN_OFFER_STATUS.PENDING:
          handleInfo(t('phrase_offer_not_signed'));
          break;
        case E_SIGN_OFFER_STATUS.E_SIGNED:
          setProcessingAlert(false);
          stopPolling();
          scrollTop();
          break;
        default:
      }
    },
    onError: () => {
      setEsignLoading(false);
    },
  });

  const [fetchEsignURL, { data: esignURL, loading: loadingESignLink }] =
    useOfferESignMutation({
      variables: { id: applicantId },
    });

  const [downloadOfferLink, { loading: downloadLoading }] =
    useDownloadOfferLinkInLazyQuery({});

  const headings = generateDummyHeaders();

  const handleDownloadOffer = () => {
    let variable = {};
    if (isLoggedIn) {
      variable = {
        variables: {
          id: applicantId,
          type: isOfferAccepted()
            ? OfferStatusEnum.COMPLETED
            : OfferStatusEnum.PROPOSED,
        },
      };
    } else {
      variable = {
        variables: {
          id: accessTokens.exporterId,
          type: OfferStatusEnum.PROPOSED,
        },
        context: {
          headers: {
            authorization: `Bearer ${accessTokens.tempToken}` || '',
          },
        },
      };
    }
    downloadOfferLink(variable).then(({ data }) => {
      if (data && data.downloadOffer) {
        saveAs(
          data.downloadOffer,
          t('title_offers_filename') + profile?.businesses?.[0]?.companyName
        );
      } else {
        handleError(t('title_file_not_found'));
      }
    });
  };

  const isOfferAccepted = () => {
    return (
      esignStatusAPI?.offerEsignStatus?.status === E_SIGN_OFFER_STATUS.E_SIGNED
    );
  };

  const isOfferGenerated = (): boolean => {
    return !!offerDetails?.offerDetails?.offerDetails;
  };

  const redirectToAcceptedPage = () => {
    goToStage?.(ONBOARDING_STAGES.COMPANY_INFORMATION_PENDING);
    goToPath?.(paths.company);
  };

  const actionHandler = () => {
    if (!isLoggedIn) {
      // if user is not logged in
      if (accessTokens.exporterId && accessTokens.tempToken) {
        navigateTo(navigate, UserRoutePath.generate, {
          exporterId: accessTokens.exporterId,
          tempToken: accessTokens.tempToken,
        });
      } else {
        handleError(t('title_error_message'));
      }
    } else {
      // if user is logged in
      if (esignURL?.offerEsign?.url) {
        setOpenESignModal(true);
      } else {
        handleError(t('Link not found'));
      }
    }
  };

  const getOfferButtons = () => {
    if (!isLoggedIn) {
      // if user is not logged in
      return [
        {
          title: t('btn_sign_up_accept'),
          variant: ButtonTypeEnum.CONTAINED,
          handler: () => actionHandler(),
        },
        {
          title: t('btn_download_offer'),
          startIcon: <Download />,
          variant: ButtonTypeEnum.OUTLINED,
          handler: () => handleDownloadOffer(),
          loading: downloadLoading,
        },
      ];
    } else {
      // if user is logged in & the document is not-signed/signed
      if (isOfferAccepted() || isDocumentSigned) {
        return [
          {
            title: t('btn_download_offer'),
            startIcon: <Download />,
            variant: ButtonTypeEnum.OUTLINED,
            handler: () => handleDownloadOffer(),
            loading: downloadLoading,
            disabled: !isOfferGenerated(),
          },
        ];
      } else {
        return [
          {
            title: t('btn_accept_esign'),
            variant: ButtonTypeEnum.CONTAINED,
            handler: () => actionHandler(),
            loading: loadingESignLink,
            disabled: !isOfferGenerated(),
          },
          // TODO: feature shifted to pause, will be implemented later
          // {
          //   title: t('btn_request_change'),
          //   variant: ButtonTypeEnum.OUTLINED,
          //   handler: () => 1,
          // },
          {
            title: t('btn_download_offer'),
            startIcon: <Download />,
            variant: ButtonTypeEnum.OUTLINED,
            handler: () => handleDownloadOffer(),
            loading: downloadLoading,
            disabled: !isOfferGenerated(),
          },
        ];
      }
    }
  };

  const renderAlerts = (): AlertType | undefined => {
    if (processingAlert) {
      return {
        severity: 'info',
        title: t('phrase_signed_e_sign_title'),
        // body: t('phrase_success_e_sign_body'),
      };
    } else {
      if (!offerDetails?.offerDetails?.offerDetails) {
        return {
          severity: 'error',
          title: t('title_error_message'),
          body: t('su_alert_subtitle'),
        };
      } else if (isOfferAccepted()) {
        return {
          severity: 'success',
          title: t('phrase_success_e_sign_title'),
          // body: t('phrase_success_e_sign_body'),
        };
      } else {
        return undefined;
      }
    }
  };

  const renderOfferComponent = () => {
    return (
      <>
        {!renderAlerts() && <Spacer verticalSpace={3} />}
        <OfferComponent
          dataTestId="in-e-sign-offer-screen"
          heading={{
            title: !isLoggedIn
              ? t('ireou_heading')
              : t('title_welcome', { name: profile?.name }),
          }}
          pageAlert={renderAlerts()}
          topSection={
            isOfferAccepted()
              ? {
                  title: t('irao_whats_next_title'),
                  body: t('irao_whats_next_body'),
                  list: t('irao_whats_next_list', { returnObjects: true }),
                  startOnboardingHandler: () => redirectToAcceptedPage(),
                  button_title: t('irao_whats_next_button'),
                }
              : undefined
          }
          centerSection={{
            title: t('title_congrats_financed_subtitle', {
              amount: cells?.[0]?.subject || '$0',
            }),
            body: t('ireou_body'),
            table: {
              headings,
              cells,
            },
            buttons: getOfferButtons(),
          }}
          sideInformation={
            isLoggedIn
              ? [
                  HOW_FINANCE_WORKS_CARD,
                  KEEP_DOCUMENT_HANDY_CARD,
                  TALK_TO_AGENT_CARD,
                  DRIP_ASSIST_CARD,
                ]
              : [
                  HOW_FINANCE_WORKS_CARD,
                  KEEP_DOCUMENT_HANDY_CARD,
                  DRIP_ASSIST_CARD,
                ]
          }
        />
      </>
    );
  };

  const renderUnauthenticatedOffer = () => {
    return (
      <Box dataTestId="in-unauthenticated-offer-screen">
        <Flex _sx={{ background: DAJ_COLORS.WHITE }}>
          <AppHeader logout={() => 1} paths={{}} goToPath={() => 1} />
        </Flex>
        {renderOfferComponent()}
        <AppFooter />
      </Box>
    );
  };

  const renderESignIFrame = () => {
    return (
      <Box>
        <Modal
          isOpen={openESignModal}
          closeDialog={() => {
            setOpenESignModal(false);
          }}
          _sx={{ maxWidth: '96%', width: '100%', height: '96%' }}
          fullscreen
        >
          <Flex direction="row" justifyContent="space-between">
            <Flex direction="column">
              <Typography variant="h5" fontWeight="bold">
                {t('ireou_iframe_title')}
              </Typography>
              <Typography variant="subtitle1">
                {t('ireou_iframe_subtitle')}
              </Typography>
            </Flex>
            <Close
              onClick={() => {
                setOpenESignModal(false);
                scrollTop();
              }}
            />
          </Flex>
          <Flex
            justifyContent={'center'}
            _sx={{ background: COLORS.GRAY, marginTop: 1 }}
          >
            <iframe
              src={esignURL?.offerEsign?.url || undefined}
              style={{ width: '100%', height: '83vh' }}
            ></iframe>
          </Flex>
        </Modal>
      </Box>
    );
  };

  // set for capturing adobe document events
  useEffect(() => {
    if (isLoggedIn) fetchEsignURL();

    const eventHandler = (e: any) => {
      if (e.origin == ADOBE_E_SIGN_ORIGIN) {
        const data = JSON.parse(e.data);

        if (data.type === 'ESIGN') {
          setIsDocumentSigned(true);
          setProcessingAlert(true);
          setOpenESignModal(false);
          scrollTop();
        }

        if (data.type === 'REJECT') {
          setIsDocumentSigned(false);
          setOpenESignModal(false);
          handleError(t('phrase_request_sign_document'));
          scrollTop();
        }
      }
    };

    window.addEventListener('message', eventHandler);

    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  // used for checking if adobe document is signed successful at a interval of 10 secs
  useEffect(() => {
    if (processingAlert) {
      startPolling(E_SIGN_CHECK_INTERVAL);
    }
    return () => {
      stopPolling();
    };
  }, [processingAlert]);

  // if Logged IN, fetch offer details | fetch adobe eSign document | check if document already eSigned
  // if not Logged IN, fetch offer details
  useEffect(() => {
    if (isLoggedIn) {
      if (esignStatusAPI?.offerEsignStatus?.status) {
        getOfferDetails({
          variables: {
            id: applicantId,
            type: isOfferAccepted()
              ? OfferStatusEnum.COMPLETED
              : OfferStatusEnum.PROPOSED,
          },
        });
      }
    } else {
      if (accessTokens.tempToken) {
        getOfferDetails({
          variables: {
            id: accessTokens.exporterId,
            type: OfferStatusEnum.PROPOSED,
          },
          context: {
            headers: {
              authorization: `Bearer ${accessTokens.tempToken}` || '',
            },
          },
          onError: () => {
            handleError(t('title_error_message'));
          },
        });
      }
    }
  }, [accessTokens.tempToken, esignStatusAPI?.offerEsignStatus?.status]);

  if (offerLoading || esignLoading) {
    return <>{<CircularLoader />}</>;
  }

  return (
    <>
      {!isLoggedIn && renderUnauthenticatedOffer()}
      {isLoggedIn && renderOfferComponent()}
      {esignURL?.offerEsign?.url && renderESignIFrame()}
    </>
  );
};

export default INESignOffer;
