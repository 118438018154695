import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@dripcapital/dripui';
import { Spacer, Upload } from 'atoms';
import { ApplicationStatus } from 'api';
import { ProgressTypeEnum } from 'enums';
import { FILE_SIZE, SUPPORTED_FILES } from 'utils';
import { useReactiveVar } from '@apollo/client';
import { appDataVar } from 'config/server';

type Props = {
  applicationDetails: ApplicationStatus;
  refetch: () => void;
  isBDM: boolean;
};

const LegalRepresentative: React.FC<Props> = ({
  applicationDetails,
  refetch,
  isBDM,
}) => {
  const { t } = useTranslation();

  const { applicantId } = useReactiveVar(appDataVar);

  const renderTitle = () => {
    return (
      <Box dataTestId="legal-representative-section">
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" color="secondary" fontWeight="bold">
              {t('cldc_legal_representative_heading')}
            </Typography>
          </Grid>
        </Grid>
        <Spacer verticalSpace={2} />
      </Box>
    );
  };

  const uploadLegalRepresentativeDocuments = () => {
    const legalRepresentativeId =
      applicationDetails?.application?.legalDocs?.['legal-representative-id-mx']
        ?.files || [];

    const legalProofOfAddress =
      applicationDetails?.application?.legalDocs?.[
        'legal-representative-proof-of-address'
      ]?.files || [];
    return (
      <Grid
        container
        rowSpacing={3}
        dataTestId="legal-representative-documents"
      >
        <Grid item xs={12} dataTestId="passport-documents">
          <Upload
            title={t('cldc_legal_representative_title_passport')}
            subtitle={t('cldc_legal_representative_subtitle_passport')}
            buttonTitle={isBDM ? '' : t('btn_upload')}
            multiple={false}
            progressType={ProgressTypeEnum.CIRCULAR}
            uploads={legalRepresentativeId}
            fileLimit={FILE_SIZE}
            url={`${process.env.REACT_APP_REST_API_URL}/v1/onboarding/leads/${applicantId}/documents?tag=legal-representative-id-mx`}
            refetch={refetch}
            supportedFiles={SUPPORTED_FILES}
          />
        </Grid>
        <Grid item xs={12} dataTestId="address-documents">
          <Upload
            title={t('cldc_legal_representative_title_address')}
            subtitle={t('cldc_legal_representative_subtitle_address')}
            buttonTitle={isBDM ? '' : t('btn_upload')}
            multiple={false}
            progressType={ProgressTypeEnum.CIRCULAR}
            uploads={legalProofOfAddress}
            fileLimit={FILE_SIZE}
            url={`${process.env.REACT_APP_REST_API_URL}/v1/onboarding/leads/${applicantId}/documents?tag=legal-representative-proof-of-address`}
            refetch={refetch}
            supportedFiles={SUPPORTED_FILES}
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      {renderTitle()}
      {uploadLegalRepresentativeDocuments()}
    </>
  );
};

export default LegalRepresentative;
