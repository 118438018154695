import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Typography } from '@dripcapital/dripui';
import { Spacer } from 'atoms';
import { DAJ_COLORS } from 'utils';
import logo from 'assets/drip-logo.png';

type Props = object;

const NotFoundPage: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Flex
      dataTestId="not-found-screen"
      alignItems="center"
      justifyContent="center"
      _sx={{ minHeight: '60vh', backgroundColor: DAJ_COLORS.WHITE }}
    >
      <Box>
        <Flex justifyContent="center">
          <img
            src={logo}
            alt="drip"
            style={{
              width: 124,
              padding: 24,
            }}
          />
        </Flex>
        <Flex
          dataTestId="not-found-component"
          direction="row"
          alignItems="baseline"
          justifyContent="center"
        >
          <Typography
            color="secondary"
            fontWeight="bold"
            _sx={{ fontSize: 120, lineHeight: 0.8 }}
          >
            {t('nfe_number')}
          </Typography>
          <Typography variant="h3" color="secondary" fontWeight="bold">
            {t('nfe_not_found')}
          </Typography>
        </Flex>
        <Spacer verticalSpace={2} />
        <Typography variant="body1" color="secondary">
          {t('nfe_subtitle')}
        </Typography>
      </Box>
    </Flex>
  );
};

export default NotFoundPage;
