import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Grid, Typography, useTheme } from '@dripcapital/dripui';
import { Alert, Spacer } from 'atoms';
import { DAJ_COLORS, FONT } from 'utils';

type Props = {
  title?: string;
  alertTitle?: string;
  alertSubTitle?: string;
  basicDetails?: {
    value: React.ReactNode;
    label: string;
    labelInfo?: string;
  }[];
  moreDetailsTitle?: string;
  moreDetails?: {
    value: string;
    label: string;
    labelInfo?: string;
  }[];
  incorporationCertificateTitle?: string;
  incorporationCertificateDetails?: {
    value: React.ReactNode;
    label: string;
  }[];
  dbaTitle?: string;
  dbaDocs?: {
    value: React.ReactNode;
    label: string;
  }[];
  shareHoldersTitle?: string;
  shareHolders?: { email: string; companyName: string; ownerShip: string }[];
};

const USInfoCard: React.FC<Props> = ({
  title,
  alertTitle,
  alertSubTitle,
  basicDetails,
  moreDetailsTitle,
  moreDetails,
  incorporationCertificateTitle,
  incorporationCertificateDetails,
  shareHoldersTitle,
  shareHolders,
  dbaDocs,
  dbaTitle,
}: Props) => {
  const { t } = useTranslation();
  const { spacing } = useTheme();

  const renderMoreDetails = (
    moreDetails: { label: string; value: string; labelInfo?: string }[]
  ) => {
    return (
      <>
        {moreDetailsTitle && (
          <Typography
            variant="h4"
            fontWeight="bold"
            _sx={{ minWidth: spacing(27.5), paddingBottom: 1 }}
            color="secondary"
          >
            {moreDetailsTitle}
          </Typography>
        )}
        {moreDetails?.map((data, index) => {
          return (
            <Grid key={index} xs={12} item _sx={{ paddingBottom: 2 }}>
              <Flex>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  _sx={{ minWidth: spacing(27.5) }}
                  noWrap
                >
                  {data.label}
                  {data.labelInfo && (
                    <Typography variant="body1" fontWeight="bold">
                      {data.labelInfo}
                    </Typography>
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="regular"
                  _sx={{ fontSize: FONT.SIZES.F16 }}
                >
                  {data.value}
                </Typography>
              </Flex>
            </Grid>
          );
        })}
      </>
    );
  };

  const renderIncorporationCertificateDetails = (
    incorporationCertificateDetails: { label: string; value: React.ReactNode }[]
  ) => {
    return (
      <>
        {incorporationCertificateTitle && (
          <Typography
            variant="h4"
            fontWeight="bold"
            _sx={{ minWidth: spacing(27.5), paddingBottom: 1 }}
            color="secondary"
          >
            {incorporationCertificateTitle}
          </Typography>
        )}
        {incorporationCertificateDetails?.length &&
          incorporationCertificateDetails?.map((data, index) => {
            return (
              <Grid key={index} xs={12} item _sx={{ paddingBottom: 2 }}>
                <Flex>
                  {data.label && (
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      _sx={{ minWidth: spacing(27.5) }}
                    >
                      {data.label}
                    </Typography>
                  )}
                  {data.value}
                </Flex>
              </Grid>
            );
          })}
      </>
    );
  };

  const renderDBADocs = (
    dbaDocs: { label: string; value: React.ReactNode }[]
  ) => {
    return (
      <>
        {dbaTitle && (
          <Typography
            variant="h4"
            fontWeight="bold"
            _sx={{ minWidth: spacing(27.5), paddingBottom: 1 }}
            color="secondary"
          >
            {dbaTitle}
          </Typography>
        )}
        {dbaDocs?.length &&
          dbaDocs?.map((data, index) => {
            return (
              <Grid key={index} xs={12} item _sx={{ paddingBottom: 2 }}>
                <Flex>
                  {data.label && (
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      _sx={{ minWidth: spacing(27.5) }}
                    >
                      {data.label}
                    </Typography>
                  )}
                  {data.value}
                </Flex>
              </Grid>
            );
          })}
      </>
    );
  };

  const renderShareHolders = (
    shareHolders: { email: string; companyName: string; ownerShip: string }[]
  ) => {
    return (
      <>
        {shareHoldersTitle && (
          <Typography
            variant="h4"
            fontWeight="bold"
            _sx={{ minWidth: spacing(27.5), paddingBottom: 1 }}
            color="secondary"
          >
            {shareHoldersTitle}
          </Typography>
        )}
        {shareHolders?.map((data, index) => {
          return (
            <Grid
              key={index}
              xs={12}
              _sx={{
                background: DAJ_COLORS.ALICE_BLUE,
                padding: 2,
                marginBottom: 2,
                border: `1px solid ${DAJ_COLORS.SKY_BLUE_400}`,
                borderRadius: 2,
              }}
            >
              <Typography variant="h4" fontWeight="bold" color="secondary">
                {data.companyName}
              </Typography>
              <Spacer verticalSpace={1} />
              <Flex>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  _sx={{ minWidth: 180 }}
                  color="secondary"
                >
                  {t('label_email')}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="regular"
                  _sx={{ fontSize: FONT.SIZES.F16 }}
                >
                  {data.email}
                </Typography>
              </Flex>
              <Flex>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  _sx={{ minWidth: 180 }}
                  color="secondary"
                >
                  {t('label_company_ownership')}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="regular"
                  _sx={{ fontSize: FONT.SIZES.F16 }}
                >
                  {data.ownerShip}
                </Typography>
              </Flex>
            </Grid>
          );
        })}
      </>
    );
  };

  const renderBasicDetails = (
    basicDetails: {
      label: string;
      value: React.ReactNode;
      labelInfo?: string;
    }[]
  ) => {
    return (
      <>
        {basicDetails?.map((data, index) => {
          return (
            <Grid key={index} xs={12} item _sx={{ paddingBottom: 2 }}>
              <Flex>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  _sx={{ width: spacing(27.5) }}
                >
                  {data.label}
                  {data.labelInfo && (
                    <Typography variant="body1" fontWeight="bold">
                      {data.labelInfo}
                    </Typography>
                  )}
                </Typography>
                {data.value}
              </Flex>
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <Box dataTestId="company-info-section">
      <Grid container>
        <>
          {alertTitle && (
            <Grid item xs={12} _sx={{ mb: 2, mt: '6px' }}>
              <Alert severity="warning" title={alertTitle}>
                {alertSubTitle}
              </Alert>
            </Grid>
          )}
          {title && (
            <Grid xs={12} item>
              <Typography variant="h3" fontWeight="regular" color="secondary">
                {title}
              </Typography>
              <Spacer verticalSpace={2} />
            </Grid>
          )}
          {basicDetails && renderBasicDetails(basicDetails)}
          {incorporationCertificateDetails &&
            renderIncorporationCertificateDetails(
              incorporationCertificateDetails
            )}
          {dbaDocs && renderDBADocs(dbaDocs)}
          {moreDetails && renderMoreDetails(moreDetails)}
          {shareHolders && renderShareHolders(shareHolders)}
        </>
      </Grid>
    </Box>
  );
};

export default USInfoCard;
