import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@dripcapital/dripui';
import { Spacer } from 'atoms';
import { ApplicationStatus } from 'api';
import { AuthenticationStatusCard } from 'components';
import { AuthenticationStatusCardEnum } from 'enums';
import { LegalDocsType } from 'types';

type Props = {
  applicationDetails?: ApplicationStatus;
};

const UploadedDocumentList: React.FC<Props> = ({ applicationDetails }) => {
  const { t } = useTranslation();

  const renderUploadedDocument = (key: string, title: string) => {
    const legalDocs = applicationDetails?.application?.legalDocs[
      key
    ] as LegalDocsType;

    if (!legalDocs) {
      return null;
    }

    const isPending = legalDocs?.status === 'pending';

    if (isPending) {
      return (
        <AuthenticationStatusCard
          title={title}
          status={AuthenticationStatusCardEnum.PENDING}
        />
      );
    }

    return (
      <AuthenticationStatusCard
        title={title}
        file={[
          { name: legalDocs.files?.[0]?.name, url: legalDocs?.files?.[0]?.url },
        ]}
      />
    );
  };

  if (!applicationDetails) {
    return null;
  }

  return (
    <>
      <Grid dataTestId="company-uploaded-documents" container rowSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" color="secondary">
            {t('cldc_legal_document_heading')}
          </Typography>
          <Spacer verticalSpace={1} />
        </Grid>
        <Grid dataTestId="tax-uploaded-documents" item xs={12}>
          {renderUploadedDocument(
            'constancia-de-situación-fiscal-mx',
            t('cldc_legal_document_title_tax')
          )}
        </Grid>
        <Grid dataTestId="address-proof-uploaded-documents" item xs={12}>
          {renderUploadedDocument(
            'proof-of-address-mx',
            t('cldc_legal_document_title_company')
          )}
        </Grid>
        <Grid
          dataTestId="incorporation-certificate-uploaded-documents"
          item
          xs={12}
        >
          {renderUploadedDocument(
            'incorporation-certificate-mx',
            t('cldc_legal_document_title_incorporation')
          )}
        </Grid>
      </Grid>
      <Spacer verticalSpace={4} />
      <Grid
        dataTestId="legal-representative-uploaded-documents"
        container
        rowSpacing={3}
      >
        <Grid item xs={12}>
          <Typography variant="h4" color="secondary">
            {t('cldc_legal_representative_heading')}
          </Typography>
          <Spacer verticalSpace={1} />
        </Grid>
        <Grid dataTestId="passport-uploaded-documents" item xs={12}>
          {renderUploadedDocument(
            'legal-representative-id-mx',
            t('cldc_legal_representative_title_passport')
          )}
        </Grid>
        <Grid dataTestId="legal-address-proof-uploaded-documents" item xs={12}>
          {renderUploadedDocument(
            'legal-representative-proof-of-address',
            t('cldc_legal_representative_title_address')
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default UploadedDocumentList;
