import React, { useContext, useEffect } from 'react';
import { Box } from '@dripcapital/dripui';
import { Onfido } from 'onfido-sdk-ui';
import { AnalyticsContext } from 'shared/context/AnalyticsContext';

type Props = {
  token: string;
  uploadCompleted: () => void;
};

const OnFidoContainer: React.FC<Props> = ({
  token,
  uploadCompleted,
}: Props) => {
  const { mixpanel } = useContext(AnalyticsContext);

  useEffect(() => {
    Onfido.init({
      token,
      containerId: 'onfido-mount',
      useMemoryHistory: true,
      crossDevicePolicy: 'force',
      onComplete: function (data: any) {
        mixpanel.track('identity-navigation-verification-completed', {
          data: data,
        });
        uploadCompleted();
      },
      onError: function (error: any) {
        console.log(error);
        mixpanel.track('identity-navigation-verification-error', {
          error: error,
        });
      },
      steps: ['welcome', 'document', 'face', 'complete'],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      dataTestId="onfido-section"
      _sx={{
        width: '100%',
      }}
    >
      <Box
        id="onfido-mount"
        _sx={{
          padding: '14px',
          paddingTop: '0',
        }}
      />
    </Box>
  );
};

export default OnFidoContainer;
