import { lazy } from 'react';

const AuthRouteHOC = lazy(() => import('shared/hoc/AuthRouteHOC'));
const Home = lazy(() => import('containers/shared/Home'));
const Profile = lazy(() => import('containers/shared/Profile'));
const NotFound = lazy(() => import('components/ErrorPages/404'));

export const UserRouteMap = {
  AuthRouteHOC,
  Home,
  Profile,
  NotFound,
};

export const UserRoutePath: { [key: string]: string } = {
  generate: '/generate',
};
