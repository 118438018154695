import { gql } from '@apollo/client';

export const SUBMIT_APPLICATION = gql`
  mutation SubmitApplication(
    $clientMutationId: String
    $id: ID!
    $consent: Boolean!
  ) {
    submitApplication(
      input: { clientMutationId: $clientMutationId, id: $id, consent: $consent }
    ) {
      error {
        message
      }
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation updateStatus($id: ID!) {
    updateStatus(input: { id: $id }) {
      error {
        message
        path
      }
      message
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout($clientMutationId: String, $id: ID!) {
    logout(input: { clientMutationId: $clientMutationId, id: $id }) {
      error {
        message
      }
      message
    }
  }
`;

export const REMOVE_FILE = gql`
  mutation removeFile($input: RemoveDocumentInput!) {
    removeDocument(input: $input) {
      id
      message
      error {
        message
        path
      }
    }
  }
`;
