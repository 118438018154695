import { gql } from '@apollo/client';

export const GET_OWNERS = gql`
  query GetOwners($id: ID!) {
    owners(id: $id) {
      index
      name
      owned
      pan
      rfc
      legalRepresentative
      majorityShareholder
      secondaryShareholder
      gender
      dob {
        year
        day
        month
      }
    }
  }
`;

export const FINANCIAL_OWNERS = gql`
  query FinancialOwners($id: ID!) {
    owners(id: $id) {
      index
      name
      gender
      rfc
      legalRepresentative
      majorityShareholder
      secondaryShareholder
    }
  }
`;

export const GET_GST_DETAILS = gql`
  query getGstDetails($exporterId: ID!) {
    getGstDetails(userId: $exporterId) {
      credLink
      gstNo
      id
      recordStatus
    }
  }
`;

export const SHAREHOLDER_FINANCE = gql`
  query ShareholderFinance($id: ID!) {
    shareholders(id: $id) {
      indexNo
      name {
        firstname
        middlename
        lastname1
        lastname2
      }
      email
      rfc
      status
      gender
      shareholderId
      address {
        city
        country
        municipality
        state
        street
        zipcode
      }
      legalRepresentative
      majorityShareholder
      secondaryShareholder
      file {
        name
        url
      }
    }
  }
`;

export const COMPANY_FINANCE = gql`
  query CompanyFinance($id: ID!) {
    company(id: $id) {
      address {
        city
        country
        municipality
        state
        street
        zipcode
      }
      companyName
      email
      rfc
      status
      file {
        name
        url
      }
    }
  }
`;

export const GET_DUNS_STATUS = gql`
  query GetDunsStatus($duns: String!) {
    getDuns(duns: $duns) {
      businessIdentifierKey
      businessIdentifierValue
      companyName
      dripProfile {
        designation
        id
        profileType
      }
      id
      location {
        address
        country {
          name
        }
        state {
          name
        }
        zipCode
      }
      productCategory
      status
      typeOfGoods
    }
  }
`;

export const ESIGN_STATUS = gql`
  query EsignStatus($id: ID!, $shareholderId: String) {
    esignStatus(id: $id, shareholderId: $shareholderId) {
      esignStatus
    }
  }
`;

export const EsignCredentials = gql`
  query EsignCredentials($id: ID!, $shareholderId: String) {
    esignCredentials(id: $id, shareholderId: $shareholderId) {
      apiKey
      documentId
      signatoryId
    }
  }
`;
