import React from 'react';
import { Flex, useTheme } from '@dripcapital/dripui';
import { ButtonTypeEnum } from 'enums';
import { AtomicButton } from 'atoms';
import { DAJ_COLORS, FONT } from 'utils';

type Props = {
  labels: React.ReactNode[];
  tabSelected: number;
  setTabSelected: (num: number) => void;
};

const Pills: React.FC<Props> = ({
  labels,
  tabSelected,
  setTabSelected,
}: Props) => {
  const { spacing } = useTheme();

  return (
    <Flex
      dataTestId="multiple-tabs-section"
      justifyContent={'stretch'}
      _sx={{ minWidth: '100%' }}
    >
      {labels &&
        Array.isArray(labels) &&
        labels.map((label, buttonIndex) => (
          <Flex key={buttonIndex} flexGrow={1} dataTestId="pills-flex">
            <AtomicButton
              dataTestId="pill-button-element"
              variant={
                tabSelected === buttonIndex
                  ? ButtonTypeEnum.CONTAINED
                  : ButtonTypeEnum.OUTLINED
              }
              onClick={() => {
                setTabSelected(buttonIndex);
              }}
              _sx={{
                whiteSpace: 'nowrap',
                height: spacing(6),
                marginRight: 1.2,
                paddingX: 2,
                fontSize: FONT.SIZES.F16,
                width: '100%',
                color: DAJ_COLORS.BLUE,
                backgroundColor:
                  tabSelected === buttonIndex
                    ? DAJ_COLORS.NOBLE_BLUE
                    : DAJ_COLORS.WHITE,
                borderColor: DAJ_COLORS.SKY_BLUE_400,
                '&:focus': {
                  color:
                    tabSelected === buttonIndex ? DAJ_COLORS.WHITE : 'initial',
                },
                '&:hover': {
                  color:
                    tabSelected === buttonIndex ? DAJ_COLORS.WHITE : 'initial',
                },
              }}
            >
              {label}
            </AtomicButton>
          </Flex>
        ))}
    </Flex>
  );
};

export default Pills;
