import mixpanel, { Dict } from 'mixpanel-browser';

export default {
  init: (mixpanelToken: string | undefined) => {
    // different tokens for prod and staging to be present on the server
    mixpanel.init(mixpanelToken as string, {
      debug: process.env.NODE_ENV !== 'production',
    });
    console.log(
      '%cMixpanel Initialized',
      'font-size: 1em; padding: 0.25em 1em; margin: 0.25em 0; color: white; background-color: #26ac75; border-radius: 6px;'
    );
  },
  track: (event_name: string, data?: Dict) => {
    mixpanel.track(event_name, data);
  },
  identify: (user_profile: string) => {
    mixpanel.identify(user_profile);
  },
  set_once: (user_data: object) => {
    mixpanel?.people?.set_once(user_data);
  },
};
