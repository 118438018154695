export const IN_BUYER_INITIAL_VALUES = {
  name: '',
  address: '',
  country: '',
  idType: '',
  idNumber: '',
  rfc: '',
  paymentTerms: '',
  paymentDays: '',
  currency: '',
  documentSendType: '',
  documentReleaseType: '',
  canSubmitAllBill: false,
  contactName: '',
  contactEmail: '',
  contactPhoneNumber: {
    countryCode: 'IN-91',
    phoneNumber: '',
  },
  pastSales: '',
  paymentTenor: '',
  hasInvoice: false,
  dripLimit: '',
  addToOfferProfile: 'yes',
  operationDrip: '',
};

export const MX_BUYER_INITIAL_VALUES = {
  name: '',
  address: '',
  country: '',
  rfc: '',
  currency: '',
  contactName: '',
  contactEmail: '',
  contactPhoneNumber: {
    countryCode: 'MX-52',
    phoneNumber: '',
  },
  paymentTenor: '',
  paymentDays: '',
  dripLimit: '',
  hasInvoice: true,
};

export const UNIQUE_IDENTIFIERS_OPTIONS = [
  { label: 'label_pan_card', value: 'pan' },
  { label: 'label_aadhaar_card', value: 'aadhaar' },
];

export const BOL_OPTIONS = [
  {
    label: 'chips_yes',
    value: 'yes',
  },
  {
    label: 'chips_no',
    value: 'no',
  },
];

export const UPLOAD_OPTIONS = [
  {
    label: 'chips_yes',
    value: true,
  },
  {
    label: 'chips_no',
    value: false,
  },
];

export const BOOL_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const DATE_TYPE_OPTIONS = [
  { label: 'options_invoice_date', value: '1' },
  { label: 'options_bl_date', value: '2' },
  { label: 'options_fda_clearance_date', value: '3' },
  { label: 'options_discharge_date', value: '5' },
  { label: 'options_good_usage_date', value: '6' },
];
