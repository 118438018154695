import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Checkbox,
  CheckboxProps,
  FormGroup,
  FormControl,
  FormHelperText,
} from '@dripcapital/dripui';
import { FieldProps } from 'formik';

type CheckboxForFormikProps = Pick<
  CheckboxProps,
  '_sx' | 'className' | 'size'
> &
  Pick<FieldProps, 'field' | 'form'> & {
    label?: string;
    row?: boolean;
    fullWidth?: boolean;
    options: {
      value: boolean;
      label: string;
    }[];
  };

const RadioForFormikAlternate = ({
  form,
  field,
  label,
  row,
  options,
  size,
  fullWidth = true,
  _sx,
  className = '',
}: CheckboxForFormikProps) => {
  const { t } = useTranslation();

  const { name } = field;

  const { values, setValues } = form;

  const { error, touched } = form.getFieldMeta(name);

  const [checkBoxValues, setCheckBoxValues] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (values) {
      setCheckBoxValues(values[name]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[name]]);

  return (
    <FormControl error={!!(error && touched)} fullWidth={fullWidth}>
      {label && (
        <Typography
          dataTestId="checkbox-label"
          variant="subtitle1"
          _sx={{ marginBottom: 1 }}
        >
          {label}
        </Typography>
      )}
      <FormGroup row={row}>
        {options.map(({ value: optionValue, label }, index) => (
          <Checkbox
            dataTestId="checkbox-element"
            {...field}
            _sx={_sx}
            className={className}
            size={size}
            key={index}
            label={label}
            name={`${name}.${label}`}
            value={optionValue}
            checked={checkBoxValues === optionValue}
            onChange={() => {
              console.debug(optionValue);
              setCheckBoxValues(optionValue);
              setValues({
                ...values,
                [name]: optionValue,
                // [name]: Object.keys(checkBoxValues).filter(
                //   (val) => checkBoxValues[val]
                // ),
              });
            }}
          />
        ))}
      </FormGroup>
      {error && touched && <FormHelperText>{t(`${error}`)}</FormHelperText>}
    </FormControl>
  );
};

export default RadioForFormikAlternate;
