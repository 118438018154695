import React from 'react';
import { Box, Typography } from '@dripcapital/dripui';
import { useTranslation } from 'react-i18next';
import logo from 'assets/drip-logo.png';

type Props = object;

const AuthHeading: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Box dataTestId="auth-headers">
      <Box>
        <img src={logo} alt="drip" />
      </Box>
      <Box _sx={{ padding: 4 }}></Box>
      <Typography variant="h1" fontWeight="bold" color="secondary">
        {t('su_title')}
      </Typography>
      <Typography variant="subtitle1">{t('su_subtitle')}</Typography>
      <Box _sx={{ padding: 2 }}></Box>
    </Box>
  );
};

export default AuthHeading;
