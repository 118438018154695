import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, COLORS, Flex, Typography } from '@dripcapital/dripui';
import { Error } from '@mui/icons-material';
import { Spacer } from 'atoms';
import { AuthenticationInfoCard } from 'components';
import { AuthenticationStatus } from 'types';
import { CibilStatus } from 'enums';

type Props = {
  isBDM?: boolean;
  authenticateCibil: () => void;
  status: AuthenticationStatus;
};

const CIBILCard: React.FC<Props> = ({
  isBDM,
  authenticateCibil,
  status,
}: Props) => {
  const { t } = useTranslation();

  const renderAlert = () => {
    if (isBDM) {
      if (status === CibilStatus.COMPLETED) {
        return undefined;
      }
      return (
        <Flex _sx={{ marginTop: 1, color: COLORS.SECONDARY }}>
          <Error color="inherit"></Error>
          <Typography _sx={{ marginX: 1 }} fontWeight="bold">
            {t('irfi_cibil_not_auth_alert')}
          </Typography>
        </Flex>
      );
    }
    return undefined;
  };

  return (
    <Box dataTestId="cibil-auth-card">
      <Typography variant="h3" color="secondary" fontWeight="bold">
        {t('irfi_cibil_info_optional')}
      </Typography>
      <Spacer verticalSpace={2} />
      <AuthenticationInfoCard
        title={t('irfi_cibil_info')}
        subTitle={isBDM ? t('irfi_cibil_auth_subtitle') : ''}
        itemList={
          !isBDM
            ? [
                t('irfi_cibil_list_3'),
                t('irfi_cibil_list_4'),
                t('irfi_cibil_list_5'),
              ]
            : undefined
        }
        buttonText={
          isBDM
            ? ''
            : !status
            ? t('irfi_cibil_auth_btn')
            : status === CibilStatus.PROGRESS
            ? t('irfi_cibil_auth_again_btn')
            : ''
        }
        status={isBDM ? undefined : status}
        subText={t('irfi_cibil_auth_sub_text')}
        onClick={isBDM ? undefined : () => authenticateCibil()}
      >
        {renderAlert()}
      </AuthenticationInfoCard>
    </Box>
  );
};

export default CIBILCard;
